import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { BoardButtonPage } from './board-button.page';
import { ColorPickerFieldPageModule } from '../color-picker-field/color-picker-field.module';

const routes: Routes = [
    {
        path: 'board-button',
        component: BoardButtonPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DatePickerModule,
        DateTimePickerModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        ColorPickerFieldPageModule
    ],
    declarations: [BoardButtonPage],
    exports: [BoardButtonPage]
})
export class BoardButtonPageModule {}
