import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { ColorPickerPageModule } from '../components/color-picker/color-picker.module';
import { IonicModule } from '@ionic/angular';
import { DeskDetailPage } from './desk-detail.page';
import { UserObjectPageModule } from '../components/user-object/user-object.module';
import { FilterPageModule } from '../components/filter/filter.module';
import { ListDisplayPageModule } from '../components/list-display/list-display.module';
import { OrderByPageModule } from '../components/order-by/order-by.module';
import { TranslateModule } from '@ngx-translate/core';
import { CallbackPageModule } from '../pipes/callback';
import { BoardButtonPageModule } from '../components/board-button/board-button.module';
import { HierarchyFieldPageModule } from '../components/hierarchy-field/hierarchy-field.module';

const routes: Routes = [
    {
        path: 'desk-details',
        component: DeskDetailPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ColorPickerPageModule,
        UserObjectPageModule,
        FilterPageModule,
        ListDisplayPageModule,
        OrderByPageModule,
        TranslateModule,
        CallbackPageModule,
        BoardButtonPageModule,
        HierarchyFieldPageModule
    ],
    declarations: [DeskDetailPage]
})
export class DeskDetailPageModule {}
