import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserDetailPage } from '../../user-detail/user-detail.page';

@Component({
    selector: 'app-user-photo',
    templateUrl: './user-photo.page.html',
    styleUrls: ['./user-photo.page.scss'],
})
export class UserPhotoPage implements OnInit {

    @Input() user: any;
    @Input() fullName: any;
    @Input() paddingTop: string;
    @Input() canOpenProfile: boolean;
    @Input() size = 32;
    @Input() showPhoto = false;
    col = {
        name: 'user',
        float: 'left',
        isDataColor: true,
    };

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {
        if (!this.paddingTop) {
            this.paddingTop = '10px';
        }

        if (this.user && this.user.options && this.user.options.user_image) {
            this.user.options.user_image = this.toImage(this.user.options.user_image);
        }
        if (this.user && this.user.image) {
            this.user.image = this.toImage(this.user.image);
        }
    }

    toImage(img: string): string {
        if (img.startsWith('image/upload/')) {
            return img.slice(13);
        }
        return img;
    }

    async openProfile() {
        if (!this.canOpenProfile || !this.user) {
            return;
        }
        const modal = await this.modalCtrl.create({
            component: UserDetailPage,
            componentProps: { user: this.user } ,
            cssClass: 'modal-item-detail-user'
        });
        return await modal.present();
    }

}
