import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { CustomClassPage } from 'src/app/custom-class/custom-class.page';
import { ModalController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';

@Component({
    selector: 'app-class-widget',
    templateUrl: './class-widget.page.html',
    styleUrls: ['./class-widget.page.scss'],
})
export class ClassWidgetPage implements OnInit {

    constructor(private base: BaseService, private modalCtrl: ModalController) { }

    @Input() w: any;
    @Output() change = new EventEmitter();
    classPk: any;

    ngOnInit() {
        this.classPk = this.base.getTablePk('Class');
    }

    async createClass(w, cls?) {

        let c = null;
        if (cls && cls.value) {
            c = this.base.getClass(cls.value, false);
        }
        const modal = await this.modalCtrl.create({
            component: CustomClassPage,
            componentProps: {item: c},
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.item) {
                if (!w.find(i => i.value === detail.item[this.classPk])) {
                    w.splice(w.length, 0, {value: detail.item[this.classPk], name: detail.item.name});
                }
            }
        });
        return await modal.present();
    }

    async addClass(w) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Class',
            },
            backdropDismiss: true,
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                w.splice(w.length, 0, {value: details.data.item[this.classPk], name: details.data.item.name});
                this.onChange();
            }
        });
        return await modal.present();
    }

    onChange() {
        this.change.emit();
    }
}
