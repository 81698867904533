import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BotService } from 'src/app/services/bot.service';
import { BaseService } from 'src/app/services/base.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Equal } from 'src/app/classes/enums';

@Component({
    selector: 'app-botgroup',
    templateUrl: './botgroup.page.html',
    styleUrls: ['./botgroup.page.scss'],
})
export class BotgroupPage implements OnInit {

    @ViewChild('name') name;
    @Input() item: any = {};
    canRequest = true;

    constructor(private modalCtrl: ModalController,
                private botService: BotService,
                private base: BaseService,
                private trans: TranslateService) { }

    ngOnInit() {
        if (this.botService.isEmpty(this.item)) {
            this.item.active = true;
        }
        setTimeout(() => {
            this.name.setFocus();
        }, 500);
    }

    save() {
        if (!this.item.name) {
            this.base.sendToast(this.trans.instant('name-is-required'));
            return;
        }
        if (!this.item.command) {
            this.base.sendToast(this.trans.instant('command-is-required'));
            return;
        }
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        if (this.item.active) {
            const filters = [
                {name: 'active', equal: Equal.EQ, value: true},
                {name: 'command', euqal: Equal.EQ, value: this.item.command},
            ];
            if (this.item._id) {
                filters.push({name: '_id', equal: Equal.NOT, value: this.item._id});
            }
            this.botService.get(this.botService.botgroupColl, 1, 1, `&filter_fields=${JSON.stringify(filters)}`)
            .pipe(
                finalize(() => {
                    this.canRequest = true;
                })
            )
            .subscribe(resp => {
                if (resp && resp.data && resp.data.length) {
                    this.base.sendToast(this.trans.instant('command-already-used', {command: this.item.command, name: resp.data[0].name}));
                } else {
                    this.saveAfterProve();
                }
            });
        } else {
            this.saveAfterProve();
        }
    }

    saveAfterProve() {
        this.canRequest = false;
        if (this.item._id) {
            this.botService.patch(this.item, this.item._id, this.botService.botgroupColl)
            .pipe(
                finalize(() => {
                    this.canRequest = true;
                })
            )
            .subscribe(resp => {
                if (resp._id) {
                    this.close();
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        } else {
            this.botService.post(this.item, this.botService.botgroupColl)
            .pipe(
                finalize(() => {
                    this.canRequest = true;
                })
            )
            .subscribe(resp => {
                if (resp._id) {
                    this.item._id = resp._id;
                    this.close();
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        }
    }

    close() {
        this.modalCtrl.dismiss({item: this.item});
    }
}
