import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BotgroupListPage } from './botgroup-list.page';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: 'bot-group-list',
        component: BotgroupListPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule
    ],
    declarations: [BotgroupListPage],
    exports: [BotgroupListPage]
})
export class BotgroupListPageModule {}
