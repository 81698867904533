import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ListPage } from './list.page';
import { DragulaModule } from 'ng2-dragula';
import { MenuPageModule } from '../menu/menu.module';
import { VisePageModule } from '../vise/vise.module';
import { MessagePageModule } from '../message/message.module';
import { UserPhotoPageModule } from '../components/user-photo/user-photo.module';
import { InfoGraphPageModule } from '../info-graph/info-graph.module';
import { FormPageModule } from '../form/form.module';
import { DeskDetailPageModule } from '../desk-detail/desk-detail.module';
import { CellPageModule } from '../components/cell/cell.module';
import { TranslateModule } from '@ngx-translate/core';
import { ExportToExcelPageModule } from '../components/export-to-excel/export-to-excel.module';
import { ScannerPageModule } from '../components/scanner/scanner.module';
import { IonicSwipeAllModule } from 'ionic-swipe-all';
import { DateRangePageModule } from '../components/date-range/date-range.module';
import { ImportFromExcelPageModule } from '../components/import-from-excel/import-from-excel.module';
import { ImportImagesPageModule } from '../components/import-images/import-images.module';
import { MapPageModule } from '../components/map/map.module';
import { HierarchyRowPageModule } from '../components/hierarchy-row/hierarchy-row.module';

const routes: Routes = [
    {
        path: 'model/:table',
        component: ListPage
    },
    {
        path: 'model/:table/:pk',
        component: ListPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        DragulaModule,
        MenuPageModule,
        VisePageModule,
        MessagePageModule,
        UserPhotoPageModule,
        InfoGraphPageModule,
        FormPageModule,
        DeskDetailPageModule,
        CellPageModule,
        TranslateModule,
        ExportToExcelPageModule,
        ScannerPageModule,
        IonicSwipeAllModule,
        DateRangePageModule,
        ImportFromExcelPageModule,
        ImportImagesPageModule,
        MapPageModule,
        HierarchyRowPageModule
    ],
    declarations: [ListPage],
    exports: [ListPage]
})
export class ListPageModule {}
