import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../../services/base.service';
import { AlertController, PopoverController } from '@ionic/angular';
import { ColorPickerPage } from '../../components/color-picker/color-picker.page';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-object',
    templateUrl: './user-object.page.html',
    styleUrls: ['./user-object.page.scss'],
})
export class UserObjectPage implements OnInit {

    @Input() obj: any;
    @Input() isShort = false;
    @Input() table: string;

    constructor(private base: BaseService, 
                private alertCtrl: AlertController, 
                private popoverCtrl: PopoverController,
                private trans: TranslateService) { }

    ngOnInit() {
    }

    async showColorPicker(ev, isPicker) {
        const popover = await this.popoverCtrl.create({
            component: ColorPickerPage,
            componentProps: {color: this.obj.options, isPicker, closeAfterSelect: true},
            showBackdrop: false,
            cssClass: 'color-picker-popover',
            event: ev
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.changed && this.isShort) {
                this.save();
            }
        });
        return await popover.present();  
    }

    save() {
        this.base.updateObj(this.obj, this.table).subscribe(_ => {});
    }

    async addDescription() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('note'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    id: 'name2-id',
                    placeholder: this.trans.instant('note'),
                    value: this.obj.options.description,
                },
            ],
            buttons: [{
                text: this.trans.instant('ok'),
                handler: (params) => {
                    this.obj.options.description = params.name;
                    this.save();
                }
            }]
        });
        await alert.present();
        setTimeout(() => {
            const e = document.getElementById('name2-id');
            if (e) {
                e.focus();
            }
        }, 500);
    }
}
