import { Base } from "./base";
import { BoardFilter } from "./board-filters";
import { ChartType, Equal, FieldType, FunctionType, Interval } from "./enums";
import * as uuid from 'uuid';
import { TranslateService } from "@ngx-translate/core";

export class ChartColor {

    constructor(public name: string,
                public value: string) {
    }

    serialize(): any {
        return {
            name: this.name,
            value: this.value
        };
    }

    static fromJson(d: any): ChartColor {
        return new ChartColor(d.name, d.value);
    }
}


export class Chart {

    constructor(public type: ChartType,
                public isAsc: boolean,
                public groupBy: any,
                public groupBySub: Interval,
                public func: FunctionType,
                public aggr: any, 
                public filters: BoardFilter[],
                public groupBy2: any,
                public groupBy2Count: number,
                public groupByCount: number,
                public isAsc2: boolean,
                public groupBySub2: Interval,
                public name: string,
                public xAxis: boolean,
                public yAxis: boolean,
                public toPercent: boolean,
                public customColorsShadow: ChartColor[],
                public customColors: ChartColor[],
                public data: any[],
                public pk: string,
                public xAxisTicks: any[], 
                public placeholder: string,
                public xLabel?: string,
                public yLabel?: string,
                public allValue?: number,
                public tooltip?: string,
                public groupByField?: string,
                public groupBy2Field?: string,
                public aggrField?: string) {

        if (this.customColorsShadow && !this.customColorsShadow.length) {
            this.customColorsShadow = null;
        }
        if (!this.pk) {
            this.pk = uuid.v4();
        }
    }

    changeOrder(): void {
        this.isAsc = !this.isAsc;
    }

    changeOrder2(): void {
        this.isAsc2 = !this.isAsc2;
    }

    serialize(): any {
        return {
            type: this.type,
            isAsc: this.isAsc,
            groupBy: this.groupBy,
            groupBySub: this.groupBySub,
            function: this.func,
            aggr: this.aggr, 
            filters: BoardFilter.toList(this.filters),
            groupBy2: this.groupBy2,
            groupBy2Count: this.groupBy2Count,
            groupByCount: this.groupByCount,
            isAsc2: this.isAsc,
            groupBySub2: this.groupBySub2,
            name: this.name,
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            toPercent: this.toPercent,
            customColorsShadow: this.customColorsShadow ? this.customColorsShadow.map(f => f.serialize()) : null,
            customColors: this.customColors.map(f => f.serialize()),
            pk: this.pk,
            groupByField: this.groupByField,
            groupBy2Field: this.groupBy2Field,
            aggrField: this.aggrField
        }
    }

    addCustomColor(name: string, value: string) {
        if (!this.customColors.find(c => c.name === name || c.value === value)) {
            this.customColors.splice(this.customColors.length, 0, new ChartColor(name, value));
        };
    }

    copy(): Chart {
        return Chart.fromJson(Base.copy(this.serialize()));
    }

    get hasSeries(): boolean {
        return [ChartType.PIE, ChartType.ADVANCED_PIE, ChartType.PIE_GRID, ChartType.VERTICAL_BAR, 
                ChartType.HORIZONTAL_BAR, ChartType.NUMBER_CARD, ChartType.TREE_MAP].indexOf(this.type) === -1;
    }
    
    get isSort(): boolean {
        return [ChartType.PIE, ChartType.ADVANCED_PIE, ChartType.PIE_GRID, ChartType.NUMBER_CARD, ChartType.TREE_MAP].indexOf(this.type) !== -1;
    }

    getTitle(trans: TranslateService, tableTitle: string): string {
        
        let title  = this.func === FunctionType.SUM && this.aggr && this.aggr.name ? this.aggr.verbose_name || this.aggr.name : trans.instant('count');
        title += ' ' + tableTitle;
        
        if (!this.filters.length) {
            return title;
        }

        const filters = this.filters.filter(i => i.isChecked);
        if (!this.filters.length) {
            return title;
        }

        const joins = [];
        for (const f of filters) {
            let v = f.repr || f.value;
            
            switch (f.type) {
            case FieldType.BOOL:
                v = v ? f.verboseName : trans.instant('not') + ` ${f.verboseName}`;
                break;
            case FieldType.DATE:
            case FieldType.DATETIME:
                v = v ? v.slice(0, 10) : v;
                break;
            case FieldType.INT:
                if (f.choices.length) {
                    const choice = f.choices.find(c => c.id === v);
                    if (choice) {
                        v = choice.title;
                    }
                }
                break;
            }

            if (f.equal !== Equal.EQ) {
                v = (f.equal === Equal.NOT ? '≠' : f.equal) + ' ' + v;
            }
            joins.push(v);
        }
        return title + ` ${trans.instant('with')} ` + joins.join(', ');
    }

    static fromJson(d: any): Chart {
        return new Chart(d.type, d.isAsc, d.groupBy, d.groupBySub, d.function, d.aggr, (d.filters || []).map(f =>  BoardFilter.fromJson(f)),
                         d.groupBy2, d.groupBy2Count, d.groupByCount, d.isAsc2, d.groupBySub2, d.name, d.xAxis, d.yAxis, d.toPercent,
                         (d.customColorsShadow || []).map(f => ChartColor.fromJson(f)),
                         (d.customColors || []).map(f => ChartColor.fromJson(f)), [], d.pk, [], null, null, null, null, null, d.groupByField,
                         d.groupBy2Field, d.aggrField)
    }

    static default(): Chart {
        return new Chart(ChartType.PIE, true, null, Interval.DAY, FunctionType.COUNT, null, [], null, 15, 15, true, Interval.DAY, null, false, false, false, [], [], [], null, [], null);
    }
}
