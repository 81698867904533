import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from './user.service';
import { BaseService } from '../services/base.service';
import { NavParams, ModalController } from '@ionic/angular';
import { Base } from '../classes/base';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.page.html',
    styleUrls: ['./user-detail.page.scss'],
})
export class UserDetailPage implements OnInit {

    user: any;
    userProfile: any;
    isMe = false;
    @ViewChild('photo') photo: ElementRef;

    constructor(private userService: UserService, 
                private base: BaseService, 
                private navParams: NavParams,
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.userProfile = this.navParams.get('user');
        this.user = Base.getCurrentUser();
        const pk = this.base.getTablePk('User');
        this.isMe = !this.userProfile || this.userProfile[pk] === this.user[pk];
        if (this.userProfile) {
            this.user = this.userProfile;
        }
    }

    ionViewWillLeave() {
        this.base.updateObj(this.user, 'User').subscribe(_ => {
            const currentUser = Base.getCurrentUser(); 
            const pk = this.base.getTablePk('User');
            if (this.user[pk] === currentUser[pk]) {
                currentUser.options = this.user.options;
                this.base.setCurrentUser(currentUser);
            }
        });
    }

    onFormSubmit(data) {
        this.userService.updateUser(data).subscribe(resp => {
            if (!resp) { 
                return; 
            }
            if (resp[this.base.getTablePk('User')]) {
                this.closeModal();
            } else {
                this.base.sendToast(this.base.getError(resp.error));
            }
        });
    }
    
    closeModal() {
        this.modalCtrl.dismiss({});
    }

    loadFile(ev) {
        const file = ev.target.files[0];
        this.base.uploadFile(file).subscribe(resp => {
            if (resp && resp.url) {
                this.user.options.user_image = resp.url;
            } else if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    chooseFile() {
        this.photo.nativeElement.click();
    }
}
