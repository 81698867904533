export class Base {
    static currentUser: any;

    static getCurrentUser() {
        if (Base.currentUser) {
            return Base.currentUser;
        }
        const u = localStorage.getItem('currentUser');
        if (u) {
            try {
                Base.currentUser = JSON.parse(u) || null;
            } catch {
            }
        }
        return Base.currentUser;
    }

    static copy(val: any): any {
        if (val === undefined) {
            return undefined;
        }
        return JSON.parse(JSON.stringify(val));
    }

    static isEqual(now, been): boolean {
        if (now && been && typeof(now) === 'object' && typeof(been) === 'object') {
            const keys = [];
            for (const key of Object.keys(now)) {
                if (!this.isEqual(now[key], been[key])) {
                    return false;
                }
                keys.push(key);
            }
            for (const key of Object.keys(been)) {
                if (keys.find(k => k === key)) {
                    continue;
                }
                if (!this.isEqual(now[key], been[key])) {
                    return false;
                }
            }
        } else if ((now && !been) || (been && !now)) {
            return false;
        } else if (!now && !been) {
            return true;    
        } else if (now !== been) {
            return false;
        }
        return true;
    }

    static toFloat(val) {
        if (!val) {
            return 0;
        }
        return (typeof val === 'string' ? parseFloat(val) : val) || 0;
    }
}
