import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormPage } from './form.page';
import { VisePageModule } from '../vise/vise.module';
import { MenuPageModule } from '../menu/menu.module';
import { FormFieldPageModule } from '../components/form-field/form-field.module';
import { ClassWidgetPageModule } from '../components/class-widget/class-widget.module';
import { StyleWidgetPageModule } from '../components/style-widget/style-widget.module';
import { RowWidgetPageModule } from '../components/row-widget/row-widget.module';
import { ChatPageModule } from '../components/chat/chat.module';
import { ChoicesPageModule } from '../choices/choices.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonWidgetPageModule } from '../components/button-widget/button-widget.module';
import { ExportToExcelPageModule } from '../components/export-to-excel/export-to-excel.module';
import { CatalogPageModule } from '../components/catalog/catalog.module';
import { CallbackPageModule } from '../pipes/callback';
import { RelatedTableFormSettingPageModule } from '../components/related-table-form-setting/related-table-form-setting.module';
import { ColorPickerFieldPageModule } from '../components/color-picker-field/color-picker-field.module';
import { HierarchyPageModule } from '../components/hierarchy/hierarchy.module';

const routes: Routes = [
    {
        path: 'form',
        component: FormPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        VisePageModule,
        ChatPageModule,
        MenuPageModule,
        FormFieldPageModule,
        ClassWidgetPageModule,
        StyleWidgetPageModule,
        RowWidgetPageModule,
        ChoicesPageModule,
        RouterModule.forChild(routes),
        TranslateModule,
        ButtonWidgetPageModule,
        ExportToExcelPageModule,
        CatalogPageModule,
        CallbackPageModule,
        RelatedTableFormSettingPageModule,
        ColorPickerFieldPageModule,
        HierarchyPageModule
    ],
    declarations: [FormPage]
})
export class FormPageModule {}
