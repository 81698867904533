import { Component, OnInit } from '@angular/core';
import { BotService } from 'src/app/services/bot.service';
import { BaseService } from 'src/app/services/base.service';
import { finalize } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { BotgroupPage } from '../botgroup/botgroup.page';
import { Constants } from 'src/app/classes/app.constants';

@Component({
    selector: 'app-botgroup-list',
    templateUrl: './botgroup-list.page.html',
    styleUrls: ['./botgroup-list.page.scss'],
})
export class BotgroupListPage implements OnInit {

    canRequest = true;
    scrollComplete = true;
    page = 1;
    data = []; 
    hasNext = false;

    constructor(private botService: BotService,
                private base: BaseService,
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.loadData();
    }

    loadData(infiniteScroll?) {
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        this.scrollComplete = this.page !== 1;

        this.botService.get(this.botService.botgroupColl, this.page, Constants.ON_PAGE, '')
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                    this.canRequest = true;
                    if (infiniteScroll) {
                        infiniteScroll.target.complete();
                    }
                }),
            )
            .subscribe(resp => {
                if (resp && resp.data) {
                    if (!this.data || this.page === 1) {
                        this.data = resp.data;
                    } else {
                        for (const item of resp.data) {
                            this.data.splice(this.data.length, 0, item);
                        }
                    }
                    this.hasNext = resp.has_next;
                    if (this.hasNext) { 
                        this.page ++; 
                    } 
                }
            });
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    choose(item) {
        this.modalCtrl.dismiss({item});
    }

    async open(item?) {
        if (!item) {
            item = {};
        }
        const modal = await this.modalCtrl.create({
            component: BotgroupPage,
            componentProps: {
                item,
            },
            showBackdrop: false,
            cssClass: 'modal-botgroup'
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item && details.data.item._id) {
                this.choose(details.data.item);
            }
        });
        return await modal.present();
    }
}
