import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-cell',
    templateUrl: './cell.page.html',
    styleUrls: ['./cell.page.scss'],
})
export class CellPage implements OnInit {

    @Input() showFilters: any = false;
    @Input() showFormating: any = false;
    @Input() o: any;
    @Input() col: any;
    @Input() isCard = false;
    @Input() hideImages = false;
    @Output() addFilterEvent = new EventEmitter();
    @Output() openFormatingEvent = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    addFilter(col, o) {
        this.addFilterEvent.emit([col, o]);
    }

    openFormating(col, o) {
        this.openFormatingEvent.emit([col, o]);
    }
}
