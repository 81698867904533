import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { BoardFilter } from 'src/app/classes/board-filters';
import { BotProcess, GroupBotProcess, BotProcessBind, MessageType, Variable, BotProcessButton, ActionType, Bot, BotProcessField, Translation, WebApp } from 'src/app/classes/botprocess';
import { Equal, FieldType, TableAction } from 'src/app/classes/enums';
import { ProcessVersion } from 'src/app/classes/processversion';
import { FormPage } from 'src/app/form/form.page';
import { BaseService } from 'src/app/services/base.service';
import { MetaService } from 'src/app/services/meta.service';
import { BotProcessBindPage } from '../bot-process-bind/bot-process-bind.page';

@Component({
    selector: 'app-bot-process-form',
    templateUrl: './bot-process-form.page.html',
    styleUrls: ['./bot-process-form.page.scss'],
})
export class BotProcessFormPage implements OnInit {

    @Input() fromId: BotProcess;
    @Input() process: BotProcess;
    @Input() group: GroupBotProcess;
    @Input() binds: BotProcessBind[];
    @Input() processes: BotProcess[];

    fromFields = [];
    messageTypes = [];
    actionTypes = [];
    userStatuses = [];
    tables = [];
    tab: number = 0;
    CONDITIONS_TAB = 0;
    BUTTONS_TAB = 1;
    ACTION_TAB = 2;
    ActionType = ActionType;
    FieldType = FieldType;
    connections: BotProcessBind[] = [];
    isDo: boolean = false;

    constructor(private base: BaseService,
                private modalCtrl: ModalController,
                private alertCtrl: AlertController,
                private trans: TranslateService,
                private meta: MetaService) { }

    ngOnInit() {
        for (const key in MessageType) {
            if (!isNaN(Number(key))) {
                continue;
            }
            this.messageTypes.splice(this.messageTypes.length, 0, {title: key, id: MessageType[key]})
        }

        this.connections = this.binds.filter(b => (b.fromId && b.fromId.id === this.process.id) || (b.toId && b.toId.id === this.process.id))

        this.messageTypes.splice(0, 0, {id: null, title: '-----'});

        for (const key in ActionType) {
            if (!isNaN(Number(key))) {
                continue;
            }

            if ([ActionType.ADD, ActionType.CHANGE, ActionType.DELETE, ActionType.CHOOSE, ActionType.SAVE_LOCAL, 
                 ActionType.SAVE_FROM, ActionType.SEND_QR, ActionType.HTTP_GET, ActionType.HTTP_POST, ActionType.HTTP_PATCH, 
                 ActionType.SEND_INVOICE, ActionType.ANSWER_SHIPPING_QUERY, ActionType.ANSWER_PRE_CHECKOUT_QUERY, ActionType.SEND_STICKER,
                 ActionType.SEND_FILE, ActionType.HTTP_PUT, ActionType.HTTP_DELETE].indexOf(parseInt(`${ActionType[key]}`)) === -1) {
                continue
            }
            this.actionTypes.splice(this.actionTypes.length, 0, {title: key, id: ActionType[key]})
        }
        this.actionTypes.splice(0, 0, {id: null, title: '-----'});

        for (const table of this.meta.getTablesArray()) {
            this.tables.splice(this.tables.length, 0, {title: table[1], id: table[0]});
        }
        this.tables.splice(0, 0, {id: null, title: '-----'});

        const filters: BoardFilter[] = [];
        const filtersLang: BoardFilter[] = [];
        const webApps: BoardFilter[] = [];
        for (const button of this.process.buttons) {
            if (button.variableId && button.variableId.id) {
                filters.push(new BoardFilter('id', Equal.EQ, button.variableId.id));
            }
            if (button.textLangId && button.textLangId.id) {
                filtersLang.push(new BoardFilter('id', Equal.EQ, button.textLangId.id));
            }
            if (button.webAppId && button.webAppId.id) {
                webApps.push(new BoardFilter('id', Equal.EQ, button.webAppId.id));
            }
        }
        for (const field of this.process.fields) {
            if (field.variableId && field.variableId.id) {
                filters.push(new BoardFilter('id', Equal.EQ, field.variableId.id));
            }
            if (field.templateLangId && field.templateLangId.id) {
                filtersLang.push(new BoardFilter('id', Equal.EQ, field.templateLangId.id));
            }
        }

        if (filters.length) {
            this.base.getData(Variable.table, 1, 999, '', filters, true).subscribe(resp => {
                if (resp && resp.data) {
                    for (const item of resp.data) {
                        const variable = Variable.fromJson(item);
                        for (const button of this.process.buttons) {
                            if (button.variableId && button.variableId.id == variable.id) {
                                button.variableId =  Variable.fromJson(item);
                            }
                        }
                        for (const field of this.process.fields) {
                            if (field.variableId && field.variableId.id == variable.id) {
                                field.variableId =  Variable.fromJson(item);
                            }
                        }
                    }
                } else {
                    this.base.sendToast(this.base.getError(resp))
                }
            })
        }

        if (filtersLang.length) {
            this.base.getData(Translation.table, 1, 999, '', filtersLang, true).subscribe(resp => {
                if (resp && resp.data) {
                    for (const item of resp.data) {
                        const trans = Translation.fromJson(item);
                        for (const button of this.process.buttons) {
                            if (button.textLangId && button.textLangId.id == trans.id) {
                                button.textLangId =  Translation.fromJson(item);
                            }
                        }
                        for (const field of this.process.fields) {
                            if (field.templateLangId && field.templateLangId.id == trans.id) {
                                field.templateLangId =  Translation.fromJson(item);
                            }
                        }
                    }
                } else {
                    this.base.sendToast(this.base.getError(resp))
                }
            })
        }

        if (webApps.length) {
            this.base.getData(WebApp.table, 1, 999, '', filtersLang, true).subscribe(resp => {
                if (resp && resp.data) {
                    for (const item of resp.data) {
                        const trans = WebApp.fromJson(item);
                        for (const button of this.process.buttons) {
                            if (button.webAppId && button.webAppId.id == trans.id) {
                                button.webAppId =  WebApp.fromJson(item);
                            }
                        }
                    }
                } else {
                    this.base.sendToast(this.base.getError(resp))
                }
            })
        }

        const table = this.base.getTableMeta(BotProcess.table);
        if (table) {
            const field = table.fields.find(f => f.name === 'user_status')
            if (field && field.choices) {
                for (let row of field.choices) {
                    this.userStatuses.splice(this.userStatuses.length, 0, {id: row[0], title: row[1]});
                }
            } 
        }

        this.changeActionType(null);

    }

    create() {
        this.modalCtrl.dismiss({action: TableAction.CREATE});
    }

    copy() {
        this.process.id = undefined;
        this.process.topNew = (this.process.topNew || this.process.top) + 200;
        this.modalCtrl.dismiss({action: TableAction.CREATE});
    }

    delete() {
        this.modalCtrl.dismiss({action: TableAction.DELETE});
    }

    async closeModal() {
        if (this.process.id) {
            this.modalCtrl.dismiss({action: TableAction.UPDATE});
        } else {
            await this.askSave();
        }
    }

    async askSave() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('want-to-create'),
            buttons:  [
                {
                    text: this.trans.instant('yes'),
                    handler: (_) => {
                        this.create()
                    }
                },
                {
                    text: this.trans.instant('no'),
                    cssClass: 'secondary',
                    handler: (_) => {
                        this.modalCtrl.dismiss({});
                    }
                }, 
            ]
        });
        await alert.present();
    }

    addButton() {
        this.process.buttons.splice(this.process.buttons.length, 0, new BotProcessButton(null, null, false, null, null, null));
    }

    async chooseVariable(field, obj?) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: Variable.table,
                filters: [new BoardFilter('is_parent', Equal.EQ, false), ProcessVersion.filter()],
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(Variable.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                if (obj) {
                    obj[field] = Variable.fromJson(details.data.item);
                } else {
                    this.process[field] = Variable.fromJson(details.data.item);
                }
            }
        });
        return await modal.present();
    }

    async chooseTranslation(field, obj?) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: Translation.table,
                filters: [new BoardFilter('is_parent', Equal.EQ, false), ProcessVersion.filter()],
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(Translation.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                if (obj) {
                    obj[field] = Translation.fromJson(details.data.item);
                } else {
                    this.process[field] = Translation.fromJson(details.data.item);
                }
            }
        });
        return await modal.present();
    }

    async chooseWebApp(field, obj?) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: WebApp.table,
                filters: [ProcessVersion.filter()]
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(WebApp.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                if (obj) {
                    obj[field] = WebApp.fromJson(details.data.item);
                } else {
                    this.process[field] = WebApp.fromJson(details.data.item);
                }
            }
        });
        return await modal.present();
    }
    

    async chooseBot() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: Bot.table,
            },
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item && details.data.item[this.base.getTablePk(Bot.table)]) {
                this.process.botId = Bot.fromJson(details.data.item)
            }
        });
        return await modal.present();
    }

    reorderButtons(ev) {
        const itemToMove = this.process.buttons.splice(ev.detail.from, 1)[0];
        this.process.buttons.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    reorderFields(ev) {
        const itemToMove = this.process.fields.splice(ev.detail.from, 1)[0];
        this.process.fields.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    refreshFields(clear?: boolean) {
        if (!this.process.table) {
            this.process.fields = [];
            return
        }

        const table = this.base.getTableMeta(this.process.table);
        if (!table || !table.fields) {
            this.process.fields = [];
            return
        }

        if (clear) {
            this.process.fields = [];
        }

        for (const field of table.fields) {
            if (!this.process.fields.find(f => f.name === field.name)) {
                const f = this.base.copy(field);
                f.repr = null;
                this.process.fields.splice(this.process.fields.length, 0, BotProcessField.fromJson(f))
            }
        }
    }

    refreshFromFields() {
        if (!this.process.tableFrom) {
            this.fromFields = [];
            return
        }
        
        const table = this.base.getTableMeta(this.process.tableFrom);
        if (!table || !table.fields) {
            this.fromFields = [];
            return
        }

        const fromFields = [{id: null, title: '-----'}];
        for (const field of table.fields) {
            fromFields.push({id: field.name, title: field.verbose_name || field.name});
        }
        this.fromFields = fromFields;
    }

    changeActionType(ev) {
        switch (this.process.actionType) {
        case ActionType.ADD:
        case ActionType.CHANGE:
        case ActionType.DELETE:
        case ActionType.SAVE_LOCAL:
            this.isDo = true;
            break;
        case ActionType.CHOOSE:
        case ActionType.SAVE_FROM:
            if (ev) {
                this.refreshFields();
                this.refreshFromFields();
            }
            this.isDo = false 
            break;
        default:
            this.isDo = false;
        }
    }
    
    changeTable(ev) {
        this.refreshFields(true);
        this.refreshFromFields();
    }

    changeTableFrom(ev) {
        this.refreshFromFields();
    }

    async chooseField(field: BotProcessField) {
        const f = this.base.getTableMeta(this.process.table).fields.find(f => f.name === field.name);
        if (!f.related_model) {
            return
        }

        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: f.related_model,
                selected: field.ref
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = f.related_field || this.base.getTablePk(f.related_model);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                field.ref = details.data.item;
                if (pk == 'id') {
                    field.id = details.data.item[pk];
                } else {
                    field.uuid = details.data.item[pk];
                }
            }
        });
        return await modal.present();
    }

    async openBind(bind?: BotProcessBind) {
        const modal = await this.modalCtrl.create({
            component: BotProcessBindPage,
            showBackdrop: false,
            componentProps: {
                processes: this.processes,
                bind,
                processFrom: this.process,
            },
            backdropDismiss: false,
            cssClass: 'modal-item-detail-big'
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.bind) {
                this.binds.splice(this.binds.length, 0, details.data.bind);
                this.connections.splice(this.connections.length, 0, details.data.bind);
            } else if (details && details.data) {

                switch (details.data.action) {
                case TableAction.DELETE:

                    for (let i = 0; i < this.binds.length; i++) {
                        if (this.binds[i].id === bind.id) {
                            this.binds.splice(i, 1);
                            break;
                        }
                    }
                    for (let i = 0; i < this.connections.length; i++) {
                        if (this.connections[i].id === bind.id) {
                            this.connections.splice(i, 1);
                            break;
                        }
                    }
                    break 
                
                case TableAction.UPDATE:

                    this.base.updateTable(bind.serialize(), bind.id, null, BotProcessBind.table).subscribe(resp => {
                        if (!resp || !resp.id) {
                            this.base.sendToast(this.base.getError(resp))
                        }
                    })
                    break
                }
                
            } 
        });
        return await modal.present();
    }

    editObj(field: any, table: string) {
        this.base.editObj(field, table, FormPage)
    }
}
