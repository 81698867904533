import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx'; 

@Component({
    selector: 'app-export-to-excel',
    templateUrl: './export-to-excel.page.html',
    styleUrls: ['./export-to-excel.page.scss'],
})
export class ExportToExcelPage implements OnInit {

    @ViewChild('table') table: ElementRef; 
    @Input() subject: any;
    @Output() complete = new EventEmitter();
    subscription: any;
    toExcel: any;
    t = (new Date()).getTime();

    constructor() { }

    ngOnInit() {
        if (this.subject) {
            this.subscription = this.subject.subscribe(data => {
                if (data) {
                    this.toExcel = data;
                    this.export();
                }
            });
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        this.ionViewWillLeave();
    }

    export() {
        setTimeout(() => {
            const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);  
            const wb: XLSX.WorkBook = XLSX.utils.book_new();  
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');  
            XLSX.writeFile(wb, 'data.xlsx');  
            this.toExcel = null;
            if (this.complete) {
                this.complete.emit();
            }
        }, 1000);
    }
}
