import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from 'src/app/services/meta.service';
import { ModalController } from '@ionic/angular';
import { Equal } from 'src/app/classes/enums';

@Component({
    selector: 'app-import-images',
    templateUrl: './import-images.page.html',
    styleUrls: ['./import-images.page.scss'],
})
export class ImportImagesPage implements OnInit {

    @ViewChild('file') file: any;
    @Output() output = new EventEmitter();
    @Input() table;
    
    notAddWithImage = true;
    useSearch = false;
    related: any;
    related2: any;
    rows = [];
    fields = [];
    tables = [];
    tablesFields = {};
    complete = true;
    imageField = '';
    alradyAdded = '';
    maxFinder: number = 1;

    constructor(private base: BaseService,
                private trans: TranslateService,
                private meta: MetaService,
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.alradyAdded = this.trans.instant('already-added')
        this.tables = this.meta.getTablesArray();
        for (const t of this.base.getLocalMeta()) {
            this.tablesFields[t.table] = t.fields;
        }
        this.fields = this.base.getTableMeta(this.table).fields;
    }

    upload() {
        this.file.nativeElement.click();
    }

    loadFile(ev) {
        this.rows = [];
        for (const file of ev.target.files) {
            const name = file.name.split('.')[0];
            this.rows.splice(this.rows.length, 0, {file, status: '', name: name});
        }
    }

    closeModal() {
        this.modalCtrl.dismiss({});
    }

    run() {

        if (!this.complete) {
            return;
        }

        if (!this.related && !this.useSearch) {
            this.base.sendToast(this.trans.instant('search-field'))
            return;
        }

        if (this.related && this.related.related_model && !this.related2 && !this.useSearch) {
            this.base.sendToast(this.trans.instant('related-field'))
            return;
        }

        if (!this.imageField) {
            this.base.sendToast(this.trans.instant('image-field'))
            return;
        }

        this.complete = false;
        this.syncRows(0);
    }

    private syncRows(next) {
        if (next >= this.rows.length) {
            this.complete = true;
        }
        for (let i = next; i < this.rows.length; i++) {
            this.syncRow(this.rows[i], i)
            break;
        }
    }

    private syncRow(row, indx) {
        if (row.status === 'ok') {
            this.syncRows(indx + 1);
            return;
        }

        const name = row.name;

        if (this.related && this.related.related_model) {
            let params = '';
            if (this.useSearch) {
                params += `&s=` + name
            } else {
                const filters = [{name: this.related2, equal: Equal.EQ, value: name}]; 
                params += `&filter_fields=${JSON.stringify(filters)}`
            }
            
            this.base.getData(this.related.related_model, 1, 1000, params).subscribe(resp => {
                if (resp && resp.data) {
                    if (resp.data.length > this.maxFinder) {
                        row.status = this.trans.instant('obj-found-more-than-one', {cnt: resp.data.length, val: name});
                    } else if (resp.data.length === 0) {
                        row.status = this.trans.instant('obj-not-found', {val: name});
                    } else {
                        this.createRows(row, resp.data, 0, indx);
                        return;
                    }
                } else {
                    row.status = this.base.getError(resp);
                }

                this.syncRows(indx + 1);
            })
        } else {
            let params = '';
            if (this.useSearch) {
                params += `&s=` + name
            } else {
                const filters = [{name: this.related.name, equal: Equal.EQ, value: name}]; 
                params += `&filter_fields=${JSON.stringify(filters)}`
            }
            this.base.getData(this.table, 1, 1000, params).subscribe(resp => {
                if (resp && resp.data) {
                    if (resp.data.length > this.maxFinder) {
                        row.status = this.trans.instant('obj-found-more-than-one', {cnt: resp.data.length, val: name});
                    } else if (resp.data.length === 0) {
                        row.status = this.trans.instant('obj-not-found', {val: name});
                    } else {
                        this.updateRows(row, resp.data, 0, indx);
                        return;
                    }
                } else {
                    row.status = this.base.getError(resp);
                }

                this.syncRows(indx + 1);
            })
        }
    }

    private get relatedPk(): string {
        if (this.related && this.related.related_model) {
            return this.related.related_field || this.base.getTablePk(this.related.related_model);
        }
        return this.base.getTablePk(this.table) 
    }

    private updateRows(row: any, items: any[], nextItem: number, indx: number) {
        if (!items[nextItem]) {
            this.syncRows(indx + 1);
            return;
        }
        const item: any = {};
        const pk = items[nextItem][this.base.getTablePk(this.table)];
        item.edited = items[nextItem].edited;

        if (!row.url) {
            this.base.uploadFile(row.file).subscribe(img => {
                if (img && img.url) {
                    row.url = img.url;
                    item[this.imageField] = row.url;
                    this.update(item, row, indx, pk, items, nextItem);
                } else {
                    row.status = this.base.getError(img);
                    this.updateRows(row, items, nextItem + 1, indx);
                }
            });
        } else {
            item[this.imageField] = row.url;
            this.update(item, row, indx, pk, items, nextItem);
        }
    }

    private update(item: any, row: any, indx: number, pk: any, items: any[], nextItem: number) {
        this.base.updateTable(item, pk, item.edited, this.table).subscribe(response => {
            if (response && response[this.base.getTablePk(this.table)]) {
                row.status = 'ok'
            } else {
                row.status = this.base.getError(response) || 'error';
            }
            this.updateRows(row, items, nextItem + 1, indx);
        })
    }

    private createRows(row: any, items: any[], nextItem: number, indx: number) {
        if (!items[nextItem]) {
            this.syncRows(indx + 1);
            return;
        }
        const item: any = {};
        item[this.related.name] = items[nextItem][this.relatedPk];
        if (this.notAddWithImage) {
            const filters = [{name: this.related.name, equal: Equal.EQ, value: item[this.related.name]}]
            this.base.getData(this.table, 1, 1, `&filter_fields=${JSON.stringify(filters)}`).subscribe(found => {
                if (found && found.data && found.data.length) {
                    row.status = this.alradyAdded;
                    this.createRows(row, items, nextItem + 1, indx);
                } else {
                    this.createImage(row, item, indx, items, nextItem);
                }
            })
        } else {
            this.createImage(row, item, indx, items, nextItem);
        }
    }

    private createImage(row: any, item: any, indx: number, items: any[], nextItem: number) {
        if (!row.url) {
            this.base.uploadFile(row.file).subscribe(img => {
                if (img && img.url) {
                    row.url = img.url;
                    item[this.imageField] = row.url;
                    this.create(item, row, indx, items, nextItem);
                } else {
                    row.status = this.base.getError(img);
                    this.createRows(row, items, nextItem + 1, indx);
                }
            });
        } else {
            item[this.imageField] = row.url;
            this.create(item, row, indx, items, nextItem);
        }
    }

    private create(item: any, row: any, indx: number, items: any[], nextItem: number) {
        this.base.createTable(item, this.table).subscribe(response => {
            if (response && response[this.base.getTablePk(this.table)]) {
                row.status = 'ok'
            } else {
                row.status = this.base.getError(response) || 'error';
            }
            this.createRows(row, items, nextItem + 1, indx);
        })
    }
}
