import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { FieldType } from 'src/app/classes/enums';
import { BaseService } from 'src/app/services/base.service';
import { OrderByPage } from '../order-by/order-by.page';

@Component({
    selector: 'app-botbutton',
    templateUrl: './botbutton.page.html',
    styleUrls: ['./botbutton.page.scss'],
})
export class BotbuttonPage implements OnInit {

    @ViewChild('text') text: any;
    @Input() item: any;
    @Input() isCallback: boolean;
    metaTables = [];
    tables = [];
    fields: any = [];
    types = [];
    relatedFields = [];
    tab = 0;

    constructor(private modalCtrl: ModalController,
                private base: BaseService,
                private popoverCtrl: PopoverController) { }

    ngOnInit() {
        this.types = ['text', 'element-list'];
        if (!this.isCallback) {
            this.types.splice(this.types.length, 0, 'contact');
            this.types.splice(this.types.length, 0, 'location');
        }
        this.metaTables = this.base.getLocalMeta().filter(t => t.fields.find(f => f.type === FieldType.REF) && !t.block);
        for (const table of this.metaTables) {
            this.tables.splice(this.tables.length, 0, [table.table, table.verbose_name || table.table]);
        }
        this.refreshTable();

        setTimeout(() => {
            this.text.setFocus();
        }, 500);
    }

    delete() {
        this.modalCtrl.dismiss({delete: true});
    }

    add() {
        this.modalCtrl.dismiss({add: true});
    }

    close() {
        this.modalCtrl.dismiss();
    }

    refreshTable() {
        this.fields = [];
        this.relatedFields = [];
        if (this.item && this.item.table) {
            const table = this.metaTables.find(t => t.table === this.item.table);
            if (table && table.fields) {
                this.fields = this.base.copy(table.fields.filter(f => f.type === FieldType.REF));
            }
            if (this.item && this.item.table && this.item.field) {
                const field = this.fields.find(f => f.name === this.item.field);
                if (field && field.related_model) {
                    const relatedModal = this.base.getTableMeta(field.related_model);
                    if (relatedModal && relatedModal.fields) {
                        this.relatedFields = this.base.copy(relatedModal.fields.filter(f => f.type !== FieldType.REF));
                    }
                }
            }
        }
    }

    async openOrderBy(ev) {
        const orderBy = this.base.getOrderBy([], this.relatedFields);
        for (const o of this.item.sort || []) {
            const f = orderBy.find(ff => ff.name === o.name);
            if (f) {
                f.isChecked = true;
                f.isAsc = o.type !== 'desc';
            }
        }

        const popover = await this.popoverCtrl.create({
            component: OrderByPage,
            componentProps: {
                orderBy, 
                showHeader: true,
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((_: any) => {
            this.item.sort = [];
            for (const f of orderBy) {
                if (f.isChecked) {
                    this.item.sort.splice(this.item.sort.length, 0, {name: f.name, type: !f.isAsc ? 'desc' : 'ask'});
                }
            }
        });
        return await popover.present();
    }
}
