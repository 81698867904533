import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PopoverController } from '@ionic/angular';
import { Constants } from 'src/app/classes/app.constants';
import { Base } from 'src/app/classes/base';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.page.html',
    styleUrls: ['./accounts.page.scss'],
})
export class AccountsPage implements OnInit {

    currentUser: any;
    accounts = [];
    app: any;
    currentApp = '';
    languages = Constants.LANGUAGES;
    lang = null;

    constructor(public base: BaseService,
                private router: Router,
                private authService: AuthService,
                private popoverCtrl: PopoverController) { }

    ngOnInit() {
        this.lang = this.base.getLanguage();
        this.currentUser = Base.getCurrentUser();
        if (this.currentUser) {
            this.currentApp = this.base.domains[this.base.getDomain()] || this.base.getCurrentApp();
        }
        
        this.accounts = this.base.getAccounts().filter(a => !(a.token === this.base.getToken() && a.rootUrl === this.base.getRootUrl()) 
                                                       && a.user);
        for (const account of this.accounts) {
            account.app = this.base.domains[this.base.getDomain(account.rootUrl)] || this.base.getCurrentApp(account.rootUrl);
        }
    }

    changeAccount(account) {
        const accounts = this.base.getAccounts();
        const lang = this.base.getLanguage();
        localStorage.clear();
        Base.currentUser = null;
        localStorage.setItem('lang', lang);
        this.base.setAccounts(accounts);
        this.base.auth.next(0);

        const subject = new BehaviorSubject(null);
        const sub = subject.subscribe(result => {
            if (sub == null) {
                return;
            }
            this.popoverCtrl.dismiss({});
            if (result === true) {
                this.router.navigate(['']);
            }
            window.location.replace('');
            sub.unsubscribe();
        });
        this.base.addAccount(account.user.username, this.base.getDomain(account.rootUrl));
        this.authService.successLogin(account.token, this.app, subject);
    }

    logout(full, current) {
        this.base.logout(full, current);
        this.popoverCtrl.dismiss({});
    }

    changeLang(lang) {
        this.base.setLanguage(lang);
    }
}
