export class BoardDate {

    date: Date;

    constructor(time: any) {
        
        switch (typeof(time)) {
        case 'number':

            this.date = new Date(time * 1000);
            break;

        case 'string':
            
            this.date = new Date(time);
            if (this.date.toDateString() === `Invalid Date`) {
                this.date = new Date(time.replace(' ', 'T').replace('+00', 'Z'));
                if (this.date.toDateString() === 'Invalid Date') {
                    this.date = new Date(time.slice(0, 10));
                    if (this.date.toDateString() === 'Invalid Date') {
                        this.date = new Date();
                    }
                }
            }
            break;

        case 'object':

            this.date = time || new Date();
            break;

        default:

            try {
                this.date = new Date(time);
            } catch {
                this.date = new Date();
            }
            if (this.date.toDateString() === 'Invalid Date') {
                this.date = new Date();
            }
        }
    }

    get dateRepr(): string {
        const days = `${this.date.getDate()}`; const month = `${this.date.getMonth() + 1}`; 
        return `${days.length === 1 ? '0' : ''}${days}.${month.length === 1 ? '0' : ''}${month}.${this.date.getFullYear()}`;
    }

    get iso(): string {
        const days = `${this.date.getDate()}`; const month = `${this.date.getMonth() + 1}`; 
        return `${this.date.getFullYear()}-${month.length === 1 ? '0' : ''}${month}-${days.length === 1 ? '0' : ''}${days}`;
    }
}