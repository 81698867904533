import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DateRangePage } from './date-range.page';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MatFormFieldModule, MatInputModule } from '@angular/material';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([]),
        SatDatepickerModule, 
        SatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [DateRangePage],
    exports: [DateRangePage]
})
export class DateRangePageModule {}
