import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UploadFileBtnPage } from './upload-file-btn.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([]),
        TranslateModule
    ],
    declarations: [UploadFileBtnPage],
    exports: [UploadFileBtnPage]
})
export class UploadFileBtnPageModule {}
