import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AccountsPage } from './accounts.page';
import { TranslateModule } from '@ngx-translate/core';
import { UserPhotoPageModule } from '../user-photo/user-photo.module';

const routes: Routes = [
    {
        path: '',
        component: AccountsPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        UserPhotoPageModule
    ],
    declarations: [AccountsPage]
})
export class AccountsPageModule {}
