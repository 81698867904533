import { ActionType, BotProcessCondition, BotProcessField, GroupBotProcess, Variable } from "./botprocess";
import { ProcessVersion } from "./processversion";

export class Process {

    static table = "process";

    constructor(public id: number,
                public name: string,
                public group: GroupBotProcess,
                public left: number,
                public top: number,
                public comment: string,
                public actionType: ActionType,
                public table: string,
                public tableFrom: string,
                public fields: BotProcessField[],
                public variableId: Variable,
                public version: ProcessVersion,
                public leftNew?: number,
                public topNew?: number) {}

    static fromJson(d: any): Process {
        return new Process(
            d.id,
            d.name,
            GroupBotProcess.fromJson(d.group_id),
            d.left,
            d.top,
            d.comment,
            d.action_type,
            d.table,
            d.table_from,
            d.fields && d.fields.length ? d.fields.map(f => BotProcessField.fromJson(f)) : [],
            Variable.fromJson(d.variable_id),
            ProcessVersion.fromJson(d.version_id)
        );
    }

    public serialize(): any {
        return {
            id: this.id, 
            name: this.name,
            group_id: this.group ? this.group.serialize() : null,
            left: this.leftNew || this.left,
            top: this.topNew || this.top,
            comment: this.comment,
            action_type: this.actionType,
            table: this.table,
            table_from: this.tableFrom,
            is_group: false,
            fields: this.fields.map(f => f.serialize()),
            variable_id: this.variableId ? this.variableId.serialize() : null,
            version_id: this.version ? this.version.id : 0
        }
    }
}

export class ProcessBind {

    static table = "processbind"

    constructor(public id: number,
                public fromId: GroupBotProcess,
                public toId: GroupBotProcess,
                public isOr: boolean,
                public condition: BotProcessCondition[],
                public version: ProcessVersion) {}

    static fromJson(d: any): ProcessBind {
        return new ProcessBind(d.id, GroupBotProcess.fromJson(d.from_id), GroupBotProcess.fromJson(d.to_id), d.is_or, 
            d.condition ? d.condition.map(f => BotProcessCondition.fromJson(f)) : [],
            ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return {
            from_id: this.fromId.serialize(),
            to_id: this.toId.serialize(),
            is_or: this.isOr,
            condition: this.condition.map(f => f.serialize()),
            version_id: this.version ? this.version.id : 0
        }
    }
}