import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PageService } from '../../services/page.service';
import { ChoicesPage } from '../../choices/choices.page';
import { ModalController, PopoverController } from '@ionic/angular';
import { BaseService } from '../../services/base.service';
import { MetaService } from '../../services/meta.service';
import { TranslateService } from '@ngx-translate/core';
import { DatetimeWidgetPage } from '../datetime-widget/datetime-widget.page';
import { ForeignPage } from '../json-schema-widgets/foreign/foreign.page';
import { InputPage } from '../json-schema-widgets/input/input.page';
import { SelectPage } from '../json-schema-widgets/select/select.page';
import { DatePage } from '../json-schema-widgets/date/date.page';
import { DatetimePage } from '../json-schema-widgets/datetime/datetime.page';
import { CheckboxPage } from '../json-schema-widgets/checkbox/checkbox.page';
import { Observable, of, zip, BehaviorSubject } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { FormPage } from 'src/app/form/form.page';
import { SipService } from 'src/app/services/sip.service';
import { Equal } from 'src/app/classes/enums';
import { BoardFilter } from 'src/app/classes/board-filters';
import { GeoLocation } from 'src/app/classes/location';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.page.html',
    styleUrls: ['./form-field.page.scss'],
})
export class FormFieldPage implements OnInit {

    @ViewChild('file') file: any;
    @ViewChild('image') image: any;
    @ViewChild('select') select: any;
    @Input() item: any;
    @Input() row: any;
    @Input() hideEmpties: any = {};
    error: any;
    @Input() fields: any;
    @Input() form: any;
    @Input() rowIndex: any;
    @Output() closeModalEvent = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() delete = new EventEmitter();
    @Output() fillTableCell = new EventEmitter();
    @Output() activateSection = new EventEmitter();
    changedField: any;
    focusField: any;
    userPk: string;
    valueIsEmpty = null;
    status = null;
    errorRepr = null;
    foreignRepr = null;
    choices = null;
    fileUrl = null;
    addField = null;
    calcRepr = '';
    @Input() relateds: any;
    @Input() root: any;
    @Input() itemChanged: any;
    @Input() fieldReprChanged: any;
    @Input() hideEmptiesSubject: any;
    @Input() refreshRelatedsSubject: any;
    @Input() activeRelateds: any;
    @Input() rootForm: any;
    saveMap: BehaviorSubject<any>;
    subscriptionMap: any;
    cnt = 0;
    subscription: any;
    subscriptionRepr: any;
    subscriptionhideEmpties: any;
    subscriptionRefreshRelatedsSubject: any;
    dateRepr = '';
    widgets = {
        foreign: ForeignPage,
        input: InputPage,
        select: SelectPage,
        date: DatePage,
        datetime: DatetimePage,
        checkbox: CheckboxPage,
    };
    tablePk = '';
    barcodeRepr = '';
    imageRepr = '';
    divRepr = null;
    valBefore: any;
    mapUpdater: BehaviorSubject<GeoLocation> = new BehaviorSubject(null);

    constructor(public pageService: PageService, 
                private modalCtrl: ModalController, 
                public base: BaseService, 
                public meta: MetaService, 
                private trans: TranslateService,
                private popoverCtrl: PopoverController,
                private sip: SipService) { }

    ngOnInit() {
        if (this.row.type === 'input' && this.row.jsonKey) {
            this.item.openedItem[this.row.fieldName] = this.item.openedItem[this.row.fieldName] || {};
        }
        if (this.row && this.row.type === 'map') {
            this.saveMap = new BehaviorSubject(null);
            this.subscriptionMap = this.saveMap.subscribe(address => {
                if (address == null || !this.row.saveCalc) {
                    return;
                }
                const fields: any = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem, 'JSON.stringify(' + this.row.saveCalc + ')', this.relateds, {address}, this);
                if (fields && fields != '{}') {
                    const data = JSON.parse(fields);
                    for (const k of Object.keys(data)) {
                        this.item.openedItem[k] = data[k];
                    }
                    this.save.emit({item: this.item, form: this.form, fields: this.getCalcFieldChanges(data), index: this.rowIndex, 
                                    notify: this.trans.instant('location-saved')});
                }
            });
        }
        const verboseName = this.base.translate(this.row.verboseNames);
        if (verboseName) {
            this.row.verboseName = verboseName;
        }
        const placeholder = this.base.translate(this.row.placeholders);
        if (placeholder) {
            this.row.placeholder = placeholder;
        }
        if (this.form && this.form.data && this.form.data.table) {
            this.tablePk = this.base.getTablePk(this.form.data.table);
            if (this.item.openedItem[this.tablePk]) {
                this.item.openedItem.tempId = this.base.copy(this.item.openedItem[this.tablePk]);
            }
            if (!this.item.openedItem.tempId) {
                this.item.openedItem.tempId = uuid.v4();
            }
        }
        this.userPk = this.base.getTablePk('User');
        if (!this.hideEmpties) {
            this.hideEmpties = {};
        }
        if (this.itemChanged) {
            this.subscription = this.itemChanged.subscribe(data => {
                if (!this.row.alwaysRefresh) {
                    if (data && (data.error || data.clearError) 
                        && (data.index === this.rowIndex || (data.index === undefined && this.rowIndex === null))) {
                        this.error = data.error;
                    } else if (data && data.index !== null && data.index !== undefined && data.index !== this.rowIndex 
                        && this.rowIndex !== null && this.rowIndex !== undefined) {
                        return;
                    }
                }
                this.refresh();
            });
        } else {
            this.refresh();
        }
        if (this.fieldReprChanged) {
            this.subscriptionRepr = this.fieldReprChanged.subscribe(fieldName => {
                if (this.row && this.row.fieldName === fieldName) {
                    this.changeForeignRepr();
                }
                if (this.row.type === 'calcfield') {
                    this.refreshCalc();
                } else if (this.row.type === 'barcode') {
                    this.refreshBarcode();
                } else if (this.row.type === 'image') {
                    this.refreshImage();
                } else if (this.row.type === 'div') {
                    this.refreshDiv();
                }
            });
        } else {
            this.changeForeignRepr();
        }
        if (this.hideEmptiesSubject) {
            this.subscriptionhideEmpties = this.hideEmptiesSubject.subscribe(hideEmpties => {
                if (this.row.type === 'add_label') {
                    this.hideEmpties = hideEmpties;
                    this.isAddFieldChanged();
                }
            });
        } else if (this.row.type === 'add_label') {
            this.isAddFieldChanged();
        }
        if (this.refreshRelatedsSubject) {
            this.subscriptionRefreshRelatedsSubject = this.refreshRelatedsSubject.subscribe(relateds => {
                if (this.row.type === 'barcode') {
                    this.refreshBarcode(relateds);
                } else if (this.row.type === 'image') {
                    this.refreshImage(relateds);
                } else if (this.row.type === 'div') {
                    this.refreshDiv(relateds);
                } else {
                    this.refreshCalc(relateds);
                }
            });
        }

        if (this.row.type === 'foreign' && this.row.setDefault && this.fields) {
            const f = this.fields.find(i => i.name === this.row.fieldName);
            if (f && this.form && this.form.data && this.form.data.table && !this.item.openedItem[this.base.getTablePk(this.form.data.table)]) {
                const filters = this.getDefaultFilters();
                let params = ``;
                if (filters && filters.length) {
                    params += `&filter_fields=${JSON.stringify(filters)}`;
                }
                this.base.getData(f.related_model, 1, 2, params).subscribe(resp => {
                    if (resp && resp.data.length === 1) {
                        this.selectForeign(f.name, resp.data[0]);
                    }
                })
            }
        }

        if (this.row.type === 'textarea' && this.item.openedItem[this.row.fieldName]) {
            const f = this.fields.find(i => i.name === this.row.fieldName);
            if (f.type === 'JSONField' && typeof(this.item.openedItem[this.row.fieldName]) === 'object') {
                this.item.openedItem[this.row.fieldName] = JSON.stringify(this.item.openedItem[this.row.fieldName])
            }
        }
    }

    getDefaultFilters() {
        return this.getCalcRepr(this.row.setDefault) || [];
    }

    onSubmit(ev) {
        const fields = {};
        fields[this.row.fieldName] = ev;
        this.item.openedItem[this.row.fieldName] = ev;
        this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex, close: true, save: true});
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionRepr) {
            this.subscriptionRepr.unsubscribe();
        }
        if (this.subscriptionhideEmpties) {
            this.subscriptionhideEmpties.unsubscribe();
        }
        if (this.subscriptionRefreshRelatedsSubject) {
            this.subscriptionRefreshRelatedsSubject.unsubscribe();
        }
        if (this.subscriptionMap) {
            this.subscriptionMap.unsubscribe();
        }
    }

    ionViewDidLeave() {
        if (this.changedField && this.focusField && this.changedField === this.focusField) {
            this.boolChanged(this.item.openedItem[this.changedField], this.changedField);
        }
    }

    refresh() {
        this.cnt ++;
        this.valueChange();

        switch (this.row.type) {
        case 'add_label':
            this.isAddFieldChanged();
            break;

        case 'calcfield':
            
            this.refreshCalc();
            break;

        case 'select':
            this.changeChoices();
            break;

       case 'error':
            
            this.changeError();
            break;

        case 'datetimepicker':
            
            this.changeDateRepr();
            break;

        case 'barcode':
            
            this.refreshBarcode();
            break;

        case 'image':

            this.refreshImage();
            break;

        case 'div':

            this.refreshDiv();
            break;

        case 'map':

            this.mapUpdater.next(new GeoLocation(this.item.openedItem[this.row.lat], this.item.openedItem[this.row.lng], null, null, null, null));
            break;

        }
    }

    refreshDiv(relateds?) {
        if (!this.row.fieldRepr) {
            this.divRepr = null;
        } else {
            this.divRepr = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem,
                                                 this.row.fieldRepr, relateds || this.relateds, null, this);
            if (this.divRepr) {
                this.divRepr = this.pageService.trustHtml(this.divRepr);
            }
        }
    }

    refreshImage(relateds?) {
        if (!this.row.fieldRepr) {
            this.imageRepr = '';
        } else {
            this.imageRepr = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem,
                                                   this.row.fieldRepr, relateds || this.relateds, null, this);

        }
    }
    
    refreshBarcode(relateds?) {
        if (!this.row.fieldRepr) {
            this.barcodeRepr = '';
        } else {
            this.barcodeRepr = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem,
                                                     this.row.fieldRepr, relateds || this.relateds, null, this);
        }
    }
   
    changeDateRepr() {
        this.dateRepr = '';
        if (this.item.openedItem[this.row.fieldName]) {
            this.dateRepr = this.base.dateTimeToString(this.item.openedItem[this.row.fieldName]);
        }
    }

    refreshCalc(relateds?) {
        this.calcRepr = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem, 
                                              this.row.fieldRepr, relateds || this.relateds, null, this);
    }

    isAddFields(rows, obj, hideEmpties) {
        for (const r of rows) {
            if (hideEmpties && r && r.fieldName && hideEmpties[r.fieldName] && obj) {
                if (this.isEmpty(obj[r.fieldName])) {
                    return true;
                }
            }
        }
        return false;
    }

    isEmpty(obj) {
        return (obj === null || obj === undefined || obj === '') || (obj && !obj.uuid && obj.uuid !== undefined) 
                || (obj && !obj.id && obj.id !== undefined);
    }

    closeModal(event) {
        this.closeModalEvent.emit(event);
    }

    deleteObj() {
        this.delete.emit({i: this.rowIndex, table: this.form.data.table});
    }
    
    getErrors(error) {
        const msg = [];
        if (error && typeof(error) === 'object') {
            for (const key of Object.keys(error)) {
                const f = this.fields.find(i => i.name === key);
                if (f) {
                    msg.push(((f.verbose_name + ': ') || '') + error[key]);
                } else if (key === 'detail' && error[key] === 'Не найдено.') {
                    msg.push(this.trans.instant('object-not-found'));
                } else {
                    msg.push(error[key]);
                }
            }
        } else if (error && typeof(error) === 'string') {
            return error;
        }
        return msg.join(', ');
    }
    
    async chooseFieldItem(name) {
        if (this.preventChange()) {
            return;
        }
        if (!this.fields) {
            return;
        }
        const f = this.fields.find(i => i.name === name);
        if (f) {
            const filters: BoardFilter[] = (this.getDefaultFilters() || []).map(f => BoardFilter.fromJson(f));
            if (this.form && this.form.data && this.form.data.rows && this.item && this.item.openedItem) {
                const rows = this.form.data.rows.filter(i => i.type === 'foreign' && i.fieldName === name &&
                                                        i.relatedFilters && i.relatedFilters.length);

                for (const row of rows) {
                    for (const ff of row.relatedFilters) {
                        if (ff.to && ff.to.name && ff.from && ff.from.name && this.item.openedItem[ff.from.name]) {
                            const field = this.fields.find(r => r.name === ff.from.name);
                            const pk = field.related_field || this.base.getTablePk(field.related_model);
                            if (!this.item.openedItem[ff.from.name][pk]) {
                                continue;
                            }
                            const filter = new BoardFilter(ff.to.name, Equal.EQ, this.item.openedItem[ff.from.name][pk]);
                            filter.obj = this.item.openedItem[ff.from.name];
                            filters.push(filter);
                        }
                    }
                }
            }

            const modal = await this.modalCtrl.create({
                component: ChoicesPage,
                componentProps: {
                    model: f.related_model || this.row.related_model,
                    filters,
                    funcRepr: f.func_repr,
                    selected: this.item.openedItem[name],
                    formPk: this.row && this.row.form ? this.row.form[this.base.getTablePk('Form')] : null, 
                },
                showBackdrop: false
            });
            modal.onDidDismiss().then((details: any) => {
                if (details && details.data && details.data.item) {
                    this.selectForeign(name, details.data.item)
                }
            });
            return await modal.present();
        }
    }

    selectForeign(name, selected) {
        const field = this.fields.find(r => r.name === name);
        const pk = field.related_field || this.base.getTablePk(field.related_model || this.row.related_model);
        if (this.item.openedItem[name] && selected[pk] === this.item.openedItem[name][pk]) {
            return;
        }
        this.item.openedItem[name] = selected;
        let fields: any; fields = {};
        fields[name] = selected;
        this.fillRelatedValue().subscribe(values => {
            for (const value of values) {
                if (value && value.length === 2) {
                    this.item.openedItem[value[0]] = value[1];
                    fields[value[0]] = value[1];
                }
            }
            fields = this.getCalcFieldChanges(fields);
            this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
            this.changeForeignRepr();
            if (this.row && this.row.type === 'label' && this.form && this.form.data && this.form.data.rows) {
                const row = this.form.data.rows.find(ff => ff.type === 'foreign' && ff.fieldName === this.row.fieldName);
                if (row && this.fillTableCell && row.tableCells) {
                    this.fillTableCell.emit({widget: row, field, value: selected[pk]});   
                }
            } else if (this.fillTableCell && this.row && this.row.tableCells) {
                this.fillTableCell.emit({widget: this.row, field, value: selected[pk]});   
            }
        });
        this.getCalcRepr(this.row.funcAfterChanged);
    }

    private getCalcRepr(func) {
        if (func) {
            return this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem, func, this.relateds, null, this);
        }
    }

    fillRelatedValue(): Observable<any> {
        const rows = [];
        if (!this.item.openedItem[this.tablePk] && this.row.defaultRels && this.row.defaultRels.length) {
            for (const row of this.row.defaultRels) {
                if (row.name) {
                    const field = this.fields.find(i => i.name === row.name);
                    if (!field || !field.related_model) {
                        continue;
                    }
                    if (this.item.openedItem[row.name] && this.item.openedItem[row.name][this.base.getTablePk(field.related_model)]) {
                        continue;
                    }
                    let params = '';
                    if (row.calc) {
                        const calc = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, row.calc, this.relateds, null, this);
                        if (calc && calc.length) {
                            params = `&filter_fields=${JSON.stringify(calc)}`;
                        }
                    }
                    rows.push(
                        this.base.getData(field.related_model, 1, 2, params).pipe(
                            tap(_ => this.base.log('fetched user')),
                            map((resp) => {
                                if (resp && resp.data && resp.data.length === 1) {
                                    return [field.name, resp.data[0]];
                                }
                                return null;
                            }),
                            catchError(this.base.handleError('getUser'))
                        )
                    );
                }
            }
        }
        if (rows.length === 0) {
            return of([]);
        }
        return zip(...rows);
    }
    
    clearField(field, fieldName) {
        if (this.preventChange()) {
            return;
        }
        if (field) {
            const ff = this.fields.find(r => r.name === fieldName);
            const pk = ff.related_field || this.base.getTablePk(ff.related_model);
            for (const k of Object.keys(field)) {
                field[k] = k === pk ? null : '';
            }
            this.foreignRepr = null;
            let fields: any; fields = {};
            fields[fieldName] = field;
            fields = this.getCalcFieldChanges(fields);
            this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
        }
    }
    
    async chooseUser(field, fieldName) {
        if (!this.item.openedItem[field]) {
            this.item.openedItem[field] = {};
        }
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'User',
                selected: this.item.openedItem[field]
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.item) {
                const pk = this.base.getTablePk('User');
                if (this.item.openedItem[field] && detail.data.item[pk] === this.item.openedItem[field][pk] ) {
                    return;
                }
                this.item.openedItem[field] = detail.data.item;
                let fields: any; fields = {};
                fields[fieldName] = detail.data.item;
                fields = this.getCalcFieldChanges(fields);
                this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
            }
        });
        return await modal.present();
    }

    dateChanged(ev, fieldName) {
        if (this.focusField === fieldName) {
            if (this.preventChange()) {
                return;
            }
            this.focusField = null;
            let fields: any; fields = {};
            fields[fieldName] = ev.value === null ? null : this.base.dateObjToISO(ev.value);
            fields = this.getCalcFieldChanges(fields);
            this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
        }
    }

    timeChanged(ev, fieldName) {
        if (this.focusField === fieldName) {
            if (this.preventChange()) {
                return;
            }
            this.focusField = null;
            let fields: any; fields = {};
            if (ev.value) {
                fields[fieldName] = this.base.datetimeToString(ev.value);
            } else if (ev.target) {
                fields[fieldName] = ev.target.value;
            }
            this.item.openedItem[fieldName] = fields[fieldName];
            fields = this.getCalcFieldChanges(fields);
            this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
        }
    }

    async openDatetime(ev) {
        if (this.preventChange()) {
            return;
        }
        const dt = this.item.openedItem[this.row.fieldName] ? this.base.copy(this.item.openedItem[this.row.fieldName]) : null;
        const item = this.base.copy({val: dt});
        const popover = await this.popoverCtrl.create({
            component: DatetimeWidgetPage,
            componentProps: {item, open: false},
            showBackdrop: false,
            event: ev
        });

        popover.onDidDismiss().then((_: any) => {
            if (dt !== item.val) {
                let fields: any; fields = {};
                fields[this.row.fieldName] = item.val || null;
                fields = this.getCalcFieldChanges(fields);
                this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
                this.item.openedItem[this.row.fieldName] = item.val || null;
                this.changeDateRepr();
            }
        });
        return await popover.present();  
    }

    boolChanged(val, fieldName) {
        if (this.preventChange()) {
            return;
        }
        let fields: any; fields = {};
        fields[fieldName] = val;
        fields = this.getCalcFieldChanges(fields);
        this.save.emit({item: this.item, form: this.form, fields, index: this.rowIndex});
    }

    selectChanged(ev, fieldName) {
        if (this.focusField === fieldName) {
            if (this.preventChange()) {
                this.focusField = null;
                this.item.openedItem[fieldName] = this.valBefore;
                this.changeChoices();
                this.select.value = this.valBefore;
                return;
            }
            this.focusField = null;
            this.boolChanged(this.item.openedItem[fieldName], fieldName);
        }
    }

    saveBefore() {
        this.valBefore = this.base.copy(this.item.openedItem[this.row.fieldName])
    }

    textChange(ev) {
        this.changedField = this.row.fieldName;
        if (this.row.jsonKey) {
            this.item.openedItem[this.row.fieldName] = this.item.openedItem[this.row.fieldName] || {};
            this.item.openedItem[this.row.fieldName][this.row.jsonKey] = ev.detail.value;
        }
    }
    textChanged(val, fieldName) {
        if (this.changedField === fieldName && this.focusField === fieldName) {
            const value = this.item.openedItem[this.row.fieldName];
            if (this.row.pattern && value) {
                const re = new RegExp(this.row.pattern);
                if (!re.test(value)) {
                    this.base.sendToast(this.trans.instant('value-is-invalid', {value}));
                    this.item.openedItem[this.row.fieldName] = null;
                    return;
                }
            }
            this.changedField = null;
            this.focusField = null;
            this.boolChanged(val, fieldName);
        }
    }

    upload() {
        this.file.nativeElement.click();
    }

    loadFile(ev) {
        const file = ev.target.files[0];
        this.base.uploadFileToDrive(file).subscribe(resp => {
            if (resp && resp.url) {
                this.item.openedItem[this.row.fieldName] = resp.url;
                this.boolChanged(resp.url, this.row.fieldName);
            } else if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    loadImage(ev) {
        const file = ev.target.files[0];
        this.base.uploadFile(file).subscribe(resp => {
            if (resp && resp.url) {
                this.item.openedItem[this.row.fieldName] = resp.url;
                this.boolChanged(resp.url, this.row.fieldName);
            } else if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
        
    }

    uploadImage() {
        this.image.nativeElement.click();
    }

    valueChange() {
        this.valueIsEmpty = this.isEmpty(this.item.openedItem[this.row.fieldName]);
        if (this.row.type === 'foreign') {
            this.changeForeignRepr();
        }
        if (this.row.type === 'file') {
            this.fileUrl = this.base.getFileUrl(this.item.openedItem[this.row.fieldName]);
        }
        if (this.row.type === 'status_type') {
            this.statusChange();
        }
        if (this.row.type === 'select') {
            this.changeChoices();
        }
    }

    statusChange() {
        this.status = this.meta.getStatus(this.item.openedItem.status_type);
    }

    changeError() {
        this.errorRepr = this.getErrors(this.error); 
    }

    changeForeignRepr() {
        if (this.row.fieldRepr) {
            this.foreignRepr = this.base.getRepr(this.item.openedItem[this.row.fieldName], this.row.fieldRepr, this.item.openedItem);
        }
    }

    changeChoices() {
        if (this.row.choicesFunc) {
            this.choices = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem,
                this.row.choicesFunc, this.relateds || this.relateds, null, this);
        } else {
            this.choices = this.base.getFieldChoices(this.row.fieldName, this.fields);
        }
        if (!this.select) {
            return;
        }
        for (const c of this.choices) {
            if (c[0] === this.item.openedItem[this.row.fieldName]) {
                this.select.selectedText = c[1];
                break;
            }
        }
    }

    isAddFieldChanged() {
        this.addField = this.isAddFields(this.form.data.rows, this.item.openedItem, this.hideEmpties);
    }

    getCalcFieldChanges(values) {
        if (this.form &&  this.form.data && this.form.data.rows) {
            const fields = this.base.getTableMeta(this.form.data.table).fields;
            for (const row of this.form.data.rows.filter(i => i.type === 'calcfield' && i.fieldName)) {
                if (fields.find(i => i.name === row.fieldName)) {
                    if (values[row.fieldName] === undefined) {
                        values[row.fieldName] = this.getCalcRepr(row.fieldRepr);
                    }
                }
            }
        }
        return values;
    }

    changeActiveRelateds() {
        if (this.activeRelateds.values && this.activeRelateds.values[this.form.data.table] === this.item.openedItem.tempId) {
            this.activeRelateds.values[this.form.data.table] = undefined;
        } else {
            this.activeRelateds.values[this.form.data.table] = this.base.copy(this.item.openedItem.tempId);
        }
    }

    onActivateSection() {
        if (this.row.sectionIndx !== undefined && this.row.sectionIndx !== null) {
            this.activateSection.emit({i: parseInt(this.row.sectionIndx, 10)});
        }
    }

    async calcfieldClick() {
        if (this.row.relatedTable && this.row.relatedField && this.row.findObject) {
            let value = this.getCalcRepr(this.row.findObject);
            const f = this.base.getTableMeta(this.row.relatedTable).fields.find(ff => ff.name === this.row.relatedField);
            if (f) {   
                const modal = await this.modalCtrl.create({
                    component: ChoicesPage,
                    componentProps: {
                        model: f.related_model,
                        funcRepr: f.func_repr,
                        selected: value ? value.item.openedItem[this.row.relatedField] : null,
                        formPk: this.row && this.row.form ? this.row.form[this.base.getTablePk('Form')] : null, 
                    },
                    showBackdrop: false
                });
                modal.onDidDismiss().then((details: any) => {
                    if (details && details.data && details.data.item) {
                        const pk = f.related_field || this.base.getTablePk(f.related_model);
                        if (value && value.item.openedItem[f.name] && details.data.item[pk] === value.item.openedItem[f.name][pk]) {
                            return;
                        }
                        if (!value) {
                            this.addRow(this.row.relatedTable);
                            value = this.relateds[this.row.relatedTable][this.relateds[this.row.relatedTable].length - 1];
                        }
                        const form = this.rootForm.data.relateds.find(i => i.data.table === this.row.relatedTable);
                        value.item.openedItem[f.name] = details.data.item;
                        let fields: any; fields = {};
                        fields[f.name] = details.data.item;

                        let rowIndex = -1; let cnt = 0;
                        for (const rel of this.relateds[this.row.relatedTable]) {
                            if (rel === value) {
                                rowIndex = cnt;
                                break;
                            }
                            cnt ++;
                        }
                        this.save.emit({item: value.item, form, fields, index: rowIndex});
                    }
                });
                return await modal.present();
            }
        }
    }

    addRow(table) {
        const fields = this.base.getTableMeta(table).fields;
        let item: any; item = {};
        const f = fields.find(i => i.related_model === this.rootForm.data.table);
        const pk = this.base.getTablePk(this.rootForm.data ? this.rootForm.data.table : null);
        if (f && this.root && this.root && this.root.openedItem && this.root.openedItem[pk]) {
            item[f.name] = this.root.openedItem;
        }
        if (!this.relateds[table]) {
            this.relateds[table] = [];
        }
        const form = this.rootForm.data.relateds.find(i => i.data.table === table);
        this.activeRelateds.values[this.form.data.table] = this.base.copy(this.item.openedItem.tempId);
        this.base.setDefault(item, form, this.root, this.activeRelateds, this.relateds);

        this.relateds[table].splice(this.relateds[table].length, 0, {item: {openedItem: item}, error: null, fields, hideEmpties: {}});
    }

    editObj() {
        if (this.item.openedItem && this.row.form) {
            const formPk = this.base.getTablePk('Form');
            const filters = JSON.stringify([{name: formPk, equal: Equal.EQ, value: this.row.form[formPk]}]);
            this.base.getData('Form', 1, 2, `&filter_fields=${filters}`).subscribe(resp => {
                if (resp && resp.data && resp.data.length === 1) {
                    this.beforeOpenItem(resp.data[0]);
                } else {
                    console.error('Object is not found', this.item.openedItem);
                }
            });
        }
    }

    async beforeOpenItem(form) {
        if (this.row.fieldName) {
            const field = this.fields.find(f => f.name === this.row.fieldName);
            if (field && field.related_model && this.item.openedItem[this.row.fieldName]) {
                const pk = this.base.getTablePk(field.related_model);
                if (pk && this.item.openedItem[this.row.fieldName][pk]) {
                    const filters = JSON.stringify([{name: pk, equal: Equal.EQ, value: this.item.openedItem[this.row.fieldName][pk]}]);
                    this.base.getData(field.related_model, 1, 2, `&filter_fields=${filters}`).subscribe(resp => {
                        if (resp && resp.data && resp.data.length === 1) {
                            this.openItem(form, {openedItem: resp.data[0]});
                        } else {
                            console.error('Object is not found', this.item.openedItem);
                        }
                    });
                }
            }
            
        } else if (this.tablePk && this.item.openedItem[this.tablePk]) {
            this.openItem(form, this.item);
        }
    }

    async openItem(form, item) {
        const props: any = {}; 
        props.item = item;
        props.form = form;
        this.base.openForm(FormPage, props, (_) => {});
    }

    checkbox() {
        this.item.openedItem[this.row.fieldName] = !this.item.openedItem[this.row.fieldName]; 
        this.boolChanged(this.item.openedItem[this.row.fieldName], this.row.fieldName)
    }

    private preventChange(): boolean {
        if (this.row && this.row.preventFunc) {
            const msg = this.getCalcRepr(this.row.preventFunc);
            if (msg) {
                this.base.sendToast(msg);
                return true;
            }
        }
        return false;
    }

    call() {
        let phone: any;
        if (this.row.fieldRepr) {
            phone = this.base.getCalcRepr(this.item.openedItem, this.root ? this.root.openedItem : this.item.openedItem, 
                this.row.fieldRepr, this.relateds, null, this);
        } else if (this.row.fieldName) {
            phone = this.item.openedItem[this.row.fieldName];
        }

        if (!phone) {
            return
        }
        this.sip.newCall(phone, this.row.nameField && this.item.openedItem[this.row.nameField] ? this.item.openedItem[this.row.nameField] : '',
                         this.row.imageField && this.item.openedItem[this.row.imageField] ? this.item.openedItem[this.row.imageField] : '');
    }
}
