import { Base } from "./base";

export class GeoLocation {

    constructor(public lat: number,
                public lon: number,
                public id: string,
                public title: string,
                public icon: string,
                public label: string) {
                    
        this.lat = Base.toFloat(lat);
        this.lon = Base.toFloat(lon);
    }
}