import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from '../services/base.service';
import { TemplateService } from '../services/template.service';
import { ModalController } from '@ionic/angular';
import { finalize, map } from 'rxjs/operators';
import { Constants } from '../classes/app.constants';

@Component({
    selector: 'app-background',
    templateUrl: './background.page.html',
    styleUrls: ['./background.page.scss'],
})
export class BackgroundPage implements OnInit {
    
    @Input() hideOptions: boolean = false;
    @Input() isColor: boolean = false;

    items: any[] = [];
    hasNext: boolean = false;
    page: number = 1;
    scrollComplete: boolean = true;

    constructor(private base: BaseService, 
                private templateService: TemplateService, 
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.loadBackgrounds();
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    loadBackgrounds(infiniteScroll?) {
        if (!this.scrollComplete) {
            return;
        }
        this.scrollComplete = false;
        this.templateService.getBackgrounds(this.isColor, this.page, Constants.ON_PAGE, null)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                    if (infiniteScroll) {
                        infiniteScroll.target.complete();
                    }
                }),
                map((resp) => {
                    resp.data = resp.data.map(item => {
                        item.style = this.base.getBackground(item, false);
                        return item;
                    });
                    return resp;
                })
            )
            .subscribe(resp => {
                if (resp && resp.data) {
                    if (this.page === 1) {
                        this.items = resp.data;
                    } else {
                        for (const item of resp.data) {
                            this.items.splice(this.items.length, 0, item);
                        }
                    }
                    this.hasNext = resp.has_next;

                    if (this.hasNext) {
                        this.page++;
                    }
                }
            });
    }

    chooseBackground(background: any) {
        this.modalCtrl.dismiss({background});
    }

    segmentChanged(_) {
        this.isColor = !this.isColor;
        this.page = 1;
        this.loadBackgrounds();
    }
}
