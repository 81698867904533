import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TaskPage } from './task.page';
import { UserPhotoPageModule } from '../components/user-photo/user-photo.module';
import { UserObjectPageModule } from '../components/user-object/user-object.module';
import { TagPageModule } from '../tag/tag.module';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CommentPageModule } from '../components/comment/comment.module';
import { UploadFileBtnPageModule } from '../components/upload-file-btn/upload-file-btn.module';

const routes: Routes = [
    {
        path: '',
        component: TaskPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        UserPhotoPageModule,
        RouterModule.forChild(routes),
        UserObjectPageModule,
        TagPageModule,
        TranslateModule,
        DatePickerModule,
        CommentPageModule,
        UploadFileBtnPageModule
    ],
    declarations: [TaskPage]
})
export class TaskPageModule {}
