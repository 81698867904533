import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MetaService } from '../services/meta.service';
import { ModalController, AlertController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { PageService } from '../services/page.service';
import { ViseStatusService } from '../services/vise-status.service';
import { ChoicesPage } from '../choices/choices.page';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import domtoimage from 'dom-to-image-improved';
import { jsPDF } from "jspdf";
import { Constants } from '../classes/app.constants';
import { ExtraFields } from '../classes/extra-fields';
import { Equal, FieldType } from '../classes/enums';
import { BoardFilter } from '../classes/board-filters';
import { Base } from '../classes/base';
import { BoardDate } from '../classes/date';
import { AppComponent } from '../app.component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-form',
    templateUrl: './form.page.html',
    styleUrls: ['./form.page.scss'],
})
export class FormPage implements OnInit {

    fromRelateds: any;
    returnCreated = false;
    openedVise = false;
    isModal = false;
    form: any;
    item: any;
    error: any;
    fields: any;
    month = '';
    currentUser = null;
    hideEmpties: any;
    showForm = false;
    languages = Constants.LANGUAGES;
    rowWidgets = [
        {
            type: 'textarea',
            fieldName: '',
            placeholder: '',
            autoGrow: false,
            maxlength: null,
            rows: 1,
            autofocus: false,
            class: [],
            style: [],
            relatedDefault: null,
        },
        {
            type: 'readonly',
            fieldName: '',
            class: [],
            style: [],
        },
        {
            type: 'closerelated',
            class: [],
            style: [],
        },
        {
            type: 'status_type',
            class: [],
            style: [],
        },
        {
            type: 'relatedcheck',
            class: [],
            style: [],
        },
        {
            type: 'close',
            class: [],
            style: [],
        },
        {
            type: 'error',
            class: [],
            style: [],
        },
        {
            type: 'label',
            class: [],
            style: [],
            fieldName: '',
            fieldType: '',
            verboseName: ''
        },
        {
            type: 'foreign',
            class: [],
            style: [],
            fieldName: '',
            fieldRepr: 'name',
            relatedFilters: [],
            fieldChoices: [],
            relatedDefault: null,
            defaultForeign: null,
        },
        {
            type: 'date',
            class: [],
            style: [],
            fieldName: '',
            placeholder: '',
            defaultDays: null,
            relatedDefault: null,
        },
        {
            type: 'datetime',
            class: [],
            style: [],
            fieldName: '',
            placeholder: '',
            defaultDays: null,
            relatedDefault: null,
        },
        {
            type: 'datetimepicker',
            class: [],
            style: [],
            fieldName: '',
            placeholder: '',
            defaultDays: null,
            relatedDefault: null,
        },
        {
            type: 'time',
            class: [],
            style: [],
            fieldName: '',
            placeholder: '',
            relatedDefault: null,
        },
        {
            type: 'user',
            class: [],
            style: [],
            fieldName: '',
            fieldRepr: 'name',
            relatedDefault: null,
            defaultForeign: null,
            defaultCurrent: false
        },
        {
            type: 'checkbox',
            class: [],
            style: [],
            fieldName: '',
            defaultBool: false,
        },
        {
            type: 'map',
            class: [],
            style: [],
            lat: '',
            lng: '',
            zoom: 10,
            address: '',
            saveCalc: ''
        },
        {
            type: 'input',
            class: [],
            style: [],
            fieldName: '',
            inputType: '',
            placeholder: '',
            relatedDefault: null,
            inputDefault: null
        },
        {
            type: 'select',
            class: [],
            style: [],
            fieldName: '',
            relatedDefault: null,
            defaultSelect: null,
        },
        {
            type: 'add_label',
            class: [],
            style: [],
            verboseName: '',
        },
        {
            type: 'add_field',
            class: [],
            style: [],
            verboseName: '',
            fieldName: ''
        },
        {
            type: 'vise',
            class: [],
            style: [],
        },
        {
            type: 'chat',
            class: [],
            style: [],
            table: '',
        },
        {
            type: 'catalog',
            class: [],
            style: [],
            parentTable: '',
            parentTable2: '',
            elementTable: '',
            relatedTable: '',
            parentField: '',
            parentField2: '',
            relatedField: '',
            title: '',
            incrementField: '',
            relatedFill: [],
            cardsCnt: 0,
        },
        {
            type: 'delete',
            class: [],
            style: [],
        },
        {
            type: 'file',
            class: [],
            style: [],
            fieldName: '',
        },
        {
            type: 'image',
            class: [],
            style: [],
            fieldName: '',
            fieldRepr: '',
        },
        {
            type: 'calcfield',
            fieldRepr: '',
            verboseName: '',
            class: [],
            style: [],
            fieldName: '',
        },
        {
            type: 'barcode',
            class: [],
            style: [],
            fieldName: '',
            width: 2,
            height: 100,
            format: 'EAN13',
            fieldRepr: ''
        },
        {
            type: 'json',
            class: [],
            style: [],
            fieldName: '',
            schema: {},
            layout: [],
            options: null,
            language: '',
            framework: 'material-design',
        },
        {
            type: 'div',
            class: [],
            style: [],
            fieldRepr: ''
        },
        {
            type: 'edit',
            class: [],
            style: [],
            fieldName: '',
        },
        {
            type: 'call',
            class: [],
            style: [],
            fieldName: '',
            nameField: '',
            imageField: '',
            fieldRepr: ''
        },
        {
            type: 'hierarchy',
            class: [],
            style: [],
            title: '',
            cardsCnt: 0,
            hierarchies: []
        },
    ];
    rowButtons = [
        {
            type: 'vise_notify',
            class: [],
            style: [],
            title: this.trans.instant('notify-viser'),
        },
        {
            type: 'send_to',
            class: [],
            style: [],
            title: this.trans.instant('send-to'),
        },
        {
            type: 'reject',
            class: [],
            style: [],
            title: this.trans.instant('reject')
        },
        {
            type: 'approve',
            class: [],
            style: [],
            title: this.trans.instant('vise')
        },
        {
            type: 'save',
            class: [],
            style: [],
            title: this.trans.instant('save')
        },
        {
            type: 'saveFrom',
            class: [],
            style: [],
            title: this.trans.instant('save-from'),
            fields: [],
            form: {},
            table: '',
            fieldFill: '',
        },
        {
            type: 'delete',
            class: [],
            style: [],
            title: this.trans.instant('delete'),
        },
        {
            type: 'fillFrom',
            class: [],
            style: [],
            title: this.trans.instant('fill-from'),
        },
        {
            type: 'print',
            class: [],
            style: [],
            title: this.trans.instant('print'),
            toPdf: {width: 0, height: 0}
        },
        {
            type: 'fillField',
            class: [],
            style: [],
            title: this.trans.instant('fill-field'),
            hideFunc: '',
            updates: '',
            close: true,
        },
        {
            type: 'execRequest',
            class: [],
            style: [],
            title: this.trans.instant('exec-request'),
            execFunc: '',
            updates: '',
            close: true,
        }
    ];
    isSaveForm = true;
    relateds: any;
    formPk: string;
    itemPk: string;
    userCanReject = false;
    userCanApprove = false;
    userViseType = null;
    userHasMore = false;
    minQueue = null;
    rowsSubject = new BehaviorSubject(null);
    changedItemSubject: any;
    itemChanged = new BehaviorSubject(null);
    fieldReprChanged = new BehaviorSubject(null);
    hideEmptiesSubject = new BehaviorSubject(null);
    refreshRelatedsSubject = new BehaviorSubject(null);
    exportSubject = new BehaviorSubject(null);
    subscriptionCatalog = new BehaviorSubject(null);
    subscription: any;
    scrollComplete = true;
    relatedsCnt = 5000;
    leftSideStyle: any;
    leftRowsStyle: any;
    activeRelateds: any = {values: {}};
    tables = [];
    tablesFields = {};
    canRequest = true;
    alreadyAdded = false;
    showRels = {};
    sectionNgStyle: any;
    actionSection = null;
    startPrint = false;
    toPdf: any;;

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.changeRelateds();
        this.changeRows();
        this.changeButtons();
    }

    constructor(public meta: MetaService, 
                private modalCtrl: ModalController, 
                public base: BaseService,
                private alertCtrl: AlertController, 
                public pageService: PageService,
                private viseStatusService: ViseStatusService, 
                private trans: TranslateService,
                private http: HttpClient, 
                @Inject(DOCUMENT) private document) { }

    ngOnInit() {
        this.tables = this.meta.getTablesArray();
        for (const t of this.base.getLocalMeta()) {
            this.tablesFields[t.table] = t.fields;
        }
        this.tables.splice(0, 0, [null, null]);
        this.currentUser = Base.getCurrentUser();
        this.month = this.base.getMonthList();
        this.formPk = this.base.getTablePk('Form');
        if (!this.startPrint) {
            this.relateds = {};
        }

        // it is opened item: not update form.
        if (this.item && this.item.openedItem) {
            this.isSaveForm = false;
        }

        // choose form.
        if (this.form && this.form[this.formPk]) {
            this.isModal = true;
            this.afterGettingForm(this.form);
        }

        // update object if that is changed.
        if (this.changedItemSubject) {
            this.subscription = this.changedItemSubject.subscribe(_ => {
                this.changeVises();
                this.updateButtonsToggle();
                this.itemChanged.next(0);
                this.refreshVisibilityWidgets();
            });
        }

        if (this.startPrint) {
            setTimeout(() => {
                this.print();
            }, 2000);
        }
    }

    ionViewCanLeave() {
        if (!this.form) {
            return true;
        }
        if (this.startPrint) {
            return true;
        }
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        if (this.item && this.item.openedItem && this.item.openedItem[pk] && !this.isSaveForm && !this.alreadyAdded && this.form.data.beforeCreateRelateds) {
            const msg = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, this.form.data.beforeCreateRelateds, this.relateds);
            if (msg) {
                this.base.sendToast(msg);
                return false;
            }
        }
        return true;
    }

    ionViewWillLeave() {
        if (!this.form) {
            return;
        }
        if (this.startPrint) {
            return;
        }
        if (this.form && this.form[this.formPk]) {
            this.save(() => {});
        }
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        if (this.item && this.item.openedItem && this.item.openedItem[pk] && !this.isSaveForm && !this.alreadyAdded) {
            this.createRelateds(this.item.openedItem[pk], this.item.openedItem);
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    closeModal(ev?) {
        if (this.ionViewCanLeave()) {
            this.modalCtrl.dismiss();
        }
    }

    async createForm() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-form-name'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: this.trans.instant('name'),
                }
            ],
            buttons: [
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params.name) {
                            this.form = {name: params.name};
                            this.alertTable();
                        } else {
                            this.base.sendToast(this.trans.instant('name-required'));
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    async alertTable() {
        let inputs = []; const tables = this.meta.getTables();
        for (const key of Object.keys(tables)) {
            inputs.push({
                name: 'table',
                type: 'radio',
                label: tables[key],
                value: key
            });
        }

        if (!inputs.length) {
            return this.base.sendToast(this.trans.instant('form-type-undefined'));
        }

        inputs = this.base.sort(inputs, 'label');
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-table'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.form.data = {table: params};
                            this.base.createTable({name: this.form.name, data: this.form.data, table: this.form.data.table,
                                                   is_default: false, type: 0}, 'Form').subscribe(resp => {
                                if (resp && resp[this.formPk]) {
                                    this.chooseForm(resp[this.formPk]);
                                } else {
                                    this.base.sendToast(this.base.getError(resp));
                                }
                            });
                        } else {
                            this.base.sendToast(this.trans.instant('form-type-undefined'));
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async getForm() {
        const selected = {}; selected[this.formPk] = this.form ? this.form[this.formPk] : null;
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
                selected,
                filters: [new BoardFilter('type', Equal.EQ, 0)]
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                this.chooseForm(details.data.item[this.formPk]);
            }
        });
        return await modal.present();
    }

    chooseForm(primaryKey) {
        const f = () => {
            const params = JSON.stringify([{name: this.formPk, equal: Equal.EQ, value: primaryKey}]);
            this.base.getData('Form', 1, 1, `&filter_fields=${params}`).subscribe(resp => {  // Get form.
                
                if (resp && resp.data.length) {
                    this.afterGettingForm(resp.data[0]);
                }
            });
        };

        if (this.form && this.form[this.formPk]) {
            this.save(f);
        } else {
            f();
        }
    }

    afterGettingForm(form) {
        if (!this.startPrint) {
            this.relateds = {};
        }
        
        this.form = form;
        if (this.form && this.form.data) {
            if (!this.form.data.lang) {
                this.form.data.lang = this.base.getLanguage();
            }
            if (!this.form.data.leftSide) {
                this.form.data.leftSide = {style: [], class: []};
            }
            if (!this.form.data.leftRows) {
                this.form.data.leftRows = {style: [], class: []};
            }
            if (this.form.data.buttons) {
                for (const btn of this.form.data.buttons) {
                    const title = this.base.translate(btn.titles);
                    if (title) {
                        btn.title = title;
                    }
                }
            }
            if (this.form.data.relateds) {
                for (const rel of this.form.data.relateds) {
                    if (!rel.title.titles) {
                        rel.title.titles = {};
                    }
                    const title = this.base.translate(rel.title.titles);
                    if (title) {
                        rel.title.title = title;
                    }
                }
            }
            if (this.form.data.sections) {
                for (const rel of this.form.data.sections) {
                    if (!rel.title.titles) {
                        rel.title.titles = {};
                    }
                    const title = this.base.translate(rel.title.titles);
                    if (title) {
                        rel.title.title = title;
                    }
                }
            }
            if (this.form.data.sections && this.form.data.sections.length) {
                this.actionSection = this.base.copy(this.form.data.sections[0]);
            }
        }
        this.changeRelateds();
        this.changeRows();
        this.changeButtons();
        this.fields = this.base.getTableMeta(this.form.data.table).fields;
        this.base.getCalcFields(this.form.data.table).subscribe(fields => {      // Get calc fields of table.
            if (fields && fields.length) {
                for (const ff of fields) {
                    this.fields.splice(this.fields.length, 0, ff);
                }
            }
            this.openedVise = this.form.data && this.form.data.rows 
                && this.item && this.item.openedItem && this.item.openedItem[this.itemPk] && 
                this.form.data.rows.find(i => i.type === 'vise' || i.type === 'chat') ? true : false;
            this.openedVise = this.openedVise || (this.form.data && this.form.data.rows 
                && this.form.data.rows.find(i => i.type === 'catalog' || i.type === 'hierarchy') ? true : false);
            this.hideEmpties = {};
            if (this.form && this.form.data && this.form.data.rows) {
                for (const r of this.form.data.rows) {
                    if (r.type === 'add_field') {
                        this.hideEmpties[r.fieldName] = true;
                    }
                }
            }
            this.hideEmptiesSubject.next(this.hideEmpties);
            
            this.itemPk = this.base.getTablePk(this.form.data.table);
            if (this.startPrint) {
                return;
            }
            // If object is not set it is edit form page: get one item for test.
            if ((!this.item || !this.item.openedItem) || !this.isModal) {
                this.item = {openedItem: {}};
                this.base.getData(this.form.data.table, 1, 1, '').subscribe(resp3 => {
                    if (resp3 && resp3.data && resp3.data.length) {
                        this.item.openedItem = resp3.data[0];
                        if (this.item.openedItem.statuses && this.item.openedItem.statuses.length) {
                            this.item.openedItem.status_type = 
                                this.item.openedItem.statuses[this.item.openedItem.statuses.length - 1].type;
                        }
                        this.updateRelateds();
                    }
                });
            } else {
                if (!this.item.openedItem[this.itemPk]) {
                    this.setDefault(this.item.openedItem, this.form);
                    this.itemChanged.next(0);
                }
                this.updateRelateds();
            }
        });
    }

    save(onsuccess) {
        if (!this.isSaveForm) {
            return;
        }
        if (this.form && this.form[this.formPk]) {
            this.base.updateTable({name: this.form.name, data: this.form.data, 
                                   table: this.form.data.table, 
                                   is_default: this.form.is_default}, this.form[this.formPk], this.form.edited, 'Form')
                .subscribe(resp => {
                    if (resp && resp[this.formPk]) {
                        onsuccess();
                    } else if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
        }
    }

    async addRow(data, fields, isCap = false, section?) {
        const inputs = [];
        for (const w of this.base.sort(fields, 'verbose_name')) {
            inputs.push({
                name: 'field',
                type: 'radio',
                label: w.verbose_name,
                value: w.name
            });
        }

        inputs.push({
            name: 'field',
            type: 'radio',
            label: this.trans.instant('without-field'),
            value: 'uknown_field'
        });

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.alertFieldType(params, data, fields, isCap, section);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async alertFieldType(fieldName, data, fields, isCap = false, section?) {
        if (!data.rows) {
            data.rows = [];
        }

        if (isCap && !data.cap) {
            data.cap = [];
        }

        const f = fields.find(i => i.name === fieldName);
        let widgets: any; widgets = {};
        
        if (f) {
            widgets.label = this.getRowWidget(fields, 'label', fieldName, f.verbose_name, f);
            widgets.label.fieldType = f.type;
        }

        if (!isCap && f) {
            widgets.calcfield = this.getRowWidget(fields, 'calcfield', fieldName);
        }

        if (isCap) {
            // only label.
        } else if (!f) {
            widgets.error = this.getRowWidget(fields, 'error', null);
            widgets.add_label = this.getRowWidget(fields, 'add_label', null);
            if (fields.find(i => i.name === 'status_type' && i.type === FieldType.INT)) {
                widgets.vise = this.getRowWidget(fields, 'vise', null);
            }
            widgets.close = this.getRowWidget(fields, 'close', null);
            if (data.table !== this.form.data.table) {
                widgets.delete = this.getRowWidget(fields, 'delete', null);
                widgets.edit = this.getRowWidget(fields, 'edit', null);
            }
            widgets.chat = this.getRowWidget(fields, 'chat', null);
            widgets.catalog = this.getRowWidget(fields, 'catalog', null);
            widgets.hierarchy = this.getRowWidget(fields, 'hierarchy', null);
            widgets.calcfield = this.getRowWidget(fields, 'calcfield', null);
            widgets.relatedcheck = this.getRowWidget(fields, 'relatedcheck', null);
            widgets.closerelated = this.getRowWidget(fields, 'closerelated', null);
            widgets.div = this.getRowWidget(fields, 'div', null);
            widgets.map = this.getRowWidget(fields, 'map', null);
            widgets.call = this.getRowWidget(fields, 'call', null, null);
        } else if (f.isCalc) {
            widgets.readonly = this.getRowWidget(fields, 'readonly', fieldName, null, f);
        } else if (f.type === FieldType.CHAR) {
            widgets.textarea = this.getRowWidget(fields, 'textarea', fieldName, null, f);
            widgets.readonly = this.getRowWidget(fields, 'readonly', fieldName, null, f);
            widgets.input = this.getRowWidget(fields, 'input', fieldName, null, f);
            widgets.input.inputType = 'text';
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
            widgets.image = this.getRowWidget(fields, 'image', fieldName, null, f);
            widgets.barcode = this.getRowWidget(fields, 'barcode', fieldName, f.verbose_name, f);
            widgets.call = this.getRowWidget(fields, 'call', fieldName, f.verbose_name, f);
            widgets.file = this.getRowWidget(fields, 'file', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.INT && f.name === 'status_type') {
            widgets.status_type = this.getRowWidget(fields, 'status_type', null, null, f);
            widgets.call = this.getRowWidget(fields, 'call', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.REF || f.type === 'UUIDField') {
            widgets.foreign = this.getRowWidget(fields, 'foreign', fieldName, null, f);
            if (f.related_model === 'User' || f.related_model2 === 'User' || f.related_model === 'portaluser') {
                widgets.user = this.getRowWidget(fields, 'user', fieldName, null, f);
            }
            
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
            widgets.edit = this.getRowWidget(fields, 'edit', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.DATE) {
            widgets.date = this.getRowWidget(fields, 'date', fieldName, null, f);
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.DATETIME) {
            widgets.datetime = this.getRowWidget(fields, 'datetime', fieldName, null, f);
            widgets.time = this.getRowWidget(fields, 'time', fieldName, null, f);
            widgets.datetimepicker = this.getRowWidget(fields, 'datetimepicker', fieldName, null, f);
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.TIME) {
            widgets.time = this.getRowWidget(fields, 'time', fieldName, null, f);
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
        } else if (f.type === FieldType.BOOL) {
            widgets.checkbox = this.getRowWidget(fields, 'checkbox', fieldName, null, f);
        } else if (f.type === FieldType.DECIMAL) {
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
            widgets.input = this.getRowWidget(fields, 'input', fieldName, null, f);
            widgets.input.inputType = 'number';
        } else if (f.type === FieldType.INT && f.choices && f.choices.length) {
            widgets.add_field = this.getRowWidget(fields, 'add_field', fieldName, f.verbose_name, f);
            widgets.select = this.getRowWidget(fields, 'select', fieldName, null, f);
        } else if (f.type === FieldType.INT) {
            widgets.input = this.getRowWidget(fields, 'input', fieldName, null, f);
            widgets.input.inputType = 'number';
        } else if (f.type === 'file') {
            widgets.file = this.getRowWidget(fields, f.type, fieldName, f.verbose_name, f);
        } else if (f.type === 'JSONField') {
            widgets.textarea = this.getRowWidget(fields, 'textarea', fieldName, null, f);
            widgets.json = this.getRowWidget(fields, 'json', fieldName, f.verbose_name, f);
            widgets.input = this.getRowWidget(fields, 'input', fieldName, null, f);
            widgets.input.inputType = 'text';
            widgets.input.jsonKey = '';
        }
        
        const inputs = [];
        for (const key of Object.keys(widgets)) {
            inputs.push({
                name: 'field',
                type: 'radio',
                label: key,
                value: key
            });
        }

        const onsuccess = (params) => {
            if (params) {
                const w = widgets[params];
                if (section) {
                    w.section = section.pk;
                }
                if (isCap) {
                    data.cap.splice(data.cap.length, 0, w);
                    data.cap = this.base.copy(data.cap);
                } else {
                    data.rows.splice(data.rows.length, 0, w);
                    data.rows = this.base.copy(data.rows);
                    this.hideEmptiesSubject.next(this.hideEmpties);
                }
                if (data.table === this.form.data.table) {
                    this.rowsSubject.next(0);
                }
            }
        };

        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('widget-field-not-found'));
            return;
        } else if (inputs.length === 1) {
            onsuccess(inputs[0].value);
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-widget'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: 'Ok',
                    handler: onsuccess
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addButton() {
        const inputs = [];
        for (const w of this.rowButtons) {
            inputs.push({
                name: 'field',
                type: 'radio',
                label: w.title,
                value: w.type
            });
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-buttom'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            const b = this.rowButtons.find(i => i.type === params);
                            if (!this.form.data.buttons) {
                                this.form.data.buttons = [];
                            }
                            this.form.data.buttons.splice(this.form.data.buttons.length, 0, b);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    getRowWidget(fields, type, name, verboseName?, field?) {
        const f = this.base.copy(this.rowWidgets.find(i => i.type === type));
        if (name) {
            f.fieldName = name;
        }
        if (verboseName) {
            f.verboseName = verboseName;
        }
        if (field && field.verbose_name) {
            f.title = field.verbose_name;
        }
        if (type === 'foreign' && field) {

            if (field.func_repr) {
                f.fieldRepr = field.func_repr;
            } else if (field.repr) {
                f.fieldRepr = field.repr;
            }
            const m = this.base.getTableMeta(field.related_model);
            if (m && m.fields) {
                const choices = [];
                for (const cField of fields) {
                    if (cField.type === FieldType.REF) {
                        const rf = m.fields.find(i => i.related_model === cField.related_model);
                        if (rf) {
                            choices.push({from: cField, to: rf});
                        }
                    } 
                }
                if (choices.length) {
                    f.fieldChoices = choices;
                }
            }
        }
        return f;
    }

    async remove() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('delete-form-warning', {name: this.form.name}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        this.base.removeTable(this.form[this.formPk], null, 'Form').subscribe(resp => {
                            if (resp && resp.error) {
                                this.base.sendToast(this.base.getError(resp.error));
                            } else {
                                this.form = null;
                            }
                        });
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                }
            ]
        });
        await alert.present();
    }

    saveObj(btn?) {
        if (this.btnPrevent(btn)) {
            return;
        }
        if (this.startPrint) {
            return;
        }
        if (this.form && this.form.data && this.form.data.validate && this.validateRelatedTables()) {
            return;
        }
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        this.saveOrCreateObj(this.form.data, this.fields, this.item, true, null, null, null, btn);
    }

    validateRelatedTables() {
        for (const related of this.form.data.relateds || []) {
            const table = related.data.table;
            if (!this.relateds[table] || !this.relateds[table].length) {
                continue;
            }
            const fields = this.base.getTableMeta(table).fields;
            const f = fields.find(i => i.related_model === this.form.data.table);
            const form = this.form.data.relateds.find(i => i.data.table === table);
            const pk = this.base.getTablePk(table);
            const pkM = f && f.related_field ? f.related_field : this.base.getTablePk(this.form.data ? this.form.data.table : null);
            for (let k = 0; k < this.relateds[table].length; k++) {
                const obj = this.base.copy(this.relateds[table][k]);
                if (obj.item.openedItem[pk]) {
                    continue;
                }
                if (f) {
                    obj.item.openedItem[f.name] = {};
                    obj.item.openedItem[f.name][pkM] = 'null';
                }
                if (form && form.data && form.data.rows) {
                    const relFields = this.base.getTableMeta(table).fields;
                    for (const row of form.data.rows.filter(i => i.type === 'calcfield' && i.fieldName)) {
                        if (relFields.find(i => i.name === row.fieldName)) {
                            if (obj.item.openedItem[row.fieldName] === undefined) {
                                obj.item.openedItem[row.fieldName] = this.base.getCalcRepr(obj.item.openedItem, 
                                                                                           this.item.openedItem, row.fieldRepr, 
                                                                                           this.relateds);
                            }
                        }
                    }
                }
                const respObj = this.getObjectBeforeSave(form.data, fields, obj.item, false);
                const error = this.presentError(respObj.errors, true);
                if (error) {
                    this.relateds[table][k].error = error;
                    this.itemChanged.next({error, index: k});
                    this.refreshVisibilityWidgets();
                    return true;
                }
            }
        }
    }

    getObjectBeforeSave(dataRows, objFields, item, upd) {
        const data = {}; let fields: any; fields = {};
        for (const f of dataRows.rows) {  // Get form fields.
            if (f && f.fieldName && ['textarea', 'foreign', 'input', 'date', 'user', 'checkbox', 'select', 'file', 
                                     'image', 'datetime', 'time', 'calcfield', 'datetimepicker', 'json'].indexOf(f.type) !== -1) {
                const ff = objFields.find(i => i.name === f.fieldName);
                if (ff) {
                    fields[f.fieldName] = ff;
                    fields[f.fieldName].related_model = fields[f.fieldName].related_model || f.related_model;
                    if (f.type === 'calcfield') {
                        const v = this.base.getCalcRepr(item.openedItem, this.item, f.fieldRepr, this.relateds);
                        if (v) {
                            item.openedItem[f.fieldName] = v;
                        } 
                    }
                }
            }
        }

        for (const key of Object.keys(fields)) {
            const t = fields[key].type;
            let v = item.openedItem[fields[key].name];
            if (v === undefined) {
                continue;
            }
            if ([FieldType.CHAR, FieldType.BOOL, FieldType.DECIMAL, FieldType.INT, 'file'].indexOf(t) !== -1) {
                data[fields[key].name] = v;
            } else if (t === FieldType.REF) {
                const pk = fields[key].related_field || this.base.getTablePk(fields[key].related_model);
                if (v && v[pk]) {
                    data[fields[key].name] = v[pk];
                } else {
                    data[fields[key].name] = null;
                }
            } else if (t === FieldType.DATE) {
                if (v) {
                    if (typeof(v) === 'object') {
                        v = (new BoardDate(v)).iso;
                    }
                    data[fields[key].name] = v.slice(0, 10); 
                } else {
                    data[fields[key].name] = null;
                }
            } else if (t === FieldType.DATETIME) {
                if (v) {
                    if (typeof(v) === 'object') {
                        // v.setDate(v.getDate() + 1);
                        v = v.toJSON();
                    }
                    data[fields[key].name] = v; // .slice(0, 16); 
                } else {
                    data[fields[key].name] = null;
                }
            } else if (t === FieldType.TIME) {
                if (v) {
                    if (typeof(v) === 'object') {
                        v.setDate(v.getDate() + 1);
                        v = v.toJSON();
                    }
                    if (v.length >= 19) {
                        v = v.slice(11, 19);
                    }
                    data[fields[key].name] = v; 
                } else {
                    data[fields[key].name] = null;
                }
            } else if (t === 'JSONField') {
                data[fields[key].name] = v;
            } else if (t === 'UUIDField') {
                const pk = fields[key].related_field || this.base.getTablePk(fields[key].related_model);
                if (v && v[pk]) {
                    data[fields[key].name] = v[pk];
                } else {
                    data[fields[key].name] = null;
                }
            }
        }
        const errors = [];
        for (const field of dataRows.rows.filter(f => (f.isRequired || f.requiredIf) && f.fieldName)) {
            if (!field.isRequired && field.requiredIf) {
                if (!this.base.getCalcRepr(item, this.item.openedItem, field.requiredIf, this.relateds)) {
                    continue
                }
            }
            if (data[field.fieldName] === null || data[field.fieldName] === '' || (!upd && data[field.fieldName] === undefined)) {
                const ff = objFields.find(i => i.name === field.fieldName);
                const fieldTitle = (ff ? ff.verbose_name : null) || field.fieldName;  
                errors.push({fieldName: field.fieldName, type: 'required', fieldTitle});  
            }
        }
        return {data, errors};
    }

    onresponse(resp, pk, showErrors, objFields, relItem, index, closeModal, btn?) {
        if (resp && resp.error) {
            if (showErrors) {
                const e = [];
                for (const k of Object.keys(resp.error)) {
                    const f = objFields.find(i => i.name === k);
                    if (f && resp.error[k] && resp.error[k].length) {
                        e.push(f.verbose_name + ': ' + resp.error[k].join(', '));
                    }
                }
                if (e.length) {
                    this.base.sendToast(e.join('; '), 5000);
                } else {
                    this.base.sendToast(this.base.getError(resp), 5000);
                }
            }
            if (relItem) {
                relItem.error = resp.error;
                this.itemChanged.next({error: resp.error, index});
                this.refreshVisibilityWidgets();
            } else {
                this.error = resp.error;
                this.itemChanged.next({error: this.error});
                this.refreshVisibilityWidgets();
            }
        } else if (resp && resp[pk]) {
            if (relItem) {
                relItem.item.openedItem[pk] = resp[pk];
                relItem.item.openedItem.edited = resp.edited;
                this.itemChanged.next({clearError: true, index});
                this.refreshVisibilityWidgets();
            } else {
                this.item.openedItem[pk] = resp[pk];
                this.item.openedItem.edited = resp.edited;
                this.itemChanged.next({clearError: true});
                this.refreshVisibilityWidgets();
            }
            if (closeModal) {
                this.alreadyAdded = true;
                this.createRelateds(resp[pk], resp, null, btn);
                if (this.returnCreated) {
                    this.modalCtrl.dismiss({item: resp});
                } else {
                    this.closeModal();
                }    
            }
        }
    }

    saveOrCreateObj(dataRows, objFields, item, closeModal, relItem?, showErrors?, index?, btn?) {
        if (this.startPrint) {
            return;
        }
        const pKey = this.base.getTablePk(dataRows.table);
        const obj = this.getObjectBeforeSave(dataRows, objFields, item, item.openedItem[pKey]);
        const data = obj.data;
        const error = this.presentError(obj.errors, true);
        if (error) {
            if (relItem) {
                relItem.error = error;
                this.itemChanged.next({error, index});
                this.refreshVisibilityWidgets();
            } else {
                this.error = error;
                this.itemChanged.next({error: this.error});
                this.refreshVisibilityWidgets();
            }
            this.canRequest = true;
            return;
        }
        if (item.openedItem[pKey]) {
            this.base.updateTable(data, item.openedItem[pKey], 
                                  item.openedItem.edited, dataRows.table)
            .pipe(
                finalize(() => {
                    this.canRequest = true;
                })
            )    
            .subscribe(resp => {
                this.onresponse(resp, pKey, showErrors, objFields, relItem, index, closeModal);
            });
        } else {
            this.base.createTable(data, dataRows.table)
            .pipe(
                finalize(() => {
                    this.canRequest = true;
                })
            )
            .subscribe(resp => {
                this.onresponse(resp, pKey, showErrors, objFields, relItem, index, closeModal, btn);
            });
        }
    }

    canReject(vises) {
        const pk = this.base.getTablePk('User');
        return vises.filter(i => !i.approve).length 
               && vises.filter(i => i.user && i.user[pk] === this.currentUser[pk] && i.type !== 0).length;
    }

    userVise(vises, isType = false) {
        return this.viseStatusService.userVise(vises, this.currentUser, isType);
    }

    approveVise(vises, btn?) {
        if (this.btnPrevent(btn)) {
            return;
        }
        const vise = this.userVise(vises);
        const pk = this.base.getTablePk('Vise');
        const pkDoc = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        if (vise) {
            this.viseStatusService.approveVise(vise[pk], this.item.openedItem[pkDoc], () => {
                this.closeModal();
            });
        }
    }

    rejectVise(btn) {
        if (this.btnPrevent(btn)) {
            return;
        }
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        this.viseStatusService.rejectVise(this.item.openedItem[pk], this.form.data.table, () => {
            this.closeModal();
        });
    }

    notif() {
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        this.viseStatusService.notifItem(this.item.openedItem[pk], this.item.openedItem);
    }

    sendTo() {
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        this.viseStatusService.sendTo(this.item.openedItem, ChoicesPage, this.item.openedItem[pk], this.form.data.table);
    }

    hasMore(buttons, vises) {
        if (!vises) {
            return false;
        }
        if (!buttons) {
            return false;
        }
        let found = buttons.filter(i => ['vise_notify', 'send_to'].indexOf(i.type) !== -1).length > 0;
        found = found && this.viseStatusService.getMinQueue( vises ) !== null;
        return found; 
    }

    async addTable(section?, toSb?) {
        if (!this.form.data.relateds) {
            this.form.data.relateds = [];
        }

        const tables = this.base.getLocalMeta().filter(t => !t.block);
        const inputs = [];
        for (const table of tables) {
            if (this.form.data.relateds.find(i => i.data.table === table.table) && !toSb) {
                continue;
            }
            if (!table.fields.find(i => i.related_model === this.form.data.table)) {
                continue;
            }
            inputs.push({
                name: 'field',
                type: 'radio',
                label: table.verbose_name,
                value: table.table
            });
        }
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('tables-not-found'));
            return;
        } else if (inputs.length === 1) {
            this.addRelatedTable(inputs[0].value, section);
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-table'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.addRelatedTable(params, section);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    addRelatedTable(params, section?) {
        const table = this.base.getTableMeta(params);
        const item = {
            title: {
                title: table.verbose_name,
                titles: {},
                style: [],
                class: [],
                table: table.verbose_name
            },
            data: {
                table: table.table,
            },
            tableStyle: {
                style: [],
                class: [],
            },
            rowStyle: {
                style: [],
                class: [],
            },
            fullTableStyle: {
                style: [],
                class: [],
            },
            capStyle: {
                style: [],
                class: [],
            },
            section: null
        };
        if (section) {
            item.section = section.pk;
        }
        this.form.data.relateds.splice(this.form.data.relateds.length, 0, item);
        this.form.data.relateds = this.base.copy(this.form.data.relateds);
        this.updateRelated(params);
    }

    updateRelated(table, orderBy?, relatedField?, filterFunc?) {
        const fields = this.base.getTableMeta(table).fields;
        const f = fields.find(i => i.related_model === this.form.data.table && (i.name === relatedField || !relatedField));
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        const filters: BoardFilter[] = [new BoardFilter(f.name, Equal.EQ, this.item.openedItem[f.related_field || pk])];
        if (filterFunc) {
            const result = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, filterFunc, this.relateds);
            if (result && result.length) {
                for (const row of result) {
                    filters.push(BoardFilter.fromJson(row))
                }
            }
        }
        this.scrollComplete = false;
        this.base.getData(table, 1, this.relatedsCnt, '', filters, true)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                })
            )
            .subscribe(data => { 
                const items = [];
                if (data && data.data) {
                    if (data.data.length > 20) {
                        this.scrollComplete = false;
                        setTimeout(() => {this.scrollComplete = true; }, 10 * data.data.length);
                    }
                    if (orderBy) {
                        data.data = this.base.sortObj(data.data, orderBy);
                    }
                    for (const item of data.data) {
                        items.push({item: {openedItem: item}, error: null, fields, hideEmpties: {}});
                    }
                }
                this.relateds[table] = items;
                this.refreshRelatedsSubject.next(this.relateds);
            });
    }

    updateRelateds() {
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        if (!this.item || !this.item.openedItem || !this.item.openedItem[pk]) {
            if (this.fromRelateds) {
                for (const table of Object.keys(this.fromRelateds)) {
                    const items = []; const fields = this.base.getTableMeta(table).feilds;
                    for (const item of this.fromRelateds[table]) {
                        items.push({item: {openedItem: item}, error: null, fields, hideEmpties: {}});
                    }
                    this.relateds[table] = items;
                    this.refreshRelatedsSubject.next(this.relateds);
                }
            }
            for (const rel of this.form.data.relateds || []) {
                if (rel.addRowsCnt) {
                    if (this.relateds[rel.data.table] && this.relateds[rel.data.table].length) {
                        continue;
                    }
                    for (let i = 0; i < rel.addRowsCnt; i++) {
                        this.addRelItem(rel.data.table);
                    }
                }
            }
            return;
        }
        for (const t of this.form.data.relateds || []) {
            this.updateRelated(t.data.table, t.data.orderBy, t.bindField, t.filterFunc);
        }
    }

    addRowRel(data, isCap = false) {
        const fields = this.base.getTableMeta(data.table).fields;
        this.addRow(data, fields, isCap);
    }

    addRelItem(table) {
        const fields = this.base.getTableMeta(table).fields;
        let item: any; item = {};
        const f = fields.find(i => i.related_model === this.form.data.table);
        const pk = this.base.getTablePk(this.form.data ? this.form.data.table : null);
        if (f && this.item && this.item.openedItem && this.item.openedItem[pk]) {
            item[f.name] = this.item.openedItem;
        }
        if (!this.relateds[table]) {
            this.relateds[table] = [];
        }
        const form = this.form.data.relateds.find(i => i.data.table === table);
        this.setDefault(item, form);
        this.relateds[table].splice(this.relateds[table].length, 0, {item: {openedItem: item}, error: null, fields, hideEmpties: {}});
    }

    createRelateds(primaryKey, data?, start?, btn?) {
        if (!this.form.data.relateds || !this.form.data.relateds.length) {
            return;
        }
        this.scrollComplete = false;
        let relateds = this.base.copy(this.form.data.relateds);
        relateds = relateds.sort((a, b) => {
            if (a.data && b.data && a.data.createOrder && b.data.createOrder) {
                if (a.data.createOrder > b.data.createOrder) {
                    return 1;
                }
                if (a.data.createOrder < b.data.createOrder) {
                    return -1;
                }
            }
            return 1;
        });

        if ((start || 0 >= relateds.length) && btn) {
            this.fillField(btn);
        }

        for (let t = start || 0; t < relateds.length; t++) {
            const table = relateds[t].data.table;
            if (!this.relateds[table] || !this.relateds[table].length) {
                if ((t + 1 >= relateds.length) && btn) {
                    this.fillField(btn);
                }
                continue;
            }
            const fields = this.base.getTableMeta(table).fields;
            const f = fields.find(i => i.related_model === this.form.data.table);
            const form = relateds.find(i => i.data.table === table);
            const pk = this.base.getTablePk(table);
            const pkM = f && f.related_field ? f.related_field : this.base.getTablePk(this.form.data ? this.form.data.table : null);
            const rows = []; const rowsIndex = []; const objs = [];
            const pKey = this.base.getTablePk(form.data.table);
            for (let k = 0; k < this.relateds[table].length; k++) {
                const obj = this.relateds[table][k];
                if (f) {
                    obj.item.openedItem[f.name] = {};
                    obj.item.openedItem[f.name][pkM] = f.related_field && data && data[f.related_field] 
                        ? data[f.related_field] : primaryKey;
                }
                if (obj.item.openedItem[pk]) {
                    continue;
                }
                if (form && form.relatedTable && form.relatedField && form.relatedFieldPk && obj.item.openedItem[form.relatedField]) {
                    const indx = obj.item.openedItem[form.relatedField][form.relatedFieldPk];
                    if (this.relateds && this.relateds[form.relatedTable]) {
                        const relItem = this.relateds[form.relatedTable].find(relItem2 => relItem2 && relItem2.item 
                                && relItem2.item.openedItem && relItem2.item.openedItem.tempId === indx);
                        if (relItem && relItem.item.openedItem[form.relatedFieldPk]) {
                            obj.item.openedItem[form.relatedField][form.relatedFieldPk] = 
                                this.base.copy(relItem.item.openedItem[form.relatedFieldPk]);
                        }
                    }
                }
                if (form && form.data && form.data.rows) {
                    const relFields = this.base.getTableMeta(table).fields;
                    for (const row of form.data.rows.filter(i => i.type === 'calcfield' && i.fieldName)) {
                        if (relFields.find(i => i.name === row.fieldName)) {
                            if (obj.item.openedItem[row.fieldName] === undefined) {
                                obj.item.openedItem[row.fieldName] = this.base.getCalcRepr(obj.item.openedItem, 
                                                                                           this.item.openedItem, row.fieldRepr, 
                                                                                           this.relateds);
                            }
                        }
                    }
                }
                const respObj = this.getObjectBeforeSave(form.data, fields, obj.item, false);
                const values = respObj.data;
                const error = this.presentError(respObj.errors, true);
                if (error) {
                    obj.error = error;
                    this.itemChanged.next({error, index: k});
                    this.refreshVisibilityWidgets();
                    return;
                }
                rows.push(this.base.createTable(values, table));
                rowsIndex.push(k);
                objs.push(obj);
            }
            if (rows.length > 0) {
                this.createRelatedsRows(relateds[t], rows, t, pKey, fields, objs, rowsIndex, primaryKey, data, btn);
                break;
            }
        }
    }

    private createNext(rows: Observable<any>[], t: number, pKey: any, fields: any, objs: any, rowsIndex: any[], primaryKey: any, data: any, i: number, btn?) {
        if (!rows[i]) {
            this.scrollComplete = true;
            this.createRelateds(primaryKey, data, t + 1, btn);
        } else {
            rows[i]
            .pipe(
                finalize(() => {
                    this.createNext(rows, t, pKey, fields, objs, rowsIndex, primaryKey, data, i + 1, btn);
                })
            )
            .subscribe((resp) => {
                this.onresponse(resp, pKey, true, fields, objs[i], rowsIndex[i], false);
            })
        }
    }

    private createRelatedsRows(rel: any, rows: Observable<any>[], t: number, pKey: any, fields: any, objs: any, rowsIndex: any[], primaryKey: any, data: any, btn?) {
        if (rel.atomicCreate) {
            this.createNext(rows, t, pKey, fields, objs, rowsIndex, primaryKey, data, 0, btn);
        } else {
            zip(...rows)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                })
            )
            .subscribe((responses) => {
                for (let i = 0; i < responses.length; i++) {
                    this.onresponse(responses[i], pKey, true, fields, objs[i], rowsIndex[i], false);
                }
                this.createRelateds(primaryKey, data, t + 1, btn);
            });
        }
    }

    presentError(errors, toast) {
        if (!errors || !errors.length) {
            return;
        }
        const error = {}; const repr = [];
        for (const e of errors) {
            error[e.fieldName] = this.trans.instant(e.type);
            repr.push(e.fieldTitle + ' - ' + error[e.fieldName]);
        }
        if (toast) {
            this.base.sendToast(repr.join('; '), 5000);
        }
        return error;
    }

    fieldChanged(obj) {
        this.refreshVisibilityWidgets();
        if (obj && obj.form && obj.item && obj.fields) {
            const fields = this.base.getTableMeta(obj.form.data.table).fields;
            const respObj = this.getObjectBeforeSave(obj.form.data, fields, {openedItem: obj.fields}, true);
            const changes = respObj.data;
            if (JSON.stringify(changes) !== '{}') {
                const error = this.presentError(respObj.errors, true);
                if (error) {
                    if (obj.form.data.table !== this.form.data.table) {
                        obj.error = error;
                        this.itemChanged.next({index: obj.index, error});
                        this.refreshVisibilityWidgets();
                    } else {
                        this.error = error;
                        this.itemChanged.next({error: this.error});
                        this.refreshVisibilityWidgets();
                    }
                    return;
                }
                this.saveFieldChanges(obj, changes);
            }
        } else if (obj && obj.refresh) {
            this.itemChanged.next({index: obj.index});
        }
    }

    getDiscount(obj, changes, deleted?) {
        this.saveFieldChanges(obj, changes);
    }
    
    saveFieldChanges(obj, changes) {
        if (JSON.stringify(changes) === '{}') {
            return;
        }
        const pk = this.base.getTablePk(obj.form.data.table);
        if (obj.item.openedItem && obj.item.openedItem[pk]) {
            this.base.updateTable(changes, obj.item.openedItem[pk], 
                                  obj.item.openedItem.edited, obj.form.data.table).subscribe(resp => {
                if (resp && resp.error) {
                    if (obj.form.data.table !== this.form.data.table) {
                        obj.error = resp.error;
                        this.itemChanged.next({index: obj.index, error: resp.error});
                        this.refreshVisibilityWidgets();
                    } else {
                        this.error = resp.error;
                        this.itemChanged.next({error: this.error});
                        this.refreshVisibilityWidgets();
                    }
                } else if (resp && resp[pk]) {
                    if (obj.notify) {
                        this.base.sendToast(obj.notify);
                    }
                    if (obj.form.data.table !== this.form.data.table) {
                        obj.item.openedItem.edited = resp.edited;
                        this.itemChanged.next({index: obj.index, clearError: true});
                        this.refreshVisibilityWidgets();
                    } else {
                        this.item.openedItem.edited = resp.edited;
                        this.itemChanged.next({clearError: true});
                        this.refreshVisibilityWidgets();
                        if (obj.close) {
                            this.closeModal();
                        }
                    }
                }
            });
        } else if (obj.save) {
            this.saveObj();
        } else {
            this.itemChanged.next({index: obj.index});
            this.refreshVisibilityWidgets();
        }
    }

    fillFrom(button) {
        if (button && button.tableCells && !this.btnPrevent(button)) {
            for (const tableCell of button.tableCells) {
                this.fillFromTable(tableCell);
            }
        }
    }

    fillFromTable(tableCell) {
        if (tableCell.runningRequest) {
            return;
        }
        const filters = this.base.getFiltersData(tableCell.filters, true);
        const extraFields = [];
        if (filters.length) {
            const meta = this.base.getTableMeta(tableCell.from.table);
            if (meta && meta.fields) {
                for (const ff of filters) {
                    if (!meta.fields.find(f => f.name === ff.name)) {
                        if (!extraFields.find(i => i === ff.name)) {
                            extraFields.push(ff.name);
                        }
                    }
                }
            }
        }
        if (tableCell.related_filters) {
            for (const rel of tableCell.related_filters) {
                if (this.item && this.item.openedItem && this.item.openedItem[rel.from.name] !== undefined) {
                    let value = this.base.copy(this.item.openedItem)[rel.from.name];
                    if (rel.from.type === FieldType.REF && value) {
                        value = value[rel.from.related_field || this.base.getTablePk(rel.from.related_model)];
                    } else if (rel.from.type === FieldType.DATE) {
                        value = this.base.dateObjToISO(this.base.getDate(value));
                    } else if (rel.from.type === FieldType.DATETIME) {
                        value = (this.base.getDate(value)).toJSON();
                    }
                    filters.push({name: rel.to.name, equal: Equal.EQ, value});
                }
            }
        }
        this.scrollComplete = false;
        tableCell.runningRequest = true;
        const fields = this.base.getTableMeta(tableCell.to.table).fields;
        let query = `&filter_fields=${JSON.stringify(filters)}`;
        if (extraFields.length) {
            query += (new ExtraFields(extraFields)).serialize;
        }
        this.base.getData(tableCell.from.table, 1, this.relatedsCnt, query)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                    tableCell.runningRequest = false;
                })
            )
            .subscribe(resp => {
                if (resp && resp.data) {
                    if (resp.data.length > 20) {
                        this.scrollComplete = false;
                        setTimeout(() => {this.scrollComplete = true; }, 30 * resp.data.length);
                    }
                    const orderBy = this.getOrderBy(tableCell.from.table);
                    if (orderBy) {
                        resp.data = this.base.sortObj(resp.data, orderBy);
                    }
                    const relateds = [];    
                    for (const item of resp.data) {
                        const relItem = {};
                        for (const row of tableCell.rows) {
                            relItem[row.to.name] = item[row.from.name];
                        }
                        if (JSON.stringify(relItem) !== '{}') {
                            const form = this.form.data.relateds.find(i => i.data.table === tableCell.to.table);
                            this.setDefault(relItem, form);
                            relateds.push({item: {openedItem: relItem}, error: null, fields, hideEmpties: {}});
                        }
                    }
                    if (relateds.length) {
                        this.relateds[tableCell.to.table] = relateds;
                        this.refreshRelatedsSubject.next(this.relateds);
                    }
                }
            });
    }

    getOrderBy(table) {
        if (!this.form || !this.form.data || !this.form.data.relateds) {
            return null;
        }
        for (const rel of this.form.data.relateds) {
            if (rel.data.table === table && rel.data.orderBy) {
                return rel.data.orderBy;
            }
        }
        return null;
    }

    fillTableCell(obj) {
        if (obj && obj.widget && obj.value && obj.field && obj.widget.tableCells) {
            const pk = this.base.getTablePk(this.form.data.table);
            for (const tableCell of obj.widget.tableCells) {
                if (tableCell && !tableCell.fillSaved) {
                    if (this.item && this.item.openedItem && this.item.openedItem[pk]) {
                        continue;
                    }
                }
                this.fillTable(tableCell, obj);
            }
        }
    }

    fillTable(tableCell, obj) {
        const f = this.base.getTableMeta(tableCell.from.table).fields.find(i => i.related_model === obj.field.related_model);
        const fields = this.base.getTableMeta(tableCell.to.table).fields;
        if (f) {
            const filters = `&filter_fields=${JSON.stringify([{name: f.name, equal: Equal.EQ, value: obj.value}])}`;
            this.scrollComplete = false;
            this.base.getData(tableCell.from.table, 1, this.relatedsCnt, filters)
                .pipe(
                    finalize(() => {
                        this.scrollComplete = true;
                    })
                )
                .subscribe(resp => {
                    if (resp && resp.data) {
                        const orderBy = this.getOrderBy(tableCell.from.table);
                        if (orderBy) {
                            resp.data = this.base.sortObj(resp.data, orderBy);
                        }
                        const relateds = [];
                        if (resp.data.length > 20) {
                            this.scrollComplete = false;
                            setTimeout(() => {this.scrollComplete = true; }, 30 * resp.data.length);
                        }
                        for (const item of resp.data) {
                            const relItem = {};
                            for (const row of tableCell.rows) {
                                relItem[row.to.name] = item[row.from.name];
                            }
                            if (JSON.stringify(relItem) !== '{}') {
                                const form = this.form.data.relateds.find(i => i.data.table === tableCell.to.table);
                                this.setDefault(relItem, form);
                                relateds.push({item: {openedItem: relItem}, error: null, fields, hideEmpties: {}});
                            }
                        }
                        if (this.relateds[tableCell.to.table] && this.relateds[tableCell.to.table].length) {
                            const deleted = []; const pk = this.base.getTablePk(tableCell.to.table); const deletedValues = [];
                            for (const r of this.relateds[tableCell.to.table].filter(ii => ii.item 
                                                                                    && ii.item.openedItem && ii.item.openedItem[pk])) {
                                deleted.push(this.base.removeTable(r.item.openedItem[pk], null, tableCell.to.table));
                                deletedValues.push(this.base.copy(r));
                            }
                            if (deleted.length) {
                                zip(...deleted).subscribe((responses) => {
                                    for (let t = 0; t < responses.length; t++) {
                                        let rr = null; rr = responses[t];
                                        if (rr && rr.error) {
                                            this.base.sendToast(this.base.getError(rr));
                                            const val = deletedValues[t];
                                            val.error = rr.error;
                                            this.relateds[tableCell.to.table].splice(this.relateds[tableCell.to.table].length, 0, val);
                                        }
                                    }
                                });
                            }
                        }
                        this.relateds[tableCell.to.table] = relateds;
                        this.refreshRelatedsSubject.next(this.relateds);
                        this.itemChanged.next(0);
                        this.refreshVisibilityWidgets();
                    }
                });
        }
    }

    setDefault(item, form) {
        this.base.setDefault(item, form, this.item, this.activeRelateds, this.relateds);
    }

    saveFrom(button) {
        if (this.btnPrevent(button)) {
            return;
        }
        const item = {};
        for (const f of button.fields) {
            if (!(f.from.model && f.from.fieldName)) {
                if (f.from.type === 'calc') {
                    item[f.to.name] = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, f.from.value, this.relateds);
                } else if (f.from.name === 'pk') {
                    item[f.to.name] = this.base.copy(this.item.openedItem);
                } else {
                    item[f.to.name] = this.item.openedItem[f.from.name];
                } 
            }
        }

        const fromRelateds = {};
        for (const relTable of Object.keys(this.relateds)) {
            for (const rel of this.relateds[relTable]) {
                const relItem = {}; let toTable = null;
                for (const f of button.fields) {
                    if (f.from.model === relTable && f.from.fieldName && (!toTable || toTable === f.to.model)) {
                        relItem[f.to.fieldName] = rel.item.openedItem[f.from.fieldName];
                        toTable = f.to.model;
                    }
                }
                if (toTable) {
                    if (!fromRelateds[toTable]) {
                        fromRelateds[toTable] = [relItem];
                    } else {
                        fromRelateds[toTable].push(relItem);
                    }
                }
            }
        }
        console.log(this.base.copy(item), item)
        const params = JSON.stringify([{name: this.formPk, equal: Equal.EQ, value: button.form[this.formPk]}]);
        this.base.getData('Form', 1, 1, `&filter_fields=${params}`).subscribe(resp => {
            if (resp && resp.data && resp.data.length) {
                this.saveFromOpen(item, resp.data[0], fromRelateds, button);
            }
        });
    }

    async saveFromOpen(item, form, fromRelateds, button) {
        this.base.openForm(FormPage, {
            item: this.base.copy({openedItem: item}), 
            form,
            fromRelateds: this.base.copy(fromRelateds),
            returnCreated: true,
        }, (details: any) => {
            if (details && details.data && details.data.item) {
                if (button && button.fieldFill) {
                    const fields = {};
                    fields[button.fieldFill] = this.base.copy(details.data.item);
                    this.fieldChanged({item: this.item, form: this.form, fields, index: null});
                }
            }
        })
    }

    btnPrevent(btn): boolean {
        if (btn && btn.preventFunc) {
            const msg = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, btn.preventFunc, this.relateds);
            if (msg) {
                this.base.sendToast(msg);
                return true;
            }
        }
        return false;
    }

    fillField(btn) {
        if (!this.btnPrevent(btn) && btn.updates) {
            const fields = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, 'JSON.stringify(' + btn.updates + ')', this.relateds);
            if (fields && fields != '{}') {
                this.item.openedItem.edited = null;
                this.fieldChanged({item: this.item, form: this.form, fields: JSON.parse(fields), index: null, close: btn.close});
            }
        }
    }

    execRequest(btn) {
        if (btn.execFunc) {
            const data = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, 'JSON.stringify(' + btn.execFunc + ')', this.relateds, null, this);
            if (data) {
                const params = JSON.parse(data)
                this.http.post<any>(params.url, params.body)
                .pipe(
                    tap(_ => this.base.log(`create ${params}`)),
                    catchError(this.base.handleError('execRequest'))
                ).subscribe(resp => {
                    console.log(resp)
                    this.base.sendToast(JSON.stringify(resp), 5000)
                });
            }
        }
    }

    delete(ev) {
        if (ev && ev.table && ev.i !== undefined && ev.i !== null) {
            const item = this.relateds[ev.table][ev.i];
            if (!item) {
                return;
            }
            const pk = this.base.getTablePk(ev.table);
            if (item.item.openedItem && item.item.openedItem[pk]) {
                this.base.removeTable(item.item.openedItem[pk], null, ev.table).subscribe(resp => {
                    if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp));
                    } else {
                        this.relateds[ev.table].splice(ev.i, 1);
                        this.getDiscount({form: this.form, item: this.item}, {}, true);
                    }
                    this.itemChanged.next({index: ev.i});
                    this.refreshVisibilityWidgets();
                });
            } else {
                this.relateds[ev.table].splice(ev.i, 1);
                this.itemChanged.next({index: ev.i});
                this.refreshVisibilityWidgets();
            }
        }
    }

    deleteObj(btn?) {
        if (this.btnPrevent(btn)) {
            return;
        }
        const pk = this.base.getTablePk(this.form.data.table);
        if (this.item && this.item.openedItem && this.item.openedItem[pk]) {
            this.base.removeTable(this.item.openedItem[pk], null, this.form.data.table).subscribe(resp => {
                if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp));
                } else {
                    this.closeModal();
                }
            });
        }
    }

    copy() {
        this.base.createTable({name: this.form.name, data: this.form.data, table: this.form.data.table, 
                               is_default: this.form.is_default, type: 0}, 'Form').subscribe(resp => {
            if (resp && resp[this.formPk]) {
                this.chooseForm(resp[this.formPk]);
            } else {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    changeRelateds() {
        if (this.form.data.leftSide) {
            this.leftSideStyle = this.pageService.getStyle(this.form.data.leftSide.style, this.form.data.leftSide.class);
        }
        if (this.form.data.leftRows) {
            this.leftRowsStyle = this.pageService.getStyle(this.form.data.leftRows.style, this.form.data.leftRows.class);
        }
        for (const rel of this.form.data.relateds || []) {
            rel.titleNgStyle = this.pageService.getStyle(rel.title.style, rel.title.class);
            if (rel.fullTableStyle) {
                rel.fullTableNgStyle = this.pageService.getStyle(rel.fullTableStyle.style, rel.fullTableStyle.class);
            }
            if (rel.tableStyle) {
                rel.tableNgStyle = this.pageService.getStyle(rel.tableStyle.style, rel.tableStyle.class);
            }
            if (rel.capStyle) {
                rel.capNgStyle = this.pageService.getStyle(rel.capStyle.style, rel.capStyle.class);
            }
            if (rel.rowStyle) {
                rel.rowNgStyle = this.pageService.getStyle(rel.rowStyle.style, rel.rowStyle.class);
            }

            for (const row2 of rel.data.rows || []) {
                row2.rowNgStyle = this.pageService.getStyle(row2.style, row2.class);
            }
            for (const cap of rel.data.cap || []) {
                cap.rowNgStyle = this.pageService.getStyle(cap.style, cap.class);
            }
        }
        for (const sec of this.form.data.sections || []) {
            sec.titleNgStyle = this.pageService.getStyle(sec.title.style, sec.title.class);
            sec.fullTableNgStyle = this.pageService.getStyle(sec.fullTableStyle.style, sec.fullTableStyle.class);
        }
        if (this.form.data.sectionStyle) {
            this.sectionNgStyle = this.pageService.getStyle(this.form.data.sectionStyle.style, this.form.data.sectionStyle.class);
        }
    }

    changeRows() {
        if (!this.form.data.rows) {
            return;
        }
        for (const row of this.form.data.rows) {
            row.rowNgStyle = this.pageService.getStyle(row.style, row.class);
        }
    }

    changeButtons() {
        if (!this.form.data.buttons) {
            return;
        }
        for (const button of this.form.data.buttons) {
            button.ngStyle = this.pageService.getStyle(button.style, button.class);
        }
        this.changeVises();
        this.updateButtonsToggle();
    }

    updateButtonsToggle() {
        if (!this.form || !this.form.data || !this.form.data.buttons || !this.item) {
            return;
        }
        for (const button of this.form.data.buttons) {
            if (button.hideFunc) {
                button.hide = this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, button.hideFunc, this.relateds) ? true : false;
            } else {
                button.hide = false;
            }
        }
    }

    changeVises() {
        if (!this.item || !this.item.openedItem || !this.item.openedItem.vises) {
            this.userCanReject = false;
            this.userCanApprove = false;
            this.userViseType = null;
            this.userHasMore = false;
            this.minQueue = null;
            return;
        }
        this.userCanReject = this.canReject(this.item.openedItem.vises);
        this.userCanApprove = this.userVise(this.item.openedItem.vises);
        this.userViseType = this.userVise(this.item.openedItem.vises, true);
        if (this.form) {
            this.userHasMore = this.hasMore(this.form.data.buttons, this.item.openedItem.vises);
        }
        this.minQueue = this.viseStatusService.getMinQueue(this.item.openedItem.vises);
    }

    exportToExcel(rel) {
        this.scrollComplete = false;
        const data = []; const rows = [];
        
        let i = 0;
        for (const row of this.base.copy(rel.data.rows || [])) {
            if (!row.exportHidden) {
                if (!row.fieldName) {
                    row.fieldName = 'calc' + i;
                    i++;
                }
                rows.push(row);
            } 
        }
        for (const item of this.relateds[rel.data.table] || []) {
            if (item && item.item && item.item.openedItem) {
                const val = {};
                for (const row of rows) {
                    if (row.type === 'foreign' && row.fieldName) {
                        val[row.fieldName] = this.base.getRepr(item.item.openedItem[row.fieldName], row.fieldRepr);
                    } else if (row.type === 'user') {
                        val[row.fieldName] = this.base.getRepr(item.item.openedItem[row.fieldName], 'name');
                    } else if (row.type === 'calcfield' && row.fieldRepr) {
                        val[row.fieldName] = this.base.getCalcRepr(item.item.openedItem, 
                                                                   this.item ? this.item.openedItem : item.item.openedItem, 
                                                                   row.fieldRepr, this.relateds);
                    } else if (row.fieldName) {
                        val[row.fieldName] = item.item.openedItem[row.fieldName]; 
                    }
                }
                data.push(val);
            }
        }

        this.exportSubject.next({data, rows});
    }

    complete() {
        this.scrollComplete = true;    
    }

    fieldReprChangedEvent(ev, w) {
        if (w) {
            this.fieldReprChanged.next(w.fieldName);
        }
    }
    
    savePDF(images) {
        let doc; 
        for (const dataUrl of images) {
            const img = new Image();
            img.src = dataUrl;
            let newImage = img.src;

            if (!doc) {
                doc = new jsPDF('l', 'px', [this.toPdf.height, this.toPdf.width]);
            } else {
                doc.addPage([this.toPdf.height, this.toPdf.width]);
            }
            doc.addImage(newImage, 'PNG',  10, 10, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
        }
        if (doc) {
            doc.save('file1.pdf');
        }
    }

    downloadPDF() {
        let nodes: any = this.document.getElementsByClassName('rel-items-class');
        const images = [];
        let root = this;
        let cnt = 0;
        for (const node of nodes) {
            if (Math.abs(node.clientHeight - this.toPdf.height) > 100 || Math.abs(node.clientWidth - this.toPdf.width) > 100) {
                cnt ++;
                continue;
            } 
            domtoimage.toPng(node).then(function(dataUrl) {
                images.push(dataUrl);
                if (images.length + cnt === nodes.length) {
                    root.savePDF(images);
                    return;
                }
            }).catch(function(error) {
                console.error(error);
            });
        }
        root.savePDF(images);
    }

    print(btn?) {
        if (btn && btn.form) {
            const relateds = btn.printValue ? this.base.getCalcRepr(this.item, this.item, btn.printValue, 
                this.base.copy(this.relateds)) : this.relateds;
            const pk = this.base.getTablePk('Form');
            const filters = [{name: pk, equal: Equal.EQ, value: btn.form[pk]}];
            this.base.getData('Form', 1, 1, `&filter_fields=${JSON.stringify(filters)}`).subscribe(resp => {
                if (resp && resp.data && resp.data.length) {
                    this.printForm(this.item, resp.data[0], relateds, btn.toPdf);
                }
            });
            return;
        }

        const elems = this.document.getElementsByClassName('for-print');
        if (!elems.length) {
            return;
        }
        if (this.toPdf && this.toPdf.height && this.toPdf.width) {
            this.downloadPDF();
            return;
        }
        const newWindow = window.open();
        newWindow.document.open('text/html');
        newWindow.document.write('<html><head><body style="margin:0">' + elems[elems.length - 1].innerHTML);
        newWindow.document.close();
        setTimeout(() => {
            newWindow.print();
        }, 1000);
        if (this.startPrint) {
            setTimeout(() => {
                this.closeModal({});
            }, 1000);
        }
    }

    filterRelateds(rel, val) {
        return !rel.section;
    }

    private hiddenWidget(rel: any): boolean {
        rel.hidden = rel.hideFunc && this.base.getCalcRepr(this.item.openedItem, this.item.openedItem, rel.hideFunc, this.relateds);
        return rel.hidden;
    }

    private refreshVisibilityWidgets() {
        if (!this.form || !this.form.data) {
            return;
        }
        for (const rel of this.form.data.rows || []) {
            this.hiddenWidget(rel);
        }
        for (const rel of this.form.data.relateds || []) {
            for (const cap of rel.data.cap || []) {
                this.hiddenWidget(cap);
            }
            for (const row of rel.data.rows || []) {
                this.hiddenWidget(row);
            }
        }
    }

    filterRelatedItems(rel, root: this) {
        return !root.hiddenWidget(rel);
    }

    filterRowsShow(rel, root: this) {
        root.hiddenWidget(rel);
        return !rel.section && ((['vise', 'catalog', 'chat', 'hierarchy'].indexOf(rel.type) === -1) === true); 
    }

    filterRowsHide(rel, root) {
        root.hiddenWidget(rel);
        return !rel.section && ((['vise', 'catalog', 'chat', 'hierarchy'].indexOf(rel.type) === -1) === false); 
    }

    filterSection(rel, val) {
        return rel.section && rel.section === val;
    }

    addSection() {
        if (!this.form.data.sections) {
            this.form.data.sections = [];
        }
        if (!this.form.data.sectionStyle) {
            this.form.data.sectionStyle = {class: [], style: []};
        }
        this.form.data.sections.splice(this.form.data.sections.length, 0, {
            title: {class: [], style: [], title: '', titles: {}}, 
            pk: uuid.v4(),
            icon: '',
            fullTableStyle: {class: [], style: []},
        });
    }

    activateSection(i) {
        this.actionSection = this.base.copy(this.form.data.sections[i]);
    }

    async printForm(item, form, fromRelateds, toPdf) {
        this.base.openForm(FormPage, {
            item: this.base.copy(item), 
            form,
            relateds: this.base.copy(fromRelateds),
            startPrint: true,
            toPdf,
        }, (details: any) => {});
    }

    async addForm() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
                selected: this.form,
                filters: [new BoardFilter('table', Equal.EQ, this.form.data.table), new BoardFilter(this.formPk, Equal.NOT, this.form[this.formPk])]
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                if (!this.form.data.forms) {
                    this.form.data.forms = [];
                }
                let item = {name: details.data.item.name}; item[this.formPk] = details.data.item[this.formPk];
                this.form.data.forms.splice(this.form.data.forms.length, 0, {form: item});
            }
        });
        return await modal.present();
    }
}
