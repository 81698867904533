import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.page.html',
    styleUrls: ['./color-picker.page.scss'],
})
export class ColorPickerPage implements OnInit {
    @Input() closeAfterSelect: any = false;
    @Input() color: any;
    @Input() isPicker: any = false;
    @Input() askData: any = false;
    @Input() field = 'image';
    @Input() onlyIcon = false;

    colors = [
        'url(/assets/no-color.svg)',
        '#DCAA56',
        '#AC5DAC',
        '#34BDC0',
        '#94BBF3',
        '#FD917B',
        '#34505F',
        '#4BB16E',
        '#D36163',
        '#CA6FC1',
        '#A5B4BD',
        '#272727',
        '#C7A684',
        '#279689',
        '#3C9CDC',
        '#CFC98D',
        '#0779BD'
    ];
    images = ['add-circle-outline', 'add-circle', 'add', 'airplane', 'alarm', 'albums', 'alert', 'american-football', 'analytics', 
              'aperture', 'apps', 'appstore', 'archive', 'arrow-back', 'arrow-down', 'arrow-dropdown-circle', 'arrow-dropdown', 
              'arrow-dropleft-circle', 'arrow-dropleft', 'arrow-dropright-circle', 'arrow-dropright', 'arrow-dropup-circle', 
              'arrow-dropup', 'arrow-forward', 'arrow-round-back', 'arrow-round-down', 'arrow-round-forward', 'arrow-round-up', 
              'arrow-up', 'at', 'attach', 'backspace', 'barcode', 'baseball', 'basket', 'basketball', 'battery-charging',
              'battery-dead', 'battery-full', 'beaker', 'bed', 'beer', 'bicycle', 'bluetooth', 'boat', 'body', 'bonfire', 
              'book', 'bookmark', 'bookmarks', 'bowtie', 'briefcase', 'browsers', 'brush', 'bug', 'build', 'bulb', 'bus', 'business', 
              'cafe', 'calculator', 'calendar', 'call', 'camera', 'car', 'card', 'cart', 'cash', 'cellular', 'chatboxes', 'chatbubbles', 
              'checkbox-outline', 'checkbox', 'checkmark-circle-outline', 'checkmark-circle', 'checkmark', 'clipboard', 'clock', 
              'close-circle-outline', 'close-circle', 'close', 'cloud-circle', 'cloud-done', 'cloud-download', 'cloud-outline', 
              'cloud-upload', 'cloud', 'cloudy-night', 'cloudy', 'code-download', 'code-working', 'code', 'cog', 'color-fill', 
              'color-filter', 'color-palette', 'color-wand', 'compass', 'construct', 'contact', 'contacts', 'contract', 'contrast', 
              'copy', 'create', 'crop', 'cube', 'cut', 'desktop', 'disc', 'document', 'done-all', 'download', 'easel', 'egg', 'exit', 
              'expand', 'eye-off', 'eye', 'fastforward', 'female', 'filing', 'film', 'finger-print', 'fitness', 'flag', 'flame', 
              'flash-off', 'flash', 'flashlight', 'flask', 'flower', 'folder-open', 'folder', 'football', 'funnel', 'gift', 
              'git-branch', 'git-commit', 'git-compare', 'git-merge', 'git-network', 'git-pull-request', 'glasses', 'globe', 'grid', 
              'hammer', 'hand', 'happy', 'headset', 'heart', 'heart-dislike', 'heart-empty', 'heart-half', 'help-buoy', 
              'help-circle-outline', 'help-circle', 'help', 'home', 'hourglass', 'ice-cream', 'image', 'images', 'infinite', 
              'information-circle-outline', 'information-circle', 'information', 'jet', 'journal', 'key', 'keypad', 'laptop', 
              'leaf', 'link', 'list-box', 'list', 'locate', 'lock', 'log-in', 'log-out', 'magnet', 'mail-open', 'mail-unread', 
              'mail', 'male', 'man', 'map', 'medal', 'medical', 'medkit', 'megaphone', 'menu', 'mic-off', 'mic', 'microphone', 'moon',
              'more', 'move', 'musical-note', 'musical-notes', 'navigate', 'notifications-off', 'notifications-outline', 
              'notifications', 'nuclear', 'nutrition', 'open', 'options', 'outlet', 'paper-plane', 'paper', 'partly-sunny', 
              'pause', 'paw', 'people', 'person-add', 'person', 'phone-landscape', 'phone-portrait', 'photos', 'pie', 'pin', 
              'pint', 'pizza', 'planet', 'play-circle', 'play', 'podium', 'power', 'pricetag', 'pricetags', 'print', 'pulse', 
              'qr-scanner', 'quote', 'radio-button-off', 'radio-button-on', 'radio', 'rainy', 'recording', 'redo', 'refresh-circle', 
              'refresh', 'remove-circle-outline', 'remove-circle', 'remove', 'reorder', 'repeat', 'resize', 'restaurant', 
              'return-left', 'return-right', 'reverse-camera', 'rewind', 'ribbon', 'rocket', 'rose', 'sad', 'save', 'school',
              'search', 'send', 'settings', 'share-alt', 'share', 'shirt', 'shuffle', 'skip-backward', 'skip-forward', 'snow', 
              'speedometer', 'square-outline', 'square', 'star-half', 'star-outline', 'star', 'stats', 'stopwatch', 'subway', 
              'sunny', 'swap', 'switch', 'sync', 'tablet-landscape', 'tablet-portrait', 'tennisball', 'text', 'thermometer', 
              'thumbs-down', 'thumbs-up', 'thunderstorm', 'time', 'timer', 'today', 'train', 'transgender', 'trash', 'trending-down', 
              'trending-up', 'trophy', 'tv', 'umbrella', 'undo', 'unlock', 'videocam', 'volume-high', 'volume-low', 'volume-mute', 
              'volume-off', 'wallet', 'walk', 'warning', 'watch', 'water', 'wifi', 'wine', 'woman', 'logo-android', 'logo-angular', 
              'logo-apple', 'logo-bitbucket', 'logo-bitcoin', 'logo-buffer', 'logo-chrome', 'logo-closed-captioning', 'logo-codepen', 
              'logo-css3', 'logo-designernews', 'logo-dribbble', 'logo-dropbox', 'logo-euro', 'logo-facebook', 'logo-flickr', 
              'logo-foursquare', 'logo-freebsd-devil', 'logo-game-controller-a', 'logo-game-controller-b', 'logo-github', 'logo-google',
              'logo-googleplus', 'logo-hackernews', 'logo-html5', 'logo-instagram', 'logo-ionic', 'logo-ionitron', 'logo-javascript', 
              'logo-linkedin', 'logo-markdown', 'logo-model-s', 'logo-no-smoking', 'logo-nodejs', 'logo-npm', 'logo-octocat', 
              'logo-pinterest', 'logo-playstation', 'logo-polymer', 'logo-python', 'logo-reddit', 'logo-rss', 'logo-sass', 
              'logo-skype', 'logo-slack', 'logo-snapchat', 'logo-steam', 'logo-tumblr', 'logo-tux', 'logo-twitch', 'logo-twitter', 
              'logo-usd', 'logo-vimeo', 'logo-vk', 'logo-whatsapp', 'logo-windows', 'logo-wordpress', 'logo-xbox', 'logo-xing', 
              'logo-yahoo', 'logo-yen', 'logo-youtube'];
    data = [];
    selected = null;

    constructor(private popoverCtrl: PopoverController) { }

    ngOnInit() {
        for (const color of this.colors) {
            this.data.splice(this.data.length, 0, {isColor: true, value: color});
        }

        if (this.isPicker) {
            this.data.splice(1, 0, {isColor: true, value: 'url(/assets/picker-img.svg)'});
        }
        if (this.onlyIcon) {
            this.data = [this.data[0]];
        }
        if (this.isPicker) {
            for (const picker of this.images) {
                this.data.splice(this.data.length, 0, {isColor: false, value: picker});
            }
        }

        if (this.isPicker) {
            this.selected = this.color[this.field];
        } else {
            this.selected = this.color.color;
        }
    }

    select(v) {
        if (!v && !this.color.isDataColor && this.askData) {
            return;
        }

        if (v === this.data[0].value) {
            v = null;
        }

        if (this.isPicker) {
            this.color[this.field] = v;
        } else {
            this.color.color = v;
        }
        this.selected = v;
        if (this.closeAfterSelect) {
            this.popoverCtrl.dismiss({changed: true});
        }
    }
}
