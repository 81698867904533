import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserPhotoPage } from './user-photo.page';
import { CellPageModule } from '../cell/cell.module';

const routes: Routes = [
    {
        path: 'user-photo',
        component: UserPhotoPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        CellPageModule
    ],
    declarations: [UserPhotoPage],
    exports: [UserPhotoPage]
})
export class UserPhotoPageModule {}
