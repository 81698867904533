import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MessagePage } from './message.page';
import { UserPhotoPageModule } from '../components/user-photo/user-photo.module';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: 'message',
        component: MessagePage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        UserPhotoPageModule,
        TranslateModule
    ],
    declarations: [MessagePage],
    exports: [MessagePage]
})
export class MessagePageModule {}
