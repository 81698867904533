import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Call } from 'src/app/classes/sip';
import { SipService } from 'src/app/services/sip.service';

@Component({
    selector: 'app-input-phone-widget',
    templateUrl: './input-phone-widget.page.html',
    styleUrls: ['./input-phone-widget.page.scss'],
})
export class InputPhoneWidgetPage implements OnInit {

    @ViewChild('input') input;
    @Input() refer: boolean;
    @Input() call: Call;
    phone = '';
    symbols = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '0', '#'];
    Call = Call;
    interval: any;

    constructor(private modalCtrl: ModalController,
                public sip: SipService) { }

    ngOnInit() {
        this.phone = '';
        this.interval = setInterval(() => {
            this.input.setFocus();
        }, 2000);
    }

    ionViewWillLeave() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    send() {
        if (this.refer) {
            this.call.refer(this.phone);
        } else {
            this.sip.newCall(this.phone);
        }
        this.modalCtrl.dismiss();
    }

    paste() {
        const nav: any = navigator;
        nav.clipboard.readText().then(clipText => {
            if (clipText) {
                this.phone = clipText.replace(/\D/g, "");
            }
        });
    }

    back() {
        this.modalCtrl.dismiss();
    }
}
