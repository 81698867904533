import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { SubMenuPage } from '../sub-menu/sub-menu.page';
import { Constants } from 'src/app/classes/app.constants';
import { Interval } from 'src/app/classes/enums';
import { BoardFilter, Choice } from 'src/app/classes/board-filters';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.page.html',
    styleUrls: ['./filter.page.scss'],
})
export class FilterPage implements OnInit {

    @Input() fields: any;
    @Input() filters: BoardFilter[] = [];
    @Input() showHiddenButtons: boolean;
    @Input() showTitle: boolean;
    @Input() showCloseButton = false;
    allowFields = [];
    month = '';
    intervals = [];

    constructor(private base: BaseService, 
                private modalCtrl: ModalController,
                private popoverCtrl: PopoverController) { }

    ngOnInit() {
        this.intervals = this.base.copy(Constants.INTERVALS);
        this.intervals.push(Interval.DAYS);
        this.allowFields = this.fields.filter(f => !f.blockFilter);
        this.month = this.base.getMonthList();

        for (const f of this.filters) {
            f.justEqual = this.justEqual(f.name);
            const field = this.fields.find(ff => ff.name == f.name)
            if (field && field.choices) {
                f.choices = Choice.fromJson(field.choices)
            }
        }
    }

    changeChoices(filter: BoardFilter) {
        filter.repr =  null;
        if (filter.choices) {
            const choice = filter.choices.find(c => c.id === filter.value);
            if (choice) {
                filter.repr = choice.title
            }
        }
    }

    addFilter(filt?: BoardFilter) {
        if (filt && filt.or) {
            filt.or.splice(filt.or.length, 0, BoardFilter.default());
        } else {
            this.filters.splice(this.filters.length, 0, BoardFilter.default());
        }
    }

    addOr() {
        const filter = BoardFilter.default();
        filter.or = [BoardFilter.default()];
        this.filters.splice(this.filters.length, 0, filter);
    }

    clear() {
        this.filters = [];
    }

    filterChange(f: BoardFilter) {
        const field = this.fields.find(item => item.name === f.name);
        if (field) {
            f.verboseName = field.verbose_name;
            f.type = field.type,
            f.choices = Choice.fromJson(field.choices);
            f.justEqual = this.justEqual(field.name);
        } else {
            f.verboseName = null;
            f.type = null;
            f.choices = null;
            f.justEqual = false;
        }
        f.value = null;
    }

    private justEqual(name: string): boolean {
        return this.base.viseFields.indexOf(name) !== -1;
    }

    async chooseItem(f: BoardFilter) {
        const field = this.fields.find(item => item.name === f.name);
        if (field) {
            const fieldPk = field.related_field || this.base.getTablePk(field.related_model);
            const selected = {};
            if (f.value && fieldPk) {
                selected[fieldPk] = f.value;
            }
            const modal = await this.modalCtrl.create({
                component: ChoicesPage,
                componentProps: {
                    model: field.related_model,
                    selected
                },
                showBackdrop: false,
            });
            modal.onDidDismiss().then((details: any) => {
                if (details && details.data && details.data.item) {
                    f.value = details.data.item[fieldPk];
                    f.repr = this.base.getRepr(details.data.item, field.func_repr || field.repr || 'name');
                }
            });
            return await modal.present();
        }
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }

    dateChanged(ev, f: BoardFilter) {
        if (f.focusField) {
            f.focusField = false;
            if (ev.value) {
                f.value = (ev.value ? this.base.dateObjToISO(ev.value) : '') || '';
            } else if (ev.target) {
                f.value = ev.target.value;
            } else {
                f.value = null;
            }
        }
    }

    timeChanged(ev, f: BoardFilter) {
        if (!f.focusField) {
            return;
        }
        f.focusField = false;
        if (ev.value) {
            f.value = (ev.value ? this.base.toDate(ev.value) : '') || '';
        } else if (ev.target) {
            f.value = ev.target.value;
        } else {
            f.value = null;
        }
    }

    async openSubMenu(ev, f: BoardFilter) {
        const buttons = [];
        if (f.hidden) {
            buttons.push({title: 'visible', src: 'assets/icon/eye-outline.svg'});
        } else {
            buttons.push({title: 'hidden', src: 'assets/icon/eye-off-outline.svg'});
        }

        const popover = await this.popoverCtrl.create({
            component: SubMenuPage,
            componentProps: {buttons},
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.btn) {
                if (details.data.btn.title === 'visible') {
                    f.hidden = false;
                } else if (details.data.btn.title === 'hidden') {
                    f.hidden = true;
                }
            }
        });
        return await popover.present();
    }
}
