import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { ExtraFields } from '../classes/extra-fields';
import { Equal, FieldType, Interval } from '../classes/enums';
import { BoardFilter, Choice } from '../classes/board-filters';
import { Board } from '../classes/board';
import { GroupBy, GroupByList } from '../classes/board-group-by';
import { Base } from '../classes/base';


@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    constructor(private http: HttpClient,
                private base: BaseService,
                private trans: TranslateService) { }

    getBackgrounds(isColor: boolean, page: number, pages: number, search: string, type: number = 0): Observable<any> {
        return this.base.getData("Background", page, pages,`&o=-edited` + (search ? `&s=${search}` : ''), 
                                 [new BoardFilter('type', Equal.EQ, type), new BoardFilter("is_color", Equal.EQ, isColor)], true);
    }

    getTemplates(table: string, page: number, pages: number, search: string, restrict = false, filters = []): Observable<any> {
        const userPk = this.base.getTablePk('User');
        let params = '';
        if (table) {
            filters.push({name: 'table', equal: Equal.EQ, value: table});
        }
        params += search ? `&s=${search}` : '';
        
        if (restrict) {
            if (this.base.getTableMeta('Template').fields.find(i => i.name === 'is_default')) {
                const groupBy = [
                    { name: 'user', value: Base.getCurrentUser()[userPk], group: 1}, 
                    { name: 'is_default', value: true, group: 1}
                ];
                params += `&group_by=${JSON.stringify(groupBy)}`;
                filters.push({ name: 'note', equal: Equal.EQ, value: 1});
            } else {
                filters.push({ name: 'user', value: Base.getCurrentUser()[userPk], equal: Equal.EQ });
            }
        }

        if (filters.length) {
            params += `&filter_fields=${JSON.stringify(filters)}`;
        }
        return this.http.get<any>(`${this.base.getRootUrl()}portal/api/template/?page=${page}&pages=${pages}&o=-edited${params}`)
        .pipe(
            tap(_ => this.base.log('fetched tempaltes')),
            catchError(this.base.handleError('getTemplates', this.base.handlerBlank))
        );
    }

    createTemplate(board: Board, user?: any): Observable<any> {
        const body = board.serialize();
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/template/`, {
            fields: body.fields,
            table: body.table,
            user: user || Base.getCurrentUser()[this.base.getTablePk('User')],
            name: body.name,
            background: (body.background ? body.background.uuid : null) || body.background,
            form: (body.form ? body.form.uuid : null) || body.form,
        })
        .pipe(
            tap(_ => this.base.log('create template')),
            catchError(this.base.handleError('createTemplate'))
        );
    }

    updateTemplate(board: Board): Observable<any> {
        const body = board.serialize();
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/template/${board.pk}/`, {
            fields: body.fields,
            name: body.name,
            edited: body.edited,
            background: (body.background ? body.background.uuid : null) || body.background,
            form: (body.form ? body.form.uuid : null) || body.form,
            is_default: body.is_default,
        })
        .pipe(
            tap(_ => this.base.log('update template')),
            catchError(this.base.handleError('updateTemplate'))
        );
    }

    removeTemplate(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/template/${pk}/`)
        .pipe(
            tap(_ => this.base.log('delete template')),
            catchError(this.base.handleError('removeTemplate'))
        );
    }

    getValueOfGroup(group, boardGroup: GroupByList, board: Board, returnArray = false) {
        const name = board.groupBy.name; const type = board.groupBy.type;
        if (group && name && type) {    // Added groups as default falue;
            const groups = board.annotations;
            if (groups.length) {
                const gr = groups.find(g => g.group === group);
                if (type === FieldType.INT && board.groupBy.choices.length) {
                    return gr ? gr.value : null;
                } else if ((type === FieldType.INT || type === FieldType.DECIMAL 
                            || type === FieldType.DATE || type === FieldType.DATETIME) && gr) {
                    return gr.value ? (returnArray ? gr.value : gr.value[0]) : null;
                } else if ((type === FieldType.DATE || type === FieldType.DATETIME) && groups[0].note) {
                    return group;                    
                } else if (type === FieldType.CHAR) {
                    return group;
                } else if (type === FieldType.BOOL && gr) {
                    return gr.value;
                } else if (type === FieldType.REF) {
                    if (boardGroup && boardGroup.obj) {
                        return this.base.copy(boardGroup.obj);
                    } else if (gr) {
                        return this.base.copy({uuid: gr.value, code: group, name: group, image: gr.image, color: gr.color});
                    }
                }
            }
        }
        return undefined;
    }

    representCell(col, value, fieldChoices, boolTo = false, covertToImg = true) {
        let v = value[col.name];
        if (col.type === FieldType.REF) {
            if (v && col.repr) {
                v = v[col.repr];
            }
            if (col.asImage && col.foreignImg && covertToImg) {
                const img = {img: null, alt: `${v || ''}`};
                if (value[col.name]) {
                    img.img = value[col.name][col.foreignImg];
                    if (col.altImage && value[col.name][col.altImage]) {
                        img.alt = `${value[col.name][col.altImage]}`;
                    }
                }
                return img;
            }
        } else if (col.type === FieldType.CHAR && col.asImage && covertToImg) {
            const img = {img: v, alt: ''};
            if (col.altImage && value[col.altImage]) {
                img.alt = `${value[col.altImage]}`;
            }
            return img;
        } else if (col.type === FieldType.DATE) {
            if (v) {
                v = this.base.dateToString(v);
            }
        } else if (col.type === FieldType.DATETIME) {
            if (v) {
                v = this.base.dateTimeToString(v);
                if (v && col.dateTime === 'date') {
                    v = v.slice(0, 10);
                } else if (v && col.dateTime === 'time') {
                    v = v.slice(11);
                }
            }
        } else if (col.type === FieldType.INT && fieldChoices[col.name]) {
            const j = fieldChoices[col.name].find(i => i[0] === v);
            if (j) {
                v = j[1];
            }
        } else if (col.type === FieldType.DECIMAL) {
            if (v) {
                v = this.base.decimalToString(v, col.digits);
            }
        } else if (boolTo && col.type === FieldType.BOOL) {
            return this.trans.instant(v ? 'yes' : 'no');
        } else if (col.parseJson) {
            return this.base.getRepr(v, col.parseJson);
        }
        return v;
    }

    addFilterToDynamicList(metaFields, board: Board, templ: Board, group: GroupByList, groupFilter: BoardFilter, fieldChoices) {
        const f = metaFields.find(i => i.name === templ.groupBy.name);
        const v = this.getValueOfGroup(group.name, group, templ);
        if (f && v !== undefined) {
            const o = {}; o[f.name] = v;
            groupFilter = new BoardFilter(
                f.name, Equal.EQ, typeof(v) === 'object' ? v.uuid : v, 
                f.type !== FieldType.BOOL ? this.representCell(f, o, fieldChoices) : (v ? f.verbose_name : `${this.trans.instant('not')} ${f.verbose_name}`),
                f.verbose_name, f.type, Choice.fromJson(f.choices || fieldChoices[f.name]), true, false, false, false,
            )
            board.filters = BoardFilter.copy([groupFilter]);
        }
        return groupFilter;
    }

    addToCalcFields(field, fields) {
        if (!fields.find(i => i === field)) {
            fields.push(field);
        }
        return fields;
    }

    escapeText(v) {
        if (typeof(v) === 'string') {
            return v.replace('%', '%25').replace('+', '%2B');
        }
        return v;
    }

    getQueryParams(search, board: Board, viseFields, table, justNote, extraFilters: BoardFilter[] = [], statusNote = null, isCustom = false, 
                   calcs = [], calcFields = []) {
        
        let params = '';        // String with query params.
        let o = '';             // Order by fields
        let isNote = false;     // Get annotation group from 'groups' property of 'groupBy'
        let groups2 = [];

        if (search) {
            params += `&s=${search}`;
        }
        
        const filters = []; let status; status = {};
        for (const f of board.filters) {
            if (f.isChecked && (!f.or || !f.or.length)) {
                if (viseFields.indexOf(f.name) !== -1) {    
                    const v = status[f.name] ? status[f.name] : [];
                    v.push(f.value);
                    status[f.name] = this.base.copy(v); 
                } else if (f.interval && [FieldType.DATETIME, FieldType.DATE].indexOf(f.type) !== -1) {
                    const now = new Date(); let today = now.getTime();
                    switch (f.interval) {
                    case Interval.DAYS:
                        if (f.intervalValue) {
                            today -= f.intervalValue * this.base.oneDay;
                        }
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(new Date(today))});
                        break;
                    case Interval.DAY:
                        if (f.intervalValue) {
                            today -= f.intervalValue * this.base.oneDay;
                        }
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(new Date(today))});
                        today += this.base.oneDay;
                        filters.push({name: f.name, equal: Equal.LT, value: this.base.dateObjToISO(new Date(today))});
                        break;
                    case Interval.WEEK:
                        today -= ((new Date()).getDay() -1) * this.base.oneDay;
                        if (f.intervalValue) {
                            today -= f.intervalValue * 7 * this.base.oneDay;
                        }
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(new Date(today))});
                        filters.push({name: f.name, equal: Equal.LT, value: this.base.dateObjToISO(new Date(today + 7 * this.base.oneDay))});
                        break;
                    case Interval.MONTH:
                        let start = new Date(now.getFullYear(), now.getMonth(), 1);
                        for (let i = 0; i < f.intervalValue; i++) {
                            start = new Date(start.getTime() - this.base.oneDay);
                            start = new Date(start.getFullYear(), start.getMonth(), 1);
                        }
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(start)});
                        filters.push({name: f.name, equal: Equal.LT, value: this.base.dateObjToISO(new Date(start.getFullYear(), start.getMonth() + 1, 1))});
                        break;
                    case Interval.QUARTER:
                        let quarter = new Date(now.getFullYear(), now.getMonth(), 1);
                        const month = Math.ceil(quarter.getMonth() / 3) * 3;
                        quarter = new Date(now.getFullYear(), month);
                        for (let i = 0; i < f.intervalValue * 3; i++) {
                            quarter = new Date(quarter.getTime() - this.base.oneDay);
                            quarter = new Date(quarter.getFullYear(), quarter.getMonth(), 1);
                        }
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(quarter)});
                        filters.push({name: f.name, equal: Equal.LT, value: this.base.dateObjToISO(new Date(quarter.getFullYear(), quarter.getMonth() + 3, 1))});
                        break;
                    case Interval.YEAR:
                        const year = new Date(now.getFullYear() - (f.intervalValue || 0), 0, 1);
                        filters.push({name: f.name, equal: Equal.GTE, value: this.base.dateObjToISO(year)});
                        filters.push({name: f.name, equal: Equal.LT, value: this.base.dateObjToISO(new Date(year.getFullYear() + 1, 0, 1))});
                        break;
                    }
                } else {
                    filters.push(f.serializeShort(true));
                }
                if (f.isCalc || calcs.find(i => i.name === f.name)) {
                    calcFields = this.addToCalcFields(f.name, calcFields);
                }
            } else if (f.or && f.or.length) {
                filters.push({or: []});
                for (const orF of f.or.filter(i => i.isChecked)) {
                    filters[filters.length-1].or.push(orF.serializeShort(true));
                    if (orF.isCalc || calcs.find(i => i.name === orF.name)) {
                        calcFields = this.addToCalcFields(orF.name, calcFields);
                    }
                }
            }
        }

        // delete global filters by customer if conflict.
        if (isCustom && extraFilters && extraFilters.length) {
            for (let i = filters.length - 1; i >= 0; i--) {
                if (extraFilters.find(f => f.name === filters[i].name && filters[i].name)) {
                    filters.splice(i, 1);
                }
            }
        }

        for (const e of extraFilters) {
            filters.push(e.serializeShort(true));
            if (calcs.find(i => i.name === e.name)) {
                calcFields = this.addToCalcFields(e.name, calcFields);
            }
            if (e.or && e.or.length) {
                for (const orF of e.or) {
                    if (calcs.find(i => i.name === orF.name)) {
                        calcFields = this.addToCalcFields(orF.name, calcFields);
                    }
                }
            }
        }

        for (const e of board.listDisplay.filter(f => f.isChecked)) {
            if (calcs.find(i => i.name === e.name)) {
                calcFields = this.addToCalcFields(e.name, calcFields);
            }
        }

        if (justNote && board.groupBy.type === FieldType.DATETIME) {
            filters.push({name: 'note', equal: Equal.NOT, value: null});
        }
        if (justNote && board.groupBy.hidden) {
            for (const h of board.groupBy.hidden) {
                if (h.fieldName !== board.groupBy.name) {
                    continue;
                }
                if (viseFields.indexOf(h.fieldName) !== -1) {
                    if (!status.filters) {
                        status.filters = [];
                    }
                    status.filters.push({ name: 'note', equal: Equal.NOT, value: h["uuid"] || h.value });
                } else {
                    filters.push({ name: 'note', equal: Equal.NOT, value: h.value });
                }
            }
        }
        if (filters.length) {
            params += `&filter_fields=${JSON.stringify(filters)}`;
        }

        if (statusNote !== null) {
            status = Object.assign(status, statusNote);
        }

        // Group by.
        if (board.groupBy.name && board.groupBy.type && !isCustom) {    
            const groups = board.annotations;
            if (viseFields.indexOf(board.groupBy.name) === -1) {
                params += `&group_by=${JSON.stringify(groups)}`;
            } else {
                status = Object.assign(status, { note: board.groupBy.name });
            }
            if (groups.length) {
                // Get annotation from groups property (control unique value).
                if (!groups[0].note) {
                    const gr = [];
                    for (const gg of groups) {
                        if (!gr.find(g => g.group === gg.group)) {
                            gr.push(gg);
                        }
                    }
                    groups2 = gr.slice();
                } else {
                    // Get annotation from loaded data.
                    isNote = true;
                    if (!board.groupBy.isAsc) {
                        status = Object.assign(status, { desc: 'desc' });
                    }            
                }
            }
        }
        if (board.groupBy.name) {
            if (calcs.find(i => i.name === board.groupBy.name)) {
                calcFields = this.addToCalcFields(board.groupBy.name, calcFields);
            }
        }

        // Add order_by.
        if (!justNote) {
            for (const oo of board.orderBy) {
                o += `, ${!oo.isAsc ? '-' : ''}${oo.name}`;
                if (calcs.find(i => i.name === oo.name)) {
                    calcFields = this.addToCalcFields(oo.name, calcFields);
                }
            }
        } else {
            let distinct = 'note';
            if (board.groupBy.name === 'vise_user') {
                distinct = 'vise_user';
            }
            status = Object.assign(status, { distinct });
            o = `, ${!board.groupBy.isAsc ? '-' : ''}${distinct}`
        }

        params += `&status=${JSON.stringify(status)}`;
        if (o.length) {
            params += `&o=${o.slice(2)}`;
        }

        const documentTypes = this.base.getDocumentTypesDict(this.base.getLocalMeta());
        if ((documentTypes[table] === undefined || documentTypes[table] === null) && status.distinct) {
            params += `&distinct=${!board.groupBy.isAsc ? '-' : ''}${status.distinct}`;
        } 
        if (calcFields.length) {
            params += (new ExtraFields(calcFields)).serialize;
        }
        params = this.escapeText(params);
        return { params, isNote, groups: groups2 };
    }

    getQuarter(d) {
        return `${Math.ceil(((d.getMonth() + 1) / 3.0))} ${this.trans.instant('qr')} ${d.getFullYear()}`;
    }

    getWeek(d) {
        const days = d.getDate(); d.setDate(d.getDate() + 6);
        return `${days.toString().length === 1 ? '0' : ''}${days}-${this.base.dateToString(d)}`;
    }

    getTruncDate(typeDate, date) {
        let verbose = null;
        const d = this.base.getDate(date);
        if (typeDate === Interval.YEAR) {
            verbose = `${d.getFullYear()}`;                                                                
        } else if (typeDate === Interval.MONTH) {
            verbose = `${this.trans.instant(this.base.month[d.getMonth()])} ${d.getFullYear()}`;
        } else if (typeDate === Interval.QUARTER) {
            verbose = this.getQuarter(d);
        } else if (typeDate === Interval.DAY) {
            verbose = this.base.dateToString(date);
        } else if (typeDate === Interval.WEEK) {
            verbose = this.getWeek(d);
        }
        return verbose;
    }

    getVerboseAndObj(data, i, groupBy: GroupBy) {
        let n = data.data[i].note; let verbose = data.data[i].note_repr ? data.data[i].note_repr : null;
        if (groupBy.type === FieldType.DATE && n) {
            n = n.slice(0, 10);
        } else if (groupBy.type === FieldType.DATETIME && n) {
            n = n.slice(0, 10) + 'T00:00:00Z';
        }
        if ((groupBy.type === FieldType.DATE || groupBy.type === FieldType.DATETIME) && n) {
            verbose = this.getTruncDate(groupBy.typeDate, n) || verbose;
        }
        let obj = data.data[i][groupBy.name]; let color = null;
        if ('tag_name' === groupBy.name && data.data[i].tags) {
            const tag = data.data[i].tags.find(t => t.name === n);
            if (tag && tag.color) {
                color = tag.color.source_full;
            } 
            obj = tag;
        } else if ('tag_parent_name' === groupBy.name && data.data[i].tags) {
            const tag = data.data[i].tags.find(t => t.parent && t.parent.name === n);
            if (tag && tag.parent && tag.parent.color) {
                color = tag.parent.color.source_full;
            }
            obj = tag;
        }
        return { obj, color, verbose, n };
    }
}
