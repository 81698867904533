import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { Interval, FieldType } from '../classes/enums';

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    resp: any;
    statuses = [[-1, 'Создан'], [0, 'Согласован'], [1, 'Утвержден'], [2, 'Исполнен'], [3, 'Закрыт'], [4, 'Отклонен']];
    vises = [[2, 'Согласовать'], [3, 'Утвердить'], [4, 'Ознакомиться'], [5, 'Исполнить'], [6, 'Проверить'], [8, 'Другое']];

    constructor(private http: HttpClient, 
                private base: BaseService,
                private trans: TranslateService) { }

    getTitleMeta(table) {
        const t = this.base.getTableMeta(table);
        if (t && t.verbose_name) {
            return t.verbose_name;
        }
    }

    getTitlePluralMeta(table) {
        const t = this.base.getTableMeta(table);
        if (t && (t.verbose_name_plural || t.verbose_name)) {
            return t.verbose_name_plural || t.verbose_name;
        }
    }

    getTables() {
        const resp = {};
        const meta = this.base.getLocalMeta();
        if (meta) {
            for (const d of meta.filter(t => !t.block)) {
                resp[d.table] = d.verbose_name;
            }
        }
        return resp;
    }

    getTablesArray() {
        const resp = [];
        const meta = this.base.getLocalMeta();
        if (meta) {
            for (const d of meta.filter(t => !t.block)) {
                resp.push([d.table, d.verbose_name]);
            }
        }
        this.base.sort(resp, 1);
        return resp;
    }

    getMetaFields(table) {
        const resp = {
            y: [],
            filters: [],
            orderBy: [
                {name: 'edited', verbose_name: this.trans.instant('edited'), isAsc: 'false', isChecked: true}
            ],
            groupBy: {
                name: null,
                verbose_name: null,
                type: null,
                groups: [],
                choices: [],
                typeDate: null,
                isAsc: 'true'
            }
        };

        const meta = this.base.getLocalMeta();
        if (meta) {
            const t = meta.find(i => i.table === table);
            if (t) {
                if (!t.order_by || !t.order_by.length) {
                    t.order_by = ['-added', '-created_at'];
                }
                if (t.order_by && t.order_by.length) {
                    resp.orderBy = [];
                    for (const i of t.order_by) {
                        const f = t.fields.find(ff => ff.name === (i[0] === '-' ? i.slice(1) : i));
                        if (!f) {
                            continue;
                        }
                        resp.orderBy.push({
                            name: f.name,
                            verbose_name: f.verbose_name,
                            isAsc: i[0] === '-' ? 'false' : 'true',
                            isChecked: true
                        });
                    }
                }
                if (!t.list_display || !t.list_display.length) {
                    t.list_display = ['date', 'name', 'amount', 'currency', 'responsible', 'user', 'added', 'created_at'];
                }
                if (t.list_display && t.list_display.length) {
                    for (const i of t.list_display) {
                        const f = t.fields.find(ff => (i[0] === '-' ? i.slice(1) : i) === ff.name);
                        if (!f) {
                            continue;
                        }
                        resp.y.push({ 
                            name: f.name, 
                            verbose_name: f.verbose_name, 
                            width: 200, 
                            type: f.type, 
                            repr: f.repr, 
                            related_model: f.related_model
                        });
                    }
                }
                if (!t.group_by) {
                    for (const i of ['-added', '-created_at']) {
                        const f = t.fields.find(ff => (i[0] === '-' ? i.slice(1) : i) === ff.name);
                        if (!f) {
                            continue;
                        }
                        resp.groupBy = {
                            name: f.name,
                            verbose_name: f.verbose_name,
                            type: f.type,
                            groups: [],
                            choices: [],
                            typeDate: Interval.MONTH,
                            isAsc: 'false'
                        };
                        break;
                    }
                } else {
                    resp.groupBy = t.group_by;
                }
            }
        }
        return resp;
    }

    getTablesMeta(): Observable<any> {
        const url = `${this.base.getRootUrl()}portal/table-meta-api`;
        return this.http.get<any>(url)
        .pipe(
            tap(resp => {
                const vise = resp.find(i => i.table === 'Vise');
                if (vise && vise.fields) {
                    const documentTypes = this.base.getDocumentTypes(resp);
                    if (documentTypes) {
                        for (const documentType of documentTypes) {
                            const doc = resp.find(i => i.table === documentType[1]);
                            if (doc && doc.fields) {
                                doc.fields.push(
                                    {
                                        verbose_name: this.trans.instant('status'),
                                        type: FieldType.INT,
                                        choices: this.getStatuses(),
                                        related_model: null,
                                        repr: null,
                                        name: 'status_type',
                                        blockOrder: true
                                    }
                                );
                                doc.fields.push(
                                    {
                                        verbose_name: this.trans.instant('viser'),
                                        type: FieldType.REF,
                                        choices: [],
                                        related_model: 'User',
                                        repr: 'name',
                                        name: 'vise_user',
                                        blockOrder: true
                                    }
                                );
                                doc.fields.push(
                                    {
                                        verbose_name: this.trans.instant('current-vise'),
                                        type: FieldType.INT,
                                        choices: this.getVises(),
                                        related_model: null,
                                        repr: null,
                                        name: 'vise_type',
                                        blockOrder: true
                                    }
                                );
                            }
                        }
                    }
                }
                
                const task = resp.find(i => i.table === 'Task');
                if (task && task.fields) {
                    task.fields.push(
                        {
                            verbose_name: this.trans.instant('tag'),
                            type: FieldType.CHAR,
                            choices: [],
                            related_model: null,
                            repr: null,
                            name: 'tag_name',
                            blockDisplay: true,
                            blockFilter: true,
                            blockOrder: true
                        }
                    );
                    task.fields.push(
                        {
                            verbose_name: this.trans.instant('tag'),
                            type: FieldType.REF,
                            choices: [],
                            related_model: 'Tag',
                            repr: 'name',
                            name: 'tag_uuid',
                            blockDisplay: true,
                            blockList: true,
                            blockOrder: true
                        }
                    );
                    task.fields.push(
                        {
                            verbose_name: this.trans.instant('group-tags'),
                            type: FieldType.REF,
                            choices: [],
                            related_model: 'Tag',
                            repr: 'name',
                            name: 'tag_parent',
                            blockDisplay: true,
                            blockList: true,
                            blockOrder: true
                        }
                    );
                    task.fields.push(
                        {
                            verbose_name: this.trans.instant('group-tags'),
                            type: FieldType.CHAR,
                            choices: [],
                            related_model: null,
                            repr: null,
                            name: 'tag_parent_name',
                            blockDisplay: true,
                            blockFilter: true,
                            blockOrder: true
                        }
                    );
                    task.fields.push(
                        {
                            verbose_name: this.trans.instant('my-stage'),
                            type: FieldType.BOOL,
                            choices: [],
                            related_model: null,
                            repr: 'first_stage',
                            name: 'first_stage',
                            blockDisplay: true,
                            blockList: true,
                            blockOrder: true
                        }
                    );
                }
                
                this.base.setMetaFields(resp);
            }),
            catchError(this.base.handleError('getTableMeta', this.base.listResp))
        );
    }

    allowFilterFields(fields, block) {
        return fields.filter(i => !i[block] && [FieldType.CHAR, FieldType.DECIMAL, FieldType.INT, FieldType.BOOL, 
                                                FieldType.REF, FieldType.DATETIME, FieldType.DATE].indexOf(i.type) !== -1);
    }

    getStatus(statusType) {
        const status = this.getStatuses().find(j => j[0] === statusType);
        if (status) {
            return status[1];
        }
        return null;
    }

    getStatuses() {
        const st = this.base.copy(this.statuses);
        for (const s of st) {
            s[1] = this.trans.instant(s[1]);
        }
        return st;
    }

    getVises() {
        const st = this.base.copy(this.vises);
        for (const s of st) {
            s[1] = this.trans.instant(s[1]);
        }
        return st;
    }
}
