import { BoardFilter } from "./board-filters";
import { Equal } from "./enums";

export class ProcessVersion {
    public static table = 'processversion';
    
    constructor(public id: number,
                public name: string,
                public type: number,
                public versionId: number) {
    }

    static fromJson(d: any): ProcessVersion {
        if (d) {
            return new ProcessVersion(d.id || 0, d.name, d.type, d.version_id || 0);
        }
        return new ProcessVersion(0, null, null, null);
    }

    serialize(): any {
        return {id: this.id, name: this.name, type: this.type, version_id: this.versionId};
    }

    static set(v: ProcessVersion) {
        localStorage.setItem(this.table, JSON.stringify(v.serialize()))
    }

    static get(): ProcessVersion {
        const val = localStorage.getItem(this.table);
        if (val) {
            return ProcessVersion.fromJson(JSON.parse(val));
        }
        return ProcessVersion.fromJson({});
    }

    static filter(): BoardFilter {
        const f = new BoardFilter('version_id', Equal.EQ, ProcessVersion.get().id);
        f.isChecked = true;
        return f;
    }
}
