import { Base } from "./base";
import { Equal, FieldType, Interval } from "./enums";

export class Choice {
    
    constructor(public id: number,
                public title: string) {
    }

    static fromJson(d: any): Choice[] {
        const list: Choice[] = [];
        if (d && d.length) {
            for (const row of d) {
                if (row && row[0] !== undefined && row[0] !== null && row[1]) {
                    list.push(new Choice(row[0], row[1]))
                }
            }
        }
        return list;
    }

    static serialize(choices: Choice[]): any[] {
        if (!choices) {
            return [];
        }
        return choices.map(c => {
            return [c.id, c.title];
        });
    }
}

export class BoardFilter {

    static queryKey: string = "&filter_fields=";
    
    constructor(public name: string, 
                public equal: Equal,
                public value: any,
                public repr?: string,
                public verboseName?: string,
                public type?: FieldType,
                public choices?: Choice[],
                public isChecked?: boolean,
                public hidden?: boolean,
                public isChart?: boolean,
                public isCalc?: boolean,
                public or?: BoardFilter[],
                public interval?: Interval,
                public intervalValue?: number,
                public justEqual?: boolean,
                public focusField?: boolean,
                public obj?: any) {

        if (this.or && !this.or.length) {
            this.or = null;
        }
        if (!this.equal) {
            this.equal = Equal.EQ;
        }
    }

    check(item: any, pk: string) {
        const v0 = this.type === FieldType.REF ? (this.value ? this.value[pk] || this.value : null) : this.value;
        let v1 = this.type === FieldType.REF ? (item[this.name] ? item[this.name][pk] : null) : item[this.name];
        if (this.name === 'tag_uuid' && item.tags) {
            v1 = item.tags.find(t => t[pk] === v0);
            if (v1) {
                v1 = v1[pk];
            } 
        }

        if (this.name === 'tag_parent' && item.tags) {
            v1 = item.tags.find(t => t.parent && t.parent[pk] === v0);
            if (v1) {
                v1 = v1.parent[pk];
            } 
        }

        switch (this.equal) {
        case Equal.EQ:
            return v0 === v1;
        case Equal.NOT: 
            return v0 !== v1;
        case Equal.GT:
            return v1 > v0;
        case Equal.GTE:
            return v1 >= v0;
        case Equal.LT:
            return v1 < v0;
        case Equal.LTE:
            return v1 <= v0;
        default:
            return true;
        }
    }

    serialize() {
        const or = BoardFilter.toList(this.or);
        return {
            name: this.name, 
            equal: this.equal || Equal.EQ, 
            value: this.value, 
            verbose_name: this.verboseName, 
            type: this.type, 
            choices: Choice.serialize(this.choices),
            isChecked: this.isChecked,
            value_repr: this.repr,
            hidden: this.hidden,
            isChart: this.isChart,
            isCalc: this.isCalc,
            or: or.length ? or : null,
            interval: this.interval,
            intervalValue: this.intervalValue
        }
    }

    serializeShort(isChecked?: boolean): any {
        if (this.or && this.or.length) {
            const or = this.or.filter(f => (f.isChecked || isChecked) && f.name).map(f => f.serializeShort(isChecked));
            if (or.length) {
                return {or};
            }
        }
        return {
            name: this.name, 
            equal: this.equal || Equal.EQ, 
            value: this.value, 
        }
    }

    static fromJson(d: any): BoardFilter {
        const or: BoardFilter[] = [];
        if (d.or && d.or.length) {
            for (const item of d.or) {
                or.push(BoardFilter.fromJson(item))
            }
        }
        return new BoardFilter(
            d.name, d.equal || Equal.EQ, d.value, d.value_repr || '', d.verbose_name || '', d.type || null, 
            Choice.fromJson(d.choices), 
            d.isChecked || false, d.hidden || false, d.isChart || false, d.isCalc || false,
            or.length ? or : null, d.interval || null, d.intervalValue || 0
        );
    }

    static toList(filters: BoardFilter[]): any[] {
        if (!filters) {
            return [];
        }
        return filters.map(f => f.serialize() );
    }

    static serialize(filters: BoardFilter[], isChecked?: boolean): string {
        return this.queryKey + `${JSON.stringify(filters.filter(f => (f.isChecked || isChecked) && f.name).map(f => f.serializeShort(isChecked)))}`;
    }

    static copy(filters: BoardFilter[]): BoardFilter[] {
        const list: BoardFilter[] = [];
        for (const f of Base.copy(BoardFilter.toList(filters))) {
            list.push(BoardFilter.fromJson(f));
        }
        return list;
    }

    static default(): BoardFilter {
        return new BoardFilter(null, Equal.EQ, null, null, null, null, [], true);
    }
}
