import { Component, OnInit, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { BoardFilter } from 'src/app/classes/board-filters';
import { Equal } from 'src/app/classes/enums';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-choice-setting',
    templateUrl: './choice-setting.page.html',
    styleUrls: ['./choice-setting.page.scss'],
})
export class ChoiceSettingPage implements OnInit {

    @Input() form: any;
    
    constructor(private popoverCtrl: PopoverController,
                private modalCtrl: ModalController,
                private base: BaseService) { }

    ngOnInit() {
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }

    async chooseForm() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
                filters: [new BoardFilter('table', Equal.EQ, this.form.data.table), new BoardFilter('type', Equal.EQ, 0)],
                funcRepr: 'name',
            },
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                const val = {name: details.data.item.name};
                const pk = this.base.getTablePk('Form');
                val[pk] = details.data.item[pk];
                this.form.data.form = val;
            }
        });
        return await modal.present();
    }
}
