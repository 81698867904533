import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserObjectPage } from './user-object.page';
import { ColorPickerPageModule } from '../color-picker/color-picker.module';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerFieldPageModule } from '../color-picker-field/color-picker-field.module';

const routes: Routes = [
    {
        path: 'user-object',
        component: UserObjectPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        ColorPickerPageModule,
        TranslateModule,
        ColorPickerFieldPageModule
    ],
    declarations: [UserObjectPage],
    exports: [UserObjectPage]
})
export class UserObjectPageModule {}
