import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../classes/app.constants';
import { BaseService } from './base.service';
import { Call, MessageType, CallStatus, Answer, Caller } from '../classes/sip';

@Injectable({
    providedIn: 'root'
})
export class SipService {

    callPage: any;
    
    subject = new BehaviorSubject<Answer>(null);
    streamSubject = new BehaviorSubject<MediaStream>(null);
    private _opened: boolean;
    private _account: any;
    private ws: WebSocket;
    private pc: RTCPeerConnection;
    private stream: MediaStream;
    private localSessionDescription: string;
    private interval: any;
    private caller: Caller;

    constructor(private base: BaseService,
                private modalCtrl: ModalController,
                private trans: TranslateService) {}

    private get url(): string {
        return this.account.ws + "?addr=" + this.account.receiver_ip + ":" + this.account.receiver_port;
    }

    private get peerIsAlive(): boolean {
        return this.pc && ["checking", "completed", "connected", "new"].indexOf(this.pc.connectionState) !== -1;
    }

    get account(): any {
        return this._account;
    }

    set account(val: any) {
        this._account = val;
    }

    private get opened(): boolean {
        return this._opened;
    }

    private set opened(val: boolean) {
        this._opened = val;
    }

    private addCall(raw: any, incoming: boolean, status: CallStatus): Call {
        let call = Call.calls.find(c => c.id == raw.call_id);
        if (call) {
            return call;
        }

        call = new Call(raw.call_id, raw.data, status, this.account, this.ws, incoming, 
                        this.caller && this.caller.phone === raw.data ? this.caller : new Caller(raw.phone, this.trans));
        Call.calls.push(call);
        
        return call;
    }

    removeCall(call: Call) {
        if (call) {
            call.drop();
        }
        if (!Call.calls.length) {
            this.initAudio(true);
        }
    }

    async openCallWidget(call: Call): Promise<void> {
        if (this.opened) {
            console.error("already opened", call);
            return;
        }
        this.opened = true;
        const modal = await this.modalCtrl.create({
            component: this.callPage,
            componentProps: {
                call
            },
            cssClass: 'call-widget',
            showBackdrop: false,
        });
        modal.onDidDismiss().then((_: any) => {
            this.opened = false;
        });
        return await modal.present();
    }

    private initWs() {
        if (this.ws && this.ws.readyState !== this.ws.CLOSED) {
            this.ws.close();
        }
        this.ws = new WebSocket(this.url);
        
        const root = this;

        this.ws.onopen = (ev) => {
            console.log('open ws', ev);
            root.initAudio(true);
        }

        this.ws.onmessage = (ev) => {
            const raw = JSON.parse(ev.data);
            const answer = new Answer(raw.data, raw.type, raw.err, Call.getCall(raw), root.ws, root.account, root.base);

            if (answer.err) {
                answer.presentError();
                return;
            }

            switch (answer.type) {

            case MessageType.INIT_TRACK:

                break;
            
            case MessageType.INCOMING_CALL:

                if (answer.call) {
                    answer.call.status = CallStatus.RECEIVE;
                } else {
                    answer.call = root.addCall(raw, true, CallStatus.RECEIVE);
                }

                answer.call.ringing();
                root.openCallWidget(answer.call);
                break;
            
            case MessageType.NEW_CALL:

                if (answer.call) {
                    answer.call.status = CallStatus.START;
                } else {
                    answer.call = root.addCall(raw, false, CallStatus.START);
                }

                root.openCallWidget(answer.call);
                break;
            
            case MessageType.HOLD_ON:

                answer.call.status = CallStatus.HOLD_ON;
                root.subject.next(answer);
                break;

            case MessageType.HOLD_UP:            
            case MessageType.ACCEPT_CALL:

                answer.call.status = CallStatus.CALLING;
                root.subject.next(answer);
                break;
            
            case MessageType.BUSY_CALL:
            case MessageType.CANCEL_CALL:

                root.removeCall(answer.call);
                root.subject.next(answer);
                break;

            case MessageType.INIT_AUDIO_MSG:

                if (answer.data) {
                    try {
                        root.pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(atob(answer.data))));
                    } catch (e) {
                        root.base.sendToast(e);
                    }
                } else {
                    root.initAudio(false);
                }
                break;
            
            }

            root.streamSubject.next(root.stream);
        }

        this.ws.onclose = (ev) => {
            console.error('close ws', ev);
        }

        this.ws.onerror = (ev) => {
            console.error('ws error', ev);
            root.base.sendToast(root.trans.instant('ws-call-init-error'))
        }
    }

    private initAudio(newTrack: boolean) {
        if (this.pc && this.peerIsAlive) {
            this.pc.close();
        }

        if (!this.ws || this.ws.readyState !== this.ws.OPEN) {
            this.initWs();
            return;
        }

        if (newTrack) {
            this.ws.send(JSON.stringify({type: MessageType.INIT_TRACK, account: this.account}))
        }
        
        this.pc = new RTCPeerConnection({
            iceServers: [
                {
                    urls: Constants.STUN
                }
            ]
        })

        const root = this;
        this.pc.ontrack = (event) => {
            console.log(event);
            if (event.streams.length && event.streams[0].id === "pion") {
                root.stream = event.streams[0];
                root.streamSubject.next(root.stream);
            }
        }

        this.pc.oniceconnectionstatechange = (_) => {
            console.log(root.pc.iceConnectionState);
        };

        this.pc.onicecandidate = (ev) => {
            if (ev.candidate === null) {
                root.localSessionDescription = btoa(JSON.stringify(root.pc.localDescription));
                root.ws.send(JSON.stringify({type: MessageType.INIT_AUDIO_MSG, params: root.localSessionDescription, account: root.account}))
            }
        };
        const audio: any = {
            autoGainControl: false,
            channelCount: 1,
            noiseSuppression: false,
            sampleRate: 8000,
        }
        navigator.mediaDevices.getUserMedia(
            { 
                video: false,
                audio
            }
        )
        .then(stream => {
            stream.getTracks().forEach(
                track => {
                    root.pc.addTrack(track, stream);
                }
            );
            root.pc.addTransceiver('audio', {'direction': "recvonly"});        
            root.pc.createOffer().then(d => root.pc.setLocalDescription(d)).catch(console.error);
        })
        .catch((ev) => {
            console.error(ev);
        })
    }

    private restart() {
        if (!this.ws || this.ws.readyState === this.ws.CLOSED) {
            this.initWs();
            return;
        }
        if (!this.peerIsAlive) {
            this.initAudio(false);
        }
    }

    init() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }

        this.interval = setInterval(() => {
            this.restart();
        }, 10000)

        this.restart();
    }

    newCall(phone, name?, image?) {
        this.caller = new Caller(phone, this.trans, name, image);
        this.ws.send(JSON.stringify({type: MessageType.NEW_CALL, params: phone, account: this.account}))
    }
}
