import { Component, OnInit, Input } from '@angular/core';
import { ViseStatusService } from '../services/vise-status.service';
import { AlertController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { Base } from '../classes/base';

@Component({
    selector: 'app-vise',
    templateUrl: './vise.page.html',
    styleUrls: ['./vise.page.scss'],
})
export class VisePage implements OnInit {

    @Input() doc: any;
    @Input() table: string;
    @Input() item: any;
    @Input() itemChanged: any;
    user: any;
    newComment = null;
    tab = 2;
    items = [];
    userPk = '';
    subscription: any;

    constructor(private alertCtrl: AlertController, 
                private viseStatusService: ViseStatusService, 
                private base: BaseService,
                private trans: TranslateService) { }

    ngOnInit() {
        this.user = Base.getCurrentUser();
        this.userPk = this.base.getTablePk('User');
        if (this.itemChanged) {
            this.subscription = this.itemChanged.subscribe(item => {
                if (item && item.vises) {
                    this.item.vises = item.vises;
                }
                this.changeTab(this.tab);
            });
        } else {
            this.changeTab(this.tab);
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getViseType(vise) {
        const t = this.viseStatusService.getViseType(vise);
        if (t) {
            return t.toLowerCase();
        }
        return t;
    }

    changeTab(tab) {
        this.tab = tab;
        this.items = this.getComments(this.item.vises, this.tab);
    }

    getComments(vises, type) {
        if (!vises) {
            return [];
        }
        const comments = []; let lastSepDate = null;
        for (const v of vises) {
            if ((((v.type === 0 || v.type === 7) && type === 0)
                  || (!(v.type === 0 || v.type === 7) && type === 1)
                  || type === 2) && v.user) {
                v.sep_date = null; const sepDate = this.base.getDateSeparator(v.edited);
                if (!comments.length || lastSepDate !== sepDate) {
                    v.sep_date = sepDate;
                    lastSepDate = sepDate.slice();
                }
                v.dateFull = this.base.getMessageDate(v.edited);
                v.dateShort = this.base.getMessageDate(v.edited).slice(6);
                v.viseType = this.getViseType(v);
                comments.push(v);
            }
        }

        return comments;
    }

    addComment(code, shiftKey?, body?) {
        if (code !== 13 || shiftKey) {
            return;
        }
        const comment = body || this.newComment.slice();
        this.newComment = null;
        const documentTypes = this.base.getDocumentTypesDict(this.base.getLocalMeta());
        const data = {
            user: this.user[this.userPk],
            document: this.doc,
            document_type: documentTypes[this.table],
            comment,
            queue: 0,
            type: 0,
            approve: true
        };
        this.viseStatusService.createVise(data).subscribe(_ => {this.newComment = null; });
    }

    async deleteVise(vise) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('do-you-want-delete-vise', {name: vise.user ? vise.user.name : ''}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        this.viseStatusService.deleteVise(vise[this.base.getTablePk('Vise')]).subscribe(resp => {
                            if (resp && resp.error) {
                                this.base.sendToast(this.base.getError(resp.error));
                            }
                        });
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                }
            ]
        });
        await alert.present();
    }

    loadFile(ev) {
        this.addComment(13, false, ev.url);
    }
}
