import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthGuardService } from './services/auth-guard.service';
import { ChoicesPageModule } from './choices/choices.module';
import { DeskDetailPageModule } from './desk-detail/desk-detail.module';
import { DragulaModule } from 'ng2-dragula';
import { VisePageModule } from './vise/vise.module';
import { MenuPageModule } from './menu/menu.module';
import { ServicePageModule } from './service/service.module';
import { NewsPageModule } from './news/news.module';
import { MessagePageModule } from './message/message.module';
import { TaskPageModule } from './task/task.module';
import { BackgroundPageModule } from './background/background.module';
import { UserPhotoPageModule } from './components/user-photo/user-photo.module';
import { ColorPickerPageModule } from './components/color-picker/color-picker.module';
import { UserDetailPageModule } from './user-detail/user-detail.module';
import { UserObjectPageModule } from './components/user-object/user-object.module';
import { InfoGraphPageModule } from './info-graph/info-graph.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TagPageModule } from './tag/tag.module';
import { ListPageModule } from './list/list.module';
import { WidgetsPageModule } from './widgets/widgets.module';
import { CustomClassPageModule } from './custom-class/custom-class.module';
import { ColorPickerFieldPageModule } from './components/color-picker-field/color-picker-field.module';
import { FormPageModule } from './form/form.module';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { FormFieldPageModule } from './components/form-field/form-field.module';
import { ClassWidgetPageModule } from './components/class-widget/class-widget.module';
import { StyleWidgetPageModule } from './components/style-widget/style-widget.module';
import { RowWidgetPageModule } from './components/row-widget/row-widget.module';
import { ChatPageModule } from './components/chat/chat.module';
import { FilterPageModule } from './components/filter/filter.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ListDisplayPageModule } from './components/list-display/list-display.module';
import { OrderByPageModule } from './components/order-by/order-by.module';
import { ChoiceSettingPageModule } from './components/choice-setting/choice-setting.module';
import { CellPageModule } from './components/cell/cell.module';
import { ButtonWidgetPageModule } from './components/button-widget/button-widget.module';
import { GraphSettingPageModule } from './components/graph-setting/graph-setting.module';
import { ExportToExcelPageModule } from './components/export-to-excel/export-to-excel.module';
import { BotgroupPageModule } from './components/botgroup/botgroup.module';
import { BotgroupListPageModule } from './components/botgroup-list/botgroup-list.module';
import { BotbuttonPageModule } from './components/botbutton/botbutton.module';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { ScannerPageModule } from './components/scanner/scanner.module';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { NgxBarcodeModule } from 'ngx-barcode';
import { CollectionPageModule } from './components/collection/collection.module';
import { SubMenuPageModule } from './components/sub-menu/sub-menu.module';
import { CollectionListPageModule } from './components/collection-list/collection-list.module';
import { DatetimeWidgetPageModule } from './components/datetime-widget/datetime-widget.module';
import { TaskParticipantsPageModule } from './task-participants/task-participants.module';
import { AccountsPageModule } from './components/accounts/accounts.module';
import { IonicSwipeAllModule } from 'ionic-swipe-all';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { ForeignPageModule } from './components/json-schema-widgets/foreign/foreign.module';
import { InputPageModule } from './components/json-schema-widgets/input/input.module';
import { SelectPageModule } from './components/json-schema-widgets/select/select.module';
import { CheckboxPageModule } from './components/json-schema-widgets/checkbox/checkbox.module';
import { DatePageModule } from './components/json-schema-widgets/date/date.module';
import { DatetimePageModule } from './components/json-schema-widgets/datetime/datetime.module';
import { JsonViewPageModule } from './components/json-view/json-view.module';
import { CatalogPageModule } from './components/catalog/catalog.module';
import { CallbackPageModule } from './pipes/callback';
import { RelatedTableFormSettingPageModule } from './components/related-table-form-setting/related-table-form-setting.module';
import { DateRangePageModule } from './components/date-range/date-range.module';
import { CommentPageModule } from './components/comment/comment.module';
import { UploadFileBtnPageModule } from './components/upload-file-btn/upload-file-btn.module';
import { ImportFromExcelPageModule } from './components/import-from-excel/import-from-excel.module';
import { ImportImagesPageModule } from './components/import-images/import-images.module';
import { MapPageModule } from './components/map/map.module';
import { CallWidgetPageModule } from './components/call-widget/call-widget.module';
import { InputPhoneWidgetPageModule } from './components/input-phone-widget/input-phone-widget.module';
import { BoardButtonPageModule } from './components/board-button/board-button.module';
import { HierarchyPageModule } from './components/hierarchy/hierarchy.module';
import { BotProcessFormPageModule } from './components/bot-process-form/bot-process-form.module';
import { BotProcessBindPageModule } from './components/bot-process-bind/bot-process-bind.module';
import { ProcessFormPageModule } from './components/process-form/process-form.module';
import { ProcessBindPageModule } from './components/process-bind/process-bind.module';
import { HierarchyRowPageModule } from './components/hierarchy-row/hierarchy-row.module';
import { ProcessVersionPageModule } from './components/process-version/process-version.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        MapPageModule,
        MessagePageModule,
        NewsPageModule,
        MenuPageModule,
        ServicePageModule,
        UserPhotoPageModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ChoicesPageModule,
        DeskDetailPageModule,
        VisePageModule,
        ChatPageModule,
        TaskPageModule,
        BackgroundPageModule,
        ColorPickerPageModule,
        UserDetailPageModule,
        UserObjectPageModule,
        InfoGraphPageModule,
        ListPageModule,
        TagPageModule,
        WidgetsPageModule,
        CustomClassPageModule,
        ColorPickerFieldPageModule,
        FormPageModule,
        DragulaModule.forRoot(),
        NgxChartsModule,
        BrowserAnimationsModule,
        DatePickerModule,
        FormFieldPageModule,
        ClassWidgetPageModule,
        StyleWidgetPageModule,
        RowWidgetPageModule,
        FilterPageModule,
        BoardButtonPageModule,
        HierarchyPageModule,
        HierarchyRowPageModule,
        ListDisplayPageModule,
        OrderByPageModule,
        ChoiceSettingPageModule,
        CellPageModule,
        ButtonWidgetPageModule,
        GraphSettingPageModule,
        ExportToExcelPageModule,
        ImportFromExcelPageModule,
        ImportImagesPageModule,
        BotgroupPageModule,
        BotgroupListPageModule,
        BotbuttonPageModule,
        NgQrScannerModule,
        ScannerPageModule,
        BarecodeScannerLivestreamModule,
        NgxBarcodeModule,
        CollectionPageModule,
        CollectionListPageModule,
        SubMenuPageModule,
        DatetimeWidgetPageModule,
        TaskParticipantsPageModule,
        AccountsPageModule,
        IonicSwipeAllModule,
        MaterialDesignFrameworkModule,
        ForeignPageModule,
        InputPageModule,
        SelectPageModule,
        CheckboxPageModule,
        DatePageModule,
        DatetimePageModule,
        JsonViewPageModule,
        CatalogPageModule,
        CallbackPageModule,
        RelatedTableFormSettingPageModule,
        DateRangePageModule,
        CommentPageModule,
        UploadFileBtnPageModule,
        CallWidgetPageModule,
        InputPhoneWidgetPageModule,
        HierarchyPageModule,
        BotProcessFormPageModule,
        BotProcessBindPageModule,
        ProcessFormPageModule,
        ProcessBindPageModule,
        ProcessVersionPageModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        AuthGuardService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
