import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Board, HierarchyField } from 'src/app/classes/board';
import { BoardFilter } from 'src/app/classes/board-filters';
import { GroupByList } from 'src/app/classes/board-group-by';
import { BaseService } from 'src/app/services/base.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
    selector: 'app-hierarchy-row',
    templateUrl: './hierarchy-row.page.html',
    styleUrls: ['./hierarchy-row.page.scss'],
})
export class HierarchyRowPage implements OnInit {

    @Input() depth: number;
    @Input() depthHierarchy: number;
    @Input() parents: any[];
    @Input() parent: any;
    @Input() allSize: number;
    @Input() showSelection: boolean;
    @Input() board: Board;
    @Input() showFilters: boolean;
    @Input() showFormating: boolean;
    @Input() group: GroupByList;
    @Input() fieldChoices: any;
    @Input() changedItemSubject: any;

    @Output() selectAll = new EventEmitter();
    @Output() openItem = new EventEmitter();
    @Output() addFilterEvent = new EventEmitter();
    @Output() openFormatingEvent = new EventEmitter();

    hasNext: boolean = false;
    depthHierarchyNew: number;
    parentsNew: any[] = [];
    hierarchy: HierarchyField;
    preHierarchy: HierarchyField;
    scrollComplete: boolean = true;
    page: number = 1;
    open: boolean = true;
    private subscription: any;
    data = [];

    constructor(private base: BaseService,
                private templateService: TemplateService) { }

    ngOnInit() {
        this.parentsNew = this.base.copy(this.parents)
        this.parentsNew.splice(this.parentsNew.length, 0, this.base.copy(this.parent));
        this.depthHierarchyNew = this.base.copy(this.depthHierarchy);

        if (this.depth < 40) {
            if (this.board.hierarchyFields && this.board.hierarchyFields[this.depthHierarchy]) {
                this.hierarchy = this.board.hierarchyFields[this.depthHierarchy];
                this.preHierarchy = this.board.hierarchyFields[this.depthHierarchy];
                if (!this.hierarchy.hasChildren || !this.group.obj.has_children) {
                    if (this.depthHierarchy < this.board.hierarchyFields.length) {
                        this.depthHierarchyNew ++;
                        this.hierarchy = this.board.hierarchyFields[this.depthHierarchyNew];
                    } else {
                        this.hierarchy = null;
                    }
                }
            }
        }
        this.load();

        if (this.changedItemSubject && this.hierarchy && this.hierarchy.table === this.board.table) {
            const pk = this.base.getTablePk(this.hierarchy.table);

            this.subscription = this.changedItemSubject.subscribe(item => {
                if (!item) {
                    return
                }
                if (item.depth === this.depth) {
                    this.load(1, this.hierarchy.maxElements * this.page);
                    console.log("load depth", item)
                    return;
                }
                let isFound = false;
                for (const row of this.data) {
                    if (row.group && row.group.obj && row.group.obj[pk] === item[pk]) {
                        isFound = true;
                        break;
                    } else if (row.item && row.item[pk] === item[pk]) {
                        isFound = true;
                        break;
                    }
                }

                if (!isFound) {
                    for (const f of this.getFilters()) {
                        if (!this.checkFilter(f, item)) {
                            return
                        }
                    }
                }
                console.log("refresh", this.group.name, this.base.copy(this.data.length), this.depth, this.base.copy(this.data));
                if (!this.data.length) {
                    this.changedItemSubject.next({depth: this.depth - 2});
                    console.log("depth", this.depth - 2)
                } else {
                    this.load(1, this.hierarchy.maxElements * this.page);
                }
            });
        }
    }

    private checkFilter(filter: BoardFilter, item: any) {
        const tt = this.base.getTableMeta(this.hierarchy.table).fields.find(f => f.name === filter.name);
        const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);

        return filter.check(item, pk)
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private getFilters(): BoardFilter[] {
        if (this.hierarchy.filterFunc) {
            const self = { parent: this.group.obj, parents: this.parents };
            const val = this.base.getCalcRepr(self, null, this.hierarchy.filterFunc);
            if (val && val.length) {
                return val.map(f => BoardFilter.fromJson(f)) 
            } 
        }

        return []
    }

    add() {
        const self = { parent: this.group.obj, parents: this.parents };
        const val = this.base.getCalcRepr(self, null, this.preHierarchy.addDefaultFunc);
        if (val) {
            console.log(val)
            this.openItem.emit({o: JSON.parse(val)});
        }
    }

    load(page?: number, onPage?: number) {
        if (!this.hierarchy) {
            return
        }

        this.scrollComplete = false;
        const self = { parent: this.group.obj, parents: this.parents };

        const filters: BoardFilter[] = this.getFilters();
        if (this.hierarchy.useDefaultFilter) {
            filters.splice(filters.length, 0, ...this.board.filters.filter(f => f.isChecked))
        }
        
        let params = '';
        if (this.hierarchy.paramsFunc) {
            params = this.base.getCalcRepr(self, null, this.hierarchy.paramsFunc);
        }

        if (this.page <= 1 || page) {
            this.page = 1; 
            this.data.splice(0, this.data.length);
        }

        this.base.getData(this.hierarchy.table, page || this.page, onPage || this.hierarchy.maxElements, params, filters, true)
        .pipe(
            finalize(() => {
                this.scrollComplete = true;
            })
        ).subscribe(resp => {
            if (resp.data) {
                
                if (this.page === 1 || page) {
                    this.data.splice(0, this.data.length);
                }

                const pk = this.base.getTablePk(this.hierarchy.table);

                for (let item of resp.data) {

                    if (this.hierarchy.isElementFunc && this.base.getCalcRepr(item, self, this.hierarchy.isElementFunc)) {

                        if (item) {
                            for (const col of this.board.listDisplay.filter(f => f.isChecked === true)) {
                                item[`${col.name}__repr`] = this.representCell(col, item);
                            }
                        }

                        let index;
                        for (let k = 0;  k < this.data.length; k++) {
                            if (this.data[k].item && this.data[k].item[pk] === item[pk]) {
                                index = k;
                                break;
                            }
                        }

                        if (index === undefined) {
                            this.data.splice(this.data.length, 0, {item});
                        } else {
                            this.data[index] = {item};
                        }

                    } else {

                        const repr = this.base.getCalcRepr(item, self, this.hierarchy.reprFunc);
                        this.data.splice(this.data.length, 0, {group: new GroupByList([], repr, [], 1, true, true, item, null, item, false, false, false, repr, null, null, true, 0)});

                    }
                }

                this.group.cnt = resp.cnt;
                this.hasNext = resp.has_next;

                if (resp.has_next && !page) { 
                    this.page ++; 
                } 
            }
        })
    }

    private representCell(col, value, covertToImg = true) {
        return this.templateService.representCell(col, value, this.fieldChoices, false, covertToImg);
    }
}
