import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-upload-file-btn',
    templateUrl: './upload-file-btn.page.html',
    styleUrls: ['./upload-file-btn.page.scss'],
})
export class UploadFileBtnPage implements OnInit {

    @ViewChild('file') file: any;
    @Input() bot: string;
    @Input() chatId: number;
    @Output() output = new EventEmitter();

    constructor(private base: BaseService) { }

    ngOnInit() {
    }

    upload() {
        this.file.nativeElement.click();
    }

    loadFile(ev) {
        const file = ev.target.files[0];
        if (this.bot && this.chatId) {
            this.base.uploadFileWoki(file, this.bot, this.chatId).subscribe(resp => {
                if (resp && resp.url) {
                    this.output.emit({url: resp.url});
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        } else {
            this.base.uploadFileHeroku(file).subscribe(resp => {
                if (resp && resp.url) {
                    this.output.emit({url: resp.url});
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        }

    }
}
