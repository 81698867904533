import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IonicModule } from '@ionic/angular';
import { InfoGraphPage } from './info-graph.page';
import { FilterPageModule } from '../components/filter/filter.module';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        NgxChartsModule,
        FilterPageModule,
        TranslateModule
    ],
    declarations: [InfoGraphPage],
    exports: [InfoGraphPage]
})
export class InfoGraphPageModule {}
