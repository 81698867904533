import { Base } from "./base";

export class OrderBy {

    constructor(public name: string, 
                public verboseName: string, 
                public isChecked: boolean, 
                public isAsc: boolean,
                public isCalc: boolean) {

    }

    serialize(): any {
        return {
            name: this.name, 
            verbose_name: this.verboseName,
            isChecked: this.isChecked,
            isAsc: this.isAsc,
            isCalc: this.isCalc
        }
    }

    changeOrder(): void {
        this.isAsc = !this.isAsc;
    }
    
    static fromJson(d: any): OrderBy {
        return new OrderBy(d.name, d.verbose_name, d.isChecked || false, d.isAsc || false, d.isCalc || false);
    }

    static toList(orderBy: OrderBy[]): any[] {
        if (!orderBy) {
            return [];
        }
        return orderBy.map(f => f.serialize() );
    }

    static copy(orderBy: OrderBy[]): OrderBy[] {
        return Base.copy(OrderBy.toList(orderBy)).map(f => OrderBy.fromJson(f))
    }

    static serialize(orderBy: OrderBy[]): string {
        const items: string[] = [];
        for (const o of orderBy.filter(f => f.isChecked)) {
            items.push(`${!o.isAsc ? '-' : ''}${o.name}`);
        }
        if (items.length) {
            return '&o=' + items.join(', ');
        }
        return '';
    }
} 

