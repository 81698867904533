import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, 
                private authService: AuthService) {}

    canActivate(_: ActivatedRouteSnapshot): boolean {
        if (window.location.search) {
            return true;
        }
        if (!this.authService.authenticated()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}
