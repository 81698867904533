import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient, 
                private base: BaseService) { }

    getUser(): Observable<any> {
        return this.http.get<any[]>(`${this.base.getRootUrl()}portal/api/user/?username=${this.base.getLogin()}`)
        .pipe(
            tap(_ => this.base.log('fetched user')),
            catchError(this.base.handleError('getUser'))
        );
    }

    updateUser(data): Observable<any> {
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/user-updater/${data[this.base.getTablePk('User')]}/`, data)
        .pipe(
            tap(_ => this.base.log('update user')),
            catchError(this.base.handleError('updateUser'))
        );
    }
}
