import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { BaseService } from '../services/base.service';
import { PageService } from '../services/page.service';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { AccountsPage } from '../components/accounts/accounts.page';
import { Base } from '../classes/base';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.page.html',
    styleUrls: ['./menu.page.scss'],
})
export class MenuPage implements OnInit {

    appPages = [];
    showMenu = false;
    isShowMenu = false;
    subscription: any;
    icon = '';
    isPopover = false;
    user: any;
    @Input() app: any;
    private initialized: boolean;

    constructor(private authService: AuthService, 
                public base: BaseService,
                private pageService: PageService,
                private popoverCtrl: PopoverController,
                private trans: TranslateService) { }

    ngOnInit() {
        this.isShowMenu = this.base.isShowMenu || this.isPopover;
        this.user = Base.getCurrentUser();
        this.subscription = this.base.auth.subscribe(_ => {
            if (!this.initialized) {
                this.refresh();
                this.initialized = true;
            }
            this.user = Base.getCurrentUser();
        });
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getPageObject(p) {
        return {
            title: p.name,
            url: `/home/${p.url}`,
            icon: p.icon || 'home',
            items: [],
        };
    }

    setPath(url) {
        this.base.openedParentPath[url] = this.base.openedParentPath[url] ? false : true;
    }

    openMenu() {
        this.isShowMenu = !this.isShowMenu;
        this.base.isShowMenu = this.isShowMenu;
    }

    refresh() {
        this.showMenu = this.authService.authenticated();
        const appPages = [];
        const pagePk = this.base.getTablePk('Page');

        if (this.authService.authenticated()) {
            const user = Base.getCurrentUser();
            this.pageService.getMenus(1, 50, null, '&is_active=1&o=priority').subscribe(resp => {
                if (resp && resp.data) {
                    for (const p of this.base.sort(resp.data.filter(i => i.parent === null), 'priority')) {
                        const page = this.getPageObject(p);
                        for (const p2 of this.base.sort(resp.data.filter(i => i.parent && i.parent[pagePk] === p[pagePk]), 'priority')) {
                            page.items.splice(page.items.length, 0, this.getPageObject(p2));
                        } 
                        appPages.splice(appPages.length, 0, page);
                    }
                }
                if (user.is_admin) {
                    appPages.splice(appPages.length, 0, {
                        title: this.trans.instant('pages-setting'),
                        url: '/page',
                        icon: 'settings',
                        items: []
                    });
                    appPages.splice(appPages.length, 0, {
                        title: this.trans.instant('forms'),
                        url: '/form',
                        icon: 'albums',
                        items: []
                    });
                    if (this.base.getTableMeta("botprocess")) {
                        appPages.splice(appPages.length, 0, {
                            title: this.trans.instant("bot"),
                            url: "/botprocess",
                            icon: 'logo-android',
                            items: []
                        })
                    }
                    if (this.base.getTableMeta("process")) {
                        appPages.splice(appPages.length, 0, {
                            title: this.trans.instant("bot-process"),
                            url: "/process",
                            icon: 'git-pull-request',
                            items: []
                        })
                    }
                }
                if (this.base.isBot()) {
                    appPages.splice(appPages.length, 0, {
                        title: this.trans.instant('bot'),
                        url: '/bot',
                        icon: 'logo-android',
                        items: []
                    });
                    appPages.splice(appPages.length, 0, {
                        title: this.trans.instant('forms'),
                        url: '/form',
                        icon: 'albums',
                        items: []
                    });
                }
                this.showMenu = true;
            });
        } else {
            appPages.splice(0, 0, {
                title: this.trans.instant('log-in'),
                url: '/login',
                icon: 'log-in',
                items: []
            });
            this.showMenu = false;
        }
        this.appPages = appPages;
    }

    openPage(url) {
        this.base.openedPath = url;
        if (this.isPopover) {
            this.popoverCtrl.dismiss({});
        }
    }

    async account(ev) {
        const popover = await this.popoverCtrl.create({
            component: AccountsPage,
            showBackdrop: false,
            event: ev,
            componentProps: {app: this.app},
            cssClass: 'account-popover'
        });
        return await popover.present();
    }
}
