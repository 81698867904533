import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-json-view',
    templateUrl: './json-view.page.html',
    styleUrls: ['./json-view.page.scss'],
})
export class JsonViewPage implements OnInit {
    @Input() data: any;
    @Input() fieldName: any;
    
    constructor() { }

    ngOnInit() {
    }

    get code() {
        return JSON.stringify(this.data[this.fieldName], null, 2);
    }
    
    set code(v) {
        try {
            this.data[this.fieldName] = JSON.parse(v);
        } catch (e) {
            console.error('error occored while you were typing the JSON', e);
        }
    }
}
