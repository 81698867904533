import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { ProcessVersion } from 'src/app/classes/processversion';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-process-version',
    templateUrl: './process-version.page.html',
    styleUrls: ['./process-version.page.scss'],
})
export class ProcessVersionPage implements OnInit {

    version: ProcessVersion;

    constructor(private base: BaseService,
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.version = ProcessVersion.get(); 
        if (!this.version.id) {
            this.base.getData(ProcessVersion.table, 1, 1, 'o=-id').subscribe(resp => {
                if (resp.data.length) {
                    this.version = ProcessVersion.fromJson(resp.data[0]);
                    ProcessVersion.set(this.version);
                }
            })
        }
    }

    async choose() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: ProcessVersion.table,
            },
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item && details.data.item.id) {
                this.version = ProcessVersion.fromJson(details.data.item);
                ProcessVersion.set(this.version);
            }
        });
        return await modal.present();
    }
}
