import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { InputPhoneWidgetPage } from './input-phone-widget.page';
import { TranslateModule } from '@ngx-translate/core';
import { PhonePipeModule } from 'src/app/pipes/phone';

const routes: Routes = [
    {
        path: '',
        component: InputPhoneWidgetPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        PhonePipeModule
    ],
    declarations: [InputPhoneWidgetPage]
})
export class InputPhoneWidgetPageModule {}
