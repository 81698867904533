import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
    {
        path: '',
        loadChildren: './home/home.module#HomePageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'home',
        loadChildren: './home/home.module#HomePageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'home/:page',
        loadChildren: './home/home.module#HomePageModule',
        canActivate: [AuthGuardService]
    },
    {   
        path: 'login', 
        loadChildren: './login/login.module#LoginPageModule' 
    },
    { 
        path: 'list', 
        loadChildren: './list/list.module#ListPageModule',
        canActivate: [AuthGuardService]    
    },
    {
        path: 'page',
        loadChildren: './page/page.module#PagePageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'form/form',
        loadChildren: './form/form.module#FormPageModule',
        canActivate: [AuthGuardService]
    },
    { 
        path: 'bot', 
        loadChildren: './bot/bot.module#BotPageModule',
        canActivate: [AuthGuardService]
    },
    { 
        path: 'botprocess', 
        loadChildren: './botprocess/botprocess.module#BotProcessPageModule',
        canActivate: [AuthGuardService]
    },
    { 
        path: 'process', 
        loadChildren: './process/process.module#ProcessPageModule',
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
