import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-related-table-form-setting',
    templateUrl: './related-table-form-setting.page.html',
    styleUrls: ['./related-table-form-setting.page.scss'],
})
export class RelatedTableFormSettingPage implements OnInit {

    @Input() tableCells: any;
    @Input() rel: any;
    @Input() form: any;
    @Input() k: any;
    @Input() rowsSubject: any;
    @Input() fieldReprChanged: any;
    @Input() tables: any;
    @Input() tablesFields: any;
    @Output() changeRelateds = new EventEmitter();
    @Output() addRowRel = new EventEmitter();
    @Input() lang: any;

    constructor(public base: BaseService) { }

    ngOnInit() {
        if (!this.lang) {
            this.lang = this.base.getLanguage();
        }
        if (this.rel && this.rel.title && !this.rel.title.titles) {
            this.rel.title.titles = {};
        }
    }

    onChangeRelateds() {
        this.changeRelateds.emit();
    }

    onAddRowRel(data, isCap = false) {
        this.addRowRel.emit({data, isCap});
    } 

    fieldReprChangedEvent(ev, w) {
        if (w) {
            this.fieldReprChanged.next(w.fieldName);
        }
    }
}
