import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavParams, ModalController, IonInfiniteScroll, AlertController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { MetaService } from '../services/meta.service';
import { BackgroundPage } from '../background/background.page';
import { TemplateService } from '../services/template.service';
import { ChoicesPage } from '../choices/choices.page';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Equal, FieldType, FunctionType } from '../classes/enums';
import { Board } from '../classes/board';
import { GroupByDate, GroupByItem, GroupByList, GroupByNumber, GroupByRef } from '../classes/board-group-by';
import { BoardFilter, Choice } from '../classes/board-filters';
import { Constants } from '../classes/app.constants';
import { Base } from '../classes/base';

@Component({
    selector: 'app-desk-detail',
    templateUrl: './desk-detail.page.html',
    styleUrls: ['./desk-detail.page.scss'],
})
export class DeskDetailPage implements OnInit {

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @Input() board: Board;
    @Input() col = null;
    @Input() calcs = [];
    @Input() group: GroupByList = null;
    @Input() obj = null;

    metaFields = []; 
    tab = {tab: 1};
    title = null;
    month = '';
    selectedField: any;
    isShow = 0;
    colorPickerPosition: any;
    boardGroup: GroupByList[] = [];
    page = 1;
    hasNext = false;
    scrollComplete = true;
    isListDisplayMain = false;
    isAdmin = false;
    allowGroupBy = [];
    canRequest = true;
    templatePk: any;
    scanFilterFieldsRelated = [];
    isGroupMain = false;
    aggrFields = [];

    private fieldChoices = {};

    constructor(private modalCtrl: ModalController, 
                private base: BaseService, 
                private meta: MetaService, 
                private alertCtrl: AlertController,
                private templateService: TemplateService, 
                private trans: TranslateService) { }

    ngOnInit() {
        this.templatePk = this.base.getTablePk('Template');
        this.isAdmin = Base.getCurrentUser().is_admin;
        this.month = this.base.getMonthList();

        if (!(this.obj && this.obj.options)) {
            this.obj = null;
        }

        this.getTableMeta(this.board.table);
        this.title = this.meta.getTitleMeta(this.board.table);
    }

    async chooseForm() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
                filters: [new BoardFilter('table', Equal.EQ, this.board.table), new BoardFilter('type', Equal.EQ, 0)],
                funcRepr: 'name',
            },
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                this.board.form = details.data.item;
            }
        });
        return await modal.present();
    }

    goBack() {
        if (this.tab.tab === 3.7) {
            this.tab.tab = 3.1;
        } else {
            this.tab.tab = 1;
        }
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    async remove() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('ask-delete-desk', {desk: this.board.name}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        this.modalCtrl.dismiss({action: 'remove'});
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                }
            ]
        });
        await alert.present();
    }

    hide() {
        this.modalCtrl.dismiss({ action: 'hide' });
    }

    show() {
        this.modalCtrl.dismiss({ action: 'show' });
    }

    async getBackgrounds(isColor) {
        const modal = await this.modalCtrl.create({
            component: BackgroundPage,
            cssClass: 'modal-desk-detail',
            componentProps: {isColor, hideOptions: true},
            backdropDismiss: true
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.background) {
                this.board.background = detail.data.background;
                this.closeModal();
            }
        });
        return await modal.present();
    }

    clearGroupBy() {
        this.board.groupBy.name = null;
        this.board.groupBy.type = null;
        this.board.groupBy.groups = [];
        this.board.groupBy.verboseName = null;
        this.board.groupBy.choices = [];
        this.board.groupBy.isAsc = true;
        this.board.groupBy.typeDate = null;
    }

    groupByChange() {
        const field = this.metaFields.find(item => item.name === this.board.groupBy.name);
        if (field) {
            this.board.groupBy.verboseName = field.verbose_name;
            this.board.groupBy.type = field.type;
            this.board.groupBy.choices = Choice.fromJson(field.choices && field.choices.length ? field.choices.filter(c => c[0] !== ""): null);
        } else {
            this.board.groupBy.verboseName = null;
            this.board.groupBy.type = null;
            this.board.groupBy.choices = [];
        }
        const groups: GroupByItem[] = [];
        if (this.board.groupBy.type === FieldType.INT && this.board.groupBy.choices.length) {
            for (const c of this.board.groupBy.choices) {
                groups.push(new GroupByItem(FieldType.INT, null, null, new GroupByRef(c.id, c.title, c.title, this.board.groupBy.name)))
            }
        }
        this.board.groupBy.groups = groups;
        this.board.groupBy.typeDate = null;
    }

    typeDateChange() {
        this.board.groupBy.groups = [];
    }

    addDateGroup() {
        const groups = this.board.groupBy.groups.filter(f => f.date);
        let valueGte = null; let valueLte = null;
        if (groups.length) {
            valueLte = this.base.getDate(groups[groups.length - 1].date.gte);
            valueLte.setDate(valueLte.getDate() + 1);
            valueGte = this.base.getDate(groups[groups.length - 1].date.gte);
            valueGte.setDate(2 * valueGte.getDate() - (this.base.getDate(groups[groups.length - 1].date.lte)).getDate() + 1);
            valueLte = valueLte.toJSON(); valueGte = valueGte.toJSON();
        }
        this.board.groupBy.groups.splice(this.board.groupBy.groups.length, 0, new GroupByItem(this.board.groupBy.type, null, new GroupByDate(valueGte, valueLte)));
    }

    addDecimalGroup() {
        const groups = this.board.groupBy.groups.filter(f => f.num);
        let valueGte = 0; let valueLte = 0;
        if (groups.length) {
            valueLte = groups[groups.length - 1].num.gte + 1;
            valueGte = 2 * groups[groups.length - 1].num.gte - groups[groups.length - 1].num.lte + 1;
        }
        this.board.groupBy.groups.splice(this.board.groupBy.groups.length, 0, new GroupByItem(this.board.groupBy.type, new GroupByNumber(valueGte, valueLte)));
    }

    addForeignGroup() {
        this.board.groupBy.groups.splice(this.board.groupBy.groups.length, 0, new GroupByItem(this.board.groupBy.type, null, null, new GroupByRef(null, null, null, this.board.groupBy.name)));
    }

    reorderGroupBy(ev, groups: GroupByItem[]) {
        const itemToMove = groups.splice(ev.detail.from, 1)[0];
        groups.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    async chooseItem(f: GroupByItem) {
        const field = this.board.listDisplay.find(item => item.name === f.ref.name);
        if (field) {
            const pk = field.relatedField || this.base.getTablePk(field.model);
            const selected = {}; selected[pk] = f.ref.value;
            const modal = await this.modalCtrl.create({
                component: ChoicesPage,
                componentProps: {
                    model: field.model,
                    selected
                },
                backdropDismiss: true,
            });
            modal.onDidDismiss().then((detail: any) => {
                if (detail && detail.data && detail.data.item) {
                    f.ref.value = detail.data.item[pk];
                    const value = {}; value[field.name] = detail.data.item;
                    const repr = this.templateService.representCell(field, value, {});
                    f.ref.repr = repr && repr.alt ? repr.alt : repr;
                }
            });
            return await modal.present();
        }
    } 

    private getTableMeta(table: string) {
        const resp = this.base.getTableMeta(table); 
        for (const f of this.calcs) {
            if (!resp.fields.find(i => i.name === f.name)) { 
                resp.fields.splice(resp.fields.length, 0, f);
            }
        }

        this.metaFields = resp.fields;
        for (const m of this.metaFields) {
            if (m.type === FieldType.INT && m.choices && m.choices.length) {
                this.fieldChoices[m.name] = m.choices;
            }
        }
        this.aggrFields = this.base.copy(resp.fields.filter(f => f.type === FieldType.DECIMAL || f.type === FieldType.INT));

        this.board.listDisplay = this.base.getListDisplay(this.board.listDisplay, resp.fields);
        this.allowGroupBy = this.meta.allowFilterFields(this.base.copy(resp.fields), 'blockList');
        this.board.orderBy = this.base.getOrderBy(this.board.orderBy, resp.fields);

        // Open field settings.
        if (this.col) {
            this.selectedField = this.board.listDisplay.find(i => i.name === this.col.name);
            if (this.selectedField) {
                this.tab.tab = 3.7;
            }
        }
        this.loadDynamicList(true);
    }

    async openList(group: GroupByList) {

        const board = this.board.copy();
        const obj = { obj : null }; let groupFilter: BoardFilter = null;
        if (group.obj) {
            obj.obj = this.base.copy(group.obj);
        }
        board.pk = group.isCustom ? board.pk : null;
        board.filters = BoardFilter.copy(group.filters);
        board.name = group.verbose ? group.verbose.slice() : (group.name ? group.name.slice() : '');
        if (!group.isCustom && this.board.groupBy.name) {
            groupFilter = this.templateService.addFilterToDynamicList(this.metaFields, board, this.board, group, 
                                                                      groupFilter, this.fieldChoices);
        }
    
        const modal = await this.modalCtrl.create({
            component: DeskDetailPage,
            componentProps: { board, isTable: this.board.isTable, obj: obj.obj, group },
            cssClass: 'modal-desk-detail',
            mode: 'md',
        });
        modal.onDidDismiss().then((detail) => {

            board.refresh();
            if (detail && detail.data) {

                switch (detail.data.action) {
                case 'remove':
                    
                    this.board.groupBy.removeCustom(group);
                    break;

                case 'hide':

                    if (group.isCustom) { // Hide custom field.
                        this.board.groupBy.getCustom(group).hidden = true;
                    } else { // Hide dynamic field
                        this.board.groupBy.addToHidden(group, "uuid");
                    }
                    break;

                case 'show':

                    if (group.isCustom) {
                        this.board.groupBy.getCustom(group).hidden = false;
                    } else {
                        this.board.groupBy.removeHidden(group);
                    }
                    break;
                }

            } else { // Save changes.
                    
                group.filters = board.filters;
                group.verbose = board.name;
                group.obj = obj.obj;

                // Empty new list - remove that and cancel changes.
                if (!board.filters.length) {
                    this.board.groupBy.removeCustom(group);
                    return;
                }

                // Dynamic field is changed.
                if (!group.isCustom && !group.hidden) {
                    // Just object changed. Not replace dynamic list to custom.
                    if (groupFilter && board.filters.length === 1 
                        && groupFilter.name === board.filters[0].name 
                        && groupFilter.value === board.filters[0].value
                        && groupFilter.isChecked === board.filters[0].isChecked 
                        && groupFilter.equal === board.filters[0].equal   ) {
                        
                        this.board.groupBy.removeCustom(group);
                    
                    } else { // Replace dynamic list to custom.
                        this.board.groupBy.addToHidden(group, "uuid");      
                    }
                }

                // Save changes of object.
                if (obj.obj && !group.isCustom && this.board.groupBy.type === FieldType.REF) {

                    const t = this.metaFields.find(i => i.name === this.board.groupBy.name);
                    if (t && t.related_model) {
                        this.base.updateObj(obj.obj, t.related_model).subscribe(_ => { });
                        return;
                    }

                } else if (obj.obj && group.isCustom && group.filters) {

                    const objs = group.filters.filter(f => f.isChecked && f.equal === Equal.EQ && f.type === FieldType.REF);
                    if (objs && objs.length) {
                        const t = this.metaFields.find(i => i.name === objs[0].name);
                        if (t && t.related_model) {
                            this.base.updateObj(obj.obj, t.related_model).subscribe(_ => { });
                            return;
                        }
                    }

                }
            }
            this.loadDynamicList(true);
        });
        return await modal.present();
    }

    loadDynamicList(reload = false) {
        if (!this.canRequest || this.group) {
            return;
        }

        if (reload || this.page <= 1) {
            this.page = 1;
            this.boardGroup = [];
        }

        const q = this.templateService.getQueryParams(null, this.board, this.base.viseFields, this.board.table, true, 
                                                      [], null, false, this.calcs);
        if (!q.isNote) {
            return; 
        }

        this.scrollComplete = false; this.canRequest = false;
        this.base.getData(this.board.table, this.page, Constants.ON_PAGE_LIST, q.params).pipe(
            finalize(() => {
                this.scrollComplete = true;
                this.canRequest = true;
            })
        ).subscribe((data: any) => {
            let groups: GroupByList[] = [];
            if (this.page !== 1) {
                groups = this.boardGroup.slice();
            }
            if (data && data.data) { 
                for (let i = 0; i < data.data.length; i++) {
                    if (!groups.find(item => item.name === data.data[i].note)) {
                        if (this.board.groupBy.name === 'vise_user' && !data.data[i].note) { 
                            continue; 
                        }
                        const q = this.templateService.getVerboseAndObj(data, i, this.board.groupBy);
                        const tt = this.metaFields.find(f => f.name === this.board.groupBy.name);
                        const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);

                        groups.push(new GroupByList([], q.n, [], 1, true, true, q.obj ? q.obj[pk] : null, 
                                                    q.color ? q.color : (q.obj && q.obj.options ? q.obj.options.color : null), q.obj,
                                                    false, false, false, q.verbose, null, null, true, 0));
                    }
                }
                this.hasNext = data.has_next;
            } else {
                this.hasNext = false;
            }
            this.boardGroup = groups;
            if (data.has_next) { 
                this.page ++; 
            } 
        });
    }

    scanFilterFieldsChange() {
        this.scanFilterFieldsRelated = [];
        if (this.board.groupBy.scanFilter) {
            const field = this.metaFields.find(f => f.name === this.board.groupBy.scanFilter);
            if (field && field.related_model) {
                const table = this.base.getTableMeta(field.related_model);
                if (table && table.fields) {
                    this.scanFilterFieldsRelated = this.base.copy(table.fields);
                }
            }
        }
    }

    chooseSum() {
        if (this.aggrFields && this.aggrFields.length) {
            this.board.groupBy.func = FunctionType.SUM;
            this.board.groupBy.aggrField = null;
            const agg = this.aggrFields.filter(i => i.type === FieldType.DECIMAL);
            if (agg.length) {
                this.board.groupBy.aggrField = agg[0];
            }
        } else {
            this.board.groupBy.func = FunctionType.COUNT;
        }
    }

    filterDate(rel, _) {
        return [FieldType.DATETIME, FieldType.DATE].indexOf(rel.type) !== -1;
    }
}
