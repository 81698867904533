import { Component, OnInit, Input, HostListener } from '@angular/core';
import { PageService } from '../services/page.service';
import { BaseService } from '../services/base.service';
import { FormPage } from '../form/form.page';
import { ModalController } from '@ionic/angular';
import { Equal } from '../classes/enums';

@Component({
    selector: 'app-widgets',
    templateUrl: './widgets.page.html',
    styleUrls: ['./widgets.page.scss'],
})
export class WidgetsPage implements OnInit {

    @Input() page: any;
    @Input() styleUpdater: any;
    subscription: any;

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.changeWidgets();
    }

    constructor(private pageService: PageService,
                private base: BaseService) { }

    ngOnInit() {
        this.changeWidgets();
        if (this.styleUpdater) {
            this.subscription = this.styleUpdater.subscribe(widgets => {
                if (widgets) {
                    this.page.widgets = widgets;
                    this.changeWidgets();
                }
            });
        }
    }

    changeWidgets() {
        if (!this.page || !this.page.widgets) {
            return;
        }
        for (const w of this.page.widgets) {
            w.ngStyle = this.pageService.getStyle(w.style, w.class);
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    openForm(formPk) {
        this.base.getData('Form', 1, 1, `&filter_fields=${JSON.stringify([{name: this.base.getTablePk('Form'), equal: Equal.EQ, value: formPk}])}`).subscribe(resp => {
            if (resp && resp.data.length) {
                let props = {form: resp.data[0], item: {openedItem: {}}};
                this.base.openForm(FormPage, props, (_) => {});
            }
        })
    }
}
