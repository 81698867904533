import { Component, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { BaseService } from 'src/app/services/base.service';
import { Observable, of, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MetaService } from 'src/app/services/meta.service';
import { ModalController } from '@ionic/angular';
import { FieldType, Equal } from 'src/app/classes/enums';

@Component({
    selector: 'app-import-from-excel',
    templateUrl: './import-from-excel.page.html',
    styleUrls: ['./import-from-excel.page.scss'],
})
export class ImportFromExcelPage implements OnInit {

    @ViewChild('file') file: any;
    @Output() output = new EventEmitter();
    @Input() table;
    
    relateds = [];
    wb: any;
    rows = [];
    columns = [];
    fields = [];
    tables = [];
    tablesFields = {};
    foreigns = [];
    primaries = [];
    addNotFound = true;
    complete = true;
    status = '';

    constructor(private base: BaseService,
                private trans: TranslateService,
                private meta: MetaService,
                private modalCtrl: ModalController) { }

    ngOnInit() {
        this.status = this.trans.instant('status');
        this.tables = this.meta.getTablesArray();
        for (const t of this.base.getLocalMeta()) {
            this.tablesFields[t.table] = t.fields;
        }

        this.fields = this.base.getTableMeta(this.table).fields;
    }

    upload() {
        this.file.nativeElement.click();
    }

    loadFile(ev) {
        const file = ev.target.files[0];

        var reader = new FileReader();
        reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            this.wb = XLSX.read(data, {type: 'array'});
            this.rows = []; this.columns = []; this.relateds = []; this.foreigns = [];
            this.rows = XLSX.utils.sheet_to_json(this.wb.Sheets[this.wb.SheetNames[0]]);
            if (this.rows.length > 0) {
                this.columns.splice(this.columns.length, 0, this.trans.instant('status'))
                for (const k of Object.keys(this.rows[0])) {
                    this.columns.splice(this.columns.length, 0, k);
                    this.relateds.splice(this.relateds.length, 0, {});
                    this.foreigns.splice(this.foreigns.length, 0, null);
                    this.primaries.splice(this.primaries.length, 0, false);
                }
            }
        };
        reader.readAsArrayBuffer(file);
    }

    change(ev, arr, i) {
        arr[i] = ev.detail.value;
    }

    changeBool(ev, arr, i) {
        arr[i] = !arr[i];
    }

    closeModal() {
        this.modalCtrl.dismiss({});
    }

    getValue(val, field, primary): Observable<any> {
        if (field.type === FieldType.REF) {
            if (!val) {
                return of([null]);
            }
            const filters = [{name: primary, equal: Equal.EQ, value: val}]; 
            return this.base.getData(field.related_model, 1, 2, `&filter_fields=${JSON.stringify(filters)}`).pipe(
                map((resp) => {
                    if (resp && resp.data && resp.data.length) {
                        return [resp.data[0][this.base.getTablePk(field.related_model)]];
                    }
                    return [];
                })
            )
        }
        return of([val]);
    }

    run() {
        if (!this.complete) {
            return;
        }

        for (let i = 0; i < this.relateds.length; i++) {
            if (this.relateds[i] && this.relateds[i].related_model && !this.foreigns[i]) {
                this.base.sendToast(this.trans.instant('filter-field-required', {name: this.relateds[i].verbose_name}))
                return;
            }
        }

        if (!this.relateds.filter(f => f && f.name).length) {
            this.base.sendToast(this.trans.instant('empty-object'))
            return;
        }

        if (!this.addNotFound && !this.primaries.filter(f => f).length) {
            this.base.sendToast(this.trans.instant('primary-keys-not-found'))
            return;
        }

        this.complete = false;
        this.syncRows(0);
    }

    syncRows(next) {
        if (next >= this.rows.length) {
            this.complete = true;
        }
        for (let i = next; i < this.rows.length; i++) {
            this.syncRow(this.rows[i], i)
            break;
        }
    }

    syncRow(row, indx) {
        if (row[this.status] === 'ok') {
            this.syncRows(indx + 1);
            return;
        }

        const values = []; const keys = []; const columns = [];
        for (let i = 0; i < this.relateds.length; i++) {
            if (this.relateds[i] && this.relateds[i].name) {
                keys.push(this.relateds[i].name);
                columns.push(this.columns[i + 1]);
                values.push(this.getValue(row[this.columns[i + 1]], this.relateds[i], this.foreigns[i]))
            }
        }

        if (!values.length) {
            row[this.trans.instant('status')] = this.trans.instant('empty-object');
            this.syncRows(indx + 1);
            return;
        }

        zip(...values).subscribe(resp => {
            const item: any = {};
            for (let i = 0; i < resp.length; i++) {
                const val: any = resp[i];
                if (!val.length) {
                    row[this.trans.instant('status')] = this.trans.instant('obj-not-found', {val: row[columns[i]]});
                    this.syncRows(indx + 1);
                    return;
                } else if (val.length > 1) {
                    row[this.trans.instant('status')] = this.trans.instant('obj-found-more-than-one', {cnt: val.length, val: row[columns[i]]});
                    this.syncRows(indx + 1);
                    return;
                }
                if (val[0] !== null) {
                    item[keys[i]] = val[0];
                }
            }
            if (JSON.stringify(item) === '{}') {
                row[this.trans.instant('status')] = this.trans.instant('empty-object');
                this.syncRows(indx + 1);
                return;
            }
            
            const filters = [];
            for (let i = 0; i < this.primaries.length; i++) {
                if (this.primaries[i]) {
                    const val = item[this.relateds[i].name];
                    if (val) {
                        filters.push({name: this.relateds[i].name, equal: Equal.EQ, value: val})
                    }
                }
            }
            if (!filters.length && !this.addNotFound) {
                row[this.trans.instant('status')] = this.trans.instant('obj-not-found', {val: ''});
                this.syncRows(indx + 1);
                return;
            } else if (!filters.length) {
                this.create(item, row, indx);
            } else {
                this.base.getData(this.table, 1, 2, `&filter_fields=${JSON.stringify(filters)}`).subscribe(response => {
                    if (response && response.data && response.data.length) {
                        if (response.data.length === 1) {
                            if (response.data[0].edited) {
                                item.edited = response.data[0].edited;
                            }
                            this.update(item, row, indx, response.data[0][this.base.getTablePk(this.table)]);
                            return;
                        } else {
                            row[this.trans.instant('status')] = this.trans.instant('obj-found-more-than-one', {cnt: response.data.length, val: ''});
                            this.syncRows(indx + 1);
                            return;
                        }
                    }
                    if (this.addNotFound) {
                        this.create(item, row, indx);
                    } else {
                        row[this.trans.instant('status')] = this.trans.instant('obj-not-found', {val: ''});
                        this.syncRows(indx + 1);
                    }
                })
            }
        })
    }

    create(item, row, indx) {
        this.base.createTable(item, this.table).subscribe(response => {
            if (response && response[this.base.getTablePk(this.table)]) {
                row[this.trans.instant('status')] = 'ok'
            } else {
                row[this.trans.instant('status')] = this.base.getError(response) || 'error';
            }
            this.syncRows(indx + 1);
        })
    }

    update(item, row, indx, pk) {
        this.base.updateTable(item, pk, item.edited, this.table).subscribe(response => {
            if (response && response[this.base.getTablePk(this.table)]) {
                row[this.trans.instant('status')] = 'ok'
            } else {
                row[this.trans.instant('status')] = this.base.getError(response) || 'error';
            }
            this.syncRows(indx + 1);
        })
    }
}
