import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MetaService } from './meta.service';
import { Equal } from '../classes/enums';

@Injectable({
    providedIn: 'root'
})
export class PageService {

    constructor(private base: BaseService, 
                private http: HttpClient, 
                private sanitizer: DomSanitizer,
                private meta: MetaService) { }

    getWidgets() {
        const tables = this.meta.getTablesArray();
        return [
            {
                title: 'desks', 
                selector: 'app-service', 
                attrs: [{ name: 'tables', title: 'desk-type', value: null, valueRepr: null, model: null, 
                          placeholder: 'desk-type', multi: true, choices: tables },
                        { name: 'hideAddButton', title: 'hide-add-button', value: null, valueRepr: null, model: null, isBool: true }], 
                class: [],
                style: [] 
            },
            { 
                title: 'news', 
                selector: 'app-news', 
                attrs: [{ name: 'group', title: 'group-news', value: null, valueRepr: null, model: 'NewsGroup', placeholder: 'group-news'},
                        { name: 'parent', title: 'parent', value: null, valueRepr: null, model: 'New', placeholder: 'parent'}], 
                class: [],
                style: [{ name: 'background-color', value: 'rgba(242,245,245,0.8)' }, { name: 'border-radius', value: '20px' },
                        { name: 'height', value: '600px' }] 
            },
            { 
                title: 'iframe', selector: 'iframe', 
                attrs: [{ name: 'src', title: 'href', value: null, valueRepr: null, model: null, placeholder: 'href'}], 
                class: [],
                style: [] 
            },
            { 
                title: 'banner', selector: 'img', 
                attrs: [{ name: 'src', title: 'href', value: null, valueRepr: null, model: null, placeholder: 'href'},
                        { name: 'title', title: 'subject', value: null, valueRepr: null, model: null, placeholder: 'subject'}], 
                class: [],
                style: [{ name: 'background', value: 'white' }, { name: 'height', value: '218px' }, { name: 'padding', value: '6px' },
                        { name: 'max-width', value: '100%' }] 
            },
            { 
                title: 'text', selector: 'div', 
                attrs: [{ name: 'title', title: 'subject', value: null, valueRepr: null, model: null, placeholder: 'subject'}], 
                class: [],
                style: [{ name: 'font-weight', value: 'bold' }, { name: 'font-size', value: '25px' }, 
                        { name: 'border-radius', value: '3px' },
                        { name: 'background', value: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))' }, 
                        { name: 'height', value: '120px' }, { name: 'color', value: 'white' }, 
                        { name: 'text-shadow', value: 'rgba(0, 0, 0, 0.1) 0px 4px 8px' },
                        { name: 'line-height', value: '29px' }, { name: 'padding', value: '10px' } ] 
            },
            { 
                title: 'desk', 
                selector: 'app-list', 
                attrs: [{ name: 'template', title: 'desk', value: null, valueRepr: null, model: 'Template', placeholder: 'desk' },
                        { name: 'table', title: 'table', value: null, valueRepr: null, model: null, placeholder: 'table', 
                          choices: tables },
                        { name: 'chartIndex', title: 'chartIndex', value: null, valueRepr: null, model: null, placeholder: 'chartIndex' },
                        { name: 'open', title: 'open-desk', value: null, valueRepr: null, model: null, isBool: true },
                        { name: 'hideHeader', title: 'hide-header', value: null, valueRepr: null, model: null, isBool: true }], 
                class: [],
                style: [{ name: 'height', value: '100%' }] 
            },
            { 
                title: 'chart', 
                selector: 'chart', 
                attrs: [{ name: 'template', title: 'desk', value: null, valueRepr: null, model: 'Template', placeholder: 'desk' },
                        { name: 'chartIndex', title: 'chartIndex', value: null, valueRepr: null, model: null, placeholder: 'chartIndex' },
                        { name: 'chartWidth', title: 'chartWidth', value: null, valueRepr: null, model: null, placeholder: 'chartWidth' },
                        { name: 'chartHeight', title: 'chartHeight', value: null, valueRepr: null, model: null, placeholder: 'chartHeight' },
                        { name: 'autoRefresh', title: 'autoRefresh', value: null, valueRepr: null, model: null, placeholder: 'autoRefresh', isBool: true },
                    ], 
                class: [],
                style: [{ name: 'height', value: '100%' }] 
            },
            { 
                title: 'button', 
                selector: 'button', 
                attrs: [{ name: 'form', title: 'form', value: null, valueRepr: null, model: 'Form', placeholder: 'form' },
                        { name: 'title', title: 'subject', value: null, valueRepr: null, model: null, placeholder: 'subject'}], 
                class: [],
                style: [] 
            },
        ];
    }

    setWidgetAttr(widgets, ignore?) {
        if (widgets && widgets.length) {
            for (const w of widgets) {
                if (w.attrs && w.attrs.length) {
                    for (const a of w.attrs) {
                        w[`${a.name}_attr`] = null;
                        if ((a.name === 'table' || a.name === 'tables') && a.choices && a.choices.length) {
                            a.choices = this.meta.getTablesArray();
                        }
                    }
                    for (const a of w.attrs) {
                        let v = a.value;
                        if (w.selector === 'iframe') {
                            v = this.photoURL(v);
                        }
                        if (!v) {
                            continue;
                        }
                        if (a.multi) {
                            if (!w[`${a.name}_attr`]) {
                                w[`${a.name}_attr`] = [v];
                            } else {
                                w[`${a.name}_attr`].splice(w[`${a.name}_attr`].length, 0, v);
                            }
                        } else {
                            if (w.selector === 'chart' && a.name === 'chartIndex' && ignore) {
                                w[`${a.name}_attr`] = null;
                                continue;
                            }
                            w[`${a.name}_attr`] = v;    
                        }
                    }
                }
            }
        }
    }

    getStyle(style, cls) {
        const resp = {};
        for (const c of cls) {
            const cl = this.base.getClass(c.value);
            if (cl && cl.length) {
                for (const row of cl) {
                    if (row.height && row.height.value && row.height.equal) {
                        if ((row.height.equal === Equal.GT && row.height.value > window.innerHeight)
                            || (row.height.equal === Equal.LT && row.height.value < window.innerHeight)) {
                            continue;
                        }
                    }
                    if (row.width && row.width.value && row.width.equal) {
                        if ((row.width.equal === Equal.GT && row.width.value > window.innerWidth)
                            || (row.width.equal === Equal.LT && row.width.value < window.innerWidth)) {
                            continue;
                        }
                    }
                    for (const p of row.styles) {
                        resp[p.name] = p.value;
                    }
                    break;
                }
            }
        }

        let st = '';
        for (const s of style) {
            st += `, "${s.name}":"${s.value}"`;
        }
        if (st.length) {
            try {
                const data = JSON.parse(`{${st.slice(2)}}`);
                for (const p of Object.keys(data)) {
                    resp[p] = data[p];
                }
            } catch {}
        } 
        return resp;
    }
    
    getPage(url: string, findFirst?): Observable<any> {
        let params = `&o=priority`;
        if (findFirst) {
            params += `&url=` + url; 
        }
        return this.http.get<any>(`${this.base.getRootUrl()}portal/api/page/?is_active=1&page=1&pages=50${params}`)
        .pipe(
            tap(_ => this.base.log('fetched pages')),
            map((resp) => {
                if (!resp || !resp.data || !resp.data.length) {
                    return resp;
                }
                for (const page of resp.data) {
                    if (page.url === url) {
                        return {data: [page]};
                    }
                }
                return {data: [resp.data[0]]};
            }),
            catchError(this.base.handleError('getPages', this.base.handlerBlank))
        );
    }

    getPages(page, pages, search, params = null) {
        params = params || '';
        params += search ? `&s=${search}` : '';
        return this.http.get<any>(`${this.base.getRootUrl()}portal/api/page/?page=${page}&pages=${pages}${params}`)
        .pipe(
            tap(_ => this.base.log('fetched pages')),
            catchError(this.base.handleError('getPages', this.base.handlerBlank))
       );
    }

    getMenus(page, pages, search, params = null) {
        params = params || '';
        params += search ? `&s=${search}` : '';
        return this.http.get<any>(`${this.base.getRootUrl()}portal/api/menu/?page=${page}&pages=${pages}${params}`)
        .pipe(
            tap(_ => this.base.log('fetched pages')),
            catchError(this.base.handleError('geMenus', this.base.handlerBlank))
       );
    }

    createPage(widgets, url, name, background, isActive): Observable<any> {
        const bgPk = this.base.getTablePk('Background'); 
        const data = {
            widgets,
            url,
            name,
            is_active: isActive,
            background: background ? (background[bgPk] ? background[bgPk] : background) : null
        };
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/page/`, data)
        .pipe(
            tap(_ => this.base.log('create page')),
            catchError(this.base.handleError('createPage'))
        );
    }

    updatePage(widgets, name, pk, edited, background, url, isActive, icon, parent, priority): Observable<any> {
        const bgPk = this.base.getTablePk('Background'); 
        const pagePk = this.base.getTablePk('Page'); 
        const data = {
            widgets,
            name,
            url,
            edited,
            icon,
            priority: priority || 0,
            parent: parent && parent[pagePk] ? parent[pagePk] : null,
            is_active: isActive,
            background: background ? (background[bgPk] ? background[bgPk] : background) : null
        };
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/page/${pk}/`, data)
        .pipe(
            tap(_ => this.base.log('update page')),
            catchError(this.base.handleError('updatePage'))
        );
    }

    removePage(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/page/${pk}/`)
        .pipe(
            tap(_ => this.base.log('delete page')),
            catchError(this.base.handleError('removePage'))
        );
    }
    
    getUserPage(page): Observable<any> {
        const url = `${this.base.getRootUrl()}portal/api/user-page/?page=${page}`;
        return this.http.get<any>(url)
        .pipe(
            tap(_ => this.base.log('fetched user-pages')),
            catchError(this.base.handleError('getUserPage', this.base.handlerBlank))
        );
    }

    createUser(user, page, role) {
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/user-page/`, {user, page, role})
        .pipe(
            tap(_ => this.base.log('create user-page')),
            catchError(this.base.handleError('createUser', this.base.handleError))
        );
    }

    deleteUser(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/user-page/${pk}/`)
        .pipe(
            tap(_ => this.base.log('delete user-page')),
            catchError(this.base.handleError('deleteUser'))
        );
    }

    photoURL(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    trustHtml(value) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
