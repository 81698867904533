import { Component, OnInit, Input } from '@angular/core';
import { SatDatepickerRangeValue } from 'saturn-datepicker';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.page.html',
    styleUrls: ['./date-range.page.scss'],
})
export class DateRangePage implements OnInit {
    date: SatDatepickerRangeValue<any>;
    @Input() getDate: any;
    @Input() setDate: any;
    subscription: any;

    constructor(private base: BaseService) {}

    ngOnInit() {
        this.subscription = this.getDate.subscribe(data => {
            if (data) {
                this.date = {
                    begin: this.base.getDate(data.begin),
                    end: this.base.getDate(data.end)
                };
            }
        });
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    dateChange(ev) {
        this.setDate.next({begin: ev.value.begin.toJSON(), end: ev.value.end.toJSON()});
    }
}
