import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CellPage } from './cell.page';
import { NgxBarcodeModule } from 'ngx-barcode';

const routes: Routes = [
    {
        path: 'cell',
        component: CellPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        NgxBarcodeModule
    ],
    declarations: [CellPage],
    exports: [CellPage]
})
export class CellPageModule {}
