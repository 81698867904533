import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { BaseService } from 'src/app/services/base.service';
import {} from 'googlemaps';
import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import AutocompleteService = google.maps.places.AutocompleteService;
import AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import PlacesService = google.maps.places.PlacesService;
import Map = google.maps.Map;
import Marker = google.maps.Marker;
import MapOptions = google.maps.MapOptions;
import Geocoder = google.maps.Geocoder;
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GeoLocation } from 'src/app/classes/location';
import { Base } from 'src/app/classes/base';


class GeoMarker {
    constructor(public id: string, public marker: Marker, public lat: number, public lon: number) {
    }
}

@Component({
    selector: 'app-map',
    templateUrl: './map.page.html',
    styleUrls: ['./map.page.scss'],
})
export class MapPage implements OnInit {

    @Input() readOnly: boolean = false;
    @Input() markerId: string;
    @Input() lat;
    @Input() lng;
    @Input() zoom;
    @Input() addressText;
    @Input() save;
    @Input() updater: BehaviorSubject<GeoLocation>;

    @ViewChild('input') inputEl: ElementRef;
    @ViewChild('map') mapEl: ElementRef;
    addressControl = new FormControl();
    inputOnFocus: boolean;
    map: Map;
    markers: GeoMarker[] = [];
    autocompleteOptions: AutocompletePrediction[];
    autocompleteService: AutocompleteService;
    placesService: PlacesService;
    geocoder: Geocoder;
    sessionToken: AutocompleteSessionToken;
    address: {
        formatted: string;
        components: GeocoderAddressComponent[];
        location: { latitude: number; longitude: number; };
    };
    subscription: Subscription;

    constructor(private base: BaseService,
                private cdr: ChangeDetectorRef,
                private trans: TranslateService) { }

    ngOnInit() {
        const loader = new Loader({
            apiKey: 'AIzaSyCg7ZtjbGBXR9a8V9m09oO9VH5IlZBojFU',
            version: 'quarterly',
            libraries: ['places'],
            language: this.base.getLanguage()
        });
          
        loader.load().then(async () => {
            await this.initMap();
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.placesService = new google.maps.places.PlacesService(this.map);
            this.geocoder = new google.maps.Geocoder();
            this.sessionToken = new google.maps.places.AutocompleteSessionToken();

            this.addressControl.valueChanges.pipe(debounceTime(500)).subscribe(value => {
                this.getAddresses(value, false);
            });
            if (!Base.toFloat(this.lat) && !Base.toFloat(this.lng) && this.addressText && !this.readOnly) {
                this.addressControl.setValue(this.addressText);
                this.getAddresses(this.addressText, true);
            } else if (!this.readOnly) {
                this.addMarker({lat: Base.toFloat(this.lat), lng: Base.toFloat(this.lng)}, this.markerId, this.addressText, null, null);
            }
            if (this.updater) {
                this.subscription = this.updater.subscribe(location => {
                    if (location && location.lat && location.lon) {
                        this.addMarker({lat: location.lat, lng: location.lon}, location.id || this.markerId, location.title, location.label, location.icon);
                    }
                })
            }
        });
    }

    getAddresses(value, selectFirst) {
        if (value) {
            this.autocompleteService.getPlacePredictions({
                    input: value,
                    sessionToken: this.sessionToken
                },
                result => {
                    this.autocompleteOptions = result;
                    this.cdr.detectChanges();
                    if (selectFirst && this.autocompleteOptions && this.autocompleteOptions.length) {
                        this.selectAddress(null, this.autocompleteOptions[0]);
                    }
                });
        } else {
            this.autocompleteOptions = null;
            this.cdr.detectChanges();
        }
    }

    async initMap() {
        const mapProp: MapOptions = {
            center: {lat: Base.toFloat(this.lat), lng: Base.toFloat(this.lng)},
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        this.map = new google.maps.Map(this.mapEl.nativeElement, mapProp);

        this.map.addListener('click', e => this.mapClickHandler(e));
    }

    mapClickHandler(e) {
        this.inputEl.nativeElement.blur();
        this.geocoder.geocode({ location: e.latLng }, results => {
            this.setAddress({
                address_components: results[ 0 ].address_components,
                formatted_address: results[ 0 ].formatted_address,
                geometry: {
                    location: e.latLng
                }
            });
        });
    }
    
    setAddress(result) {
        let location;
        if (typeof result.geometry.location.lat === 'function') {
            location = {
                latitude: result.geometry.location.lat(),
                longitude: result.geometry.location.lng()
            };
        } else {
            location = {
                latitude: result.geometry.location.lat,
                longitude: result.geometry.location.lng
            };
        }
        this.address = {
            formatted: this.removePostalCode(result.address_components, result.formatted_address),
            components: result.address_components,
            location
        };
        this.addMarker(result.geometry.location, this.markerId, this.addressText, null, null);
        this.addressControl.setValue(this.address.formatted);
    }

    addMarker(location, id: string, title: string, label: string, icon: string) {
        let marker = this.markers.find(m => m.id === id || !this.readOnly);
        if (marker) {
            if (marker.lat == location.lat && marker.lon == location.lng) {
                return;
            }
            marker.marker.setMap(null);
        } else {
            marker = new GeoMarker(id, null, location.lat, location.lng);
            this.markers.push(marker);
        }

        marker.marker = new google.maps.Marker({
            position: location,
            map: this.map,
            label: label,
            icon: icon,
            title: title,
        });

        if (this.markers.length == 1) {
            this.map.panTo(location);
            this.map.setZoom(17);
        }
    }

    removePostalCode(components: GeocoderAddressComponent[], formatted: string): string {
        const code = components.find(c => c.types.includes('postal_code'));
        const postalCode = code ? code.short_name : '';
        if (postalCode) {
            const addressArray = formatted.split(', ');
            if (addressArray.indexOf(postalCode) !== -1) {
                addressArray.splice(addressArray.indexOf(postalCode), 1);
                return addressArray.join(', ');
            }
        }
        return formatted;
    }

    onFocus(e?) {
        if (e) {
            this.inputOnFocus = true;
        } else {
            setTimeout(() => {
                this.inputOnFocus = false;
                this.cdr.detectChanges();
            }, 100);
        }
    }

    validateAddress(): boolean {
        if (this.address && this.address.components) {
            for (const component of this.address.components) {
                if (component.types.includes('street_number')) {
                    return true;
                }
            }
        }
        return false;
    }

    selectAddress(e, option) {
        this.placesService.getDetails({
            placeId: option.place_id,
            sessionToken: this.sessionToken,
            fields: [ 'address_components', 'formatted_address', 'geometry' ]
        },
        (response) => {
            this.setAddress(response);
            this.cdr.detectChanges();
        });
    }

    async submit() {
        if (!this.address || !this.address.location || !this.address.location.latitude || !this.address.location.longitude) {
            this.base.sendToast(this.trans.instant('address-incorrect'))
        } else {
            this.save.next(this.address);
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
