import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from 'src/app/services/form.service';
import { FilterPage } from '../filter/filter.page';
import { Equal, FieldType } from 'src/app/classes/enums';
import { BoardFilter } from 'src/app/classes/board-filters';

@Component({
    selector: 'app-button-widget',
    templateUrl: './button-widget.page.html',
    styleUrls: ['./button-widget.page.scss'],
})
export class ButtonWidgetPage implements OnInit {

    @Input() i: any;
    @Input() w: any;
    @Input() fields: any;
    @Input() form: any;
    @Input() tableCells: any;
    @Input() lang: any;
    @Output() change = new EventEmitter();

    colors = ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark', 'custom'];
    formPk = '';

    constructor(private base: BaseService, 
                private modalCtrl: ModalController,
                private trans: TranslateService,
                private alertCtrl: AlertController,
                private formService: FormService,
                private popoverCtrl: PopoverController) { }

    ngOnInit() {
        if (!this.lang) {
            this.lang = this.base.getLanguage();
        }
        if (this.w && !this.w.titles) {
            this.w.titles = {};
        }
        this.formPk = this.base.getTablePk('Form');
    }

    async saveFromForm(w) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                w.form = {name: details.data.item.name};
                w.form[this.formPk] = details.data.item[this.formPk];
                w.table = details.data.item.data.table;
            }
        });
        return await modal.present();
    }

    async saveFromField(w) {

        const inputs = [];
        for (const f of this.fields) {
            inputs.push({ name: 'field', type: 'radio', label: f.verbose_name, value: f.name});
        }
        inputs.push({ name: 'field', type: 'radio', label: 'pk', value: 'pk' });
        inputs.push({ name: 'field', type: 'radio', label: 'calc', value: 'calc' });

        if (this.form.data.relateds) {
            for (const rel of this.form.data.relateds) {
                const model = this.base.getTableMeta(rel.data.table);
                if (model && model.fields) {
                    for (const f of model.fields) {
                        inputs.push({ 
                            name: 'field', 
                            type: 'radio', 
                            label: rel.title.title + '.' + f.verbose_name,
                            value: rel.data.table + '.' + f.name
                        });
                    }
                }
            }
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-source-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.saveFromFieldTo(params, w);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async saveFromFieldTo(fieldName, w) {

        let field = this.fields.find(i => i.name === fieldName);
        if (!field && fieldName === 'pk') {
            field = {name: 'pk', related_model: this.form.data.table, verbose_name: 'pk', type: FieldType.REF};
        } else if (!field && fieldName === 'calc') {
            field = {name: 'calc', verbose_name: 'calc', type: 'calc', value: ''};
        } else if (!field && fieldName.split('.').length === 2) {
            const model = this.base.getTableMeta(fieldName.split('.')[0]);
            const f = model.fields.find(i => i.name === fieldName.split('.')[1]);
            field = f;
            field.fieldName = f.name; field.model = model.table;
            field.name = fieldName; field.verbose_name = model.verbose_name + '.' + f.verbose_name; 
        }

        let fields = []; const inputs = [];
        if (field && field.fieldName && field.model && w.form && w.form[this.formPk]) {
            const filters = `&filter_fields=${JSON.stringify([{name: this.formPk, equal: Equal.EQ, value: w.form[this.formPk]}])}`;
            this.base.getData('Form', 1, 1, filters).subscribe(resp => {
                if (resp && resp.data && resp.data.length === 1) {
                    const data = resp.data[0].data;
                    if (data && data.relateds) {
                        for (const rel of data.relateds) {
                            const model = this.base.getTableMeta(rel.data.table);
                            if (model && model.fields) {
                                for (const f of model.fields) {
                                    if (f.type === field.type && f.related_model === field.related_model) {
                                        f.fieldName = f.name; f.model = model.table;
                                        f.name = model.table + '.' + f.name;
                                        f.verbose_name = model.verbose_name + '.' + f.verbose_name;
                                        fields.push(f); 
                                        inputs.push({ name: 'field', type: 'radio', label: f.verbose_name, value: f.name });
                                    }
                                }
                            } 
                        }
                    }
                }
                this.askFieldTo(inputs, fields, field, w);
            });
        } else {
            fields = this.base.getTableMeta(w.table).fields;
            for (const f of fields) {
                if (field.type === 'calc' || (f.type === field.type && f.related_model === field.related_model)) {
                    inputs.push({ name: 'field', type: 'radio', label: f.verbose_name, value: f.name });
                }
            }
            this.askFieldTo(inputs, fields, field, w);
        }
    }

    async askFieldTo(inputs, fields, field, w) {
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('field-not-found'));
            return;
        } else if (inputs.length === 1) {
            w.fields.splice(w.fields.length, 0, {from: field, to: fields.find(i => i.name === inputs[0].value)});
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-resource-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            w.fields.splice(w.fields.length, 0, {from: field, to: fields.find(i => i.name === params)});
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    changeButtons() {
        this.change.emit();
    }

    addTableCell() {
        this.formService.addTableCell(this.tableCells, this.w, true, this.form.data.table);
    }

    addTableCellRow(tableCell) {
        this.formService.addTableCellRow(tableCell);
    }

    openFilters(ev, tableCell) {
        const fields = this.base.getTableMeta(tableCell.from.table).fields;
        this.base.getCalcFields(tableCell.from.table).subscribe(resp => {
            if (resp && resp.length) {
                for (const ff of resp) {
                    fields.splice(fields.length, 0, ff);
                }
            }
            this.openFIltersFields(ev, tableCell, fields);
        });
    }

    async openFIltersFields(ev, tableCell, fields) {
        const filters: BoardFilter[] = (tableCell.filters || []).map(f => BoardFilter.fromJson(f))
        const popover = await this.popoverCtrl.create({
            component: FilterPage,
            componentProps: {
                filters: filters, 
                showTitle: true,
                showHiddenButtons: false,
                fields,
                showCloseButton: true,
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((_: any) => {
            tableCell.filters = BoardFilter.toList(filters);
        });
        return await popover.present();
    }

    async addRelateFilter(tableCell) {

        const inputs = [];
        for (const f of this.base.getTableMeta(this.form.data.table).fields) {
            inputs.push({ name: 'field', type: 'radio', label: f.verbose_name, value: f.name});
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-source-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.addRelateFilterTo(params, tableCell);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addRelateFilterTo(fieldFrom, tableCell) {

        const inputs = [];
        const field = this.base.getTableMeta(this.form.data.table).fields.find(i => i.name === fieldFrom);
        for (const f of this.base.getTableMeta(tableCell.from.table).fields) {
            if (field.type === f.type) {
                inputs.push({ name: 'field', type: 'radio', label: f.verbose_name, value: f.name});
            }
        }

        const success = (fieldName) => {
            if (!tableCell.related_filters) {
                tableCell.related_filters = [];
            }
            const fieldTo = this.base.getTableMeta(tableCell.from.table).fields.find(i => i.name === fieldName);
            tableCell.related_filters.splice(tableCell.related_filters.length, 0, {from: field, to: fieldTo});
        };

        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('field-not-found'));
            return;
        } else if (inputs.length === 1) {
            success(inputs[0].value);
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-resource-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            success(params);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }
}
