import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { MetaService } from 'src/app/services/meta.service';
import { Constants } from '../../classes/app.constants';
import { BaseService } from 'src/app/services/base.service';
import { FieldType, FunctionType, Interval } from 'src/app/classes/enums';
import { ChartType } from 'src/app/classes/enums';
import { Chart } from 'src/app/classes/chart';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-graph-setting',
    templateUrl: './graph-setting.page.html',
    styleUrls: ['./graph-setting.page.scss'],
})
export class GraphSettingPage implements OnInit {
    
    @Input() newChart: Chart;
    @Input() fields: any;
    @Input() table: any;
    allowFields = [];
    aggrFields = [];
    hasSeries = false;
    chartTypes = Constants.CHART_TYPES;
    intervals = Constants.INTERVALS;
    chartTitle = '';
    isSort = false;
    isAxis = false;
    subtab = 0;

    constructor(private popoverCtrl: PopoverController,
                private meta: MetaService,
                private trans: TranslateService,
                private base: BaseService) { }

    ngOnInit() {
        this.allowFields = this.allowFilterFields(this.fields, 'blockList');
        this.aggrFields = this.getAggr(this.fields);
        this.refreshTitle();
        this.changedType();
        if (this.newChart) {
            if (this.newChart.groupBy && this.newChart.groupBy.name) {
                this.newChart.groupByField = this.newChart.groupBy.name;
            }
            if (this.newChart.groupBy2 && this.newChart.groupBy2.name) {
                this.newChart.groupBy2Field = this.newChart.groupBy2.name;
            }
            if (this.newChart.aggr && this.newChart.aggr.name) {
                this.newChart.aggrField = this.newChart.aggr.name;
            }
        }
    }

    close() {
        this.popoverCtrl.dismiss();
    }

    changedType() {
        this.hasSeries = this.newChart ? this.newChart.hasSeries : false;
        this.isSort = this.newChart ? this.newChart.isSort : false;
        this.isAxis = this.hasAxis(this.newChart);
    }

    changedGroupBy(groupBy, groupBySub, groupByName) {
        if (this.newChart) {
            const val = this.allowFields.find(f => f.name === this.newChart[groupByName + 'Field']);
            this.newChart[groupByName] = val ? this.base.copy(val) : null;
            groupBy = this.newChart[groupByName];
        }
        if (groupBy && groupBy.type === FieldType.REF && groupBy.hasParent) {
            this.newChart[groupBySub] = 'element';
        } else if (groupBy && (groupBy.type === FieldType.DATETIME || groupBy.type === FieldType.DATE)) {
            this.newChart[groupBySub] = Interval.DAY;
        }
    }

    private allowFilterFields(fields, block) {
        if (!fields) {
            return [];
        }
        return this.meta.allowFilterFields(fields, block);
    }

    private getAggr(fields) {
        if (!fields) {
            return [];
        }
        return fields.filter(i => i.type === FieldType.DECIMAL || i.type === FieldType.INT);
    }

    refreshTitle() {
        if (this.newChart) {
            const val = this.aggrFields.find(f => f.name === this.newChart.aggrField);
            this.newChart.aggr = val ? this.base.copy(val) : null;
        }
        this.chartTitle = this.newChart.getTitle(this.trans, this.meta.getTitleMeta(this.table));
    }

    chooseSum() {
        if (this.newChart) {
            if (this.fields && this.fields.filter(i => i.type === FieldType.DECIMAL || i.type === FieldType.INT).length) {
                this.newChart.func = FunctionType.SUM;
                this.newChart.aggr = null;
                const agg = this.fields.filter(i => i.type === FieldType.DECIMAL);
                if (agg.length) {
                    this.newChart.aggr = agg[0];
                }
            } else {
                this.newChart.func = FunctionType.COUNT;
            }
            this.refreshTitle();
        }
    }

    private hasAxis(chart: Chart) {
        if (chart && chart.type) {
            return [ChartType.VERTICAL_BAR, ChartType.HORIZONTAL_BAR, ChartType.STACKED_HORIZONTAL_BAR, ChartType.STACKED_VERTICAL_BAR, 
                    ChartType.NORMALIZED_HORIZONTAL_BAR, ChartType.NORMALIZED_VERTICAL_BAR, ChartType.LINE, ChartType.AREA, ChartType.AREA_NORMALIZED, 
                    ChartType.AREA_STACKED, ChartType.POLAR, ChartType.HEAT_MAP].indexOf(chart.type) !== -1;
        }
        return false;
    }
}
