import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ColorPickerPage } from '../color-picker/color-picker.page';
import { BaseService } from 'src/app/services/base.service';
import { FieldType } from 'src/app/classes/enums';
import { ListDisplay } from 'src/app/classes/board-list-display';

@Component({
    selector: 'app-list-display',
    templateUrl: './list-display.page.html',
    styleUrls: ['./list-display.page.scss'],
})
export class ListDisplayPage implements OnInit {

    @Input() listDisplay: ListDisplay[] = [];
    @Input() selectedField: any;
    @Input() tab: any;
    @Input() showHeader = false;
    @Input() hideShowNewLine = false;
    @Input() hideShowFieldName = false;
    imgFields = [];

    constructor(private popoverCtrl: PopoverController,
                private base: BaseService) { }

    ngOnInit() {
        if (this.tab.tab === 3.7 && this.selectedField) {
            this.select(this.selectedField);
        }
    }

    reorderListDisplayY(ev) {
        const itemToMove = this.listDisplay.splice(ev.detail.from, 1)[0];
        this.listDisplay.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    async openColorPicker(ev) {
        const popover = await this.popoverCtrl.create({
            component: ColorPickerPage,
            componentProps: {color: this.selectedField, askData: true, closeAfterSelect: true},
            cssClass: 'color-picker-popover',
            showBackdrop: false,
            event: ev,
        });
        return await popover.present();  
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }

    select(field) {
        field.isChecked = true; 
        this.selectedField = field; 
        this.tab.tab = 3.7;
        this.imgFields = [];
        if (field.type === FieldType.REF && field.related_model) {
            const relatedModel = this.base.getTableMeta(field.related_model);
            if (relatedModel && relatedModel.fields) {
                this.imgFields = relatedModel.fields.filter(i => i.type === FieldType.CHAR);
            }
        } else if (field.type !== FieldType.REF) {
            this.imgFields = JSON.parse(JSON.stringify(this.listDisplay.filter(i => i.type === FieldType.CHAR)));
        }
    }
}
