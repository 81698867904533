import { Component, OnInit, Input, HostListener, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { finalize } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/services/page.service';
import { Equal } from 'src/app/classes/enums';
import { BoardFilter } from 'src/app/classes/board-filters';
import { Hierarchy } from 'src/app/classes/hierarchy';
import { Constants } from 'src/app/classes/app.constants';

@Component({
    selector: 'app-hierarchy',
    templateUrl: './hierarchy.page.html',
    styleUrls: ['./hierarchy.page.scss'],
})
export class HierarchyPage implements OnInit {

    height: number = 200;
    width: number = 200;
    searchFocus: boolean = false;
    searchValue: string = null;
    pistolScan: boolean = false;
    parents: Hierarchy[] = [];
    elements: any[] = [];
    canRequest: boolean = true;

    private nextPage: boolean = true;
    private page: number = 1;
    private hierarchies: Hierarchy[] = [];

    @Input() row: any;
    @Input() subscription: any;
    @Input() relateds: any;
    @Input() rootItem: any;
    @Input() form: any;
    @Input() activeRelateds: any;
    @Input() itemChanged: any;
    @Output() save = new EventEmitter();

    @ViewChild('pistolInput') pistolInput: any;
    @ViewChild('searchInput') searchInput: any;

    constructor(private base: BaseService,
                private trans: TranslateService,
                private pageService: PageService,
                @Inject(DOCUMENT) private document) { }

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.changeSize();
    }

    private changeSize() {
        this.height = window.innerHeight;
        const el = this.document.getElementsByClassName('elements')[0];
        if (el) {
            this.width = el.clientWidth - 8 - 10;
        }
    }

    ngOnInit() {
        this.changeSize();
        setTimeout(() => {
            this.changeSize();
        }, 1000);
        setTimeout(() => {
            this.changeSize();
        }, 2000);

        this.init();
        
        if (this.subscription) {
            this.subscription.subscribe(resp => {
                if (resp) {
                    this.init()
                }
            });
        }
    }

    openSearch() {
        this.searchFocus = true;
        setTimeout(() => {
            this.searchInput.setFocus();
        }, 100);
    }

    private init() {
        this.hierarchies = (this.row.hierarchies || []).map(f => Hierarchy.fromJson(f));
        this.parents = [];
        this.page = 1;
        this.loadData();
    }

    private get currentHierarchy(): Hierarchy {
        if (this.parents.length) {
            return this.parents[this.parents.length-1];
        }

        if (this.searchValue) {
            const h = this.hierarchies.find(i => i.isSearch);
            if (h) {
                return h;
            }
        }
        return this.hierarchies[0];
    }

    private loadData(eventRefresh?, pistolValue?: string) {

        const hierarchy = this.currentHierarchy;
        let params = '';
        if (this.searchValue) {
            params += '&s=' + this.searchValue;
        }
        if (hierarchy.extraFunc) {
            const val = this.getCalcVal(hierarchy, pistolValue, hierarchy.extraFunc, null);
            if (val) {
                params += val 
            }
        }

        this.base.getData(hierarchy.table, this.page, Constants.ON_PAGE, params, this.getFilters(hierarchy, pistolValue), true).pipe(
            finalize(() => {
                if (eventRefresh) {   
                    eventRefresh.target.complete();
                }
                this.canRequest = true;
            })
        ).subscribe(data => {
            if (data && data.data) {
                let elements = [];
                if (this.page !== 1) {
                    elements = this.elements.slice();
                }
                elements.push(...data.data);
                this.elements = elements;
                this.refreshChecked(hierarchy);
                this.nextPage = data.has_next;
                if (data.has_next) { 
                    this.page ++; 
                } 
            } else {
                this.nextPage = false;
            }
        });
    }

    private refreshChecked(hierarchy: Hierarchy) {
        const pk = this.base.getTablePk(hierarchy.table);
        const relateds = hierarchy.relatedTable && this.relateds && this.relateds[hierarchy.relatedTable] ? this.relateds[hierarchy.relatedTable] : [];
        for (const el of this.elements) {
            el._checked = relateds.find(f => f.item.openedItem[hierarchy.relatedField] && f.item.openedItem[hierarchy.relatedField][pk] === el[pk]) ? true : false;
            el._repr = this.getRepr(el, hierarchy.reprFunc);
        }
    }

    private getRepr(element, func: string) {
        const divRepr = this.base.getCalcRepr(element, this, func);
        if (divRepr) {
            return this.pageService.trustHtml(divRepr);
        }
    }

    private getCalcVal(hierarchy: Hierarchy, pistolValue: string, func: string, element: any): any {
        return this.base.getCalcRepr({
            elements: this.elements, 
            parents: this.parents, 
            hierarchies: this.hierarchies, 
            hierarchy, 
            search: this.searchValue, 
            pistolValue,
            element
        }, this.rootItem, func, this.relateds);
    }

    private getFilters(hierarchy: Hierarchy, pistolValue: string): BoardFilter[] {
        if (!hierarchy.filterFunc) {
            return [];
        }
        return (this.getCalcVal(hierarchy, pistolValue, hierarchy.filterFunc, null) || []).map(f => BoardFilter.fromJson(f));
    }

    doSearch(event) {
        this.searchValue = event.detail.value;
        if (this.searchValue && this.hierarchies.find(i => i.isSearch)) {
            this.parents = [];
        }
        this.page = 1;
        this.loadData();
    }

    select(i: number) {
        if (i === this.parents.length - 1) {
            return;
        }
        this.parents.splice(i + 1, this.parents.length - i - 1);
        this.searchValue = null;
        this.page = 1;
        this.loadData();
    }

    clear() {
        if (!this.parents.length) {
            return;
        }
        this.page = 1;
        this.parents = [];
        this.searchValue = null;
        this.page = 1;
        this.loadData();
    }

    scrollElements(event) {
        if (this.nextPage && this.canRequest && 
            event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.loadData();
        }
    }

    openElement(element: any) {
        const currentHierarchy = this.currentHierarchy;
        const pk = this.getCalcVal(currentHierarchy, null, currentHierarchy.nextStepFunc, element);

        if (!pk) {
            
            this.setElement(element, currentHierarchy, false);

        } else {

            this.page = 1;
            this.searchValue = null;
            const hierarchy = this.hierarchies.find(h => h.pk === pk).copy();
            hierarchy.element = this.base.copy(element);
            hierarchy.element._repr_short = this.getRepr(hierarchy.element, hierarchy.reprFuncShort);
            this.parents.splice(this.parents.length, 0, hierarchy);
            this.loadData(null, null);
        }
    }

    private setElement(el: any, hierarchy: Hierarchy, pistol: boolean) {
        if (!this.relateds[hierarchy.relatedTable]) {
            this.relateds[hierarchy.relatedTable] = [];
        }
        const pk = this.base.getTablePk(hierarchy.table);
        const element = JSON.parse(this.getCalcVal(hierarchy, null, `JSON.stringify(${hierarchy.elementFunc})`, this.base.copy(el)));
        const form = this.form.data.relateds.find(i => i.data.table === hierarchy.relatedTable);

        if (hierarchy.incrementField) {
            element[hierarchy.incrementField] = 1;
            
            for (let i = 0; i < this.relateds[hierarchy.relatedTable].length; i++) {
                
                const row = this.relateds[hierarchy.relatedTable][i]; 
                if (row.item.openedItem && row.item.openedItem[hierarchy.relatedField] && row.item.openedItem[hierarchy.relatedField][pk] === el[pk]) {
                    
                    row.item.openedItem[hierarchy.incrementField] = parseInt(row.item.openedItem[hierarchy.incrementField] || 0, 10) + 1;
                    this.itemChanged.next(0);

                    let changes: any; changes = {};
                    changes[hierarchy.incrementField] = row.item.openedItem[hierarchy.incrementField];
                    this.save.emit({item: row.item, form, fields: changes, index: i});
                    return; 
                }
            }
        }

        const item = {
            item: {openedItem: element}, 
            error: null, 
            fields: this.base.getTableMeta(hierarchy.relatedTable).fields, 
            hideEmpties: {}
        };
        this.relateds[hierarchy.relatedTable].splice(this.relateds[hierarchy.relatedTable].length, 0, item);
        
        const changes: any= {};
        changes[hierarchy.incrementField] = element[hierarchy.incrementField];
        this.save.emit({item: this.relateds[hierarchy.relatedTable][this.relateds[hierarchy.relatedTable].length - 1].item, form, 
                        fields: changes, index: this.relateds[hierarchy.relatedTable].length});
        
        if (pistol) {
            this.refreshChecked(this.currentHierarchy)
        } else {
            this.refreshChecked(hierarchy);
        }

        this.itemChanged.next(0);
        this.funcAfterChanged(item.item, hierarchy);
    }

    pistol() {
        this.pistolScan = !this.pistolScan;
        if (this.pistolScan) {
            this.pistolInput.setFocus();
        }
    }

    pistolValue(ev) {
        if (ev.detail.value && this.pistolScan) {
            if (!this.canRequest) {
                return;
            }
            this.canRequest = false;
            const hierarchy = this.hierarchies.find(f => f.pistolField ? true : false);
            let params = ''
            if (hierarchy.extraFunc) {
                const val = this.getCalcVal(hierarchy, ev.detail.value, hierarchy.extraFunc, null);
                if (val) {
                    params += val 
                }
            }
            this.base.getData(hierarchy.table, 1, 2, params, [new BoardFilter(hierarchy.pistolField, Equal.EQ, ev.detail.value)], true).pipe(
                finalize(() => {
                    this.canRequest = true;
                    this.pistolInput.value = null;
                })
            ).subscribe(data => {
                if (data && data.data) {
                    if (data.data.length === 1) {
                        this.setElement(data.data[0], hierarchy, true);
                    } else if (data.data.length > 1) {
                        this.base.sendToast(this.trans.instant('found-more-than-one-element', 
                                            {cnt: data.data.length, code: ev.detail.value}), 5000);
                    } else {
                        this.base.sendToast(this.trans.instant('not-found-element', {code: ev.detail.value}), 5000);
                    }
                }
            });
        }
    }

    private funcAfterChanged(item: any, hierarchy: Hierarchy) {
        if (hierarchy.funcAfterChanged) {
            return this.base.getCalcRepr(item.openedItem, this.rootItem ? this.rootItem.openedItem : item.openedItem, hierarchy.funcAfterChanged, this.relateds);
        }
    }
}
