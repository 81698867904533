import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SelectPage } from './select.page';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        MaterialDesignFrameworkModule
    ],
    declarations: [SelectPage],
    exports: [SelectPage],
    entryComponents: [SelectPage]
})
export class SelectPageModule {}
