import { Base } from "./base";
import * as uuid from 'uuid';

export class Hierarchy {
    public element: any;

    constructor(public table: string,
                public filterFunc: string,
                public isSearch: boolean,
                public relatedTable: string,
                public reprFunc: string,
                public relatedField: string,
                public pk: string,
                public nextStepFunc: string,
                public incrementField: string,
                public elementFunc: string,
                public funcAfterChanged: string,
                public pistolField: string,
                public reprFuncShort: string,
                public extraFunc: string) {

        if (!this.pk) {
            this.pk = uuid.v4();
        }
    }

    serialize(): any {
        return {
            table: this.table, 
            filterFunc: this.filterFunc,
            isSearch: this.isSearch,
            relatedTable: this.relatedTable,
            reprFunc: this.reprFunc,
            relatedField: this.relatedField,
            pk: this.pk,
            nextStepFunc: this.nextStepFunc,
            incrementField: this.incrementField,
            elementFunc: this.elementFunc,
            funcAfterChanged: this.funcAfterChanged,
            pistolField: this.pistolField,
            reprFuncShort: this.reprFuncShort,
            extraFunc: this.extraFunc
        };
    }

    copy(): Hierarchy {
        return Hierarchy.fromJson(Base.copy(this.serialize()))
    }

    static fromJson(d: any): Hierarchy {
        return new Hierarchy(d.table, d.filterFunc, d.isSearch, d.relatedTable, d.reprFunc, d.relatedField, d.pk, d.nextStepFunc, d.incrementField, d.elementFunc, d.funcAfterChanged, d.pistolField, d.reprFuncShort, d.extraFunc);
    }
}
