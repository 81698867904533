import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, PopoverController, AlertController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { finalize, map } from 'rxjs/operators';
import { TemplateService } from '../services/template.service';
import { ListDisplayPage } from '../components/list-display/list-display.page';
import { OrderByPage } from '../components/order-by/order-by.page';
import { FilterPage } from '../components/filter/filter.page';
import { ChoiceSettingPage } from '../components/choice-setting/choice-setting.page';
import { TranslateService } from '@ngx-translate/core';
import { zip } from 'rxjs';
import { FormPage } from '../form/form.page';
import { ExtraFields } from '../classes/extra-fields';
import { Equal } from '../classes/enums';
import { ListDisplay } from '../classes/board-list-display';
import { BoardFilter } from '../classes/board-filters';
import { OrderBy } from '../classes/board-order-by';
import { Constants } from '../classes/app.constants';
import { Base } from '../classes/base';

@Component({
    selector: 'app-choices',
    templateUrl: './choices.page.html',
    styleUrls: ['./choices.page.scss'],
})
export class ChoicesPage implements OnInit {

    @ViewChild('search') search;
    @Input() model: any;
    @Input() filters: BoardFilter[] = [];
    @Input() funcRepr: any;
    @Input() formPk: any;
    @Input() selected: any;
    @Input() title: any;
    
    data: any = [];
    scrollComplete: boolean = true;
    page: number = 1;
    hasNext: boolean = false;

    listDisplay: ListDisplay[] = [];
    orderBy: OrderBy[] = [];

    isAdmin: boolean = false;
    form: any;
    createdForm: any = null;
    objPk: string = '';

    private isChanged: boolean = false;
    private metaFields: any[] = [];
    private formPkField: string = null;
    private calcs: any[] = [];

    constructor(private modalCtrl: ModalController, 
                private base: BaseService,
                private templateService: TemplateService, 
                private popoverCtrl: PopoverController,
                private alertCtrl: AlertController,
                private trans: TranslateService) { }

    ngOnInit() {
        this.fixForm();
        this.formPkField = this.base.getTablePk('Form');
        this.isAdmin = Base.getCurrentUser().is_admin;
        const table = this.base.getTableMeta(this.model);
        if (table && table.fields) {
            this.title = this.title || table.verbose_name;
            this.metaFields = table.fields;
            this.objPk = this.base.getTablePk(this.model);
        }

        if (!this.funcRepr) {
            this.funcRepr = 'name';
        }

        const filters = [new BoardFilter('type', Equal.EQ, 1), new BoardFilter('table', Equal.EQ, this.model)];
        if (this.formPk) {
            filters.push(new BoardFilter(this.base.getTablePk('Form'), Equal.EQ, this.formPk));
        } else {
            filters.push(new BoardFilter('is_default', Equal.EQ, true));
        }

        this.scrollComplete = false;
        const form = this.base.getData('Form', 1, 1, '', filters, true)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;

                    const filters = [
                        new BoardFilter('type', Equal.EQ, 0), 
                        new BoardFilter('table', Equal.EQ, this.model)
                    ];
                    if (this.form && this.form.data && this.form.data.form) {
                        const pk = this.base.getTablePk('Form');
                        filters.push(new BoardFilter(pk, Equal.EQ, this.form.data.form[pk]));
                    } else {
                        filters.push(new BoardFilter('is_default', Equal.EQ, true));
                    }
                    this.base.getData('Form', 1, 1, '', filters, true).subscribe(resp => {
                        if (resp && resp.data && resp.data.length === 1 && resp.data[0].table === this.model) {
                            this.createdForm = resp.data[0];
                        }
                    });
                })
            );

        zip(this.base.getCalcFields(this.model), form).subscribe(([fields, forms]) => {
            if (forms && forms.data && forms.data.length === 1 && forms.data[0].table === this.model) {
                this.form = forms.data[0];
                this.fixForm();
                if (this.form && this.form.data && this.form.data.listDisplay) {
                    this.listDisplay = (this.form.data.listDisplay || []).map(f => ListDisplay.fromJson(f));
                    this.orderBy = (this.form.data.orderBy || []).map(f => OrderBy.fromJson(f));
                }
            }
            if (fields && fields.length) {
                this.calcs = fields;
            }
            for (const f of this.calcs) {
                this.metaFields.splice(this.metaFields.length, 0, f);
            }
            this.scrollComplete = true;
            this.loadData();
        }); 
    }

    ionViewDidEnter() {
        this.search.setFocus();
    }

    ionViewWillLeave() {
        if (!this.isAdmin || !this.isChanged) {
            return;
        }
        if (this.form && (this.form[this.formPkField] || this.form.name)) {
            this.alertSaveForm(this.trans.instant('yes'), this.trans.instant('no'), this.trans.instant('save-form', {name: this.form.name}), this.trans.instant('create-form'));  
        }
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    choose(item) {
        this.modalCtrl.dismiss({item});
    }

    loadData(infiniteScroll?) {
        if (!this.scrollComplete) {
            return;
        }
        this.scrollComplete = this.page !== 1;

        let params = '';
        const filters = BoardFilter.copy(this.filters || []);
        if (this.form && this.form.data) {
            if (this.form.data.filters) {
                filters.push(...this.form.data.filters.filter(f => f.isChecked).map(f => BoardFilter.fromJson(f)));
            }
            if (this.form.data.orderBy) {
                params += OrderBy.serialize(this.form.data.orderBy.map(f => OrderBy.fromJson(f)));
            }
        }
        
        if (this.search.value) {
            params += '&s=' + this.search.value;
        }
        this.base.getData(this.model, this.page, Constants.ON_PAGE, params + ExtraFields.build(this.form, this.base.getTableMeta(this.model).fields).serialize, filters, true)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true;
                    if (infiniteScroll) {
                        infiniteScroll.target.complete();
                    }
                }),
                map((resp) => {
                    resp.data = resp.data.map(item => {
                        item.repr = this.base.getRepr(item, this.funcRepr);
                        for (const col of this.listDisplay.filter(f => f.isChecked)) {
                            const fieldChoices: any = {}; fieldChoices[col.name] = col.choices;  
                            item[col.name + '__repr'] = this.templateService.representCell(col, item, fieldChoices);
                        }
                        return item;
                    });
                    return resp;
                })
            )
            .subscribe(resp => {
                if (resp && resp.data) {
                    if (!this.data || this.page === 1) {
                        this.data = resp.data;
                    } else {
                        for (const item of resp.data) {
                            this.data.splice(this.data.length, 0, item);
                        }
                    }
                    this.hasNext = resp.has_next;
                    if (this.hasNext) { 
                        this.page ++; 
                    } 
                }
            });
    }

    doSearch(event) {
        this.page = 1;
        this.search.value = event.detail.value;
        this.loadData();             
    }

    async openListDisplay(ev) {
        this.isChanged = true;

        const listDisplay = this.base.getListDisplay(ListDisplay.copy(this.listDisplay), this.metaFields);

        const popover = await this.popoverCtrl.create({
            component: ListDisplayPage,
            componentProps: {
                listDisplay, 
                tab: {tab: 3.1}, 
                showHeader: true,
                hideShowNewLine: true,
                hideShowFieldName: true
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((_: any) => {
            this.listDisplay = listDisplay.filter(f => f.isChecked);
            this.form.data.listDisplay = this.listDisplay.map(f => f.serialize());
            this.page = 1;
            this.loadData();
        });
        return await popover.present();
    }

    addOrder(col: ListDisplay) {
        this.isChanged = true;

        const c = this.orderBy.find(i => i.name === col.name);
        if (!c) {
            this.orderBy.splice(this.orderBy.length, 0, new OrderBy(col.name, col.verboseName, true, true, false));
        } else {
            c.isAsc = !c.isAsc;
            c.isChecked = true;
        }
        this.form.data.orderBy = this.orderBy.filter(f => f.isChecked).map(f => f.serialize());
        this.page = 1;
        this.loadData();
    }

    async openOrderBy(ev) {
        this.isChanged = true;
        const orderBy = this.base.getOrderBy(OrderBy.copy(this.orderBy), this.metaFields);

        const popover = await this.popoverCtrl.create({
            component: OrderByPage,
            componentProps: {
                orderBy, 
                showHeader: true,
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((_: any) => {
            this.orderBy = orderBy.filter(i => i.isChecked);
            this.form.data.orderBy = this.orderBy.map(f => f.serialize());
            this.page = 1;
            this.loadData();
        });
        return await popover.present();
    }

    async openFilters(ev) {
        this.isChanged = true;

        const filters: BoardFilter[] = (this.form.data.filters || []).map(f => BoardFilter.fromJson(f))
        const popover = await this.popoverCtrl.create({
            component: FilterPage,
            componentProps: {
                filters: filters, 
                showTitle: true,
                showHiddenButtons: false,
                fields: this.metaFields,
                showCloseButton: true,
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((_: any) => {
            this.form.data.filters = BoardFilter.toList(filters);
            this.page = 1;
            this.loadData();
        });
        return await popover.present();
    }

    async openSetting(ev) {
        this.isChanged = true;

        const popover = await this.popoverCtrl.create({
            component: ChoiceSettingPage,
            componentProps: {form: this.form},
            cssClass: 'choice-setting-popover',
            showBackdrop: false,
            event: ev,
        });
        return await popover.present();
    }

    create() {
        const item = {};
        for (const f of this.filters) {
            if (f.name && f.equal === Equal.EQ && f.obj) {
                item[f.name] = f.obj;
            }
        }
        this.base.openForm(FormPage, {
            item: {openedItem: this.base.copy(item)},
            form: this.createdForm,
            returnCreated: true,
        }, (detail) => {
            if (detail && detail.data && detail.data.item) {
                this.choose(detail.data.item);
            }
        })
    }

    private fixForm() {
        if (!this.form) {
            this.form = {name: '', is_default: false};
        }
        if (!this.form.data) {
            this.form.data = {orderBy: [], listDisplay: [], filters: []};
        }
    }

    private async alertSaveForm(yes: string, no: string, saveForm: string, createForm: string) {
        const alert = await this.alertCtrl.create({
            header: this.form[this.formPkField] ? saveForm : createForm,
            buttons:  [
                {
                    text: no,
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (_) => {}
                }, 
                {
                    text: yes,
                    handler: (_) => {
                        this.saveForm();
                    }
                }
            ]
        });
        await alert.present();
    }

    private saveForm() {
        this.form.data.table = this.model;
        if (this.form[this.formPkField]) {
            this.base.updateTable({name: this.form.name, data: this.form.data,  
                                   is_default: this.form.is_default}, this.form[this.formPkField], this.form.edited, 'Form')
            .subscribe(resp => {
                if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                }
            });
        } else {
            this.base.createTable({name: this.form.name, data: this.form.data, table: this.model, 
                                  is_default: this.form.is_default, type: 1}, 'Form').subscribe(resp => {
                if (resp && !resp[this.formPkField]) {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        }
    }
}
