import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Base } from 'src/app/classes/base';
import { BaseService } from '../../services/base.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.page.html',
    styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {

    @ViewChild("comment") comment;
    @Input() table: string;
    @Input() item: any;
    @Input() itemChanged: any;
    @Input() defaultCalc: string;
    @Input() chatInfoFunc: string;
    messageStyle = {};
    user: any;
    subscription: any;
    newComment = null;
    items = [];
    userPk: any;
    ticketPk: any;
    bot: string;
    chatId: number;

    constructor(private base: BaseService) { }

    ngOnInit() {
        this.userPk = this.base.getTablePk('User');
        this.ticketPk = this.base.getTablePk(this.table);
        if (this.chatInfoFunc) {
            const val = this.base.getCalcRepr({user: this.user, item: this.item}, null, this.chatInfoFunc);
            if (val) {
                const chat = JSON.parse(val);
                if (chat.chatId && chat.bot) {
                    this.chatId = chat.chatId;
                    this.bot = chat.bot;
                } 
            }
        }
        this.user = Base.getCurrentUser();
        if (this.itemChanged) {
            this.subscription = this.itemChanged.subscribe(_ => {
                this.changeItems();
            });
        } else {
            this.changeItems();
        }
    }

    changeComment(ev) {
        if (this.comment && this.comment.el && this.comment.el.clientHeight && this.comment.el.clientHeight > 30) {
            this.messageStyle['height'] = `calc(100% - ${(this.comment.el.clientHeight + 45)}px)`
        } else {
            this.messageStyle = {};
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getComments(item) {
        if (!item || !item.chat) {
            return [];
        }

        const comments = []; let lastSepDate = null;
        for (const v of item.chat) {
            v.sep_date = null; const sepDate = this.base.getDateSeparator(v.created_at);
            if (!comments.length || lastSepDate !== sepDate) {
                v.sep_date = sepDate;
                lastSepDate = sepDate.slice();
            }
            v.dateFull = this.base.getMessageDate(v.created_at);
            v.dateShort = v.dateFull.slice(6);
            comments.push(v);
        }
        return comments;
    }

    addComment(code, isShift?) {
        if (isShift || code !== 13) {
            return;
        }

        const comment = this.newComment.slice();
        this.newComment = null;
        let obj = {
            text: comment, 
            is_read: false, 
            is_sent: false,
            is_answer: true,
        };
        let userKey = 'user_id'; let ticketKey = 'ticket_id';
        const table = this.base.getTableMeta(this.table);
        if (table && table.fields) {
            let f = table.fields.find(i => i.name === 'user');
            if (f && f.name) {
                userKey = f.name;
            }
            f = table.fields.find(i => i.name === 'ticket');
            if (f && f.name) {
                ticketKey = f.name;
            } 
        }
        
        obj[userKey] = this.user[this.userPk];
        obj[ticketKey] = this.item[this.ticketPk];

        if (this.defaultCalc) {
            const val = this.base.getCalcRepr({user: this.user, item: this.item, comment}, null, this.defaultCalc);
            if (val) {
                obj = JSON.parse(val);
            }
        }
        
        this.base.createTable(obj, this.table).subscribe(resp => {
            if (!(resp && resp[this.ticketPk])) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    changeItems() {
        this.items = this.getComments(this.item);
    }

    loadFile(ev) {
        this.newComment = ev.url;
        this.addComment(13, false);
    }
}
