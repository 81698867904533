import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Base } from '../classes/base';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private base: BaseService, 
                private http: HttpClient) { }

    getBackground(token) {
        const user = Base.getCurrentUser()[this.base.getTablePk('User')];
        return this.http.get<any>(token + '$' + `${this.base.getRootUrl()}portal/api/chat-background/?user=${user}`)
         .pipe(
            tap(_ => this.base.log('fetched chat background')),
            catchError(this.base.handleError('getBackground', this.base.handlerBlank))
       );
    }

    createBackground(pk): Observable<any> {
        const data = {
            user: Base.getCurrentUser()[this.base.getTablePk('User')],
            background: pk
        };
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/chat-background/`, data)
        .pipe(
            tap(_ => this.base.log('create chat-backgound')),
            catchError(this.base.handleError('createBackground'))
        );
    }

    updateBackground(pk, edited, background): Observable<any> {
        const data = {
            edited,
            background
        };
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/chat-background/${pk}/`, data)
        .pipe(
            tap(_ => this.base.log('create chat-backgound')),
            catchError(this.base.handleError('createBackground'))
        );
    }

    getMessages(page, pages, search, token?) {
        let params = '';
        if (search) {
            params = `&s=${search}`;
        }
        return this.http.get<any>((token ? token + '$' : '') + 
                                  `${this.base.getRootUrl()}portal/messages?page=${page}&pages=${pages}${params}`)
         .pipe(
            tap(_ => this.base.log('fetched messages')),
            catchError(this.base.handleError('getMessages', this.base.handlerBlank))
       );
    }

    getMessage(pk) {
        return this.http.get<any>(`${this.base.getRootUrl()}portal/messages?uuid=${pk}`)
         .pipe(
            tap(_ => this.base.log('fetched message')),
            catchError(this.base.handleError('getMessage', this.base.handlerBlank))
       );
    }

    getReplyMessage() {
        return this.http.get<any>(`${this.base.getRootUrl()}portal/messages`)
         .pipe(
            tap(_ => this.base.log('fetched reply message')),
            catchError(this.base.handleError('getReplayMessage', this.base.handlerBlank))
       );
    }

    createMessage(chatId, callbackData, messageId, text, sticker): Observable<any> {
        let data = null;
        if (callbackData) {
            data = {
                callback_query : {
                    message: {
                        message_id: messageId,
                        chat: {
                            id: chatId,
                            first_name: Base.getCurrentUser().name
                        },
                        text,        
                    },
                    data: callbackData
                }
            };
        } else if (text) {
            data = {
                message: {
                    message_id: messageId,
                    chat: {
                        id: chatId,
                        first_name: Base.getCurrentUser().name
                    },
                    text,        
                },
            };
        } else if (sticker) {
            data = {
                message: {
                    message_id: messageId,
                    chat: {
                        id: chatId,
                        first_name: Base.getCurrentUser().name
                    },
                    sticker: {
                        file_id: sticker
                    },        
                },
            };
        }

        return this.http.post<any>(`${this.base.getRootUrl()}portal/messages`, data)
        .pipe(
            tap(_ => this.base.log('create message')),
            catchError(this.base.handleError('createMessage'))
        );
    }

    readMessage(pk): Observable<any> {
        const data = {
            uuid: pk
        };
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/messages`, data)
        .pipe(
            tap(_ => this.base.log('read message')),
            catchError(this.base.handleError('readMessage'))
        );
    }

}
