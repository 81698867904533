import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UserPhotoPageModule } from '../user-photo/user-photo.module';
import { ChatPage } from './chat.page';
import { TranslateModule } from '@ngx-translate/core';
import { UploadFileBtnPageModule } from '../upload-file-btn/upload-file-btn.module';
import { CommentPageModule } from '../comment/comment.module';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        UserPhotoPageModule,
        RouterModule.forChild(routes),
        TranslateModule,
        UploadFileBtnPageModule,
        CommentPageModule
    ],
    declarations: [ChatPage],
    exports: [ChatPage]
})
export class ChatPageModule {}
