import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { finalize } from 'rxjs/operators';
import { Constants } from '../classes/app.constants';

@Component({
    selector: 'app-task-participants',
    templateUrl: './task-participants.page.html',
    styleUrls: ['./task-participants.page.scss'],
})
export class TaskParticipantsPage implements OnInit {

    @ViewChild('searchInput') searchInput: any;
    users = [];
    search = '';
    page = 1;
    scrollComplete = true;
    hasNext = false;

    @Input() addRemoveUserFunc: any;
    @Input() participants = [];
    @Input() subject: any;
    
    subscription: any;
    userPk = '';

    constructor(private modalCtrl: ModalController,
                private base: BaseService) { }

    ngOnInit() {
        this.userPk = this.base.getTablePk('User');
        this.loadUsers();

        this.subscription = this.subject.subscribe(item => {
            if (item && item.participants) {
                this.participants = item.participants;
            }
            this.refreshParticipants();
        });

        setTimeout(() => {
            this.searchInput.setFocus();
        }, 500);
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    close() {
        this.modalCtrl.dismiss({});
    }

    refreshParticipants() {
        for (const u of this.users) {
            u.checked = (this.participants || []).find(p => p.user && p.user[this.userPk] === u[this.userPk]) ? true : false;
        }
    }

    loadUsers() {
        let params = '';
        if (this.search) {
            this.page = 1;
            params += '&s=' + this.search;
        }

        this.scrollComplete = false;
        this.base.getData('User', this.page, Constants.ON_PAGE, params)
        .pipe(
            finalize(() => {
                this.scrollComplete = true;
            })
        )
        .subscribe(resp => {
            if (this.page === 1) {
                this.users = [];
            }
            if (resp && resp.data) {
                for (const t of resp.data) {
                    this.users.splice(this.users.length, 0, t);
                }
                this.hasNext = resp.has_next;
                if (this.hasNext) {
                    this.page ++; 
                } 
            }
            this.refreshParticipants();
        });
    }

    scrollUsers(event) {
        if (this.hasNext && this.scrollComplete 
            && event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.loadUsers();
        }
    }

    addRemoveUser(user) {
        this.addRemoveUserFunc.next(user);
    }
}
