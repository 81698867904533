import { Component, ViewChild, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

@Component({
    selector: 'app-scanner',
    templateUrl: './scanner.page.html',
    styleUrls: ['./scanner.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScannerPage implements OnInit {

    @ViewChild(BarecodeScannerLivestreamComponent)
    barecodeScanner: BarecodeScannerLivestreamComponent;

    @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
    @Input() result: any;
    @Input() subject: any;
    @Input() isQr = false;
    subscription: any;

    ngOnInit() {
        if (this.subject) {
            this.subscription = this.subject.subscribe(resp => {
                if (resp) {
                    setTimeout(() => {
                        if (this.isQr) {
                            this.scan();
                        } else {
                            this.barecodeScanner.start();
                        }
                    }, 500);
                }
            });
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    scan() {
        this.qrScannerComponent.getMediaDevices().then(devices => {
            const videoDevices: MediaDeviceInfo[] = [];
            for (const device of devices) {
                if (device.kind.toString() === 'videoinput') {
                    videoDevices.push(device);
                }
            }
            if (videoDevices.length > 0) {
                let choosenDev;
                for (const dev of videoDevices) {
                    if (dev.label.includes('front')) {
                        choosenDev = dev;
                        break;
                    }
                }
                if (choosenDev) {
                    this.qrScannerComponent.chooseCamera.next(choosenDev);
                } else {
                    this.qrScannerComponent.chooseCamera.next(videoDevices[0]);
                }
            }
        });
 
        this.qrScannerComponent.capturedQr.subscribe(result => {
            if (this.result) {
                this.result.next(result);
            }
        });
    }

    onValueChanges(result) {
        const code = result.codeResult.code;
        if (this.result) {
            this.result.next(code);
        }
    }

    onStarted(started) {
    }
}
