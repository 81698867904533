import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { HierarchyRowPage } from './hierarchy-row.page';
import { TranslateModule } from '@ngx-translate/core';
import { CellPageModule } from '../cell/cell.module';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        CellPageModule,
    ],
    declarations: [HierarchyRowPage],
    exports: [HierarchyRowPage]
})
export class HierarchyRowPageModule {}
