import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Equal } from '../classes/enums';
import { Base } from '../classes/base';

@Injectable({
    providedIn: 'root'
})
export class ViseStatusService {

    constructor(private http: HttpClient, 
                private base: BaseService, 
                private alertCtrl: AlertController, 
                private modalCtrl: ModalController,
                private trans: TranslateService) { }

    getVises(document, params = ''): Observable<any> {
        return this.http.get<any[]>(`${this.base.getRootUrl()}portal/api/vise/?document=${document}&page=1&pages=500${params}`)
        .pipe(
            tap(_ => this.base.log('fetched vises')),
            catchError(this.base.handleError('getVises', []))
        );
    }

    createVise(data): Observable<any> {
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/vise/`, data)
        .pipe(
            tap(_ => this.base.log('create vise')),
            catchError(this.base.handleError('createVise'))
        );
    }

    updateVise(data, pk, edited): Observable<any> {
        data.edited = edited;
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/vise/${pk}/`, data)
        .pipe(
            tap(_ => this.base.log('update vise')),
            catchError(this.base.handleError('updateVise'))
        );
    }
    
    deleteVise(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/vise/${pk}/`)
        .pipe(
            tap(_ => this.base.log('update vise')),
            catchError(this.base.handleError('updateVise'))
        );
    }

    notif(document): Observable<any> {
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/notif`, {document})
        .pipe(
            tap(_ => this.base.log('send notif')),
            catchError(this.base.handleError('send notif'))
        );
    } 

    checkApproveVise(pk, doc, presentAlert, onSuccess) {
        const user = Base.getCurrentUser();
        const userPk = this.base.getTablePk('User');
        const visePk = this.base.getTablePk('Vise');
        this.getVises(doc, `&filter_fields=${JSON.stringify([{name: 'approve', equal: Equal.EQ, value: false}])}`).subscribe(resp => {
            if (resp && resp.data && resp.data.length) {
                const vise = resp.data.find(i => i[visePk] === pk);
                if (!vise) {
                    if (presentAlert) { 
                        this.base.sendToast(this.trans.instant('vise-not-found'));
                    }
                } else if (vise.queue !== resp.data[0].queue) {
                    if (presentAlert) {
                        this.base.sendToast(this.trans.instant('your-queue-not-now'));    
                    }
                } else if (!vise.user || (vise.user && vise.user[userPk] !== user[userPk])) {
                    if (presentAlert) {
                        this.base.sendToast(this.trans.instant('vise-not-found'));
                    }
                } else { 
                    onSuccess(vise);
                }
            }
        });
    }

    approveVise(pk, doc, onSuccess) {
        const visePk = this.base.getTablePk('Vise');
        this.checkApproveVise(pk, doc, true, (vise) => {
            this.updateVise({approve: true}, vise[visePk], vise.edited).subscribe(resp => {
                if (resp && resp[visePk]) {
                    this.base.sendToast(this.trans.instant('document-vised'), 2000);
                    onSuccess();
                } else if (resp) {
                    let message = this.trans.instant('error');
                    if (resp.erorr) {
                        message += `: ${this.base.getError(resp.error)}`;
                    }
                    this.base.sendToast(message);
                }
            });
        });
    }

    checkRejectVise(doc, presentAlert, onSuccess) {
        const user = Base.getCurrentUser();
        const userPk = this.base.getTablePk('User');
        this.getVises(doc, `&filter_fields=${JSON.stringify([{name: 'type', equal: Equal.NOT, value: 0}])}`).subscribe(resp => {
            if (resp && resp.data && resp.data.length) {
                if (resp.data.filter(i => i.user && i.user[userPk] === user[userPk]).length) {
                    if (resp.data.filter(i => !i.approve).length) {
                        onSuccess();
                    } else {
                        if (presentAlert) {
                            this.base.sendToast(this.trans.instant('document-closed'));
                        }
                    }
                } else {
                    if (presentAlert) {
                        this.base.sendToast(this.trans.instant('vise-not-found'));
                    }
                }
            } else {
                if (presentAlert) { 
                    this.base.sendToast(this.trans.instant('vise-not-found'));
                }
            }
        });
    }

    async rejectVise(doc, table, onSuccess) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-reject-reason') ,
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                },
            ],
            buttons: [{
                text: this.trans.instant('ok'),
                handler: (params) => {
                    if (params.name) {
                        const documentTypes = this.base.getDocumentTypesDict(this.base.getLocalMeta());
                        this.checkRejectVise(doc, true, () => {
                            const data = {
                                user: Base.getCurrentUser()[this.base.getTablePk('User')],
                                document: doc,
                                document_type: documentTypes[table],
                                comment: params.name,
                                queue: 0,
                                type: 7,
                                approve: true
                            };
                            this.createVise(data).subscribe(resp => {
                                if (resp && resp[this.base.getTablePk('Vise')]) {
                                    this.base.sendToast(this.trans.instant('document-rejected'), 2000);
                                    onSuccess();
                                } else if (resp) {
                                    let message = this.trans.instant('error');
                                    if (resp.erorr) {
                                        message += `: ${this.base.getError(resp.error)}`;
                                    }
                                    this.base.sendToast(message);
                                }
                            });
                        });
                    }
                }
            }]
        });
        await alert.present();
    }

    async addComment(doc, table) {
        const visePk = this.base.getTablePk('Vise');
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-comment'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                },
            ],
            buttons: [{
                text: this.trans.instant('ok'),
                handler: (params) => {
                    if (params.name) {
                        const documentTypes = this.base.getDocumentTypesDict(this.base.getLocalMeta());
                        const data = {
                            user: Base.getCurrentUser()[this.base.getTablePk('User')],
                            document: doc,
                            document_type: documentTypes[table],
                            comment: params.name,
                            queue: 0,
                            type: 0,
                            approve: true
                        };
                        this.createVise(data).subscribe(resp => {
                            if (resp && !resp[visePk]) {
                                let message = this.trans.instant('error');
                                if (resp.error) {
                                    message += `: ${this.base.getError(resp.error)}`;
                                }
                                this.base.sendToast(message);         
                            } else if (resp && resp[visePk]) {
                                this.base.sendToast(this.trans.instant('comment-added'), 2000);
                            }
                        });
                    }
                }
            }]
        });
        await alert.present();
    }

    getViseType(vise) {
        if (vise.type === 0) {
            return this.trans.instant(vise.approve ? 'commented' : 'comment-do');
        } else if (vise.type === 1) {
            return this.trans.instant(vise.approve ? 'initiated' : 'initiate');
        } else if (vise.type === 2) {
            return this.trans.instant(vise.approve ? 'agreeded' : 'agree');
        } else if (vise.type === 3) {
            return this.trans.instant(vise.approve ? 'approveded' : 'approve');
        } else if (vise.type === 4) {
            return this.trans.instant(vise.approve ? 'seeded' : 'see');
        } else if (vise.type === 5) {
            return this.trans.instant(vise.approve ? 'completeded' : 'complete');
        } else if (vise.type === 6) {
            return this.trans.instant(vise.approve ? 'verified' : 'verify');
        } else if (vise.type === 7) {
            return this.trans.instant(vise.approve ? 'rejecteded' : 'reject');
        } else if (vise.type === 8) {
            return vise.name;
        }
        return vise.type;
    }

    userVise(vises, currentUser, isType) {
        const userPk = this.base.getTablePk('User');
        const userVise = vises.filter(i => i.user && i.user[userPk] === currentUser[userPk] && !i.approve);
        if (userVise.length) {
            const queues = vises.filter(i => !i.approve);
            if (queues.length && userVise[0].queue === queues[0].queue) {
                const vise = userVise[0];
                return isType ? this.getViseType(vise) : vise;
            }
        }
        return null;
    }

    getMinQueue(vises) {
        if (vises) {
            const v = vises.filter(i => !i.approve);
            if (v.length) {
                return v.queue;
            }
        }
        return null;
    }

    notifItem(doc, item) {
        if (doc && this.getMinQueue(item.vises) !== null) {
            this.notif(doc).subscribe(resp => {
                if (resp && resp.cnt) {
                    this.base.sendToast(this.trans.instant('sent-messages', {cnt: resp.cnt}), 2000);
                } else if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                }
            });
        }
    }

    async sendTo(item, ChoicesPage, doc, table) {

        const inputs = [];
        const type2 = item.vises.filter(i => !i.approve && [2, 3].indexOf(i.type)    !== -1);
        if (type2 && type2.length) {
            inputs.push({
                name: 'type',
                type: 'radio',
                label: this.trans.instant('send-to-agree'),
                value: `2$${type2[0].queue - 1}`
            });
        }
        const type41 = item.vises.filter(i => !i.approve && i.type === 5);
        if (type41 && type41.length) {
            inputs.push({
                name: 'type',
                type: 'radio',
                label: this.trans.instant('see-after-completed'),
                value: `4$${type41[type41.length - 1].queue + 1}`
            });
        }
        const type42 = item.vises.filter(i => !i.approve && i.type === 3);
        if (type42 && type42.length) {
            inputs.push({
                name: 'type',
                type: 'radio',
                label: this.trans.instant('see-after-approved'),
                value: `4$${type42[type42.length - 1].queue + 1}`
            });
        }

        if (inputs.length === 1) {
            const v = inputs[0].value.split('$');
            return this.askUser(parseInt(v[0], 10), parseInt(v[1], 10), ChoicesPage, doc, table);
        } else if (!inputs.length) {
            return this.base.sendToast(this.trans.instant('cannot-add-vise'));
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-vise-type'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            const v = params.split('$');
                            return this.askUser(parseInt(v[0], 10), parseInt(v[1], 10), ChoicesPage, doc, table);
                        } 
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async askUser(type, queue, ChoicesPage, doc, table) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'User',
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.item) {
                const documentTypes = this.base.getDocumentTypesDict(this.base.getLocalMeta());
                const data = {
                    user: detail.data.item[this.base.getTablePk('User')],
                    document: doc,
                    document_type: documentTypes[table],
                    queue,
                    type,
                    approve: false
                };
                this.createVise(data).subscribe(resp => {
                    if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
            }
        });
        return await modal.present();
    }
}
