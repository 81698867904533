import { Base } from "./base";
import { Choice } from "./board-filters";
import { FieldType } from "./enums"

export class ListDisplay {
    public styles: any;

    constructor(public name: string, 
                public verboseName: string, 
                public width: number, 
                public type: FieldType, 
                public repr: string,
                public model: string,
                public showTitle: boolean,
                public isFlex: boolean,
                public lineHeight: string,
                public float: string,
                public dateTime: string,
                public fontWeight: string,
                public fontSize: string,
                public color: string,
                public backgroundColor: string,
                public isDataColor: boolean,
                public verboseName2: string,
                public isCalc: boolean,
                public asImage: boolean,
                public asBarcode: boolean,
                public altImage: string,
                public foreignImg: string,
                public parseJson: string,
                public digits: number,
                public isChecked: boolean,
                public flex: boolean,
                public relatedField: string,
                public textStyle: string,
                public choices: Choice[],
                public isSeparator: boolean,
                public styleFunc: string) {

        if (!this.width) {
            this.width = 100;
        }
        this.refresh();
    }

    refresh() {
        this.styles = null;
        if (this.styleFunc) {
            try {
                this.styles = JSON.parse(this.styleFunc);
            } catch (e) {
                console.error(this.styleFunc, e);
            }
        }
    }

    static fromJson(f: any): ListDisplay {
        return new ListDisplay(f.name, f.verbose_name, f.width || 0, f.type, f.repr || '', f.related_model,
                               f.showTitle || false, f.isFlex || false, f.lineHeight || '1', f.float || 'left',
                               f.dateTime || 'datetime', f.fontWeight || 'normal', f.fontSize || '14px', f.color || '',
                               f.backgroundColor || '', f.isDataColor || false, f.verbose_name2 || '', 
                               f.isCalc || false, f.asImage || false, f.asBarcode || false, f.altImage || '',
                               f.foreignImg || '', f.parseJson || '', f.digits || 0, (f.isChecked === undefined ? true : f.isChecked) || false, f.flex, f.related_field, f.textStyle || 'initial', 
                               Choice.fromJson(f.choices), false, f.styleFunc);
    }

    serialize(): any {
        return { 
            name: this.name, 
            verbose_name: this.verboseName, 
            width: this.width, 
            type: this.type, 
            repr: this.repr,
            related_model: this.model,
            showTitle: this.showTitle,
            isFlex: this.isFlex,
            lineHeight: this.lineHeight,
            float: this.float,
            dateTime: this.dateTime,
            fontWeight: this.fontWeight,
            fontSize: this.fontSize,
            color: this.color,
            backgroundColor: this.backgroundColor,
            isDataColor: this.isDataColor,
            verbose_name2: this.verboseName2,
            isCalc: this.isCalc,
            asImage: this.asImage,
            asBarcode: this.asBarcode,
            altImage: this.altImage,
            foreignImg: this.foreignImg,
            parseJson: this.parseJson,
            digits: this.digits,
            isChecked: this.isChecked,
            flex: this.flex,
            related_field: this.relatedField,
            textStyle: this.textStyle,
            choices: Choice.serialize(this.choices),
            styleFunc: this.styleFunc
        }
    }

    static toList(list: ListDisplay[]): any[] {
        if (!list) {
            return [];
        }
        return list.map(f => f.serialize() )
    }

    static copy(list: ListDisplay[]): ListDisplay[] {
        return Base.copy(ListDisplay.toList(list)).map(f => ListDisplay.fromJson(f));
    }
}

