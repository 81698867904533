import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { StyleWidgetPage } from './style-widget.page';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule
    ],
    declarations: [StyleWidgetPage],
    exports: [StyleWidgetPage]
})
export class StyleWidgetPageModule {}
