import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormFieldPage } from './form-field.page';
import { UserPhotoPageModule } from '../user-photo/user-photo.module';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TranslateModule } from '@ngx-translate/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { ForeignPageModule } from '../json-schema-widgets/foreign/foreign.module';
import { InputPageModule } from '../json-schema-widgets/input/input.module';
import { DatePageModule } from '../json-schema-widgets/date/date.module';
import { CheckboxPageModule } from '../json-schema-widgets/checkbox/checkbox.module';
import { DatetimePageModule } from '../json-schema-widgets/datetime/datetime.module';
import { SelectPageModule } from '../json-schema-widgets/select/select.module';
import { MapPageModule } from '../map/map.module';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        UserPhotoPageModule,
        DatePickerModule,
        RouterModule.forChild(routes),
        TranslateModule,
        NgxBarcodeModule,
        MaterialDesignFrameworkModule,
        ForeignPageModule,
        InputPageModule,
        DatePageModule,
        DatetimePageModule,
        SelectPageModule,
        CheckboxPageModule,
        MapPageModule
    ],
    declarations: [FormFieldPage],
    exports: [FormFieldPage]
})
export class FormFieldPageModule {}
