import * as uuid from 'uuid';

export enum ButtonAction {
    UPDATE,
    DELETE
}

export class BoardButton {
    public pressed: boolean;
    public success: number = 0;
    
    constructor(public id: string,
                public title: string,
                public action: ButtonAction,
                public func: string,
                public icon: string) {
        
        if (!this.id) {
            this.id = uuid.v4();
        }
    }

    static fromJson(d: any): BoardButton[] {
        const list: BoardButton[] = [];
        if (d && d.length) {
            for (const row of d) {
                list.push(new BoardButton(row.id, row.title, row.action, row.func, row.icon));
            }
        }
        return list;
    }

    static serialize(list: BoardButton[]): any[] {
        if (!list) {
            return [];
        }
        return list.map(c => {
            return {id: c.id, title: c.title, action: c.action, func: c.func, icon: c.icon};
        });
    }
}