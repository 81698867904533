import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ColorPickerPage } from '../color-picker/color-picker.page';


@Component({
    selector: 'app-color-picker-field',
    templateUrl: './color-picker-field.page.html',
    styleUrls: ['./color-picker-field.page.scss'],
})
export class ColorPickerFieldPage implements OnInit {

    @Input() obj: any;
    @Input() field: string;
    @Input() onlyIcon: boolean;
    @Input() title = '';
    @Input() isPicker = true;
    @Input() closeAfterSelect = true;

    constructor(private popoverCtrl: PopoverController) { }

    ngOnInit() {
    }

    async showColorPicker(ev) {
        const popover = await this.popoverCtrl.create({
            component: ColorPickerPage,
            componentProps: {color: this.obj, isPicker: this.isPicker, field: this.field, onlyIcon: this.onlyIcon, 
                             closeAfterSelect: this.closeAfterSelect},
            cssClass: 'color-picker-popover',
            showBackdrop: false,
            event: ev
        });
        return await popover.present();  
    }
}
