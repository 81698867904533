import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../services/base.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private router: Router, 
                private trans: TranslateService,
                private base: BaseService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let token = this.base.getToken();
        if (request.url.split('$').length === 2) {
            token = request.url.split('$')[0];
            request = request.clone({
                url: request.url.split('$')[1],
            });
        }
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Token ' + token
                }
            });
        }
        if (!request.headers.has('Content-Type') && !request.url.endsWith('image-upload') && !request.url.endsWith('upload-file') && !request.url.startsWith("https://upload.woki.one")
            && !request.url.endsWith('upload') ) {
            request = request.clone({
                setHeaders: {
                    'content-type': 'application/json'
                }
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json')
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {}
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    if (error.error.success === false) {
                        this.base.sendToast('login-fail');
                    } else if (request.url.endsWith('chat-background/')) {
                        console.log('chat-background', this.base.getToken());
                    } else {
                        console.log(request.url);
                        this.router.navigate(['login']);
                    }
                }
                if (error.status === 0 && !request.url.endsWith('portalfield/') ) {
                    this.base.sendToast(this.trans.instant('internet-error'));
                }
                return throwError(error);
            }));
    }
}
