import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { UserPhotoPageModule } from '../components/user-photo/user-photo.module';
import { IonicModule } from '@ionic/angular';
import { VisePage } from './vise.page';
import { TranslateModule } from '@ngx-translate/core';
import { CommentPageModule } from '../components/comment/comment.module';
import { UploadFileBtnPageModule } from '../components/upload-file-btn/upload-file-btn.module';

const routes: Routes = [
    {
        path: 'vise',
        component: VisePage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        UserPhotoPageModule,
        TranslateModule,
        CommentPageModule,
        UploadFileBtnPageModule
    ],
    declarations: [VisePage],
    exports: [VisePage],
})
export class VisePageModule {}
