import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TemplateService } from '../services/template.service';
import { BaseService } from '../services/base.service';
import { MetaService } from '../services/meta.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Equal } from '../classes/enums';
import { Board } from '../classes/board';
import { Base } from '../classes/base';

@Component({
    selector: 'app-service',
    templateUrl: './service.page.html',
    styleUrls: ['./service.page.scss'],
})
export class ServicePage implements OnInit {

    @Input() hideAddButton: any;
    @Input() tables: any;
    user = null;
    templates = [];
    templatePk: any;

    constructor(public authService: AuthService, 
                private router: Router, 
                private templateService: TemplateService,
                private base: BaseService, 
                private alertCtrl: AlertController,
                private meta: MetaService,
                private trans: TranslateService) {}

    ngOnInit() {
        this.user = Base.getCurrentUser();
        this.templatePk = this.base.getTablePk('Template');
        this.getTemplates();
    }

    getTemplates() {
        if (typeof(this.tables) === 'string' && this.tables) {
            this.tables = this.tables.split(',');
        }
        const tt = [];
        if (this.tables && this.tables.length) {
            for (const t of this.tables) {
                tt.push({name: 'table', equal: Equal.EQ, value: t});
            }
        } else {
            this.tables = [];
        }

        this.templateService.getTemplates(null, 1, 100, null, true, tt).pipe(
            map((resp) => {
                if (resp.data && resp.data.length) {
                    for (const template of resp.data) {
                        template.bg = this.base.getBackground(template.background);
                    }
                }
                return resp;
            })
        ).subscribe(resp => {
            if (resp.data) { 
                const tables = [];
                for (const el of resp.data) {
                    const t = tables.find(item => item.table === el.table);
                    if (!t) {
                        tables.push({table: el.table, data: [el], title: this.meta.getTitlePluralMeta(el.table)});
                    } else {
                        t.data.push(el);
                    }
                }
                const other = [];
                for (const t of tables) {
                    if (t.data.length >= 3) {
                        this.templates.splice(this.templates.length, 0, t);
                    } else {
                        for (const j of t.data) {
                            other.push(j);
                        }
                    }
                }
                if (this.templates.length && other.length) {
                    this.templates.splice(this.templates.length, 0, {table: null, data: other, title: this.trans.instant('desk-others')});
                } else if (other.length) {
                    this.templates.splice(this.templates.length, 0, {table: null, data: other, title: this.trans.instant('desk-private')});
                }
            }
            if (!this.templates.length) {
                this.templates.splice(this.templates.length, 0, {table: null, data: [], title: this.trans.instant('desk-private')});
            }
        });
    }

    openTemplate(board: Board, isNew: boolean = false, template?: any) {
        this.base.board = board || Board.fromJson(template);
        this.base.board.isNew = isNew;
        this.router.navigate(['list/model', this.base.board.table, this.base.board.pk]);
    }

    async createTemplate() {
        let inputs = [];
        const tables = this.meta.getTables();
        for (const key of Object.keys(tables)) {
            if (!this.tables || !this.tables.length || this.tables.find(i => i === key)) {
                inputs.push({
                    name: 'table',
                    type: 'radio',
                    label: tables[key],
                    value: key
                });
            }
        }
        if (inputs.length === 1) {
            return this.alertName(inputs[0].value);
        } else if (!inputs.length) {
            return this.base.sendToast(this.trans.instant('desk-type-unknown'));
        }
        inputs = this.base.sort(inputs, 'label');

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-type'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.alertName(params);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async alertName(table) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-desk-name'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: this.trans.instant('name')
                }
            ],
            buttons: [
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params.name) {
                            this.create(table, params.name);
                        }
                    }
                }
            ]
        });
        await alert.present();
    }

    create(table, name) {
        const board = Board.fromJson({table, name, fields: this.meta.getMetaFields(table)});
        this.templateService.getBackgrounds(false, 1, 250, null).subscribe(resp => {
            if (resp && resp.data && resp.data.length) {
                board.background = resp.data[Math.floor(Math.random() * resp.data.length)];
            } 
            this.templateService.createTemplate(board).subscribe(temp => {
                if (temp && temp[this.templatePk]) {
                    board.edited = temp.edited;
                    board.pk = temp[this.templatePk];
                    board.user = Base.getCurrentUser();
                    this.openTemplate(board, true);
                }
            });
        });
    }
}
