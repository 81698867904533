import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from '../services/base.service';
import { TemplateService } from '../services/template.service';
import { Constants } from '../classes/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { GraphSettingPage } from '../components/graph-setting/graph-setting.page';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ChartType, Equal, FieldType, FunctionType, Interval } from '../classes/enums';
import { BoardFilter, Choice } from '../classes/board-filters';
import { Board } from '../classes/board';
import { BehaviorSubject } from 'rxjs';
import { Chart, ChartColor } from '../classes/chart';
import { MetaService } from '../services/meta.service';
import { Base } from '../classes/base';

@Component({
    selector: 'app-info-graph',
    templateUrl: './info-graph.page.html',
    styleUrls: ['./info-graph.page.scss'],
})
export class InfoGraphPage implements OnInit {

    @Input() table: string;
    @Input() showGraph: any;
    @Input() templateVal: any;
    @Input() board = Board.default('');
    @Input() refresher: BehaviorSubject<Board>;
    @Input() calcs = [];
    @Input() chartIndex: any;
    @Output() chartRefresher = new EventEmitter();
    blockChanged = false;
    subscription: any;
    tab = 0;
    fields: any;
    sizedGraph = null;
    view: any[] = [400, 400];
    colorScheme = {
        domain: []
    };
    @Input() chartWidth: any;
    @Input() chartHeight: any;
    @Input() onlyGraph = false;
    @Input() autoRefresh = false;
    colorSchemeShadow = Constants.COLORS_SHADOW;
    ws: any;
    url: any;
    interval: any;

    private newChart: Chart;
    private state: string;

    constructor(private base: BaseService,
                private templateService: TemplateService,
                private meta: MetaService,
                private trans: TranslateService,
                private popoverCtrl: PopoverController,
                private router: Router) {
        for (const item of this.colorSchemeShadow) {
            this.colorScheme.domain.splice(this.colorScheme.domain.length, 0, item[0]);
        }
    }

    ngOnInit() {
        this.state = window.location.href;
        if (this.templateVal) {
            const templatePk = this.base.getTablePk('Template');
            this.templateService.getTemplates(null, 1, 1, null, false, 
                                              [{name: templatePk, equal: Equal.EQ, value: this.templateVal}]).subscribe(resp => {
                if (resp && resp.data && resp.data.length === 1) {
                    this.board = Board.fromJson(resp.data[0]);
                    this.table = this.board.table;
                    this.base.getCalcFields(this.table).subscribe((fields) => {            
                        if (fields && fields.length) {
                            this.calcs = fields;
                        }
                        this.init();
                    });
                }
            });
        } else {
            this.init();
        }
    }

    private init() {
        if (!this.table) {
            return;
        }
        const resp = this.base.getTableMeta(this.table); 
        for (const f of this.calcs) {
            if (!resp.fields.find(i => i.name === f.name)) {
                resp.fields.splice(resp.fields.length, 0, f);
            }
        }
        this.fields = resp.fields;
        for (const f of this.fields) {
            if (f.type === FieldType.REF && f.related_model) {
                const subresp = this.base.getTableMeta(f.related_model);
                if (subresp && subresp.fields) {
                    f.hasParent = subresp.fields.filter(j => j.name === 'parent' && j.related_model === f.related_model).length > 0;
                }
            }
        }
        
        this.loadCharts();
        this.checkSize();
        if (this.refresher) {
            this.subscription = this.refresher.subscribe(board => {
                if (board) {
                    this.board = board;
                }
                this.chartChanged();
            });
        } else {
            this.chartChanged();
        }
        if ((this.chartIndex || this.chartIndex === 0) && this.board.charts.length) {
            this.chartIndex = parseInt(this.chartIndex, 10);
            if (this.chartIndex < 0 || this.chartIndex >= this.board.charts.length) {
                this.chartIndex = 0;
            }
            this.sizeGraph(this.board.charts[this.chartIndex]);
        }

        if (this.autoRefresh) {
            this.startWs();
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    openTemplate() {
        if ((this.chartIndex || this.chartIndex === 0) && this.board.charts.length) {
            const board = this.board.copy()
            board.isNew = false;
            board.modalGraph = false
            this.base.board = board;
            this.router.navigate(['list/model', this.board.table]);
        }
    }

    changeSide(side) {
        this.board.graphPosition = side; 
        this.saveTemplate();
    }

    sizeGraph(chart: Chart) {
        if (this.board) {
            this.board.modalGraph = true;
            this.sizedGraph = chart.pk;
            if (this.chartHeight && this.chartWidth) {
                this.view = [parseInt(this.chartWidth, 10), parseInt(this.chartHeight, 10)];
            } else if (window.innerWidth < 400) {
                this.view = [window.innerWidth, 400];
            } else {
                this.view = [window.innerWidth - 100, window.innerHeight - 250];
            }
            chart.data = this.base.copy(chart.data);
        } else {
            this.sizedGraph = null;
        }
    }

    private deleteFilter(notDelete, value, justReturn, name) {
        let isFound = false;
        for (let i = this.board.filters.length - 1; i >= 0 ; i--) {
            if (this.board.filters[i].name === name && this.board.filters[i].isChecked) {
                if (this.board.filters[i].isChart) {
                    isFound = isFound || this.board.filters[i].value === value;
                    if (justReturn) {
                        return isFound;
                    }
                    if (isFound && notDelete) {
                        return isFound;
                    }
                    this.board.filters.splice(i, 1);
                } else {
                    return isFound;
                }
            } 
        }
        return isFound;
    }

    private addFilterToTemplate(name, value, valueRepr, notDelete, subtype, chart: Chart, isSeries) {
        if (valueRepr === this.trans.instant('other')) {
            return;
        }
        const label = valueRepr.slice();
        const isFound = this.deleteFilter(notDelete, value, false, name);
        if (!isFound) {
            const col = this.fields.find(item => item.name === name);
            if (!col || [FieldType.CHAR, FieldType.INT, FieldType.DECIMAL, FieldType.REF, FieldType.BOOL, 
                         FieldType.DATETIME, FieldType.DATE].indexOf(col.type) === -1) {
                return;
            }
            let equal = Equal.EQ; 
            valueRepr = col.type !== FieldType.BOOL ? valueRepr : 
                (value ? col.verbose_name : this.trans.instant('not') + ` ${col.verbose_name}`);
            if (([Interval.WEEK, Interval.MONTH, Interval.QUARTER, Interval.YEAR].indexOf(subtype) !== -1 && [FieldType.DATETIME, FieldType.DATE].indexOf(col.type) !== -1) 
                     || (subtype === Interval.DAY && col.type === FieldType.DATETIME)) {
                 equal = Equal.GTE; valueRepr = this.base.dateToString(value);
                 const d1 = this.base.getDate(value);
                 if (subtype === Interval.WEEK) {
                    d1.setDate(d1.getDate() + 7);
                 } else if (subtype === Interval.MONTH) {
                    d1.setMonth(d1.getMonth() + 1);
                 } else if (subtype === Interval.QUARTER) {
                    d1.setMonth(d1.getMonth() + 3);
                 } else if (subtype === Interval.YEAR) {
                    d1.setMonth(d1.getMonth() + 12);
                 } else if (subtype === Interval.DAY) {
                    d1.setDate(d1.getDate() + 1);
                 }

                 this.board.filters.splice(this.board.filters.length, 0, new BoardFilter(col.name, Equal.LT, d1.toJSON().slice(0, 10), this.base.dateToString(d1), col.verbose_name, col.type, Choice.fromJson(col.choices), true, false, true));
            }
            
            this.board.filters.splice(this.board.filters.length, 0, 
                new BoardFilter(col.name, equal, value, valueRepr, col.verbose_name, col.type, Choice.fromJson(col.choices), true, false, true)
            );
            const require = this.mainIsSeries(chart.type);
            if (((require && isSeries) || (!require && !isSeries)) && chart.customColors) {
                const customColorsShadow: ChartColor[] = [];
                for (const customColor of chart.customColors) {
                    const shadow = this.colorSchemeShadow.find(kk => kk[0] === customColor.value);
                    if (shadow && label !== customColor.name) {
                        customColorsShadow.push(new ChartColor(customColor.name, shadow[1]));
                    } else {
                        customColorsShadow.push(new ChartColor(label,  customColor.value));
                    } 
                }
                chart.customColorsShadow = customColorsShadow;
            } 
        }
        return isFound;
    }

    onSelect(event, chart: Chart, hasSeries: boolean = false) {
        let valueRepr = null; let value = null;
        chart.customColorsShadow = null;
        if (hasSeries && typeof(event) === 'object' && event.v) {
            valueRepr = event.name;
            const v = event.v[chart.groupBy.name];
            value = v ? v.id || v.uuid || v : v; 
        } else if (chart && chart.data && chart.groupBy) {
            const label = typeof(event) === 'object' ? event.name : event; 
            const v = chart.data.find(i => i.name === label);
            if (v && v.v !== undefined) {
                valueRepr = label;
                const vv = v.v[chart.groupBy.name];
                value = vv ? vv.id || vv.uuid || vv : vv;
            }
        }

        if (chart && valueRepr) {
            if (hasSeries && typeof(event) === 'object' && chart.groupBy2 && event.v) {
                let value2 = event.v[chart.groupBy2.name];
                value2 = value2 ? value2.id || value2.uuid || value2 : value2;
                let notDelete = !this.deleteFilter(false, value2, true, chart.groupBy2.name);
                notDelete = this.addFilterToTemplate(chart.groupBy.name, value, valueRepr, notDelete, chart.groupBySub, chart, false);
                this.addFilterToTemplate(chart.groupBy2.name, value2, event.series, !notDelete, chart.groupBySub2, chart, true);
            } else {
                this.addFilterToTemplate(chart.groupBy.name, value, valueRepr, false, chart.groupBySub, chart, false);
            }
            if (this.chartRefresher) {
                this.chartRefresher.emit();
            }
        }
    }

    addChart(ev?) {
        this.tab = 1;
        this.newChart = Chart.default();

        if (this.board.groupBy.name) {
            const f = this.fields.find(i => i.name === this.board.groupBy.name);
            if (f) {
                this.board
                this.newChart.groupBy = f;
            }
        }
        this.openChartSetting(ev);
    }

    private async openChartSetting(ev) {
        const chart = this.newChart.copy();
        const popover = await this.popoverCtrl.create({
            component: GraphSettingPage,
            componentProps: {
                newChart: chart,
                fields: this.fields,
                table: this.table
            },
            cssClass: 'list-display-popover',
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            this.newChart = chart;
            this.afterChangeChart(ev);
        });
        return await popover.present();
    }

    private afterChangeChart(ev?) {
        let alert = null;
        if (this.tab > 0 && this.newChart) {
            if (this.requireSeries(this.newChart.type) && !this.newChart.groupBy2) {
                alert = this.trans.instant('require-series', {chartType: this.trans.instant(this.newChart.type)});
            } else if (!this.newChart.groupBy && this.newChart.type !== ChartType.NUMBER_CARD) {
                alert = this.trans.instant('require-group-by');
            } else if (this.newChart.func === FunctionType.SUM && !this.newChart.aggr) {
                alert = this.trans.instant('require-sum');
            } else if (this.requireSeries(this.newChart.type) && this.newChart.groupBy2 && this.newChart.groupBy 
                       && this.newChart.groupBy2.name === this.newChart.groupBy.name) {
                alert = this.trans.instant('group-by-equal-series');
            }
        } 

        if (alert) {
            this.openChartSetting(ev);
            return this.base.sendToast(alert);
        }

        if (this.tab === 1) {

            const chart = this.newChart.copy();
            this.board.charts.splice(this.board.charts.length, 0, chart);
            this.loadData(chart);

        } else if (this.tab === 2) {

            const chart = this.newChart.copy()
            this.board.setChart(chart);
            this.loadData(chart);

        } else {

            this.loadCharts();

        }


        this.saveTemplate();
        this.tab = 0;
    }

    chartChanged() {
        this.loadCharts();
    }

    private loadCharts() {
        for (const chart of this.board.charts) {
            this.loadData(chart);
        }
    }

    private getGroupByQuery(chartQuery, chart, field, fieldSub) {
        if (chart[field].type === FieldType.DATETIME || chart[field].type === FieldType.DATE) {
            if (Constants.INTERVALS.indexOf(chart[fieldSub]) !== -1) {
                chartQuery[field] += '__' + chart[fieldSub];
            } else {
                chartQuery[field] +=    '__day';
            }
        } else if (chart[field].type === FieldType.REF && chart[field].hasParent && chart[fieldSub] === 'parent') {
            chartQuery[field] += '__parent';
        }
        return chartQuery;
    }

    private loadData(chart: Chart) {
        const other = this.trans.instant('other');
        if (!chart || !chart.type || (!chart.groupBy && chart.type !== ChartType.NUMBER_CARD) || !chart.func) {
            return;
        }
        let calcFields = []; let chartQuery; 
        chartQuery = { 
            function: chart.func,    
            isAsc: chart.isAsc && chart.isAsc,
            isAsc2: chart.isAsc2 && chart.isAsc2,
            sort_value: chart.isSort
        };
        if (chart.aggr) {
            chartQuery.aggr = chart.aggr.name;
            if (this.calcs.find(i => i.name === chart.aggr.name)) {
                calcFields = this.templateService.addToCalcFields(chart.aggr.name, calcFields);
            }
        }

        if (chart.groupBy) {
            chartQuery.groupBy = chart.groupBy.name;
            if (this.calcs.find(i => i.name === chart.groupBy.name)) {
                calcFields = this.templateService.addToCalcFields(chart.groupBy.name, calcFields);
            }
            if (chart.groupByCount) {
                chartQuery.groupByCount = chart.groupByCount;
            }
            chartQuery = this.getGroupByQuery(chartQuery, chart, 'groupBy', 'groupBySub');
            if (chart.groupBy2 && chart.groupBy2.name && chart.hasSeries 
                && chart.groupBy2.name !== chart.groupBy.name) {
                chartQuery.groupBy2 = chart.groupBy2.name;
                chartQuery = this.getGroupByQuery(chartQuery, chart, 'groupBy2', 'groupBySub2');
                if (chart.groupBy2Count) {
                    chartQuery.groupBy2Count = chart.groupBy2Count;
                }
                if (this.calcs.find(i => i.name === chart.groupBy2.name)) {
                    calcFields = this.templateService.addToCalcFields(chart.groupBy2.name, calcFields);
                }
            }
        }

        if (this.requireSeries(chart.type) && !chartQuery.groupBy2) {
            return;
        } 

        const board = Board.default(this.board.table);
        board.filters = BoardFilter.copy(chart.filters);

        if (this.board.filters.length) {
            for (const f of this.board.filters) {
                if (!f.isChart && chart.filters.filter(j => j.name === f.name).length === 0) {
                    board.filters.push(f);
                }
            }
        }
        const q = this.templateService.getQueryParams(null, board, this.base.viseFields, this.table, false, [], null, false, this.calcs, 
                                                      calcFields);
        const params = q.params + `&chart=${JSON.stringify(chartQuery)}`;
        this.base.getData(this.table, 1, 1, params).subscribe(resp => {
            if (resp && resp.data) {
                const data = resp.data;
                chart.data = [];
                chart.xAxisTicks = []; 
                chart.placeholder = chart.getTitle(this.trans, this.meta.getTitleMeta(this.table))
                chart.customColors = [];

                let colSeries = null;
                if (chart.groupBy2 && chart.groupBy2.name && chart.hasSeries) {
                    colSeries = this.fields.find(ii => ii.name === chart.groupBy2.name);
                }
                let colGroup = null;
                if (chart.groupBy) {
                    colGroup = this.fields.find(ii => ii.name === chart.groupBy.name);
                    if (colGroup) {
                        chart.xLabel = colGroup.verbose_name;
                    }
                }
                if (chart.func === FunctionType.COUNT) {
                    chart.yLabel = this.trans.instant('count');
                } else {
                    if (chart.aggr) {
                        const colAggr = this.fields.find(ii => ii.name === chart.aggr.name);
                        if (colAggr) {
                            chart.yLabel = colAggr.verbose_name;
                        }
                    }
                }

                let allValue = 0;
                chart.tooltip = chart.func === FunctionType.COUNT  ? this.trans.instant('count').toLowerCase() : 
                    this.trans.instant('sum-by', {by: chart.yLabel});
                for (const ii of data) { allValue += ii.value; }
                if (allValue && allValue !== 0 && chart.toPercent) {
                    chart.yLabel += ' %';
                }
                chart.allValue = allValue;
                
                const labels = [];
                for (const ii of data) {
                    const choices = {}; 
                    if (colGroup && colGroup.choices) {
                        choices[colGroup.name] = colGroup.choices;
                    }
                    if (colGroup) {
                        colGroup.dateTime = 'date';
                    }
                    let value = ii.value;
                    if (chart.toPercent && allValue && allValue !== 0) {
                        value = parseFloat((value * 100.0 / allValue).toFixed(2));
                    }
                    let obj = null;
                    if (colGroup) {
                        let objName = this.templateService.representCell(colGroup, ii, choices, true) || other;
                        if (colGroup.type === FieldType.DATETIME || colGroup.type === FieldType.DATE && objName !== other) {
                            objName = this.templateService.getTruncDate(chart.groupBySub, ii[colGroup.name]) || objName;
                        }
                        obj = { 
                            name: `${objName}`, 
                            value,
                            v: ii 
                        };
                    } else {
                        obj = {
                            name: `${chart.yLabel}`,
                            value
                        };
                    }

                    let s = null;
                    if (colSeries) {
                        const choices2 = {}; 
                        if (colSeries.choices) {
                            choices2[colSeries.name] = colSeries.choices;
                        }
                        colSeries.dateTime = 'date';
                        s = this.templateService.representCell(colSeries, ii, choices2, true) || other;
                        if (colSeries.type === FieldType.DATETIME || colSeries.type === FieldType.DATE && s !== other) {
                            s = this.templateService.getTruncDate(chart.groupBySub2, ii[colSeries.name]) || s;
                        }
                        const f = chart.data.find(c => c.name === s);
                        if (f) {
                            f.series.splice(f.series.length, 0, obj);
                        } else {
                            chart.data.splice(chart.data.length, 0, {name: s, series: [obj]});
                        }
                    } else {
                        chart.data.splice(chart.data.length, 0, obj);
                    }

                    if (colGroup && !this.mainIsSeries(chart.type)) {
                        if (ii[colGroup.name] && ii[colGroup.name].options && ii[colGroup.name].options.color) {
                            chart.addCustomColor(obj.name, ii[colGroup.name].options.color);
                        }
                        labels.push(obj.name);
                        
                    } else if (colGroup && colSeries) {
                        if (ii[colSeries.name] && ii[colSeries.name].options && ii[colSeries.name].options.color) {
                            chart.addCustomColor(s, ii[colSeries.name].options.color)
                        }
                        labels.push(obj.name);
                    }
                }
                
                for (const label of labels) {
                    for (const kk of this.colorScheme.domain) {
                        chart.addCustomColor(label, kk);
                    }
                }
            }
        });
    }

    private mainIsSeries(type) {
        return [ChartType.LINE, ChartType.AREA, ChartType.AREA_NORMALIZED, ChartType.AREA_STACKED, ChartType.POLAR].indexOf(type) !== -1;
    }

    private requireSeries(type) {
        return [ChartType.STACKED_VERTICAL_BAR, ChartType.STACKED_HORIZONTAL_BAR, ChartType.NORMALIZED_VERTICAL_BAR, ChartType.NORMALIZED_HORIZONTAL_BAR, ChartType.LINE, ChartType.AREA,
                ChartType.AREA_NORMALIZED, ChartType.AREA_STACKED, ChartType.POLAR, ChartType.HEAT_MAP].indexOf(type) !== -1;
    }

    openChart(chart: Chart, ev) {
        this.newChart = chart.copy(); 
        this.tab = 2;
        const attrs = ['groupBy', 'groupBy2', 'aggr'];
        for (const a of  attrs) {
            if (this.newChart[a] && this.newChart[a].name) {
                const f = this.fields.find(i => i.name === this.newChart[a].name);
                if (f) {
                    this.newChart[a] = f;
                }
            }
        }
        this.openChartSetting(ev);
    }

    onActivate(ev) {
    }

    private saveTemplate() {        
        if (this.board.canUpdate) {
            this.templateService.updateTemplate(this.board).subscribe(resp => {
                this.board.edited = resp.edited;
            });
        }
        this.checkSize();
    }

    private checkSize() {
        if (window.innerWidth <= 400) {
            this.view = [window.innerWidth, 400];
            return;
        }
        if (!this.board.modalGraph && (this.board.graphPosition === 'top' || this.board.graphPosition === 'bottom')) {
            this.view = [400, 400 - 53 - 7 - 5 - 3];
        } else if (this.board.modalGraph) {
            this.view = [window.innerWidth - 100, window.innerHeight - 250];
        } else {
            this.view = [400, 400];
        }
    }

    removeChart(i) {
        if (this.board.modalGraph) {
            this.sizedGraph = null;
            this.board.modalGraph = false;
            this.checkSize();
            return;
        }
        this.board.charts.splice(i, 1); 
        this.saveTemplate();
    }

    private toFloat(v) {
        return v.toFixed();
    }

    getTooltip(model, chart: Chart) {
        const v = chart.toPercent ? this.toFloat(model.value * chart.allValue / 100) : model.value;
        const p = !chart.toPercent ? this.toFloat(model.value * 100 / chart.allValue) : model.value;
        const s = model.series ? `${model.series} ` : '';
        const value = `${s}${model.name} ${ chart.tooltip } ${v} (${p}%)`;
        return value;
    }

    moveChart(from, to) {
        if (to < this.board.charts.length && to >= 0) {
            this.base.move(this.board.charts, from, to);
            this.saveTemplate();
        }
    }

    private startWs() {
        if (!(this.table || this.board.table)) {
            return;
        }
        this.url = this.base.getUpdaterUrl(this.table || this.board.table);
        this.interval = setInterval(() => {
            if (!this.ws || this.ws.readyState === 3) {
                this.createWebSocket();
            }
        }, 5000);
        this.createWebSocket();
    }

    private createWebSocket() {
        if (this.ws && this.ws.readyState !== 3) {
            this.ws.close();
        }
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = (event) => {
            this.onmessage(event);
        };
        this.ws.onopen = () => {
            this.ws.send('start');
        };
    }

    private onmessage(event) { 
        if (!event || event.data === 'ping') {
            return;
        }
        if (window.location.href !== this.state) {
            return
        }
        const msg = JSON.parse(event.data);
        if (msg.method === 'save' || msg.method === 'edit' || msg.method === 'delete') {
            this.chartChanged();
        }
    }
}
