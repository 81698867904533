import { Subject } from "rxjs";
import { Base } from "./base";
import { BoardButton } from "./board-buttons";
import { BoardFilter } from "./board-filters";
import { GroupBy } from "./board-group-by";
import { ListDisplay } from "./board-list-display";
import { OrderBy } from "./board-order-by";
import { Chart } from "./chart";
import { BoardDate } from "./date";
import { BoardEventType, FieldType, Interval } from "./enums";


export class BoardEvent {

    static subject = new Subject<BoardEvent>();

    constructor(public type: BoardEventType,
                public filters: BoardFilter[],
                public table: string,
                public pk: string) {

    }

    static fromJson(d: any): BoardEvent {
        const b = new BoardEvent(d.type, [], d.table, d.pk);
        for (const f of d.filters || []) {
            b.filters.push(BoardFilter.fromJson(f));
        }
        return b;
    }

    run() {
        BoardEvent.subject.next(this);
    }
}

export class HierarchyField {

    constructor(public name: string,
                public filterFunc: string,
                public useDefaultFilter: boolean,
                public table: string,
                public maxElements: number,
                public hasChildren: boolean,
                public reprFunc: string,
                public paramsFunc: string,
                public isElementFunc: string,
                public addDefaultFunc: string) {
    }

    serialize(): any {
        return {
            name: this.name,
            filterFunc: this.filterFunc,
            useDefaultFilter: this.useDefaultFilter,
            table: this.table,
            maxElements: this.maxElements,
            hasChildren: this.hasChildren,
            reprFunc: this.reprFunc,
            paramsFunc: this.paramsFunc,
            isElementFunc: this.isElementFunc,
            addDefaultFunc: this.addDefaultFunc
        }
    }

    static fromJson(d: any): HierarchyField {
        return new HierarchyField(d.name, d.filterFunc, d.useDefaultFilter || false, d.table, d.maxElements || 20, d.hasChildren || false, d.reprFunc, d.paramsFunc, d.isElementFunc, d.addDefaultFunc)
    }
}

export class Board {
    public my: boolean;

    constructor(public table: string,
                public name: string,
                public edited: number,
                public background: any,
                public form: any,
                public isDefault: boolean,
                public filters: BoardFilter[],
                public orderBy: OrderBy[],
                public listDisplay: ListDisplay[],
                public groupBy: GroupBy,
                public graphPosition?: string,
                public isTable?: boolean,
                public user?: any,
                public pk?: string,
                public modalGraph?: boolean,
                public isNew?: boolean,
                public charts?: Chart[],
                public disableAskSavePublic?: boolean,
                public changeOnlyOwner?: boolean,
                public buttons?: BoardButton[],
                public isMap?: boolean,
                public mapFunc?: string,
                public hierarchyFields?: HierarchyField[]) {
        
        if (!this.graphPosition) {
            this.graphPosition = 'right';
        }
        if (!this.charts) {
            this.charts = [];
        }
        if (!this.buttons) {
            this.buttons = [];
        }
        if (!this.hierarchyFields) {
            this.hierarchyFields = [];
        }
        if (!this.isMap) {
            this.isMap = false;
        }
    }

    get userId(): string {
        return this.user ? this.user.uuid : null;
    }

    isMy(currentUser: any): boolean {
        return currentUser.uuid === this.userId
    }

    get canUpdate(): boolean {
        const currentUser = Base.getCurrentUser()
        return (currentUser.is_admin && !this.changeOnlyOwner) || this.isMy(currentUser);
    }

    serialize(): any {
        return {
            fields: {
                filters: BoardFilter.toList(this.filters),
                orderBy: OrderBy.toList(this.orderBy.filter(f => f.isChecked)),
                y: ListDisplay.toList(this.listDisplay.filter(f => f.isChecked)),
                groupBy: this.groupBy.serialize(),
                graphPosition: this.graphPosition,
                isTable: this.isTable,
                isMap: this.isMap,
                modalGraph: this.modalGraph,
                charts: this.charts.map(f => f.serialize()),
                disableAskSavePublic: this.disableAskSavePublic || false,
                changeOnlyOwner: this.changeOnlyOwner || false,
                buttons: BoardButton.serialize(this.buttons),
                mapFunc: this.mapFunc,
                hierarchyFields: this.hierarchyFields.map(f => f.serialize())
            },
            table: this.table,
            name: this.name,
            edited: this.edited,
            background: this.background,
            form: this.form,
            is_default: this.isDefault || false,
            uuid: this.pk,
            user: this.user
        }
    }

    copy(): Board {
        return Board.fromJson(Base.copy(this.serialize()));
    }

    refresh() {
        this.removeUnchecked();
        for (const item of this.listDisplay) {
            item.refresh();
        }
    }

    private removeUnchecked() {
        this.orderBy = this.orderBy.filter(f => f.isChecked);
        this.listDisplay = this.listDisplay.filter(f => f.isChecked);
    }

    get listDisplaySize(): number {
        let size = 0;
        for (const f of this.listDisplay) {
            f.width = f.width > 0 ? f.width : 100;
            size += f.width;
        }
        return size;
    }

    changeMode() {
        this.isTable = !this.isTable;
    }

    get annotations(): any[] {
        const groups = []; 
        
        if (this.groupBy.type === FieldType.BOOL) {
            
            return [
                {
                    name: this.groupBy.name,
                    value: true,
                    group: this.groupBy.verboseName 
                },
                {
                    name: this.groupBy.name,
                    value: false,
                    group: `HE ${this.groupBy.verboseName}`
                }
            ];

        } else if (this.groupBy.type === FieldType.DECIMAL || (this.groupBy.type === FieldType.INT && !this.groupBy.choices.length)) {

            for (const g of this.groupBy.groups.filter(f => f.num)) {
                groups.push(
                    {
                        name: this.groupBy.name,
                        value: [g.num.lte, g.num.gte],
                        group: `${g.num.lte} <= ${this.groupBy.verboseName} <= ${g.num.gte}`
                    }
                );
            }

        } else if (this.groupBy.type === FieldType.INT && this.groupBy.choices.length) {
            
            for (const g of this.groupBy.groups.filter(f => f.ref)) {
                groups.push(
                    {
                        name: this.groupBy.name,
                        value: g.ref.value,
                        group: g.ref.groupName ? `${g.ref.groupName}` : `${g.ref.name}` 
                    }
                );
            }

        } else if (this.groupBy.type === FieldType.REF) {
            
            if (this.groupBy.groups.length) {

                for (const g of this.groupBy.groups.filter(f => f.ref)) {
                    groups.push(
                        {
                            name: this.groupBy.name,
                            value: g.ref.value,
                            group: g.ref.groupName ? `${g.ref.groupName}` : `${g.ref.repr}`,
                        }
                    );
                } 

             } else {

                groups.push(
                    {
                        name: this.groupBy.name,
                        note: `${this.groupBy.name}__name`,
                    }
                );

            }

        } else if (this.groupBy.type === FieldType.DATETIME || this.groupBy.type === FieldType.DATE) {
            
            if (this.groupBy.typeDate === Interval.OTHER) {
                
                for (const g of this.groupBy.groups.filter(f => f.date)) {
                    if (g.date.gte && g.date.lte) {
                        groups.push(
                            {
                                name: this.groupBy.name,
                                value: [g.date.lte.slice(0, 10), g.date.gte.slice(0, 10)],
                                group: `${(new BoardDate(g.date.lte)).dateRepr} - ${(new BoardDate(g.date.gte)).dateRepr}`
                            }
                        );
                    }
                } 

            } else if (this.groupBy.typeDate) {

                return [
                    {
                        name: `${this.groupBy.name}`,
                        note: `${this.groupBy.name}__${this.groupBy.typeDate}`
                    }
                ];

            }

        } else if (this.groupBy.type === FieldType.CHAR) {
            
            return [
                {
                    name: this.groupBy.name,
                    note: this.groupBy.name
                }
            ];

        }
        return groups;
    }

    removeButton(btn: BoardButton) {
        for (var i = this.buttons.length - 1; i >= 0; i--) {
            if (btn.id === this.buttons[i].id) {
                this.buttons.splice(i, 1);
            }
        }
    }

    setChart(chart: Chart): Chart {
        for (let i = 0; i < this.charts.length; i++) {
            if (this.charts[i].pk === chart.pk) {
                this.charts[i] = chart;
                return chart;
            }
        }
        return chart;
    }

    static fromJson(d: any): Board {
        const filters: BoardFilter[] = [];
        const orderBy: OrderBy[] = [];
        const listDisplay: ListDisplay[] = [];
        let charts: Chart[] = [];
        let hierarchyFields: HierarchyField[] = [];
        let buttons: BoardButton[] = [];

        let groupBy: GroupBy = GroupBy.default();
        let graphPosition = '';
        let isTable = false;
        let isMap = false;
        let modalGraph = false;
        let disableAskSavePublic = false;
        let changeOnlyOwner = false;
        let mapFunc: string;

        if (d && d.fields) {
            if (d.fields.filters) {
                for (const f of d.fields.filters) {
                    filters.push(BoardFilter.fromJson(f));
                }
            }

            if (d.fields.orderBy) {
                for (const f of d.fields.orderBy) {
                    orderBy.push(OrderBy.fromJson(f));
                }
            }

            if (d.fields.y) {
                for (const f of d.fields.y) {
                    listDisplay.push(ListDisplay.fromJson(f));
                }
            }

            if (d.fields.groupBy) {
                groupBy = GroupBy.fromJson(d.fields.groupBy);
            }

            if (d.fields.charts) {
                charts = d.fields.charts.map(f => Chart.fromJson(f));
            }
            if (d.fields.buttons) {
                buttons = BoardButton.fromJson(d.fields.buttons);
            }

            if (d.fields.hierarchyFields) {
                hierarchyFields = d.fields.hierarchyFields.map(f => HierarchyField.fromJson(f));
            }

            graphPosition = d.fields.graphPosition;
            isTable = d.fields.isTable || false;
            isMap = d.fields.isMap || false;
            modalGraph = d.fields.modalGraph || false;
            disableAskSavePublic = d.fields.disableAskSavePublic || false;
            changeOnlyOwner = d.fields.changeOnlyOwner || false;
            mapFunc = d.fields.mapFunc
        }
        
        const board = new Board(d.table, d.name, d.edited, d.background, d.form, d.is_default, filters, orderBy, listDisplay, groupBy, graphPosition, isTable, d.user, d.uuid, modalGraph, false, charts, disableAskSavePublic, changeOnlyOwner, buttons, isMap, mapFunc, hierarchyFields);
        board.my = board.isMy(Base.getCurrentUser());
        return board;
    }

    static isValidField(val: string): boolean {
        for (const f in FieldType) {
            if (val == FieldType[f]) {
                return true;
            }
        }
        return false;
    }

    static default(table: string): Board {
        const board = new Board(table, null, null, null, null, false, [], [], [], GroupBy.default());
        board.my = true;
        return board;
    }
}
