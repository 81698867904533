import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RowWidgetPage } from './row-widget.page';
import { ClassWidgetPageModule } from '../class-widget/class-widget.module';
import { StyleWidgetPageModule } from '../style-widget/style-widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { JsonViewPageModule } from '../json-view/json-view.module';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ClassWidgetPageModule,
        StyleWidgetPageModule,
        TranslateModule,
        RouterModule.forChild(routes),
        JsonViewPageModule
    ],
    declarations: [RowWidgetPage],
    exports: [RowWidgetPage]
})
export class RowWidgetPageModule {}
