import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-style-widget',
    templateUrl: './style-widget.page.html',
    styleUrls: ['./style-widget.page.scss'],
})
export class StyleWidgetPage implements OnInit {

    @Input() w: any;
    @Output() change = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }

    onChange() {
        this.change.emit();
    }
}
