import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RelatedTableFormSettingPage } from './related-table-form-setting.page';
import { TranslateModule } from '@ngx-translate/core';
import { StyleWidgetPageModule } from '../style-widget/style-widget.module';
import { ClassWidgetPageModule } from '../class-widget/class-widget.module';
import { RowWidgetPageModule } from '../row-widget/row-widget.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([]),
        TranslateModule,
        RowWidgetPageModule,
        ClassWidgetPageModule,
        StyleWidgetPageModule
    ],
    declarations: [RelatedTableFormSettingPage],
    exports: [RelatedTableFormSettingPage]
})
export class RelatedTableFormSettingPageModule {}
