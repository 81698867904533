import { Component, OnInit, HostListener, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MessageService } from '../services/message.service';
import { BaseService } from '../services/base.service';
import { ToastController, ModalController } from '@ionic/angular';
import { ViseStatusService } from '../services/vise-status.service';
import { BackgroundPage } from '../background/background.page';
import { FormPage } from '../form/form.page';
import { ChoicesPage } from '../choices/choices.page';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';
import { Equal } from '../classes/enums';
import { BoardFilter } from '../classes/board-filters';
import { Base } from '../classes/base';

@Component({
    selector: 'app-message',
    templateUrl: './message.page.html',
    styleUrls: ['./message.page.scss'],
})
export class MessagePage implements OnInit {

    @ViewChild('messagesContainer') messagesContainer: ElementRef;
    @Input() isShowChat: any;
    @Input() unread: any;
    @Input() showChatSubject: any;
    @Output() closeEvent = new EventEmitter();

    messages = [];
    pages = null;
    search = null;
    scrollPages = 500;
    hasNext = false;
    scrollComplete = true;
    currentUser: any;
    preScroll = 0;
    readMessages = [];
    newMessage = null;
    replyMessage = null;
    messagesHeight = 0;
    lastMessageHeight = null;
    lastMessageTime: any = null;
    url: any; 
    ws: any;
    chatBackground: any = null;
    background: any = null;
    openedItem: any;
    interval: any;
    subscription: any;
    subscriptionChat: any;
    userPk: any;
    messagePk: any;
    private initialized: boolean;

    constructor(private messageService: MessageService,
                private base: BaseService, 
                private toastController: ToastController,
                private viseStatusService: ViseStatusService,
                private modalCtrl: ModalController,
                private authService: AuthService,
                private trans: TranslateService) { }

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.messagesHeight = window.innerHeight - 200;
    }

    beforeInit() {
        const token = this.base.getToken();
        if (!this.authService.authenticated() || !token) {
            return;
        }
        
        this.messagesHeight = window.innerHeight - 200;
        this.currentUser = Base.getCurrentUser();
        this.pages = this.scrollPages + 0;
        this.messagePk = this.base.getTablePk('SentMessage');
        this.userPk = this.base.getTablePk('User');

        this.doRefresh(null, true, token);

        // Get ws url.
        this.url = 'wss://chat.woki.one/websocket?user=' 
                    + this.currentUser[this.userPk] + '&url=' + this.base.getDomain() + '&token=' + this.base.getToken();
        this.chatBackground = this.messageService.getBackground(token).subscribe(resp => {
            if (resp && resp.length) {
                this.chatBackground = resp[0];
                this.background = this.base.getBackground(this.chatBackground.background, true);
            }
        });
    }
            
    ngOnInit() {
        this.beforeInit();

        // Bind event of login.
        this.subscription = this.base.auth.subscribe(_ => {
            if (!this.initialized) {
                this.beforeInit();
                this.refresh();
                this.initialized = true;
            }
        });

        if (this.showChatSubject) {
            this.subscriptionChat = this.showChatSubject.subscribe(resp => {
                this.isShowChat = resp.isShowChat;
                this.unread = resp.unread;
                this.showChat();
            });
        }
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.subscriptionChat) {
            this.subscriptionChat.unsubscribe();
        }
        this.refresh(true);
    }

    refresh(close?) {
        if (close || !this.authService.authenticated()) {
            if (this.interval) {
                clearInterval(this.interval);
            }
            if (this.ws) {
                this.ws.close();
            }
        } else {
            if (!this.interval) {
                this.interval = setInterval(() => {
                    if (!this.ws || this.ws.readyState === 3) {
                        this.createWebSocket();
                        this.doRefresh(null, true);
                    }
                }, 5000);
                this.createWebSocket();
            }
        }
    }

    createWebSocket() {
        if (this.ws && this.ws.readyState !== 3) {
            this.ws.close();
        }
        console.error('socket is closed, try create new.', this.ws);
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = (event) => { 
            this.onmessage(event);
        };
        this.ws.onopen = () => {
            this.ws.send('start');
        };
    }

    onmessage(event) {
        if (event.data === 'ping') {
            return;
        }
        
        const data = JSON.parse(event.data);
        if (data.method === 'delete') {
            // Menu buttons is changed: update them.
            if (this.replyMessage && this.replyMessage[this.messagePk] === data.data[this.messagePk]) {
                this.replyMessage = null;
                this.getReplayMessage();
            }
            // Delete message.
            for (let i = 0; i < this.messages.length; i++) { 
                for (let j = 0; j < this.messages[i].length; j++) {
                    if (this.messages[i][j][this.messagePk] === data.data[this.messagePk]) {
                        if (this.messages[i].length === 1) {
                            this.messages.splice(i, 1);
                        } else {
                            this.messages[i].splice(j, 1);
                        }
                        return;
                    } 
                }
            }
            return;
        }
        
        if (data.method === 'edit') {
            for (const mess of this.messages) {
                let isFound = false;
                for (let j = 0; j < mess.length; j++) {
                    if (mess[j][this.messagePk] === data.data[this.messagePk]) {
                        mess[j] = this.convertButton(data.data);
                        isFound = true;
                        break;
                    }
                }
                if (isFound) {
                    break;
                }
            }
        } else if (data.method === 'save') {
            // Is updated already.
            if (this.messages.find(a => a.find(i => i[this.messagePk] === data.data[this.messagePk]))) {
                return;
            }
            // Add to the end.
            this.messages.splice(this.messages.length, 0, [this.convertButton(data.data)]);
            if (data.data.is_read === false) {
                if (!this.isShowChat) {
                    this.unread ++;
                    this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
                    if (this.unread === 1) {
                        this.messages.splice(this.messages.length - 1, 1);
                        this.messages.splice(this.messages.length, 0, [{unread: true}]);
                        this.messages.splice(this.messages.length, 0, [data.data]);
                    }
                    let sender = 'Bot'; let text = data.data.text;
                    if (data.data.sender && data.data.sender[this.userPk] !== this.currentUser[this.userPk]) {
                        sender = data.data.sender.name;
                    }
                    if (text.length > 64) {
                        text = text.slice(0, 64) + '...';
                    }
                    this.showNewMessage(sender, text);
                }
                this.readMessages.push(data.data[this.messagePk]);
                if (data.data.is_reply) {
                    this.replyMessage = data.data;
                }
            }
        } else {
            // unknown method.
            return;
        }

        // Scroll to bottom: if message received till 8 s.
        if (this.isShowChat) {
            const d = new Date(); 
            if (this.lastMessageTime && (d.getTime() - this.lastMessageTime.getTime()) / 1000 < 8) {
                setTimeout(() => {
                    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
                    if (data.data.ignore_business_process === undefined) {
                        this.lastMessageTime = null;
                    }
                }, 500);
            }
        }
    }

    scroll(event) {
        if (this.hasNext && this.scrollComplete && event.srcElement.scrollTop === 0) {
            this.doRefresh();
        }
        if (this.readMessages.length) {
            for (const mm of this.readMessages) {
                this.messageService.readMessage(mm).subscribe(_ => {});
            }
            this.readMessages = [];
            const unread = this.unread;
            setTimeout(() => {
                this.unread -= unread;
                if (this.unread < 0) {
                    this.unread = 0;
                }
                this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
                if (!this.unread) {
                    this.removeUnread();
                }
            }, 1000 * 30);
        }
    }

    getTimeSeparator(time) {
        try {
            const d = this.base.getDate(time); 
            const days = `${d.getDate()}`;
            let repr = `${days} `;
            repr += `${this.trans.instant(this.base.month[d.getMonth()])} `;
            repr += `${d.getFullYear()}`;
            return repr;
        } catch (e) {
            console.error('getTimeSeparator', e);
        }
        return '';
    }

    getTime(time) {
        try {
            const d = this.base.getDate(time); 
            const days = `${d.getDate()}`;
            const hours = `${d.getHours()}`; 
            const minutes = `${d.getMinutes()}`;
            let repr = `${days} `;
            repr += `${this.trans.instant(this.base.month[d.getMonth()])} `;
            repr += `${d.getFullYear()} `;
            repr += `${hours.length === 1 ? '0' : ''}`;
            repr += `${hours}:`;
            repr += `${minutes.length === 1 ? '0' : ''}`;
            repr += `${minutes}`;
        } catch (e) {
            console.error('getTime', e);
        }
        return '';
    }

    loadMessages(event, isStart, token?) {
        this.scrollComplete = false;
        this.messageService.getMessages(1, this.pages, this.search, token)
        .pipe(
            finalize(() => {
                this.scrollComplete = true;
                if (event && event.target) { 
                    event.target.complete();
                }
            })
        ).subscribe(resp => {
            this.afterGetMessages(resp, isStart);
        });
    }

    afterGetMessages(resp, isStart) {
        if (resp && resp.data) {
            if (!this.isShowChat) {
                this.unread += resp.unread;
                this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
            }
            let notRead = false;
            if (isStart) {
                this.messages = [];
            }
            for (let i = 0 ; i < resp.data.length; i++) {
                if (this.unread > 0 && resp.data[i].is_read === false && isStart && !notRead) {
                    this.messages.splice(this.messages.length, 0, [{unread: true}]);
                } else if (i === 0 || resp.data[i].added.slice(0, 10) !== resp.data[i - 1].added.slice(0, 10)) {
                    this.messages.splice(this.messages.length, 0, [{time: this.getTimeSeparator(resp.data[i].added)}]);
                } 
                const item = this.convertButton(resp.data[i]);

                this.messages.splice(this.messages.length, 0, [item]);
                if (!notRead && ((item.is_read === false && isStart) || (!isStart && i >= this.scrollPages))) {
                    notRead = true;
                }
                if (resp.data[i].is_reply) {
                    if (!this.replyMessage || (this.base.getDate(resp.data[i].added)) > (this.base.getDate(this.replyMessage.added))) {
                        this.replyMessage = resp.data[i];
                    }
                }
            }
            if (this.isShowChat) {
                setTimeout(() => {this.scrollToUnread(false); }, 1000);
            } 
            if (!this.replyMessage) {
                this.getReplayMessage();
            }
            this.hasNext = resp.has_next;
            if (resp.has_next) {
                this.pages += (this.scrollPages + 0);
            } 
        } else if (resp && resp.error) {
            this.base.sendToast(this.base.getError(resp.error));
        }
    }

    doRefresh(event?, isStart?, token?) {
        this.loadMessages(event, isStart, token);
    }

    doSearch(event) {
        this.preScroll = 0;
        this.search = event.detail.value;
        this.pages = this.scrollPages + 0;
        this.doRefresh(null, true);
    }

    convertText(text) {
        const img = [];
        for (let i = text.indexOf('<a href="http'); i !== -1; i = text.indexOf('<a href="http')) {
            let newText = text.slice(0, i);
            const less = text.slice(i + 8).split('>&#8205;</a>');
            newText += less.slice(1, 2).join('') + less.slice(2).join('>&#8205;</a>');
            text = newText.slice();
            if (less.length) {
                img.push(less[0].slice(1, less[0].length - 1));
            }
        }
        return img;
    }

    convertButton(data) {
        if (data.text_web) {
            data.img_text_web = this.convertText(data.text_web);
        } else if (data.text) {
            data.img_text = this.convertText(data.text);
        }
        
        if (data.vises && data.vises.responsible && data.vises.responsible[this.userPk] !== this.currentUser[this.userPk]) {
            data.sender = data.vises.responsible;
        }

        if (data.vises && data.vises.data && data.vises.data.length) {
            const receivers = []; let queue = null;
            for (const v of data.vises.data) {
                if (!v.approve) {
                    if (queue !== null && queue !== v.queue) {
                        break;
                    }
                    queue = v.queue;
                    receivers.push(v.user);
                }
            }
            data.receivers = receivers;
            for (const v of data.vises.data) {
                v.time = this.getMessageDate(v.edited);
                v.viseType = this.getViseType(v);
            }
        }

        if (data.edited) {
            data._date = this.getTime(data.edited);
            data._time = data._date.slice(data._date.length - 5);
        }
        if (data.vises && data.vises.responsible && data.vises.responsible[this.userPk] !== this.currentUser[this.userPk]) {
            data.sender = data.vises.responsible;
        }
        if (data.buttons && data.buttons.length) {
            data.buttons = this.getButtons(data.buttons, data.is_reply, data.is_buttons_ready);
        }
        return data;
    }

    getButtons(data, isReply, isReady) {
        if (isReady) {
            if (data && data.length && data[0] && data[0].length) {
                return data;
            }
        }
        const newButtons = [];
        if (!data) {
            return [];
        }

        const buttons = data.slice().filter(b => !b.not_show_web);
        if (isReply) {
            for (let i = 0; i < buttons.length; i++) {
                if (i === 0 || newButtons[newButtons.length - 1].length === 2) {
                    newButtons.push([buttons[i]]);
                } else {
                    newButtons[newButtons.length - 1].push(buttons[i]);
                }
            }
        } else {
            let maxLength = 0; let group = null; const paginatorBtns = []; const backNextBtns = []; let cancelBtn = null;
            for (let i = buttons.length - 1; i > 0; i--) {
                if (buttons[i].text) {
                    if (['/cancel', '/skip-snap-template', '/save-snap-template'].indexOf(buttons[i].callback_data) === -1) {
                        maxLength = Math.max(buttons[i].text.length, maxLength);
                    }
                }
                if (this.isPaginator(buttons[i])) {
                    paginatorBtns.push(buttons[i]);
                    buttons.splice(i, 1);
                } else if (this.isBackNext(buttons[i])) {
                    backNextBtns.push(buttons[i]);
                    buttons.splice(i, 1);
                } else if (buttons[i].callback_data === '/cancel') {
                    cancelBtn = buttons[i];
                    buttons.splice(i, 1);
                }
            }

            if (buttons.length >= 27 && maxLength < 4) {
                group = 7;
            } else if (buttons.length >= 10 && maxLength < 10) {
                group = 3;
            } else if (buttons.length > 1 && maxLength < 20) {
                group = 2;
            } else {
                group = 1;
            }

            if (group) {
                for (let i = 0; i < buttons.length; i++) {
                    if (i === 0 || newButtons[newButtons.length - 1].length === group) {
                        newButtons.push([buttons[i]]);
                    } else {
                        newButtons[newButtons.length - 1].push(buttons[i]);
                    }
                }
            }

            if (paginatorBtns.length) {
                newButtons.push(paginatorBtns);
            }
            if (backNextBtns.length) {
                newButtons.push(backNextBtns);
            } 
            if (cancelBtn) {
                newButtons.push([cancelBtn]);
            }
        }
        return newButtons;
    }

    isPaginator(button) {
        return ['➡️', '⬅️', '⬅️ Предыдущиее', '➡ Следующие️'].indexOf(button.text) !== -1;
    }
        
    isBackNext(button) {
        return ['↩ ️Назад', '➡ Далее️'].indexOf(button.text) !== -1;
    }

    sendMessage(chatId, callbackData, messageId, text, sticker, scrollToEnd = false) {
        if (scrollToEnd || this.messagesContainer.nativeElement.scrollHeight <= 
            this.messagesContainer.nativeElement.scrollTop + this.messagesContainer.nativeElement.clientHeight) {
            this.lastMessageTime = new Date();
        }
        this.removeUnread();
        this.unread = 0;
        this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
        this.messageService.createMessage(chatId, callbackData, messageId, text, sticker).subscribe(_ => {});
    }

    removeUnread() {
        if (this.unread) {
            for (let i = 0; i < this.messages.length; i++) {
                if (this.messages[i][0].unread) {
                    this.messages.splice(i, 1);
                    break;
                }
            }
        }
    }

    getReplayMessage() {
        this.messageService.getReplyMessage().subscribe(resp => {
            if (resp && resp[this.messagePk]) {
                this.replyMessage = this.convertButton(resp);
            }
        });
    }

    closeChat() {
        this.lastMessageHeight = this.messagesContainer.nativeElement.scrollHeight;
        this.isShowChat = false;
        this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
    }

    scrollToUnread(isOpenChat) {
        if (this.messages.length) {
            if (isOpenChat) {
                const sep = this.messagesContainer.nativeElement.getElementsByClassName('sep-unread');
                if (this.lastMessageHeight === null && sep.length) {
                    this.scrollComplete = false;
                    // tslint:disable-next-line: no-string-literal
                    this.messagesContainer.nativeElement.scrollTop = sep[0].parentNode['offsetTop'];
                    this.scrollComplete = true;
                } else if (!this.lastMessageHeight) {
                    this.scrollComplete = false;
                    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
                    this.scrollComplete = true;
                }
            } else {
                this.scrollComplete = false;
                this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight 
                    - this.preScroll - this.messagesContainer.nativeElement.clientHeight;
                this.scrollComplete = true;
            }
            this.preScroll = this.messagesContainer.nativeElement.scrollHeight - this.messagesContainer.nativeElement.clientHeight;
        }
    }

    showChat() {
        if (this.isShowChat) {
            setTimeout(() => {
                this.scrollToUnread(true);
            }, 1000);
        }
    }

    getMessageDate(date) {
        const d = this.base.dateTimeToString(date);
        return `${d.slice(0, 5)}${d.slice(10, 16)}`;
    }

    getViseType(vise) {
        return this.viseStatusService.getViseType(vise);
    }

    addComment(msg) {
        if (msg.newComment && msg.vises && msg.vises.data && msg.vises.data.length) {
            const data = {
                user: this.currentUser[this.userPk],
                document: msg.vises.data[0].document,
                document_type: msg.vises.data[0].document_type,
                comment: msg.newComment.trim(),
                queue: 0,
                type: 0,
                approve: true
            };
            msg.newComment = null;
            this.viseStatusService.createVise(data).subscribe(resp => {
                if (resp && !resp.uuid) {
                    let message = this.trans.instant('message-not-sent');
                    if (resp.error) {
                        message += `: ${this.base.getError(resp.error)}`;
                    }
                    this.base.sendToast(message);         
                } else if (resp && resp.uuid) {
                    this.base.sendToast(this.trans.instant('comment-added'));
                    this.updateMessage(msg);
                }
            });
        }
    }

    updateMessage(msg) {
        this.messageService.getMessage(msg[this.messagePk]).subscribe(resp => {
            if (resp && resp[this.messagePk]) {
                for (const mm of this.messages) {
                    for (let j = 0; j < mm.length; j++) {
                        if (mm[j][this.messagePk] === msg[this.messagePk]) {
                            mm[j] = this.convertButton(resp);
                            return;
                        }
                    }
                }
            }
        });
    }

    openDocument(msg) {
        if (msg && msg.vises && msg.vises.data && msg.vises.data.length) {
            const table = this.base.getTableOfDocumentType(msg.vises.data[0].document_type);
            if (table) {
                this.base.getDocument(table, msg.vises.data[0].document).subscribe(resp => {
                    if (resp && resp.data && resp.data.length === 1) {
                        this.openItem(resp.data[0], table);
                    }
                });
            }
        }
    }

    async openItem(item, table) {
        const filters = [{name: 'table', equal: Equal.EQ, value: table}, {name: 'is_default', equal: Equal.EQ, value: true},
                         {name: 'type', equal: Equal.EQ, value: 0}]; 
        this.base.getData('Form', 1, 1, `&filter_fields=${JSON.stringify(filters)}`).subscribe(resp => {
            if (resp && resp.data && resp.data.length > 0) {
                this.openedItem = {openedItem: this.base.copy(item)};
                let props: any; props = {item: this.openedItem};
                props.form = resp.data[0];
                this.base.openForm(FormPage, props, null, table, this.openedItem);
            }
        });
    }

    addLike(obj, isLike, data, msg) {
        if (data.likes && (data.likes.is_my_like || data.likes.is_my_dislike)) {
            this.base.removeTable(data.likes.is_my_like || data.likes.is_my_dislike, null, 'Like').subscribe(_ => {
               if ((isLike && data.likes.is_my_like) || (!isLike && data.likes.is_my_dislike)) {
                   this.updateMessage(msg);
                   return;
               }
               this.createLike(obj, isLike, msg);
            });
        } else {
            this.createLike(obj, isLike, msg);
        }    
    }

    createLike(obj, isLike, msg) {
        this.base.createTable({obj, is_like: isLike, user: Base.getCurrentUser()[this.userPk]}, 'Like').subscribe(resp => {
            if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp.error));
            }
            this.updateMessage(msg);
        });
    }

    async showLikes(obj, isLike, cnt) {
        if (!cnt) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Like',
                filters: [new BoardFilter('obj', Equal.EQ, obj), new BoardFilter('is_like', Equal.EQ, isLike)],
                funcRepr: 'self_.user.name',
                title: this.trans.instant(isLike ? 'like' : 'dislike'),
            },
            showBackdrop: false
        });
        return await modal.present();
    }

    trySendMessage(code) {
        if (code !== 13 || !this.newMessage) {
            return;
        }
        this.sendMessage(this.currentUser.chat_id, null, 0, this.newMessage, null); 
        setTimeout(() => {
            this.newMessage = null;
        }, 100);
    }

    async getBackground() {
        const chatPk = this.base.getTablePk('ChatBackground');
        const backgroundPk = this.base.getTablePk('Background');
        const modal = await this.modalCtrl.create({
            component: BackgroundPage,
            cssClass: 'modal-desk-detail',
            showBackdrop: false,
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.background) {
                if (this.chatBackground && this.chatBackground[chatPk]) {
                    this.messageService.updateBackground(this.chatBackground[chatPk], this.chatBackground.edited, 
                                                         detail.data.background[backgroundPk]).subscribe(resp => {
                        if (resp && resp[chatPk]) {
                            this.chatBackground.background = detail.data.background;
                            this.chatBackground.edited = resp.edited;
                            this.chatBackground[chatPk] = resp[chatPk];
                            this.background = this.base.getBackground(this.chatBackground.background, true);
                        } else if (resp && resp.error) {
                            this.base.sendToast(this.base.getError(resp.error));
                        }
                    });
                } else {
                    this.messageService.createBackground(detail.data.background[backgroundPk]).subscribe(resp => {
                        if (resp && resp[chatPk]) {
                            this.chatBackground =    {
                                background: detail.data.background,
                                edited: resp.edited
                            };
                            this.chatBackground[chatPk] = resp[chatPk];
                            this.background = this.base.getBackground(this.chatBackground.background, true);
                        } else if (resp && resp.error) {
                            this.base.sendToast(this.base.getError(resp.error));
                        }
                    });
                }
            }
        });
        return await modal.present();
    }

    async showNewMessage(sender, message) {
        const toast = await this.toastController.create({
            header: this.trans.instant('new-message-from', {from: sender}),
            message,
            position: 'bottom',
            duration: 1000 * 10,
            showCloseButton: false,
            animated: true,
            cssClass: 'new-msg-toast',
            buttons: [
                {
                    side: 'end',
                    icon: 'mail-open',
                    role: 'success',
                    handler: () => {
                        this.isShowChat = true;
                        this.closeEvent.emit({isShowChat: this.isShowChat, unread: this.unread});
                    }
                },
                {
                    side: 'end',
                    icon: 'close',
                    role: 'cancel',
                    handler: () => {}
                },
            ]
        });
        toast.present();
    }
}
