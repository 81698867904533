import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Equal } from '../classes/enums';
import { BaseService } from '../services/base.service';

@Component({
    selector: 'app-custom-class',
    templateUrl: './custom-class.page.html',
    styleUrls: ['./custom-class.page.scss'],
})
export class CustomClassPage implements OnInit {

    item: any;
    classPk: any;
    
    constructor(private navParams: NavParams, private modalCtrl: ModalController,
                public base: BaseService) { }

    ngOnInit() {
        this.classPk = this.base.getTablePk('Class');
        if (this.navParams.get('item')) {
            this.item = this.navParams.get('item');
        } else {
            this.item = {
                data: [],
                name: '',
            };
            this.addBlock();
        }
    }

    closeModal() {
        this.modalCtrl.dismiss({item: this.item});
    }
    
    addBlock() {
        this.item.data.splice(this.item.data.length, 0, {
            height: { value: '', equal: Equal.LT },
            width: { value: '', equal: Equal.LT },
            styles: [],
        });
    }

    save() {
        if (this.item[this.classPk]) {
            this.base.updateTable({data: this.item.data, name: this.item.name}, 
                                  this.item[this.classPk], this.item.edited, 'Class').subscribe(resp => {
                if (resp && resp[this.classPk]) {
                    this.item.edited = resp.edited;
                    this.base.getClasses().subscribe(_ => {});
                    this.closeModal();
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        } else if (this.item.name && this.item.data) {
            this.base.createTable({data: this.item.data, name: this.item.name}, 'Class').subscribe(resp => {
                if (resp && resp[this.classPk]) {
                    this.item[this.classPk] = resp[this.classPk];
                    this.item.edited = resp.edited;
                    this.base.getClasses().subscribe(_ => {});
                    this.closeModal();
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        } else {
            this.closeModal();
        }
    }
}
