import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DatetimePage } from './datetime.page';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        MaterialDesignFrameworkModule,
        DatePickerModule,
        TranslateModule
    ],
    declarations: [DatetimePage],
    exports: [DatetimePage],
    entryComponents: [DatetimePage],
})
export class DatetimePageModule {}
