import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { BoardFilter } from 'src/app/classes/board-filters';
import { GroupBotProcess, Variable, ActionType, BotProcessField } from 'src/app/classes/botprocess';
import { Equal, FieldType, TableAction } from 'src/app/classes/enums';
import { Process, ProcessBind } from 'src/app/classes/process';
import { ProcessVersion } from 'src/app/classes/processversion';
import { FormPage } from 'src/app/form/form.page';
import { BaseService } from 'src/app/services/base.service';
import { MetaService } from 'src/app/services/meta.service';
import { ProcessBindPage } from '../process-bind/process-bind.page';

@Component({
    selector: 'app-process-form',
    templateUrl: './process-form.page.html',
    styleUrls: ['./process-form.page.scss'],
})
export class ProcessFormPage implements OnInit {

    @Input() fromId: Process;
    @Input() process: Process;
    @Input() group: GroupBotProcess;
    @Input() binds: ProcessBind[];
    @Input() processes: Process[];

    fromFields = [];
    actionTypes = [];
    tables = [];
    tab: number = 0;
    CONDITIONS_TAB = 0;
    ACTION_TAB = 1;
    ActionType = ActionType;
    FieldType = FieldType;
    connections: ProcessBind[] = [];

    constructor(private base: BaseService,
                private modalCtrl: ModalController,
                private alertCtrl: AlertController,
                private trans: TranslateService,
                private meta: MetaService) { }

    ngOnInit() {

        this.connections = this.binds.filter(b => (b.fromId && b.fromId.id === this.process.id) || (b.toId && b.toId.id === this.process.id))

        for (const key in ActionType) {
            if (!isNaN(Number(key))) {
                continue;
            }

            if ([ActionType.ADD, ActionType.CHANGE, ActionType.DELETE, ActionType.HTTP_GET, ActionType.HTTP_POST, ActionType.HTTP_PATCH, 
                ActionType.HTTP_PUT, ActionType.HTTP_DELETE, ActionType.ADD_FROM, ActionType.CHANGE_FROM].indexOf(parseInt(`${ActionType[key]}`)) === -1) {
                continue
            }
            this.actionTypes.splice(this.actionTypes.length, 0, {title: key, id: ActionType[key]})
        }
        this.actionTypes.splice(0, 0, {id: null, title: '-----'});

        for (const table of this.meta.getTablesArray()) {
            this.tables.splice(this.tables.length, 0, {title: table[1], id: table[0]});
        }
        this.tables.splice(0, 0, {id: null, title: '-----'});

        const filters: BoardFilter[] = [];
        for (const field of this.process.fields) {
            if (field.variableId && field.variableId.id) {
                filters.push(new BoardFilter('id', Equal.EQ, field.variableId.id));
            }
        }

        if (filters.length) {
            this.base.getData(Variable.table, 1, 999, '', filters, true).subscribe(resp => {
                if (resp && resp.data) {
                    for (const item of resp.data) {
                        const variable = Variable.fromJson(item);
                        for (const field of this.process.fields) {
                            if (field.variableId && field.variableId.id == variable.id) {
                                field.variableId =  Variable.fromJson(item);
                            }
                        }
                    }
                } else {
                    this.base.sendToast(this.base.getError(resp))
                }
            })
        }
    }

    create() {
        this.modalCtrl.dismiss({action: TableAction.CREATE});
    }

    delete() {
        this.modalCtrl.dismiss({action: TableAction.DELETE});
    }

    async closeModal() {
        if (this.process.id) {
            this.modalCtrl.dismiss({action: TableAction.UPDATE});
        } else {
            await this.askSave();
        }
    }

    async askSave() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('want-to-create'),
            buttons:  [
                {
                    text: this.trans.instant('yes'),
                    handler: (_) => {
                        this.create()
                    }
                },
                {
                    text: this.trans.instant('no'),
                    cssClass: 'secondary',
                    handler: (_) => {
                        this.modalCtrl.dismiss({});
                    }
                }, 
            ]
        });
        await alert.present();
    }

    async chooseVariable(field, obj?) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: Variable.table,
                filters: [new BoardFilter('is_parent', Equal.EQ, false), ProcessVersion.filter()],
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(Variable.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                if (obj) {
                    obj[field] = Variable.fromJson(details.data.item);
                } else {
                    this.process[field] = Variable.fromJson(details.data.item);
                }
            }
        });
        return await modal.present();
    }

    refreshFields(clear?: boolean) {
        if (!this.process.table) {
            this.process.fields = [];
            return
        }

        const table = this.base.getTableMeta(this.process.table);
        if (!table || !table.fields) {
            this.process.fields = [];
            return
        }

        if (clear) {
            this.process.fields = [];
        }

        for (const field of table.fields) {
            if (this.process.actionType === ActionType.DELETE && ['id', 'uuid'].indexOf(field.name) === -1) {
                continue
            }
            if (!this.process.fields.find(f => f.name === field.name)) {
                const f = this.base.copy(field);
                f.repr = null;
                this.process.fields.splice(this.process.fields.length, 0, BotProcessField.fromJson(f))
            }
        }
    }

    refreshFromFields() {
        if (!this.process.tableFrom) {
            this.fromFields = [];
            return
        }
        
        const table = this.base.getTableMeta(this.process.tableFrom);
        if (!table || !table.fields) {
            this.fromFields = [];
            return
        }

        const fromFields = [{id: null, title: '-----'}];
        for (const field of table.fields) {
            fromFields.push({id: field.name, title: field.verbose_name || field.name});
        }
        this.fromFields = fromFields;
    }

    changeActionType(ev) {
        switch (this.process.actionType) {
        case ActionType.ADD:
        case ActionType.CHANGE:
        case ActionType.DELETE:
        case ActionType.ADD_FROM:
        case ActionType.CHANGE_FROM:
            this.refreshFields(true);
            this.refreshFromFields();
            break;
        }
    }
    
    changeTable(ev) {
        this.refreshFields(true);
        this.refreshFromFields();
    }

    changeTableFrom(ev) {
        this.refreshFromFields();
    }

    async chooseField(field: BotProcessField) {
        const f = this.base.getTableMeta(this.process.table).fields.find(f => f.name === field.name);
        if (!f.related_model) {
            return
        }

        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: f.related_model,
                selected: field.ref
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = f.related_field || this.base.getTablePk(f.related_model);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                field.ref = details.data.item;
                if (pk == 'id') {
                    field.id = details.data.item[pk];
                } else {
                    field.uuid = details.data.item[pk];
                }
            }
        });
        return await modal.present();
    }

    async openBind(bind?: ProcessBind) {
        const modal = await this.modalCtrl.create({
            component: ProcessBindPage,
            showBackdrop: false,
            componentProps: {
                processes: this.processes,
                bind,
                processFrom: this.process,
            },
            backdropDismiss: false,
            cssClass: 'modal-item-detail-big'
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.bind) {
                this.binds.splice(this.binds.length, 0, details.data.bind);
                this.connections.splice(this.connections.length, 0, details.data.bind);
            } else if (details && details.data) {

                switch (details.data.action) {
                case TableAction.DELETE:

                    for (let i = 0; i < this.binds.length; i++) {
                        if (this.binds[i].id === bind.id) {
                            this.binds.splice(i, 1);
                            break;
                        }
                    }
                    for (let i = 0; i < this.connections.length; i++) {
                        if (this.connections[i].id === bind.id) {
                            this.connections.splice(i, 1);
                            break;
                        }
                    }
                    break 
                
                case TableAction.UPDATE:

                    this.base.updateTable(bind.serialize(), bind.id, null, ProcessBind.table).subscribe(resp => {
                        if (!resp || !resp.id) {
                            this.base.sendToast(this.base.getError(resp))
                        }
                    })
                    break
                }
                
            } 
        });
        return await modal.present();
    }

    editObj(field: any, table: string) {
        this.base.editObj(field, table, FormPage)
    }
}
