import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { AlertController, ModalController } from '@ionic/angular';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { TranslateService } from '@ngx-translate/core';
import { FormService } from '../../services/form.service';
import { MetaService } from 'src/app/services/meta.service';
import { Equal } from 'src/app/classes/enums';
import { BoardFilter } from 'src/app/classes/board-filters';

@Component({
    selector: 'app-row-widget',
    templateUrl: './row-widget.page.html',
    styleUrls: ['./row-widget.page.scss'],
})
export class RowWidgetPage implements OnInit {

    @Input() form: any;
    @Input() catalogSubject: any;
    @Input() w: any;
    @Input() rows: any;
    @Input() i: any;
    @Input() owner: any;
    @Input() table: any;
    @Input() tableCells: any;
    @Input() ownerSubject: any;
    @Output() change = new EventEmitter();
    @Output() fieldReprChanged = new EventEmitter();
    @Output() refreshRows = new EventEmitter();
    @Input() lang: string;
    relateds = [];
    choices = [];
    subscription: any;
    tables = [];
    tablesFields = {};
    hideBy2Table = null;

    constructor(public base: BaseService, 
                private meta: MetaService,
                private alertCtrl: AlertController, 
                private modalCtrl: ModalController,
                private trans: TranslateService,
                private formService: FormService) { }

    ngOnInit() {
        if (!this.lang) {
            this.lang = this.base.getLanguage();
        }
        if (this.w && !this.w.verboseNames) {
            this.w.verboseNames = {};
        }
        if (this.w && !this.w.placeholders) {
            this.w.placeholders = {};
        }
        this.tables = this.meta.getTablesArray();
        for (const t of this.base.getLocalMeta()) {
            this.tablesFields[t.table] = t.fields;
        }
        this.tables.splice(0, 0, [null, null]);
        this.changeField();
        if (this.ownerSubject) {
            this.subscription = this.ownerSubject.subscribe(_ => {
                this.changeOwner();
            });
        } else {
            this.changeOwner();
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    addHiearchy() {
        if (!this.w.hierarchies) {
            this.w.hierarchies = [];
        } 
        this.w.hierarchies.splice(this.w.hierarchies.length, 0, {});
    }

    async addRelatedFilter(w) {
        if (w.fieldChoices.length === 1) {
            w.relatedFilters.splice(w.relatedFilters.length, 0, w.fieldChoices[0]);
            return;
        }

        const inputs = [];
        for (let i = 0; i < w.fieldChoices.length; i++) {
            inputs.push({
                name: 'field',
                type: 'radio',
                label: w.fieldChoices[i].from.verbose_name,
                value: i.toString()
            });
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-filter-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            w.relatedFilters.splice(w.relatedFilters.length, 0, w.fieldChoices[parseInt(params, 10)]);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    getRelatedsFields(type, owner) {
        if (!owner) {
            return [];
        }
        const fields = this.base.copy(owner.filter(i => i.type === type || 
                                     (i.type === 'foreign' && type === 'user') || (i.type === 'user' && type === 'foreign')));
        fields.push({fieldName: ''});
        return fields;
    }

    getFieldChoices(name) {
        const fields = this.base.getTableMeta(this.table).fields;
        return this.base.getFieldChoices(name, fields);
    }

    changeOwner() {
        this.relateds = this.getRelatedsFields(this.w.type, this.owner);
    }

    changeField() {
        this.choices = this.getFieldChoices(this.w.fieldName);
    }

    onChange() {
        this.change.emit();
    }

    async chooseForeignDefault() {
        const field = this.base.getTableMeta(this.table).fields.find(i => i.name === this.w.fieldName);
        this.w.repr = field.repr || 'name';
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: field.related_model,
            },
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                this.w.defaultForeign = details.data.item;
            }
        });
        return await modal.present();
    }

    catalogRelatedFill(tableFrom, tableTo, rows) {
        this.formService.addRelatedCatalog(tableFrom, tableTo, rows);
    }

    addTableCell() {
        this.formService.addTableCell(this.tableCells, this.w, false, this.table);
    }

    addTableCellRow(tableCell) {
        this.formService.addTableCellRow(tableCell);
    }

    async chooseForm(table?, f?) {
        let value: any; let type = 1;
        if (table && f) {
            value = this.tablesFields[table].find(i => i.name === f).related_model;
        } else {
            if (this.w.type === 'edit') {
                if (this.w.fieldName) {
                    value = this.base.getTableMeta(this.table).fields.find(i => i.name === this.w.fieldName).related_model;
                    type = 0;
                } else {
                    value = this.table;
                    type = 0;
                }
            } else {
                value = this.base.getTableMeta(this.table).fields.find(i => i.name === this.w.fieldName).related_model;
            }
        }
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Form',
                selected: this.w.form,
                filters: [new BoardFilter('type', Equal.EQ, type), new BoardFilter('table', Equal.EQ, value)]
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                this.w.form = {name: details.data.item.name};
                const pk = this.base.getTablePk('Form');
                this.w.form[pk] = details.data.item[pk];
            }
        });
        return await modal.present();
    }

    runFieldReprChanged() {
        if (this.fieldReprChanged) {
            this.fieldReprChanged.emit({i: this.i});
        }
    }

    updateCatalog() {
        if (this.catalogSubject) {
            setTimeout(() => {
                this.catalogSubject.next(1);
            }, 2000);
        }
    }

    setHideBy2Table() {
        setTimeout(() => {
            this.hideBy2Table = null;
            if (this.w.hideBy) {
                const field = this.tablesFields[this.table].find(f => f.name === this.w.hideBy);
                if (field && field.related_model) {
                    this.hideBy2Table = this.base.copy(field.related_model);
                }
            }
        }, 1000);
    }

    onRefreshRows() {
        this.refreshRows.emit({rows: this.rows});
    }

    async addTable() {
        const tables = this.base.getLocalMeta().filter(t => !t.block);
        const inputs = [];
        for (const table of tables) {
            if (!table.fields.find(i => i.related_model === this.form.data.table)) {
                continue;
            }
            inputs.push({
                name: 'field',
                type: 'radio',
                label: table.verbose_name,
                value: table.table
            });
        }
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('tables-not-found'));
            return;
        } else if (inputs.length === 1) {
            this.w.relatedTable = inputs[0].value;
            this.w.relatedTableTitle = inputs[0].label;
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('choose-table'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            const input = inputs.find(i => i.value === params);
                            this.w.relatedTable = input.value;
                            this.w.relatedTableTitle = input.label;
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

}
