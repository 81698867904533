import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AlertController, ToastController, ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Constants } from '../classes/app.constants';
import { FieldType, Equal } from '../classes/enums';
import { BoardFilter } from '../classes/board-filters';
import { Board } from '../classes/board';
import { BoardDate } from '../classes/date';
import { ListDisplay } from '../classes/board-list-display';
import { OrderBy } from '../classes/board-order-by';
import { PortalResponse } from '../interfaces/portal-response';
import { Base } from '../classes/base';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    any: any;
    rootUrl = '';
    botDomain = '-';
    domains = {
        'woki.woki.site': 'Woki'
    };

    allowTelegram = [
    ];

    month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    weekdayShort = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    listResp: any;
    page = 1; 
    preCreatedFields = {
        Task: [
            {
                name: 'name', type: FieldType.CHAR, value: '', title: this.trans.instant('input-caption-task'), maxlength: 512
            }
        ]
    };
    viseFields = ['status_type', 'vise_type', 'vise_user'];
    auth = new BehaviorSubject(null);
    isShowMenu = false;
    openedPath = null;
    openedParentPath = {};
    board: Board = null;
    
    brands = {
    };

    barcodeFormats = ['CODE128', 'CODE128A', 'CODE128B', 'CODE128C', 'EAN', 'UPC', 'EAN8', 'EAN5', 'EAN13',
                      'EAN2', 'CODE39', 'ITF14', 'MSI', 'MSI10', 'MSI11', 'MSI1010', 'MSI1110', 'pharmacode', 'codabar'];

    oneDay = 1000 * 60 * 60 * 24;

    constructor(public alertController: AlertController, 
                private http: HttpClient, 
                private toastCtrl: ToastController,
                private trans: TranslateService,
                private router: Router, 
                private modalCtrl: ModalController) { }

    isBot(domain?) {
        if (!domain) {
            domain = this.getDomain();
        }
        return domain === this.botDomain;
    }

    getDomain(domain?) {
        return (domain || this.getRootUrl() || '').split('/')[2];
    }

    getCurrentApp(d?) {
        const domain = d || this.getDomain(d);
        if (domain) {
            return domain.split('-')[0].split('/')[0];
        }
    }

    sendToast(message, duration = 5000) {
        this.toastCtrl.create({
            message,
            duration
        }).then(toast => toast.present());
    }

    getErrorText(error) {
        if (error && error.error) {
            error = error.error;
        }
        if (typeof(error) === 'object' && error) {
            let msg = '';
            for (const key of Object.keys(error)) {
                if (typeof(error[key]) === 'object') {
                    msg += this.getErrorText(error[key]);
                } else if (error[key]) {
                    msg += `, ${error[key]}`;
                }
            }
            return msg;
        } else {
            return `, ${error}`;
        }
    }
    
    getError(error) {
        const e = this.getErrorText(error);
        return e.slice(2, e.length);
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            if (error && error.error && error.error.error) {
                this.sendToast(error.error.error);
            } else if (error && error.error) {
                result = error;
            }
            this.log(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }

    log(_: string) {}

    handlerBlank() {}

    toDate(v) {
        return (new BoardDate(v)).date;
    }

    dateTimeToString(v) {
        const d = this.toDate(v); 
        const hours = `${d.getHours()}`; const minutes = `${d.getMinutes()}`;
        return `${this.dateToString(v)} ${hours.length === 1 ? '0' : ''}${hours}:${minutes.length === 1 ? '0' : ''}${minutes}`;
    }

    dateToString(v) {
        return (new BoardDate(v)).dateRepr;
    }

    datetimeToString(v) {
        if (!v) {
            return '';
        }
        const d = this.toDate(v);
        return d.toJSON().slice(0, 11) + d.toTimeString().slice(0, 5);
    }

    dateObjToISO(d) {
        const days = `${d.getDate()}`; const month = `${d.getMonth() + 1}`; 
        return `${d.getFullYear()}-${month.length === 1 ? '0' : ''}${month}-${days.length === 1 ? '0' : ''}${days}`;
    }

    dateToStringPretty(v) {
        const d = this.toDate(v); 
        const hours = `${d.getHours()}`; const minutes = `${d.getMinutes()}`;
        let year = '';
        if ((new Date()).getFullYear() !== d.getFullYear()) {
            year = ` ${d.getFullYear()} ${this.trans.instant('year-short')}`;
        }
        return `${d.getDate()} ${this.trans.instant(this.month[d.getMonth()])}${year} ${this.trans.instant('at')}` + 
               ` ${hours.length === 1 ? '0' : ''}${hours}:${minutes.length === 1 ? '0' : ''}${minutes}`;
    }

    getData(table, page: number, pages: number, params: string, filters?: BoardFilter[], isChecked?: boolean): Observable<PortalResponse> {
        if (filters && filters.length) {
            params += BoardFilter.serialize(filters, isChecked);
        }
        let url = `${this.getRootUrl(table)}${this.getTablePath(table)}/${this.getPrefixMeta(table)}/?page=${page}&pages=${pages}`;
        if (params) {
            url += params;
        }
        return this.http.get<PortalResponse>(url)
    }

    getTablePath(table) {
        const t = this.getTableMeta(table);
        if (t && t.path) {
            return t.path;
        }
        return 'portal/api';
    }

    getDocument(table, primaryKey: string): Observable<any> {
        const pk = this.getTablePk(table);
        const params = `&status={}&${pk}=${primaryKey}`;
        return this.getData(table, 1, 1, params);
    }

    getTableOfDocumentType(docType) {
        let table = null;
        const documentTypes = this.getDocumentTypesDict(this.getLocalMeta());
        for (const p of Object.keys(documentTypes) ) {
            if (documentTypes[p] === docType) {
                table = p;
                break;
            }
        }
        return table;
    }

    updateObj(obj, table): Observable<any> {
        const pk = this.getTablePk(table);
        let data;
        data = {
            table,
            obj: obj[pk],
        };
        if (obj.options) {
            data.color = obj.options.color;
            data.image = obj.options.image;
            data.description = obj.options.description;
            data.user_image = obj.options.user_image;
        }
        return this.http.post<any>(`${this.getRootUrl(table)}portal/api/user-object/`, data)
        .pipe(
            tap(_ => this.log(`create ${table}`)),
            catchError(this.handleError('create'))
        );
    }

    uploadFile(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`${this.getRootUrl()}portal/image-upload`, formData)
        .pipe(
            tap(_ => this.log(`store image`)),
            catchError(this.handleError('store image'))
        );
    }

    getBackground(background: any, isFull: boolean = false): any {
        const style = {background: '#f4f5f7'};
        const attr = isFull ? 'source_full' : 'source';
        if (background) {
            if (background.is_color && background[attr]) {
                style.background = `${background[attr]}`;
            } else if (background[attr]) {
                return {background: `url(${background[attr]})`};
            }
        }
        return style;
    }

    decimalToString(num, digits?) {
        let v = null;
        if (typeof(num) === 'number') {
            v = num.toFixed(digits || 0);
        } else {
            v = num;
        }
        let s = String(v).split('.')[0];
        s = this.toMoney(s);
        if (digits && v) {
            const l = String(v).split('.');
            if (l.length === 2) {
                s += '.' + l[1].slice(0, digits);
            }
        }
        return s;
    }

    toMoney(s): string {
        let newString = '';
        let isMinus = false;
        if (s.slice(0, 1) === '-') {
            s = s.slice(1, s.length);
            isMinus = true;
        }
        for (let i = s.length; i > 0; i = i - 3) {
            let start = i - 3;
            if (start < 0) {
                start = 0;
            }
            if (newString) {
                newString = ' ' + newString;
            }
            newString = s.slice(start, i) + newString;
        }
        if (isMinus) {
            newString = '-' + newString;
        }
        return newString;
    }
    
    focusClass(cl) {
        const el = document.getElementsByClassName(cl);
        if (el && el.length) {
            const e = el[el.length - 1];
            const input = e.getElementsByTagName('input');
            if (input.length) {
                input[0].focus();
            } else {
                const t = e.getElementsByTagName('textarea');
                if ( t.length ) {
                    t[0].focus();
                } else {
                    const f = (ee) => {
                        ee.focus();
                    };
                    f(e);
                }
            }
        }
    }

    getMonthList() {
        const month = [];
        for (const m of this.month) {
            month.push(this.trans.instant(m));
        }
        return month.join(', ');
    }

    getTerm(date , isShort?) {
        if (date) {
            const currentDate = new Date();
            const d = this.toDate(date); 
            const hours = `${d.getHours()}`; const minutes = `${d.getMinutes()}`;
            const diff = Math.floor(Math.abs(d.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)) * 
                (d.getTime() - currentDate.getTime() < 0 ? -1 : 1) ;
            const time = `${hours.length === 1 ? '0' : ''}${hours}:${minutes.length === 1 ? '0' : ''}${minutes}`;
            let strDate = '';
            const tomorrow = new Date(); tomorrow.setDate(tomorrow.getDate() + 1);
            const yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);

            if (d.toJSON().slice(0, 10) === currentDate.toJSON().slice(0, 10)) {
                strDate = this.trans.instant('today-at') + ` ${time}`;
            } else if (d.toJSON().slice(0, 10) === tomorrow.toJSON().slice(0, 10)) {
                strDate = this.trans.instant('tomorrow-at') + ` ${time}`;
            } else if (d.toJSON().slice(0, 10) === yesterday.toJSON().slice(0, 10)) {
                strDate = this.trans.instant('yesterday-at') + ` ${time}`;
            } else if (diff >= 0 && diff < 7) {
                strDate = `${this.trans.instant(this.weekdayShort[d.getDay()])} ${this.trans.instant('at')} ${time}`;
            } else {
                strDate = this.dateToStringPretty(date);
            }

            if (isShort) {
                return strDate;
            }
            const status = this.getDateStatus(date);
            if (status === -2) {
                strDate += ` (${this.trans.instant('expired')}!)`;
            } else if (status === -1) {
                strDate += ` ${this.trans.instant('recently-expired')} (!)`;
            } else if (status === 1) {
                strDate += ` (${this.trans.instant('expired-soon')})`;
            } 
            return strDate;
        }
        return this.trans.instant('term');
    }

    getDateStatus(date) {
        const d = this.toDate(date); 
        const currentDate = new Date();
        if ((currentDate.getTime() - d.getTime()) / (1000 * 60 * 60) > 24) {
            return -2;
        } else if ((currentDate.getTime() - d.getTime()) / (1000 * 60 * 60) <= 24 && (currentDate.getTime() - d.getTime()) > 0) {
            return -1;
        } else if ((d.getTime() - currentDate.getTime() ) / (1000 * 60 * 60) <= 24 && (d.getTime() - currentDate.getTime()) > 0) {
            return 1;
        } else {
            return 2;
        }
    }

    getDateClass(date, isDone?) {
        if (date) {
            if (isDone) {
                return {'term-success': true};
            }
            const status = this.getDateStatus(date);
            if (status < 0) {
                return {'term-due-done': true};
            } else if (status === 1) {
                return {'term-due-go': true};
            }
        }
        return {'term-wait': true};
    }

    getDateSeparator(date) {
        const d = this.toDate(date);
        const tomorrow = new Date(); tomorrow.setDate(tomorrow.getDate() + 1);
        const yesterday = new Date(); yesterday.setDate(yesterday.getDate() - 1);
        const currentDate = new Date();
        let strDate = '';
        if (d.toJSON().slice(0, 10) === currentDate.toJSON().slice(0, 10)) {
            strDate = `${this.trans.instant('today')}, `;
        } else if (d.toJSON().slice(0, 10) === tomorrow.toJSON().slice(0, 10)) {
            strDate = `${this.trans.instant('tomorrow')}, `;
        } else if (d.toJSON().slice(0, 10) === yesterday.toJSON().slice(0, 10)) {
            strDate = `${this.trans.instant('yesterday')}, `;
        } else {
            strDate = `${this.trans.instant(this.weekday[d.getDay()])}, `;
        }
        return `${strDate}${d.getDate()} ${this.trans.instant(this.month[d.getMonth()])}, ${d.getFullYear()}`;
    }

    getUpdaterUrl(table) {
        let pk = Base.getCurrentUser()[this.getTablePk('User')];
        let token = this.getToken();
        let domain = this.getDomain();
        const data = this.getTableMeta(table);
        if (data && data.rootUrl && data.token && data.user) {
            pk = data.user; 
            token = data.token; 
            domain = data.rootUrl.split('/')[2];
            table = data.tableRoot;
        }
        return 'wss://chat.woki.one/updader?user=' + pk + '&url=' + domain + '&token=' + token + '&table=' + table;
    }

    getFieldChoices(name, fields) {
        if (fields) {
            const field = fields.find(i => i.name === name);
            if (field) {
                return field.choices.filter(i => i[0] !== '');
            }
        }
        return [];
    }

    createWebSocketUpdater(primaryKey, wsUpdater, urlUpdater, openedItem, subject?) {
        if (wsUpdater && wsUpdater.readyState !== 3) {
            wsUpdater.close();
        }
        if (!primaryKey || !urlUpdater) {
            return;
        }
        wsUpdater = new WebSocket(urlUpdater);
        wsUpdater.onmessage = (event) => { 
            if (event.data === 'ping') {
                return;
            }
            const data = JSON.parse(event.data);
            let table = '';
            if (urlUpdater) {
                table = urlUpdater.split(Equal.EQ)[urlUpdater.split(Equal.EQ).length - 1];
            }
            const pk = this.getTablePk(table);
            if ((data.method === 'save' || data.method === 'edit') && data.data[pk] === primaryKey && table) {
                this.getDocument(table, primaryKey).subscribe(resp => {
                    if (resp && resp.data && resp.data.length === 1 && openedItem && openedItem.openedItem) {
                        openedItem.openedItem = resp.data[0];
                        if (subject) {
                            subject.next(resp.data[0]);
                        }
                    }
                });
            }    
        };
        wsUpdater.onopen = () => {
            wsUpdater.send('start');
        };
        return wsUpdater;
    }

    getMessageDate(date) {
        const d = this.dateTimeToString(date);
        return `${d.slice(0, 5)}${d.slice(10, 16)}`;
    }

    getRootUrl(table?) {
        if (table) {
            const t = this.getTableMeta(table);
            if (t && t.rootUrl && t.token) {
                return t.token + '$' + t.rootUrl;
            }
        }
        return this.rootUrl || localStorage.getItem('rootUrl');
    }

    getDate(time) {
        return (new BoardDate(time)).date;
    }

    createTable(data, table): Observable<any> {
        const prefix = this.getPrefixMeta(table);
        return this.http.post<any>(`${this.getRootUrl(table)}${this.getTablePath(table)}/${prefix}/`, data)
        .pipe(
            tap(_ => this.log(`create ${table}`)),
            catchError(this.handleError('createTable'))
        );
    }

    removeTable(primaryKey, prefix, table?): Observable<any> {
        if (!prefix) {
            prefix = this.getPrefixMeta(table);
        }
        return this.http.delete<any>(`${this.getRootUrl(table || prefix)}${this.getTablePath(table || prefix)}/${prefix}/${primaryKey}/`)
        .pipe(
            tap(_ => this.log('delete table')),
            catchError(this.handleError('removeTable'))
        );
    }

    updateTable(data, primaryKey, edited, table): Observable<any> {
        data.edited = edited;
        const prefix = this.getPrefixMeta(table);
        return this.http.patch<any>(`${this.getRootUrl(table)}${this.getTablePath(table)}/${prefix}/${primaryKey}/`, data)
        .pipe(
            tap(_ => this.log(`update ${table}`)),
            catchError(this.handleError('updateTable'))
        );
    }

    getLocalMeta() {
        const m = localStorage.getItem('metaFields');
        if (m) {
            try {
                const a = JSON.parse(m);
                if (this.isBot() && a) {
                    const blockTables = ['portalbackground', 'portalchatbackground', 'portalclass', 'portalform', 'portallike', 
                                         'portalpage', 'portalrole', 'portaltemplate', 'portaluser', 'portaluserobject', 
                                         'portaluserpage', 'portalfield', 'device', 'slidergroupdevice', 'slider', 
                                         'slidergroup', 'botgroup', 'botmessage', 'botbind'];
                    for (const t of a) {
                        if (t && blockTables.find(i => i === t.table)) {
                            t.block = true;
                        }
                    }
                } 
                return a || [];
            } catch (e) {
                console.error(e);
            }
        }
        return [];
    }

    getPrefixMeta(table: string) {
        const t = this.getTableMeta(table);
        if (t && t.prefix) {
            return t.prefix;
        }
        return table.toLowerCase();
    }

    getTableMeta(table) {
        return this.getLocalMeta().find(i => i.table === table || i.table2 === table);
    }

    getTablePk(table) {
        const t = this.getTableMeta(table);
        if (t && t.fields && t.fields.length) {
            const field = t.fields.find(f => f.name === 'uuid') || t.fields.find(f => f.name === 'id') 
                || t.fields.find(f => f.name === '_id');
            if (field && field.name) {
                return field.name;
            }
        }
        return 'uuid';
    }

    getDocumentTypes(meta) {
        if (meta) {
            const vise = meta.find(i => i.table === 'Vise');
            if (vise && vise.fields) {
                const documentType = vise.fields.find(f => f.name === 'document_type');
                if (documentType) {
                    return documentType.choices;
                }
            }
        }
        return null;
    }

    getDocumentTypesDict(meta) {
        const types = this.getDocumentTypes(meta);
        const resp = {};
        if (types && types.length) {
            for (const t of types) {
                resp[t[1]] = t[0];
            }
        }
        return resp;
    }

    getClass(pk, returnData = true) {
        const m = localStorage.getItem('classes');
        if (m) {
            const classes = JSON.parse(m);
            if (classes && classes.length) {
                const classPk = this.getTablePk('Class'); 
                const cl = classes.find(i => i[classPk] === pk);
                if (cl) {
                    return returnData ? cl.data : cl;
                }
            }
        }
        return null;
    }

    getClasses(): Observable<any> {
        return this.http.get<any>(`${this.getRootUrl()}portal/api/class/?page=1&pages=5000`)
            .pipe(
                tap(resp => {
                    let data = [];
                    if (resp && resp.length) {
                        data = resp;
                    } else if (resp && resp.data && resp.data.length) {
                        data = resp.data;
                    }
                    localStorage.setItem('classes', JSON.stringify(data));
                }),
                catchError(this.handleError('getClasses', this.any))
            );
    }

    move(data, from, to) {
        if (to < data.length && to >= 0) {
            const c = data.splice(from, 1)[0];
            data.splice(to, 0, c);
        }
    }

    sort(data, field) {
        return data.sort((a, b) => {
            if (a[field] > b[field]) {
                return 1;
            }
            if (a[field] < b[field]) {
                return -1;
            }
            return 0;
        });
    }

    sortObj(data, field) {
        if (field.split('.').length === 2) {
            const f1 = field.split('.')[0]; const f2 = field.split('.')[1];
            return data.sort((a, b) => {
                if (a[f1] && b[f1] && a[f1][f2] > b[f1][f2]) {
                    return 1;
                }
                if (a[f1] && b[f1] && a[f1][f2] < b[f1][f2]) {
                    return -1;
                }
                return 0;
            });
        } else {
            return this.sort(data, field);
        }
    }

    // tslint:disable-next-line: variable-name
    getRepr(self_: any, funcRepr: string, root_?, page?) {
        const lang = this.getLanguage();
        const user = Base.getCurrentUser();
        if (funcRepr && self_ && (funcRepr.indexOf('self_') !== -1 || funcRepr.indexOf('root_') !== -1)) {
            try {
                // tslint:disable-next-line: no-eval
                const r = eval(funcRepr);
                return r;
            } catch {}
        } 
        if (self_) {
            return self_[funcRepr];
        } else {
            return null;
        }
    }

    // tslint:disable-next-line: variable-name
    getCalcRepr(self_: any, root_: any, funcRepr: string, relateds?, extra?, page?) {
        const lang = this.getLanguage();
        const user = Base.getCurrentUser();
        try {
            // tslint:disable-next-line: no-eval
            const r = eval(funcRepr);
            return r || '';
        } catch (e) {
            console.log(e, funcRepr);
        }
        return '';
    } 

    Up(index, data) {
        if (index > 0 && data) {
            const itemToMove = data.splice(index, 1)[0];
            data.splice(index - 1, 0, itemToMove);
        }
    }

    Down(index, data) {
        if (data.length && index + 1 < data.length) {
            const itemToMove = data.splice(index, 1)[0];
            data.splice(index + 1, 0, itemToMove);
        }
    }

    getFileUrl(fileId: string) {
        return fileId && fileId.startsWith("http") ? fileId : this.getRootUrl() + 'portal/get-file?file_id=' + fileId; 
    }

    uploadFileToDrive(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`${this.getRootUrl()}portal/upload-file`, formData)
        .pipe(
            tap(_ => this.log(`store file`)),
            catchError(this.handleError('store file'))
        );
    }

    uploadFileHeroku(file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<any>(`https://uploader-file.herokuapp.com/upload`, formData)
        .pipe(
            tap(_ => this.log(`store file`)),
            map((resp) => {
                if (resp && resp.file_id) {
                    resp.url = 'https://uploader-file.herokuapp.com/file/' + resp.file_id;
                }
                return resp;
            }),
            catchError(this.handleError('store file')),
        );
    }

    uploadFileWoki(file, bot: string, chatId: number) {
        const formData = new FormData();
        const url = "https://upload.woki.one/"
        formData.append('file', file, file.name);
        return this.http.post<any>(`${url}upload/${bot}/${chatId}`, formData)
        .pipe(
            tap(_ => this.log(`store file`)),
            map((resp) => {
                if (resp && resp.file_id) {
                    resp.url = `${url}file/${bot}/${resp.file_id}`;
                }
                return resp;
            }),
            catchError(this.handleError('store file')),
        );
    }

    doReorder(ev, data) {
        const itemToMove = data.splice(ev.detail.from, 1)[0];
        data.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    getCalcFields(tableName): Observable<any> {
        const table = this.getTableMeta('Portalfield');
        if (!table) {
            return of([]);
        }
        let tableRoot = tableName.slice();
        const t = this.getTableMeta(tableName);
        if (t && t.tableRoot) {
            tableRoot = t.tableRoot;
        }
        const q = `&filter_fields=${JSON.stringify([{name: 'table', equal: Equal.EQ, value: tableRoot}])}`;
        const url = `${this.getRootUrl(tableName)}portal/api/portalfield/?&page=1&pages=500&o=-edited${q}`;
        const type = table.fields.find(i => i.name === 'type');
        return this.http.get<any>(url).pipe(
            map((resp) => {
                resp.data = resp.data.map(item => {
                    let choices = [];
                    if (item.choices && item.choices.length) {
                        choices = item.choices;
                    } 
                    return {
                        verbose_name: item.verbose_name || item.name,
                        type: type.choices.find(i => i[0] === item.type)[1],
                        choices,
                        related_model: item.related_model || null,
                        repr: item.repr || null,
                        name: item.name || null,
                        blockDisplay: false,
                        blockList: false,
                        blockOrder: false,
                        isCalc: true,
                    };
                });
                return resp.data;
            })
        );
    }

    getEmptyFilter() {
        return {
            name: null, 
            verbose_name: null, 
            value: null, 
            type: null, 
            choices: null, 
            isChecked: true,
            equal: Equal.EQ,
            value_repr: null,
            hidden: false
        };
    }

    getListDisplay(listDisplay: ListDisplay[], metaFields): ListDisplay[] {
        const list: ListDisplay[] = ListDisplay.copy(listDisplay.filter(f => f.isChecked));
        
        for (const field of metaFields.filter(f => !f.blockDisplay)) {
            if (!listDisplay.find(item => item.name === field.name)) {
                const f = this.copy(field);
                f.isChecked = false
                list.push(ListDisplay.fromJson(f))
            }
        }
        return list;
    }

    getOrderBy(orderBy: OrderBy[], fields) {
        const list = OrderBy.copy(orderBy);
        for (const field of fields) {
            if (!orderBy.find(item => item.name === field.name) && !field.blockOrder) {
                list.push(OrderBy.fromJson(field));
            }
        }
        return list;
    }

    copy(val) {
        return Base.copy(val);
    }

    itemWsUpdate(pk, ws, url, subject, key, openedItem) {
        if (!openedItem || !openedItem.openedItem || openedItem.openedItem[key] !== pk || !pk || !url) {
            if (ws && ws.readyState !== 3) {
                ws.close();
            }
            return;
        }
        if (!ws || ws.readyState === 3) {
            ws = this.createWebSocketUpdater(pk, ws, url, openedItem, subject);
        }
        return ws;
    }

    async openItemModal(table, props, openedItem, page, cssClass, backdropDismiss) {
        props.changedItemSubject = new BehaviorSubject(null);
        const url = this.getUpdaterUrl(table); 
        const pk = this.getTablePk(table);
        let ws = this.itemWsUpdate(openedItem.openedItem[pk], null, url, props.changedItemSubject, pk, openedItem);

        const interval = setInterval(() => {
            ws = this.itemWsUpdate(openedItem.openedItem[pk], ws, url, props.changedItemSubject, pk, openedItem);
            if (!ws) {
                clearInterval(interval);
            }
        }, 5000);

        const modal = await this.modalCtrl.create({
            component: page,
            componentProps: props,
            showBackdrop: false,
            cssClass,
            backdropDismiss: backdropDismiss
        });

        modal.onDidDismiss().then((_) => {
            openedItem = null;
            clearInterval(interval);
            if (ws) {
                ws.close();
            }
        });
        return await modal.present();
    }

    addAccount(username, host) {
        this.setLogin(username);
        this.setRootUrl(host);
    }

    setRootUrl(host: string) {
        if (host.startsWith("http://") || host.startsWith("https://")) {
            this.rootUrl = `${host}/`;
            localStorage.setItem('rootUrl', this.rootUrl);
        } else {
            this.rootUrl = `https://${host}/`;
            localStorage.setItem('rootUrl', this.rootUrl);
        }
    }

    setLogin(username) {
        localStorage.setItem('username', username);
    }

    getLogin() {
        return localStorage.getItem('username');
    }

    getAccounts() {
        const accounts = localStorage.getItem('accounts');
        if (accounts) {
            try {
                const a =  JSON.parse(accounts);
                return a || [];
            } catch {
            }
        }
        return [];
    }

    setAccounts(accounts) {
        localStorage.setItem('accounts', JSON.stringify(accounts));
    }

    setAccount(token, user) {
        const accounts = this.getAccounts().filter(a => !(a.token === token && a.rootUrl === this.getRootUrl()));
        accounts.push({token, user, rootUrl: this.getRootUrl()});
        this.setAccounts(accounts);
    }

    logout(full, current) {
        let accounts = this.getAccounts();
        if (full) {
            accounts = [];
        } else if (current) {
            accounts = accounts.filter(f => !(f.token === this.getToken() && f.rootUrl === this.getRootUrl()));
        }
        const lang = this.getLanguage();
        localStorage.clear();
        Base.currentUser = null;
        localStorage.setItem('lang', lang);
        this.setAccounts(accounts);
        this.auth.next(0);
        this.router.navigate(['login']);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    setCurrentUser(user) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.setAccount(this.getToken(), user);
    }

    setMetaFields(fields) {
        localStorage.setItem('metaFields', JSON.stringify(fields));
    }

    setLogo() {

        let el: any; el = document.getElementById('document-title');
        document.title = 'Woki';
        if (el) {
            el.href = 'assets/icon/favicon.png';
        }
    }

    setLanguage(lang) {
        if (!Constants.LANGUAGES.find(l => l.code === lang)) {
            lang = Constants.DEFAULT_LANG;
        }
        localStorage.setItem('lang', lang);
        return this.trans.use(lang);
    }

    getLanguage() {
        const lang = localStorage.getItem('lang');
        if (!Constants.LANGUAGES.find(l => l.code === lang)) {
            return Constants.DEFAULT_LANG;
        }
        return lang;
    }

    getFiltersData(filterData, isChecked?) {
        const filters = [];
        for (const f of filterData || []) {
            if (f.name && f.equal && (f.isChecked || isChecked)) {
                filters.push({
                    name: f.name,
                    value: f.value,
                    equal: f.equal
                });
            } else if (f.or && f.or.length) {
                const or = this.getFiltersData(f.or);
                if (or.length) {
                    filters.push({or});
                }
            }
        }
        return filters;
    }

    setDefault(item, form, rootItem, activeRelateds, relateds) {
        for (const row of form.data.rows || []) {
            if (row.relatedDefault && rootItem.openedItem[row.relatedDefault]) {
                item[row.fieldName] = this.copy(rootItem.openedItem[row.relatedDefault]);
            } else if (item[row.fieldName] !== undefined && item[row.fieldName] !== null) {
                continue;
            } else if (row.type === 'checkbox') {
                item[row.fieldName] = row.defaultBool ? true : false;
            } else if (row.type === 'date') {
                const d = new Date();
                if (row.defaultDays) {
                    d.setDate(d.getDate() + row.defaultDays);
                }
                item[row.fieldName] = this.dateObjToISO(d);
            } else if (row.type === 'datetime') {
                const d = new Date();
                if (row.defaultDays) {
                    d.setDate(d.getDate() + row.defaultDays);
                }
                item[row.fieldName] = this.datetimeToString(d);
            } else if (row.type === 'datetimepicker') {
                const d = new Date();
                if (row.defaultDays) {
                    d.setDate(d.getDate() + row.defaultDays);
                }
                item[row.fieldName] = d;
            } else if (row.type === 'user') {
                if (row.defaultCurrent) {
                    item[row.fieldName] = Base.getCurrentUser();
                }
            } else if (row.type === 'select') {
                if (row.defaultSelect !== null && row.defaultSelect !== undefined) {
                    item[row.fieldName] = row.defaultSelect;
                }
            } else if (row.type === 'input' && row.inputDefault !== null && row.inputDefault !== undefined) {
                item[row.fieldName] = row.inputDefault;
            } else if (row.defaultForeign) {
                item[row.fieldName] = this.copy(row.defaultForeign);
            }

            if (row.defaultCalc) {
                item[row.fieldName] = this.getCalcRepr(item, rootItem, row.defaultCalc, relateds);
            }
        }
        if (form.relatedTable && form.relatedField && form.relatedFieldPk && activeRelateds && activeRelateds.values) {
            let relValue;
            if (activeRelateds.values[form.relatedTable]) {
                relValue = {};
                relValue[form.relatedFieldPk] = this.copy(activeRelateds.values[form.relatedTable]);
            }
            item[form.relatedField] = relValue;
        }
    }

    translate(keys) {
        const lang = this.getLanguage();
        if (keys) {
            const val = keys[lang];
            if (val) {
                return val;
            } else {
                for (const k of Object.keys(keys)) {
                    if (keys[k]) {
                        return keys[k];
                    }
                }
            }
        }
        return null;
    }

    getFormSize(props) {
        if (props.form && props.form.data && props.form.data.size === 'big') {
            return 'modal-item-detail-big';
        }
        return 'modal-item-detail';
    }

    openForm(formPage, props, onDidDismiss, table?, openedItem?, backdropDismiss?) {
        const formPk = this.getTablePk('Form');
        if (props.form && props.form.data && props.form.data.forms && props.form.data.forms.length) {
            let minWidth = 999999; let minHeight = 999999; let newForm;
            for (const row of props.form.data.forms) {
                if (row.width && row.height && row.width >= window.innerWidth && row.height >= window.innerHeight
                    && row.width < minWidth && row.height < minHeight && row.form && row.form[formPk]) {
                    minHeight = row.height; minWidth = row.width;
                    newForm = row.form[formPk];
                }
            }
            if (newForm) {
                this.getData('Form', 1, 1, '', [new BoardFilter(formPk, Equal.EQ, newForm)], true).subscribe(resp => {
                    if (resp && resp.data.length) {
                        props.form = resp.data[0];   
                    }
                    if (table) {
                        this.openItemModal(table, props, openedItem, formPage, this.getFormSize(props), backdropDismiss);
                    } else {
                        this.openFormModal(props, this.getFormSize(props), formPage, onDidDismiss, backdropDismiss);
                    }
                })
                return;
            }
        }
        if (table) {
            this.openItemModal(table, props, openedItem, formPage, this.getFormSize(props), backdropDismiss);
        } else {
            this.openFormModal(props, this.getFormSize(props), formPage, onDidDismiss, backdropDismiss);
        }
    }

    async openFormModal(props, cssClass, formPage, onDidDismiss, backdropDismiss) {
        const modal = await this.modalCtrl.create({
            component: formPage,
            componentProps: props,
            cssClass,
            showBackdrop: false,
            backdropDismiss
        });
        modal.onDidDismiss().then((detail) => {
            if (onDidDismiss) {
                onDidDismiss(detail);
            }
        });
        return await modal.present();
    }

    editObj(field: any, table: string, formPage: any) {
        if (!field || !table) {
            return 
        }

        this.getData('Form', 1, 1, '', [new BoardFilter('type', Equal.EQ, 0), new BoardFilter('table', Equal.EQ, table), new BoardFilter('is_default', Equal.EQ, true)], true).subscribe(resp => {
            if (resp && resp.data && resp.data.length === 1) {
                this.beforeOpenItem(resp.data[0], field, table, formPage);
            } else {
                console.error('Object is not found', table, field);
            }
        });
    }

    async beforeOpenItem(form: any, field: any, table: string, formPage) {
        const pk = this.getTablePk(table);
        this.getData(table, 1, 2, '', [new BoardFilter(pk, Equal.EQ, field[pk])], true).subscribe(resp => {
            if (resp && resp.data && resp.data.length === 1) {
                const props: any = {}; 
                props.item = {openedItem: resp.data[0]};
                props.form = form;
                this.openForm(formPage, props, (_) => {});
            } else {
                console.error('Object is not found', form, field, table);
            }
        });
    }
}
