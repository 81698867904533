import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor(private base: BaseService, 
                private alertCtrl: AlertController,
                private trans: TranslateService) { }

    async addTableCell(tableCells, w, withoutField, rootTable) {
        const inputs = [];
        for (const rel of tableCells) {
            const table = this.base.getTableMeta(rel.data.table);
            inputs.push({name: 'field', type: 'radio', label: table.verbose_name || table.table, value: table.table});
        }
        
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('resource-table-is-not-found'));
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-resource-table'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.addTableCellSouce(params, w, withoutField, rootTable);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addTableCellSouce(tableT, w, withoutField, rootTable) {
        let field;
        
        if (!withoutField) {
            field = this.base.getTableMeta(rootTable).fields.find(i => i.name === w.fieldName);
            if (!field.related_model) {
                return;
            }
        }

        let inputs = [];
        for (const table of this.base.getLocalMeta().filter(t => !t.block)) {
            if (table.table === rootTable || table.table === tableT) {
                continue;
            }
            if (withoutField || table.fields.find(i => i.related_model === field.related_model)) {
                inputs.push({ name: 'field', type: 'radio', label: table.verbose_name || table.table, value: table.table });
            }
        }

        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('source-table-is-not-found'));
            return;
        }

        inputs = this.base.sort(inputs, 'label');
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-source-table'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            const tableTo = this.base.getTableMeta(tableT);
                            const tableFrom = this.base.getTableMeta(params);
                            if (!w.tableCells) {
                                w.tableCells = [];
                            }
                            tableFrom.title = tableFrom.verbose_name || tableFrom.table;
                            tableTo.title = tableTo.verbose_name || tableTo.table;
                            w.tableCells.splice(w.tableCells.length, 0, {from: tableFrom, to: tableTo, rows: [], 
                                                                         filters: [], related_filters: []});
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addTableCellRow(tableCell) {
        const fields = this.base.getTableMeta(tableCell.from.table).fields;
        const inputs = [];

        for (const f of fields) {
            inputs.push({ name: 'field', type: 'radio', label: f.verbose_name || f.name, value: f.name });
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-source-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.addTableCellRowTo(fields.find(i => i.name === params), tableCell);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addTableCellRowTo(fieldFrom, tableCell) {
        const fields = this.base.getTableMeta(tableCell.to.table).fields;
        const inputs = [];

        for (const f of fields) {
            if (fieldFrom.type === f.type && fieldFrom.related_model === f.related_model) {
                inputs.push({ name: 'field', type: 'radio', label: f.verbose_name || f.name, value: f.name });
            }
        }
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('field-not-found'));
            return;
        }

        const success = (params) => {
            if (params) {
                const fieldTo = fields.find(i => i.name === params);
                fieldTo.title = fieldTo.verbose_name || fieldTo.name;
                fieldFrom.title = fieldFrom.verbose_name || fieldFrom.name;
                tableCell.rows.splice(tableCell.rows.length, 0, {from: fieldFrom, to: fieldTo});
            }
        };
        if (inputs.length === 1) {
            success(inputs[0].value);
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-resource-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: success
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addRelatedCatalog(tableFrom, tableTo, rows) {
        const fields = this.base.getTableMeta(tableFrom).fields;
        const inputs = [];

        for (const f of fields) {
            inputs.push({ name: 'field', type: 'radio', label: f.verbose_name || f.name, value: f.name });
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-source-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params) {
                            this.addRelatedCatalogTo(fields.find(i => i.name === params), tableTo, rows);
                        }
                    }
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }

    async addRelatedCatalogTo(fieldFrom, tableTo, rows) {
        const fields = this.base.getTableMeta(tableTo).fields;
        const inputs = [];

        for (const f of fields) {
            if (fieldFrom.type === f.type && fieldFrom.related_model === f.related_model) {
                inputs.push({ name: 'field', type: 'radio', label: f.verbose_name || f.name, value: f.name });
            }
        }
        if (!inputs.length) {
            this.base.sendToast(this.trans.instant('field-not-found'));
            return;
        }

        const success = (params) => {
            if (params) {
                const fieldTo = fields.find(i => i.name === params);
                fieldTo.title = fieldTo.verbose_name || fieldTo.name;
                fieldFrom.title = fieldFrom.verbose_name || fieldFrom.name;
                rows.splice(rows.length, 0, {from: fieldFrom, to: fieldTo});
            }
        };
        if (inputs.length === 1) {
            success(inputs[0].value);
            return;
        }

        const alert = await this.alertCtrl.create({
            header: this.trans.instant('input-resource-field'),
            inputs,
            buttons: [
                {
                    text: this.trans.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }, 
                {
                    text: this.trans.instant('ok'),
                    handler: success
                }
            ],
            cssClass: 'alert-width-big'
        });
        await alert.present();
    }
}
