import { Component, OnInit, HostListener, Input, ViewChild, ElementRef } from '@angular/core';
import { ModalController, AlertController, PopoverController } from '@ionic/angular';
import { BaseService } from '../services/base.service';
import { DeskDetailPage } from '../desk-detail/desk-detail.page';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '../services/template.service';
import { DragulaService } from 'ng2-dragula/dist';
import { MetaService } from '../services/meta.service';
import { ViseStatusService } from '../services/vise-status.service';
import { AuthService } from '../services/auth.service';
import { TaskPage } from '../task/task.page';
import { TaskService } from '../services/task.service';
import { FormPage } from '../form/form.page';
import { AppComponent } from '../app.component';
import { Observable, zip, of, BehaviorSubject, Subscription } from 'rxjs';
import { ChoicesPage } from '../choices/choices.page';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { SubMenuPage } from '../components/sub-menu/sub-menu.page';
import { MenuPage } from '../menu/menu.page';
import { ImportFromExcelPage } from '../components/import-from-excel/import-from-excel.page';
import { ImportImagesPage } from '../components/import-images/import-images.page';
import { BoardEventType, Equal, FieldType, Interval, FunctionType } from '../classes/enums';
import { BoardEvent, Board } from '../classes/board';
import { BoardFilter, Choice } from '../classes/board-filters';
import { GroupByList } from '../classes/board-group-by';
import { OrderBy } from '../classes/board-order-by';
import { ListDisplay } from '../classes/board-list-display';
import { Base } from '../classes/base';
import { Constants } from '../classes/app.constants';
import { BoardButton, ButtonAction } from '../classes/board-buttons';
import { GeoLocation } from '../classes/location';

@Component({
    selector: 'app-list',
    templateUrl: './list.page.html',
    styleUrls: ['./list.page.scss'],
})
export class ListPage implements OnInit {
    
    @Input() hideHeader: any;
    @Input() table: string;
    @Input() templateVal: any;
    @Input() widgetDesk = false;
    @ViewChild('caption') caption: ElementRef;
    @ViewChild('boardContainter') container: ElementRef;
    @ViewChild('body') body: ElementRef;
    @ViewChild('searchInput') searchInput: any;
    @ViewChild('inputSearch') inputSearch: any;

    // used in groupBy period
    getDate = new BehaviorSubject(null);
    setDate = new BehaviorSubject(null);

    refresherGraph = new BehaviorSubject<Board>(null);

    // export to excel data.
    exportSubject = new BehaviorSubject(null);

    // send & receive scanned data.
    scannerResult = new BehaviorSubject(null);
    scannerSubject = new BehaviorSubject(1);
    openScanner: boolean = false;

    showFormating: boolean = false;
    showSelection: boolean = false;

    allSize = 0;
    loadingSpinnerX = false;
    search: string;
    innerWidth = 0;
    groups = [];
    isNote = false;
    isChanged = false;
    title: any;
    nextTemplate: Board = null;
    background: any;
    boardGroup: GroupByList[] = [];
    canRequestGroup = true;
    metaFields = [];
    fieldChoices = {};
    currentUser = null;
    dataIsLoaded = true;
    blockScroll = false;
    startIndex = 0;
    preCreatedFields: any;    // Fields that used by short creating item.
    bag = 'bag';
    viseFields = [];
    lBag = 'lbag';
    showFilters = false;
    url = '';
    ws: any;
    openedItem: any;
    showGraph = {ok: false};
    columns: ListDisplay[] = [];
    searchFocus = false;
    userPk: string;
    templatePk: string;
    interval: any;
    cardCanMoving = false;
    calcs = [];
    defaultForm = null;
    searchValue: any;
    emptyGroup: GroupByList = GroupByList.default();
    pistolScan = false;
    isShowMenu = false;
    currentBoard = 0;
    xDown;
    yDown;
    scaleBig = false;

    subscriptionResult: Subscription;
    subscriptionBoardEvent: Subscription;
    setDateSubscription: Subscription;

    updateVersion: number;

    _board: Board;

    private pageList: number = 1;

    // send changes of current opened object
    private changedItemSubject = new BehaviorSubject(null);
    hierarchySubject = new BehaviorSubject(null);
    
    mapUpdater: BehaviorSubject<GeoLocation> = new BehaviorSubject(null);

    private state: string;

    // Remember the width of window when that is changed.
    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.innerWidth = window.innerWidth - 20;
    }

    constructor(private base: BaseService, 
                private modalCtrl: ModalController, 
                private activatedRoute: ActivatedRoute, 
                private templateService: TemplateService, 
                private alertCtrl: AlertController, 
                private meta: MetaService, 
                private dragulaService: DragulaService, 
                private viseStatusService: ViseStatusService,
                private authService: AuthService, 
                private router: Router, 
                private taskService: TaskService,
                public app: AppComponent, 
                private trans: TranslateService,
                private popoverCtrl: PopoverController) {
        this.drag();
    }

    get board(): Board {
        if (!this._board) {
            this._board = Board.default(this.table);
        }
        return this._board;
    }

    set board(val: Board) {
        val.refresh();
        this._board = val;
    }

    refreshMap() {
        if (!this.board.isMap) {
            return;
        }
        if (!this.board.mapFunc) {
            return;
        }

        for (const group of this.boardGroup.filter(g => g.data && g.data.length)) {
            for (const item of group.data) {
                const val = this.base.getCalcRepr(item, item, `JSON.stringify(` + this.board.mapFunc + `)`);
                if (val && val !== '{}') {
                    const loc = JSON.parse(val);
                    this.mapUpdater.next(new GeoLocation(loc.lat, loc.lon, loc.id, loc.title, loc.icon, loc.label));
                } 
            }
        }
    }

    ngOnInit() {
        this.state = window.location.href;
        this.userPk = this.base.getTablePk('User');
        this.templatePk = this.base.getTablePk('Template');
        this.viseFields = this.base.viseFields;
        this.currentUser = Base.getCurrentUser(); 
        this.innerWidth = window.innerWidth - 20;    // Get inner width of window.

        // Get table from url.
        if (!this.table) {
            this.table = this.activatedRoute.snapshot.paramMap.get('table');
        }
        // Get first table.
        if (!this.table) {
            this.table = this.base.getLocalMeta().filter(t => !t.block)[0].table;
        }

        this.preCreatedFields = this.base.preCreatedFields[this.table];
        this.title = this.meta.getTitlePluralMeta(this.table);
        const meta = this.base.getTableMeta(this.table);
        this.metaFields = meta ? meta.fields : {};

        // Get template from widget.
        const temp = this.templateVal ? this.base.copy(this.templateVal) : null;
        
        // Set default empty template: for escape error.
        this.board = Board.default(this.table);

        this.changeGroupBy();
        if (temp) {
            this.templateService.getTemplates(null, 1, 1, null, false, 
                                              [{name: this.templatePk, equal: Equal.EQ, value: temp}]).subscribe(resp => {
                if (resp && resp.data && resp.data.length === 1) {
                    this.board = Board.fromJson(resp.data[0]);
                    this.setPeriodDate();
                    this.changeGroupBy();
                    this.afterGettingTemplate(true);
                }
            });
        } else {
            this.afterGettingTemplate();
        }

        if (!this.app.isAuth) {
            this.authService.refreshTablesClasses().subscribe(_ => {});
            this.app.isAuth = true;
            this.app.changed = true;
            this.base.auth.next(0);
        } else {
            const user = Base.getCurrentUser();
            if (user && user.binotel) {
                this.app.isAuth = true;
                this.app.changed = true;
                this.base.auth.next(0);
            }
        }

        this.subscriptionResult = this.scannerResult.subscribe(text => {
            this.scanned(text);
        });

        this.subscriptionBoardEvent = BoardEvent.subject.subscribe(ev => {
            if (ev && ev.table === this.table) {
                if (ev.filters.length) {
                    this.filterBoard(ev.filters);
                }

                switch (ev.type) {
                case BoardEventType.OPEN_ITEM:
                    if (!ev.pk) {
                        this.openItem({}, true);
                    } else {
                        this.base.getData(this.table, 1, 2, '', [new BoardFilter(this.base.getTablePk(this.table), Equal.EQ, ev.pk)], true).subscribe(resp => {
                            if (resp && resp.data && resp.data.length === 1) {
                                this.openItem(resp.data[0], false);
                            }
                        })
                    }
                    break;
                }
            }
        })

        this.setDateSubscription = this.setDate.subscribe(resp => {
            if (resp && this.board.groupBy.periodBegin && this.board.groupBy.periodEnd) {
                this.findPeriodFilter(resp.begin, false, this.board.groupBy.periodBegin, Equal.GTE);
                this.findPeriodFilter(resp.end, this.board.groupBy.periodEnd === this.board.groupBy.periodBegin, this.board.groupBy.periodEnd, Equal.LTE);

                this.doRefresh(undefined);
                this.isChanged = true;
                this.refresherGraph.next(this.board);
            }
        });
    }
    
    private filterBoard(filters: BoardFilter[]) {
        for (const f of filters) {
            const field = this.metaFields.find(m => m.name === f.name);
            if (!field) {
                continue
            }
            this.board.filters.splice(this.board.filters.length, 0, new BoardFilter(
                f.name, f.equal, f.value, f.repr, field.verbose_name, field.type, 
                Choice.fromJson(field.choices || this.fieldChoices[field.name]), true, false)
            )
        }
        this.refreshAfterFilters();
    }

    private findPeriodFilter(value, sameField, fieldName, equal) {
        let isFound = false; 
        for (const f of this.board.filters) {
            if (f.name === fieldName) {
                if (!isFound && ((sameField && !f.isChecked) || !sameField)) {
                    f.isChecked = true;
                    f.equal = equal;
                    f.value = value;
                    f.repr = '';
                    f.hidden = true;
                    isFound = true;
                } else if (!sameField) {
                    f.isChecked = false;
                }
            }
        }
        if (!isFound) {
            const o: any = {}; o[fieldName] = value;
            this.addFilter(ListDisplay.fromJson(this.metaFields.find(m => m.name === fieldName)), o, true, equal, true);
        }
    }

    private setPeriodDate() {
        if ( this.board.groupBy.periodBegin && this.board.groupBy.periodEnd) {
            let begin; let end;
            const beginFilter = this.board.filters.find(f => f.name === this.board.groupBy.periodBegin 
                && f.isChecked && f.hidden && f.equal === Equal.GTE && f.value);
            if (beginFilter) {
                begin = this.base.copy(beginFilter.value);
            }
            const endFilter = this.board.filters.find(f => f.name === this.board.groupBy.periodEnd 
                && f.isChecked && f.hidden && f.equal === Equal.LTE && f.value);
            if (endFilter) {
                end = this.base.copy(endFilter.value);
            }
            if (begin && end) {
                this.getDate.next({begin, end});
            }
        }
    }

    ionViewCanEnter() {
        const isLogged = this.authService.authenticated();
        if (!isLogged) {
            this.router.navigate(['login']);
        }
        return isLogged;
    }

    ionViewDidEnter() {
        setTimeout(() => {
            // Scroll board by mouse move.
            if (!this.container) {
                return;
            }
            let x = 0;
            const onmousemove = (e) => {
                if (this.blockScroll) {
                    return;
                }
                const delta = x - e.clientX;
                this.container.nativeElement.scrollLeft = this.container.nativeElement.scrollLeft + delta;
                x = e.clientX;
            };
            this.body.nativeElement.onmousedown = (ev) => {
                if (ev.which !== 1 || this.blockScroll) {
                    return;
                }
                this.body.nativeElement.onmousemove = (e) => {
                    onmousemove(e);
                };
                x = ev.clientX;
            };
            this.body.nativeElement.onmouseup = (_) => {
                x = 0;
                this.body.nativeElement.onmousemove = null;
            };
        }, 1000);
    }

    ionViewWillLeave() {
        if (this.subscriptionResult) {
            this.subscriptionResult.unsubscribe();
        }
        if (this.subscriptionBoardEvent) {
            this.subscriptionBoardEvent.unsubscribe();
        }
        if (this.setDateSubscription) {
            this.setDateSubscription.unsubscribe();
        }
        if (this.isChanged) {
            this.saveTemplate();
        }
        if (this.ws && this.ws.readyState !== 3) {
            this.ws.close();
            this.ws = null;
        }
        clearInterval(this.interval);
    }

    async openMenu(ev) {
        this.isShowMenu = !this.isShowMenu;
        if (!this.isShowMenu) {
            return;
        }
        const popover = await this.popoverCtrl.create({
            component: MenuPage,
            showBackdrop: false,
            event: ev,
            componentProps: {isPopover: true, app: this.app},
            cssClass: 'menu-popover'
        });
        popover.onDidDismiss().then((_: any) => {
            this.isShowMenu = false;
        });
        return await popover.present();
    }

    scan() {
        this.openScanner = true;
    }

    pistol() {
        this.pistolScan = !this.pistolScan;
        if (this.pistolScan) {
            this.searchInput.setFocus();
        }
    }

    private scanned(text) {
        this.openScanner = false;
        if (text) {

            let scanFilter = null; let scanFilterField = null; let scanFilterFieldRelated = null;
            if (this.board.groupBy.scanFilter) {
                scanFilter = this.metaFields.find(f => f.name === this.board.groupBy.scanFilter);
                if (scanFilter && scanFilter.related_model && this.board.groupBy.scanFilterFieldRelated) {
                    const t = this.base.getTableMeta(scanFilter.related_model);
                    if (t && t.fields) {
                        scanFilterFieldRelated = t.fields.find(f => f.name === this.board.groupBy.scanFilterFieldRelated);
                    }
                }
                if (this.board.groupBy.scanFilterField) {
                    scanFilterField = this.metaFields.find(f => f.name === this.board.groupBy.scanFilterField);
                }
            }

            if (scanFilterFieldRelated && scanFilterField && scanFilter && scanFilter.related_model) {

                this.base.getData(scanFilter.related_model, 1, 1, '', [new BoardFilter(scanFilterFieldRelated.name, Equal.EQ, text)], true).subscribe(resp => {
                    if (resp && resp.data && resp.data.length) {
                        this.addScannedFilter(scanFilterField, resp.data[0][scanFilterField.name]);
                    } else {
                        this.base.sendToast(this.trans.instant('not-found'));
                    }
                });

            } else if (scanFilterField && scanFilter) {
                
                this.base.getData(this.board.table, 1, 1, '', [new BoardFilter(scanFilter.name, Equal.EQ, text)], true).subscribe(resp => {
                    if (resp && resp.data && resp.data.length) {
                        this.addScannedFilter(scanFilterField, resp.data[0][scanFilterField.name]);
                    } else {
                        this.base.sendToast(this.trans.instant('not-found'));
                    }
                });

            } else if (scanFilter) {

                this.addScannedFilter(scanFilter, text);

            } else {
                this.searchValue = text;
            }
        }
    }

    private addScannedFilter(scanFilter, text) {
        let isFound = false;
        for (const f of this.board.filters) {
            if (f.name === scanFilter.name) {
                if (!isFound) {
                    f.isChecked = true;
                    f.equal = Equal.EQ;
                    f.value = text;
                    f.repr = text;
                    isFound = true;
                } else {
                    f.isChecked = false;
                }
            }
        }
        if (!isFound) {
            const o: any = {};
            o[scanFilter.name] = text;
            this.addFilter(ListDisplay.fromJson(scanFilter), o);
        } else {
            this.doRefresh(undefined);
            this.isChanged = true;
            this.refresherGraph.next(this.board);
        }
    }

    print() {
        let data = '';
        const elems = document.getElementsByClassName('print');
        let width = '100%';
        if (this.board.groupBy.printWidth) {
            width = this.board.groupBy.printWidth + 'px';
        }
        for (let i = 0; i < document.getElementsByClassName('print').length; i++) {
            data += `<div style="width:${width};float:left;padding: 5px 10px 5px 0px;">` + elems[i].innerHTML + '</div>';
        }
        const newWindow = window.open();
        newWindow.document.open('text/html');
        newWindow.document.write('<html><head>' + data);
        newWindow.document.close();
    
        newWindow.print();
        newWindow.onfocus = () => {
            // newWindow.close();
        };
    }

    changeView() {
        this.board.changeMode(); 
        this.isChanged = true;
        if (!this.board.isTable) {
            this.ionViewDidEnter();
        }
    }

    private afterGettingTemplate(isWidgetTemplate?) {
        
        let form: Observable<any>;
        if (this.board.form) {
            form = of({data: [this.board.form]});
        } else {
            form = this.getDefaultForm(this.table);
        }

        zip(this.base.getCalcFields(this.table), form).subscribe(([fields, forms]) => {
            if (forms && forms.data && forms.data[0]) {
                this.defaultForm = forms.data[0];
            }

            if (fields && fields.length) {
                this.calcs = fields;
            }
            for (const f of this.calcs) {
                this.metaFields.splice(this.metaFields.length, 0, f);
            }
            for (const field of this.metaFields) {
                if (field.type === FieldType.INT && field.choices && field.choices.length) {
                    this.fieldChoices[field.name] = field.choices;
                }
            }
            if (this.base.board || isWidgetTemplate) {
                if (!isWidgetTemplate) {
                    this.board = this.base.board.copy();
                    this.setPeriodDate();
                    this.changeGroupBy();
                }
                this.base.board = null;
                if (this.board.charts.length) {
                    this.showGraph.ok = window.innerWidth > 500;
                }
                this.background = this.base.getBackground(this.board.background, true);
                this.setAllSize();
                this.doRefresh(undefined);
                this.startWs();
                if (this.board.isNew) {
                    this.board.isNew = false;
                    this.showDesk();
                }
                if (this.board.name) {
                    document.title = this.board.name;
                }
            } else {
                const filters = [];
                const pk = this.activatedRoute.snapshot.paramMap.get('pk');
                if (pk) {
                    filters.push({name: this.templatePk, equal: Equal.EQ, value: pk}); 
                }
                this.templateService.getTemplates(this.table, 1, 1, null, true, filters).subscribe(resp => {    
                    if (resp && resp.data && resp.data.length) {
                        this.board = Board.fromJson(resp.data[0]);
                        this.setPeriodDate();
                        this.changeGroupBy();
                    }
                    if (this.board.charts.length) {
                        this.showGraph.ok = true;
                    }
                    this.background = this.base.getBackground(this.board.background, true);
                    this.setAllSize();
                    this.doRefresh(undefined);
                    this.startWs();
                    if (this.board.name) {
                        document.title = this.board.name;
                    }
                });
            }
        });
    }

    private startWs() {
        this.url = this.base.getUpdaterUrl(this.table);
        this.interval = setInterval(() => {
            if (!this.ws || this.ws.readyState === 3) {
                this.createWebSocket();
            }
        }, 5000);
        this.createWebSocket();
    }

    private reloadList(i) {
        let nextPage = parseInt(`${this.boardGroup[i].nextPage}`, 10);
        if (this.boardGroup[i].hasNext) {
            nextPage --;
        }
        this.loadDeskItem(this.boardGroup[i], nextPage * Constants.ON_PAGE_ITEMS, true);
    }

    private reloadFoundInList(pk, msg) {
        let refresh = false;
        for (let i = 0 ; i < this.boardGroup.length; i++) {
            if (this.boardGroup[i].data && this.boardGroup[i].data.find(item => item[pk] == msg.data[pk])) {
                this.reloadList(i);
                refresh = true;
            }
        }
        if (refresh) {
            this.refresherGraph.next(this.board);
        }
    }

    private createWebSocket() {
        if (this.ws && this.ws.readyState !== 3) {
            this.ws.close();
        }
        this.ws = new WebSocket(this.url);
        this.ws.onmessage = (event) => {
            this.onmessage(event);
        };
        this.ws.onopen = () => {
            this.ws.send('start');
        };
    }

    private onmessage(event) { 
        if (!event || event.data === 'ping') {
            return;
        }

        if (window.location.href !== this.state) {
            return
        }

        const pk = this.base.getTablePk(this.table);
        const msg = JSON.parse(event.data);
        // Just update list when item is display.
        if (msg.method === 'delete') {
            if (this.isHierarchy) {
                this.hierarchyRefresh();
                return
            }
            this.reloadFoundInList(pk, msg);
            return;
        }

        // unknown method.
        if (msg.method !== 'save' && msg.method !== 'edit') {
            return;
        }

        // Try to get item.
        const params = this.getQueryParams(false, [new BoardFilter(pk, Equal.EQ, msg.data[pk])], null);
        this.base.getData(this.table, 1, 1, params).subscribe(data => {

            if (data && data.data && data.data.length) {
                const item = data.data[0];
                // Changed opened item: update object.
                if (this.openedItem && this.openedItem.openedItem && this.openedItem.openedItem[pk] === item[pk]) {
                    this.openedItem.openedItem = item;
                    this.changedItemSubject.next(item);
                }

                if (this.isHierarchy) {
                    this.hierarchyRefresh(item);
                    return
                }

                // If item is found in list, reload that list.
                let itemRefreshed = false;
                for (let i = 0; i < this.boardGroup.length; i++) {
                    if (this.boardGroup[i].data && this.boardGroup[i].data.find(o => o[pk] === item[pk])) {
                        this.reloadList(i);
                        itemRefreshed = true;
                        continue;
                    }        

                    // Check board custom filters of list.
                    if (this.boardGroup[i].isCustom && this.boardGroup[i].filters) {
                        let isChecked = true;
                        for (const f of this.boardGroup[i].filters) {
                            if (!this.checkFilter(f, item)) {
                                isChecked = false;
                                break;
                            }
                        }
                        if (!isChecked) {
                            continue;
                        }
                    }

                    // Check board group of list.
                    if (!this.boardGroup[i].isCustom && this.board.groupBy.name && this.board.groupBy.type) {

                        const fieldName = this.board.groupBy.name;
                        let v = this.templateService.getValueOfGroup(this.boardGroup[i].name, this.boardGroup[i], this.board, true);
                        let v0 = item[fieldName];
                        const fff = this.metaFields.find(f => f.name === fieldName);
                        const pkObj = fff.related_field || this.base.getTablePk(fff.related_model);

                        if (['tag_name', 'tag_parent_name'].indexOf(fieldName) !== -1 && item.tags && this.boardGroup[i].obj) {
                            v = this.boardGroup[i].obj[pkObj];
                            v0 = item.tags.find(ii => ii[pkObj] === v);
                            v0 = v0 ? v0[pkObj] : v0; 
                        }

                        if (this.board.groupBy.type === FieldType.REF) {

                            if (!((!v && !v0) || (v && v0 && v[pkObj] === v0[pkObj]))) {
                                continue;
                            }

                        } else if (typeof(v) === 'object') {

                            if (this.board.groupBy.type === FieldType.DATETIME || this.board.groupBy.type === FieldType.DATE) {
                                if (!((this.base.getDate(v0)) >= (this.base.getDate(v[0])) 
                                    && (this.base.getDate(v0)) <= (this.base.getDate(v[1])))) {
                                    continue;
                                }
                            } else if (!(v0 >= v[0] && v0 <= v[1])) {
                                continue;
                            }

                        } else if ((this.board.groupBy.type === FieldType.DATETIME || this.board.groupBy.type === FieldType.DATE) && v && v0) {
                            
                            if (this.board.groupBy.typeDate === Interval.YEAR) {

                                if (v.slice(0, 4) !== v0.slice(0, 4)) {
                                    continue;
                                }

                            } else if (this.board.groupBy.typeDate === Interval.QUARTER) {

                                if (this.templateService.getQuarter(this.base.getDate(v)) !== 
                                    this.templateService.getQuarter(this.base.getDate(v0))) {
                                    continue;
                                }

                            } else if (this.board.groupBy.typeDate === Interval.MONTH) {

                                if (v.slice(0, 7) !== v0.slice(0, 7)) {
                                    continue;
                                }

                            } else if (this.board.groupBy.typeDate === Interval.WEEK) {

                                if (this.templateService.getWeek(this.base.getDate(v)) !== 
                                    this.templateService.getWeek(this.base.getDate(v0))) {
                                    continue;
                                }

                            } else if (v.slice(0, 10) !== v0.slice(0, 10)) {

                                continue;
                            }

                        } else if (v !== v0) {
                            
                            continue;
                        }
                    }
                    this.reloadList(i);
                    itemRefreshed = true;
                }

                if (!itemRefreshed) {
                    if (this.boardGroup.length === 1 && this.boardGroup[0].hidden) {
                        this.doRefresh(undefined);
                    } else if (this.board.groupBy.type && this.board.groupBy.name) {
                        const groups = this.boardGroup.filter(g => !g.isCustom);
                        if (!groups.length) {
                            this.refresherGraph.next(this.board);
                            return;
                        }
                        if (groups.length < Constants.ON_PAGE_LIST) {
                            this.doRefresh(undefined);
                        }
                    }
                }

                this.refresherGraph.next(this.board);

            } else {
                // Access deny or filter restrict. Reload list when item is display.
                this.reloadFoundInList(pk, msg);
            }
        });         
    }

    selectAll(group: GroupByList) {
        if (!this.showSelection) {
            return;
        }
        for (const item of group.data) {
            item._checked = !item._checked;
        }
    }

    updateRow(data: any[], updates: any, btn: BoardButton, i: number) {
        if (!data[i]) {
            btn.pressed = false;
            if (btn.success) {
                this.base.sendToast(this.trans.instant('changes-finished'));
            }
            return;
        }
        const pk = this.base.getTablePk(this.board.table);
        this.base.updateTable(updates, data[i][pk], data[i].edited, this.board.table).pipe(
            finalize(() => {
                this.updateRow(data, updates, btn, i+1)
            })
        ).subscribe(resp => {
            if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp));
            } else {
                btn.success++
            }
        });
    }

    deleteRow(data: any[], btn: BoardButton, i: number) {
        if (!data[i]) {
            btn.pressed = false;
            this.base.sendToast(this.trans.instant('deletion-finished'));
            return;
        }
        const pk = this.base.getTablePk(this.board.table);
        this.base.removeTable(data[i][pk], null, this.board.table).pipe(
            finalize(() => {
                this.deleteRow(data, btn, i+1)
            })
        ).subscribe(resp => {
            if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    actionBtn(btn: BoardButton) {
        if (btn.pressed) {
            this.base.sendToast(this.trans.instant('button-is-not-finished'));
            return;
        }
    
        let data = [];
        for (const group of this.boardGroup) {
            data.push(...group.data.filter(f => f._checked));
        }
        if (!data.length) {
            this.base.sendToast(this.trans.instant('not-items-selected'));
            return;
        }
        data = Base.copy(data);
        btn.pressed = true;

        switch (btn.action) {
        case ButtonAction.DELETE:

            this.deleteRow(data, btn, 0);
            break;

        case ButtonAction.UPDATE:

            if (btn.func) {
                const updates = this.base.getCalcRepr(data, null, 'JSON.stringify(' + btn.func + ')');
                if (updates && updates != '{}') {
                    const obj = JSON.parse(updates);
                    this.updateRow(data, obj, btn, 0);
                } else {
                    this.base.sendToast(this.trans.instant('no-changes-deleted'));
                    btn.pressed = false;
                }
            } else {
                this.base.sendToast(this.trans.instant('no-changes-deleted'));
                btn.pressed = false;
            }
            break;

        default:
            btn.pressed = false;
        }
    }

    openItem(item, fromFilter = false, group?, boardGroup?: GroupByList) {
        
        if (item.openedFormating) {
            item.openedFormating = false;        
            return;
        }

        if (this.showSelection) {
            item._checked = !item._checked
            return;
        }

        this.openedItem = {openedItem: this.base.copy(item)};
        let page = null;
        // Added filters as default value.
        if (fromFilter) {
            const filters = BoardFilter.copy(this.board.filters);
            if (boardGroup && boardGroup.filters) {
                for (const f of boardGroup.filters) {
                    filters.push(f);
                }
            }
            for (const field of filters) {
                if (field.isChecked && field.equal && field.name) {
                    let value: any;
                    if (field.type === FieldType.REF) {
                        const ff = this.metaFields.find(ii => ii.name === field.name) || {};
                        const pk = ff.related_field || this.base.getTablePk(ff.related_model);
                        let foreign: any;
                        if (this.boardGroup && this.boardGroup.length) {
                            for (const bgroup of this.boardGroup) {
                                if (bgroup && bgroup.data && bgroup.data.length) {
                                    foreign = bgroup.data.find(ii => ii[field.name] && ii[field.name][pk] === field.value);
                                    if (foreign && foreign[field.name]) {
                                        break;
                                    }
                                }
                            }
                        }
                        if (foreign && foreign[field.name]) {
                            value = this.base.copy(foreign[field.name]);
                        } else {
                            value = this.base.copy({name: field.repr, code: field.repr});
                            value[pk] = field.value;
                        }
                    } else {
                        value = field.value;
                    }
                    this.openedItem.openedItem[field.name] = value;
                }
            }
        }

        if (group !== undefined) {
            const name = this.board.groupBy.name;
            const v = this.templateService.getValueOfGroup(group, boardGroup, this.board);
            if (v !== undefined) {
                this.openedItem.openedItem[name] = v;
            }
            const field = this.metaFields.find(ii => ii.name === name);
            if (group && group.data && group.data.length && field && field.type === FieldType.REF && v) {
                const pk = field.related_field || this.base.getTablePk(field.related_model);
                const foreign = group.data.find(ii => ii[name] && ii[name][pk] === v[pk]);
                if (foreign[field.name]) {
                    this.openedItem.openedItem[name] = this.base.copy(foreign[field.name]);
                }
            }
        }
        if (this.changedItemSubject) {
            this.changedItemSubject.next(this.base.copy(this.openedItem.openedItem || {}));
        }
        let props: any; props = {item: this.openedItem, changedItemSubject: this.changedItemSubject};
        let cssClass = 'modal-item-detail';
        if (this.board.form) {
            props.form = this.board.form;
            page = FormPage;
        } else if (this.table === 'Task') {
            page = TaskPage;
            cssClass = 'task-window';
        } else if (this.defaultForm) {
            props.form = this.defaultForm;
            page = FormPage;
        } else {
            return;
        }
        if (this.table === 'Task') {
            this.openItemModal(page, props, cssClass);
        } else {
            let backdropDismiss = true;
            if (props.form && props.form.data && props.form.data.beforeCreateRelateds) {
                backdropDismiss = false;
            }
            this.base.openForm(FormPage, props, (_) => {
                this.openedItem = null;
            }, null, null, backdropDismiss)
        }
    }

    private getDefaultForm(table): Observable<any> {
        const filters = [
            new BoardFilter('table', Equal.EQ, table),
            new BoardFilter('is_default', Equal.EQ, true),
            new BoardFilter('type', Equal.EQ, 0),
        ]
        return this.base.getData('Form', 1, 1, '', filters, true);
    }

    private async openItemModal(page, props, cssClass) {
        const modal = await this.modalCtrl.create({
            component: page,
            componentProps: props,
            cssClass,
            showBackdrop: false
        });
        modal.onDidDismiss().then((_) => {
            this.openedItem = null;
        });
        return await modal.present();
    }

    /* Work with templates */
    private changeDesk() {
        if (this.isChanged) {
            this.saveTemplate(this.nextTemplate);
        } else if (this.nextTemplate) {
            this.board = this.nextTemplate.copy();
            this.setPeriodDate();
            this.changeGroupBy();
            this.nextTemplate = null;
            this.background = this.base.getBackground(this.board.background, true);
            this.doRefresh(undefined);
            this.setAllSize();
            this.refresherGraph.next(this.board);
        }
    }

    async saveTemplate(nextTemplate = null) {
        this.isChanged = false;
        if (this.board.disableAskSavePublic && !this.board.my) {
            return;
        }
        const buttons: any[] = [
            { 
                text: this.trans.instant('no'), 
                role: 'cancel', 
                cssClass: 'secondary', 
                handler: (_) => {
                    if (nextTemplate) {
                        this.changeDesk();
                    }
                }
            }
        ];
        if (this.board.canUpdate) {
            buttons.push(
                {
                    text: this.trans.instant('yes'),
                    handler: (params) => {
                        if (this.board.pk) {
                            this.board.name = params.name;
                            this.templateService.updateTemplate(this.board).subscribe(resp => {
                                this.board.edited = resp.edited;
                                if (nextTemplate) { 
                                    this.changeDesk();
                                }
                            });
                        } else {
                            this.createTemplate(params.name, nextTemplate, false);
                        }
                    }
                }
            );
        }
        if (this.board.pk) {
            buttons.push(
                {
                    text: this.trans.instant('yes-as-new'),
                    handler: (params) => {
                        this.board.background = null;
                        this.createTemplate(params.name, nextTemplate, false);
                    }
                }
           );
        }
        const alert = await this.alertCtrl.create({
            header: this.board.pk ? this.trans.instant('save-desk-chages', {desk: this.board.name}) : 
                this.trans.instant('want-save-desk'),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: this.trans.instant('input-desk-name'),
                    value: this.board.name
                },
            ],
            buttons
        });
        await alert.present();
    }

    private createTemplate(name, nextTemplate, refreshDesk) {
        this.board.name = name;
        this.board.table = this.table;

        if (!this.board.background) {
            this.templateService.getBackgrounds(false, 1, 50, null).subscribe(resp => {
                if (resp && resp.data && resp.data.length) {
                    this.board.background = resp.data[Math.floor(Math.random() * resp.data.length)];
                } 
                this.templateService.createTemplate(this.board).subscribe(temp => {
                    this.addCreatedTemplate(temp, nextTemplate);
                    if (refreshDesk) {
                        this.doRefresh(undefined);
                    }
                });
            });
        } else { 
            this.templateService.createTemplate(this.board).subscribe(resp => {
                this.addCreatedTemplate(resp, nextTemplate);
                if (refreshDesk) {
                    this.doRefresh(undefined);
                }
            });
        }
    }

    private addCreatedTemplate(resp, nextTemplate) {
        this.board = Board.fromJson({
            fields: this.board.serialize().fields, 
            table: this.table, 
            name: resp.name, 
            background: this.board.background,
            edited: resp.edited,
            uuid: resp.uuid,
        });

        this.background = this.base.getBackground(this.board.background, true);
        this.setAllSize();
        this.changeGroupBy();
        this.refresherGraph.next(this.board);
        if (nextTemplate) {
            this.changeDesk();
        }
    }

    async showDesks() { 
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Template',
                selected: this.base.copy(this.board.serialize()),
                filters: [new BoardFilter('table', Equal.EQ, this.table)],
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                this.nextTemplate =  Board.fromJson(details.data.item);
                this.changeDesk();
            }
        });
        return await modal.present();
    }

    openSetting(group?: GroupByList) {
        const been = this.getTemplateObj();
        if (group === undefined) {
            const gr = new GroupByList([], null, [], 1, false, true, null, null, null, false, true, false, null, null, null, true, 0);
            this.board.groupBy.custom.splice(this.board.groupBy.custom.length, 0, gr);
            this.showDesk(gr, null, been);
        } else {
            this.showDesk(group, null, been);
        }
    }

    private getTemplateObj(): any {
        return this.base.copy(this.board.serialize());
    }

    private updateOrCreateTemplate(been) {
        this.setAllSize();
        const refresh = () => {
            const now = this.getTemplateObj();
            if (!Base.isEqual(now, been)) {
                this.doRefresh(undefined);
                this.refresherGraph.next(this.board);
            }
            this.background = this.base.getBackground(this.board.background, true);
        };

        if (this.board.pk) {
            if (!this.board.canUpdate) {
                refresh();
                return;
            }
            this.templateService.updateTemplate(this.board).subscribe(resp => {
                this.board.edited = resp.edited;
                this.isChanged = false;
                if (been) {
                    been.edited = resp.edited;
                }
                refresh();
            });
        } else {
            this.createTemplate(this.board.name, null, true);
        }
    }

    openFormating(col, o) {
        o.openedFormating = true;
        this.showDesk(null, col);
    }

    async showDesk(group?: GroupByList, col?, been?) {
        been = been || this.getTemplateObj();
        const board = this.board.copy();
        const obj = {obj : null}; let groupFilter: BoardFilter = null;
        if (group) {
            if (group.obj && typeof(group.obj) === 'object') {
                obj.obj = this.base.copy(group.obj);
                const t = this.metaFields.find(i => i.name === this.board.groupBy.name);
                if (t) {
                    obj.obj.verbose_name = t.verbose_name;
                }
            }
            board.pk = group.isCustom ? board.pk : null;
            board.filters = group.filters ? BoardFilter.copy(group.filters) : [];
            board.name = group.verbose ? group.verbose.slice() : (group.name ? group.name.slice() : '');
            if (!group.isCustom && this.board.groupBy.name) {
                groupFilter = this.templateService.addFilterToDynamicList(
                    this.metaFields, board, this.board, group, groupFilter, this.fieldChoices);
                if (groupFilter && obj.obj && !obj.obj.verbose_name) {
                    obj.obj.verbose_name = groupFilter.verboseName;
                }
            }
        }

        const modal = await this.modalCtrl.create({
            component: DeskDetailPage,
            componentProps: { 
                board, 
                obj: obj.obj, 
                group, col, 
                calcs: this.calcs 
            },
            cssClass: 'modal-desk-detail',
            showBackdrop: false,
            mode: 'md',
        });
        modal.onDidDismiss().then((detail) => {
            const action = detail && detail.data ? detail.data.action : null; 
            if (group) { // This is setting of object or custom list.
                
                switch (action) {
                case 'remove':

                    this.board.groupBy.removeCustom(group);
                    break;
                
                case 'hide':

                    if (group.isCustom) { // Hide custom field.
                        this.board.groupBy.getCustom(group).hidden = true;
                    } else { // Hide dynamic field
                        const table = this.metaFields.find(f => f.name === group.name);
                        this.board.groupBy.addToHidden(group, (table ? table.related_field : null) || this.base.getTablePk(table ? table.related_model : null));                     
                        this.board.groupBy.removeCustom(group);
                    }
                    break;

                case 'show':

                    if (group.isCustom) {
                        this.board.groupBy.getCustom(group).hidden = false;
                    } else {
                        this.board.groupBy.removeHidden(group);
                    }
                    break;

                default: // save

                    const groupByList = this.board.groupBy.getCustom(group);
                    groupByList.filters = board.filters;
                    groupByList.verbose = board.name;
                    groupByList.obj = obj.obj;
                    
                    // Empty new list - remove that and cancel changes.
                    if (!board.filters.length) {
                        this.board.groupBy.removeCustom(group);
                        return;
                    }

                    // Dynamic field is changed.
                    if (!group.isCustom && !group.hidden) {

                        // Just object changed. Not replace dynamic list to custom.
                        if (groupFilter && board.filters.length === 1 
                            && groupFilter.name === board.filters[0].name 
                            && groupFilter.value === board.filters[0].value
                            && groupFilter.isChecked === board.filters[0].isChecked 
                            && groupFilter.equal === board.filters[0].equal) {
                            
                            this.board.groupBy.removeCustom(group);

                        } else { // Replace dynamic list to custom.
                            const table = this.metaFields.find(f => f.name === group.name);
                            this.board.groupBy.addToHidden(group, (table ? table.related_field : null) || this.base.getTablePk(table ? table.related_model : null));  
                        }
                    }

                    // Save changes of object.
                    if (obj.obj && !group.isCustom && this.board.groupBy.type === FieldType.REF) {

                        const t = this.metaFields.find(i => i.name === this.board.groupBy.name);
                        if (t && t.related_model) {
                            this.base.updateObj(obj.obj, t.related_model).subscribe(_ => { this.updateOrCreateTemplate(been); });
                            return;
                        }

                    } else if (obj.obj && group.isCustom && group.filters) {

                        const objs = group.filters.filter(f => f.isChecked && f.equal === Equal.EQ && f.type === FieldType.REF);
                        if (objs && objs.length) {
                            const t = this.metaFields.find(i => i.name === objs[0].name);
                            if (t && t.related_model) {
                                this.base.updateObj(obj.obj, t.related_model).subscribe(_ => { this.updateOrCreateTemplate(been); });
                                return;
                            }
                        }
                    }
                }
            
            } else if (action === 'remove') {

                // Remove template of board.
                this.templateService.removeTemplate(this.board.pk).subscribe(_ => {
                    this.templateService.getTemplates(this.table, 1, 1, null, true).subscribe(resp => {
                        if (resp.data && resp.data.length) {
                            this.board = Board.fromJson(resp.data[0]);
                            this.setPeriodDate();
                        } else {
                            this.board = Board.default(this.table);
                        }
                        this.background = this.base.getBackground(this.board.background, true);
                        this.setAllSize();
                        this.changeGroupBy();
                        this.nextTemplate = this.board.copy();
                        this.changeDesk();
                        this.refresherGraph.next(this.board);
                    });
                });
            
            } else {

                this.board = board;
                this.changeGroupBy();
                this.setPeriodDate();

            }

            this.updateOrCreateTemplate(been);
        });
        return await modal.present();
    }

    async share() {
        if (this.board.pk) {
            const modal = await this.modalCtrl.create({
                component: ChoicesPage,
                componentProps: {
                    model: 'User',
                },
                showBackdrop: false
            });
            modal.onDidDismiss().then((details: any) => {
                if (details && details.data && details.data.item) {
                    this.templateService.createTemplate(this.board, details.data.item[this.userPk]).subscribe(resp => {
                        if (resp && resp[this.templatePk]) {
                            this.base.sendToast(this.trans.instant('desk-shared', {desk: this.board.name, user: details.data.item.name}));
                        } else if (resp) {
                            this.base.sendToast(this.base.getError(resp));
                        }
                    }); 
                }
            });
            return await modal.present();
        }
    }

    /* Tools */
    private setAllSize() {
        this.allSize = this.board.listDisplaySize;
    }

    private representCell(col, value, covertToImg = true) {
        return this.templateService.representCell(col, value, this.fieldChoices, false, covertToImg);
    }

    private calcExtraField(item, group?: GroupByList) {
        if (item.date_to) {
            item.dateToTerm = this.base.getTerm(item.date_to, true);
            item.dateToTermClass = this.base.getDateClass(item.date_to, item.is_done);
        }
        item.attentionClass = (item.vise && item.vise_user && item.vise_user[this.userPk] === this.currentUser[this.userPk]) 
                              || item.approve_changes || item.attention;
        if (item.tags && item.tags.length) {
            let filters = BoardFilter.copy(this.board.filters);
            if (group && group.filters.length) {
                for (const f of group.filters) {
                    filters.push(f);
                }
            }
            filters = filters.filter(f => f.isChecked && f.equal === Equal.EQ);
            for (const tag of item.tags) {
                tag.hide = filters.find(f => f.value === tag.uuid) ? true : false;
            }
        }
        return item;
    }

    deleteFilter(f, i) {
        this.board.filters.splice(i, 1);
        if (f.isChecked) {
            this.pageList = 1; 
            this.doRefresh(undefined); 
            this.refresherGraph.next(this.board);
        }
        this.isChanged = true;
    }

    private addFilter(col: ListDisplay, o, notRefresh?, equal?, hidden?) {
        if (!o || !Board.isValidField(col.type)) {
            return;
        }

        let value = o[col.name];
        if (col.type === FieldType.REF) {
            const ff = this.metaFields.find(f => f.name === col.name);
            const pk = ff.related_field || this.base.getTablePk(ff.related_model);
            value = value ? value[pk] : null;
        }
        this.board.filters.splice(this.board.filters.length, 0, new BoardFilter(
            col.name, equal, value, 
            col.type !== FieldType.BOOL ? this.representCell(col, o, false) : (value ? col.verboseName : `${this.trans.instant('not')} ${col.verboseName}`),
            col.verboseName, col.type, col.choices.length ? Choice.serialize(col.choices) : this.fieldChoices[col.name], true, hidden));
        this.refreshAfterFilters(notRefresh);
    }

    private refreshAfterFilters(notRefresh?) {
        this.isChanged = true;
        if (!notRefresh) {
            this.doRefresh(undefined);
            this.refresherGraph.next(this.board);
        }
    }

    private checkFilter(filter: BoardFilter, item) {
        const tt = this.metaFields.find(f => f.name === filter.name);
        const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
        return filter.check(item, pk)
    }

    addOrder(col) {
        const c = this.board.orderBy.find(i => i.name === col.name);
        if (!c) {
            this.board.orderBy.splice(this.board.orderBy.length, 0,  new OrderBy(col.name, col.verbose_name, true, true, false));
        } else {
            c.isAsc = !c.isAsc;
        }
        this.doRefresh(undefined);
        this.isChanged = true;
    }

    async scrollTableContent(ev) {
        if (this.caption) {
            const refresh = () => {
                this.caption.nativeElement.scrollLeft = ev.srcElement.scrollLeft;
            };
            return await refresh(); 
        }
    }

    moreItems(group: GroupByList, k: number) {
        if (group.hasNext && group.scrollComplete !== false) {
            group.scrollComplete = false;
            this.loadDeskItem(group);
            if (this.container) {
                // scroll to bottom.
                setTimeout(() => {
                    const cards = this.container.nativeElement.getElementsByClassName('list-cards');
                    if (cards && k < cards.length) {
                        cards[k].scrollTop = cards[k].scrollHeight;
                    }
                }, 100);
            }
        }
    }

    scrollBoardWidth(event) {
        if (this.loadingSpinnerX && this.canRequestGroup && 
            event.srcElement.scrollLeft / (event.srcElement.scrollWidth - event.srcElement.clientWidth) >= 0.9) {
            this.canRequestGroup = false;
            this.loadNoteDesk();
        }
    }
    
    scrollBoardHeight(event) {
        if (this.loadingSpinnerX && this.canRequestGroup && 
            event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.canRequestGroup = false;
            this.loadNoteDesk();
        }
    }
    
    /* Load new data */
    doRefresh(event) {
        this.columns = this.addSepColumn();
        this.pageList = 1;
        this.loadNoteDesk(event);
    }

    doSearch(event) {
        if (this.pistolScan) {
            this.search = null;
            this.scanned(event.detail.value);
            return;
        }
        this.search = event.detail.value;
        this.doRefresh(undefined);
    }

    private loadNoteDesk(eventRefresh?) {
        if (this.isHierarchy) {
            this.initHierarchy(eventRefresh)
            return
        }

        let start = false;
        if (this.pageList <= 1) {
            this.pageList = 1; this.boardGroup = [];
            start = true;
        }
        
        const updateVersion = (new Date()).getTime();
        this.updateVersion = updateVersion;
        this.loadingSpinnerX = false; const params = this.getQueryParams(true);
        if (this.isNote) {

            this.dataIsLoaded = false;
            this.base.getData(this.table, this.pageList, Constants.ON_PAGE_LIST, params).pipe(
                finalize(() => {
                    // User refresh page, complete that.
                    if (eventRefresh) {   
                        eventRefresh.target.complete();
                    }
                    this.canRequestGroup = true;
                    this.dataIsLoaded = true;
                })
            ).subscribe(data => {
                // Append data or replace.
                if (updateVersion !== this.updateVersion) {
                    console.log('skip', updateVersion, this.updateVersion)
                    return;
                }
                if (data && data.data) {
                    let groups: GroupByList[] = [];
                    if (this.pageList !== 1) {
                        groups = this.boardGroup.slice();
                    }
                    for (let i = 0; i < data.data.length; i++) {
                        if (!groups.find(item => item.name === data.data[i].note)) {
                            if (this.board.groupBy.name === 'vise_user' && !data.data[i].note) {
                                continue;
                            }
                            const q = this.templateService.getVerboseAndObj(data, i, this.board.groupBy);
                            const tt = this.metaFields.find(f => f.name === this.board.groupBy.name);
                            const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);

                            groups.push(new GroupByList([], q.n, [], 1, true, true, q.obj ? q.obj[pk] : null, 
                                                        q.color ? q.color : (q.obj && q.obj.options ? q.obj.options.color : null), q.obj,
                                                        false, false, false, q.verbose, null, null, true, 0));
                        }
                    }
                    // Controll the sroll event.
                    this.boardGroup = groups;
                    this.loadingSpinnerX = data.has_next;
                    // Increament page if query has next page.
                    if (data.has_next) { 
                        this.pageList ++; 
                    } 
                    this.loadListDesk(start, updateVersion);
                }
            });

        } else if (!this.boardGroup.length) {

            this.canRequestGroup = true;
            if (eventRefresh) {    // User refresh page, complete that.
                eventRefresh.target.complete();
            }
            const groups: GroupByList[] = []; 
            for (const group of this.groups) {
                const obj = {name: group.group, options: group.options};
                const tt = this.metaFields.find(f => f.name === this.board.groupBy.name);
                const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                obj[pk] = group.value;
                groups.push(new GroupByList([], group.group, [], 1, true, true, group.value, obj && obj.options ? obj.options.color : null, obj, false, false, false, null, null, null, true, 0));
            }
            this.boardGroup = groups;
            this.loadListDesk(true, updateVersion);
        } else {
            this.canRequestGroup = true;
        }
    }

    private loadListDesk(loadCustom: boolean, updateVersion: number) {
        if (this.board.groupBy.custom.length && loadCustom) {
            let i = 0;
            for (const list of this.board.groupBy.custom.filter(ff => !ff.hidden)) {
                list.isCustom = true;
                list.nextPage = 1;
                list.isNew = true;
                this.boardGroup.splice(i, 0, list);
                i++
            }
        }
        if (!this.boardGroup.length) {
            this.boardGroup.splice(0, 1, new GroupByList([], '', [], 1, false, true, null, null, null, false, false, true, null, null, null, true, 0));
        }
        for (const group of this.boardGroup.filter(b => b.isNew)) {
            group.isNew = false;
            this.loadDeskItem(group, Constants.ON_PAGE_ITEMS, true, updateVersion);
        }    
    }

    private loadDeskItem(group: GroupByList, pages?: number, clearList?: boolean, updateVersion?: number) {
        let extraFilter: BoardFilter[] = []; let statusNote = null;
        if (group.isExtraFilter) {
            if (this.board.groupBy.name === 'status_type') {
                statusNote = {status_type: [this.metaFields.find(i => i.name === 'status_type').choices.find(j => j[1] === group.name)[0]]};
            } else if (this.board.groupBy.name === 'vise_type') {
                statusNote = {vise_type: [this.metaFields.find(i => i.name === 'vise_type').choices.find(j => j[1] === group.name)[0]]};
            } else if (this.board.groupBy.name === 'vise_user') {
                statusNote = {vise_user: [group.value]};
            } else {
                extraFilter.push(new BoardFilter('note', Equal.EQ, group.name));
            }
        }

        if (group.isCustom) {
            extraFilter = group.filters.filter(i => i.isChecked);
            for (const f of group.filters) {
                if (this.viseFields.indexOf(f.name) !== -1) {
                    if (!statusNote) {
                        statusNote = {}; 
                    }
                    if (statusNote[f.name]) {
                        statusNote[f.name].push(f.value);
                    } else {
                        statusNote[f.name] = [f.value];
                    }
                }
            }
        }
        
        const params = this.getQueryParams(false, extraFilter, statusNote, group.isCustom);
        this.dataIsLoaded = false;
        const page = clearList ? 1 : group.nextPage;
        this.base.getData(this.table, page, clearList ? pages : Constants.ON_PAGE_ITEMS, params).pipe(
            finalize(() => {
                if (group) {
                    group.scrollComplete = true;
                }
                this.dataIsLoaded = true;
            })
        ).subscribe(data => {
            if (updateVersion && updateVersion !== this.updateVersion) {
                console.log('skip', updateVersion, this.updateVersion)
                return;
            }
            const pk = this.base.getTablePk(this.table);
            const checked = [];
            for (const item of group.data) {
                if (item._checked && item[pk]) {
                    checked.push(item[pk]);
                }
            }
            if (clearList) {
                group.data = [];
                group.cnt = 0;
                group.nextPage = pages / Constants.ON_PAGE_ITEMS;
            }
            
            if (data && data.data) {
                for (let item of data.data) {
                    if (item) {
                        for (const col of this.board.listDisplay.filter(f => f.isChecked === true)) {
                            item[`${col.name}__repr`] = this.representCell(col, item);
                        }
                        item = this.calcExtraField(item, group);
                    }
                    item._checked = checked.find(c => c === item[pk]) ? true : false;
                    let index;
                    for (let k = 0;  k < group.data.length; k++) {
                        if (group.data[k][pk] === item[pk]) {
                            index = k;
                            break;
                        }
                    }
                    if (index === undefined) {
                        group.data.splice(group.data.length, 0, item);
                    } else {
                        group.data[index] = item;
                    }
                }
                // Update opened object.
                if (this.openedItem && this.openedItem.openedItem) {
                    const op = data.data.find(ii => ii[pk] === this.openedItem.openedItem[pk]); 
                    if (op) {
                        this.openedItem.openedItem = op;
                        this.changedItemSubject.next(op);
                    }
                }
                group.hasNext = data.has_next;
                // Increament page if query has next page.
                if (data.has_next) { 
                    group.nextPage ++; 
                }
                group.cnt = data.cnt; 
                if (data.data.length) {
                    if (!group.isCustom && data.data[0][this.board.groupBy.name]) {
                        group.obj = data.data[0][this.board.groupBy.name];
                    } else if (group.isCustom) {
                        const objs = group.filters.filter(f => f.isChecked && f.equal === Equal.EQ && f.type === FieldType.REF);
                        if (objs && objs.length) {
                            let obj = data.data[0][objs[0].name];
                            if (objs[0].name === 'tag_uuid') {
                                const objItem = data.data.find(f => f.tags && f.tags.length && f.tags.find(t => t.uuid === obj));
                                if (objItem) {
                                    obj = objItem.tags.find(t => t.uuid === obj);
                                    group.color = obj && obj.color ? obj.color.source_full : null;
                                }
                            }
                            if (objs[0].name === 'tag_parent') {
                                const objItem = data.data.find(f => f.tags && f.tags.length && 
                                    f.tags.find(t => t.parent && t.parent.uuid === obj));
                                if (objItem) {
                                    obj = objItem.tags.find(t => t.parent && t.parent.uuid === obj).parent;
                                    group.color = obj && obj.color ? obj.color.source_full : null;
                                }
                            }
                            group.obj = obj;
                            this.board.groupBy.getCustom(group).obj = obj;
                        }
                    }     
                }
            }
            if (page === 1) {
                group.groupValue = null;
                group.groupTitle = null;
                if (group.data.length) {
                    if ((this.board.groupBy.aggrField && this.board.groupBy.func === FunctionType.SUM) || this.board.groupBy.func === FunctionType.COUNT) {
                        this.base.getData(this.table, 1, 1, params + '&chart=' + 
                                          JSON.stringify({function: this.board.groupBy.func, aggr: this.board.groupBy.aggrField})).subscribe(chart => {
                            if (chart && chart.data && chart.data.length === 1) {
                                group.groupValue = this.base.decimalToString(chart.data[0].value);
                                group.groupTitle = this.board.groupBy.aggrFieldTitle || this.trans.instant('groups-all');
                            }
                        });
                    }
                }
            }
            this.refreshMap();
        });
    }

    private getQueryParams(justNote, extraFilters: BoardFilter[] = [], statusNote = null, isCustom = false) {
        const form = this.board.form || this.defaultForm;
        let calcFields = [];
        if (form && form.data && form.data.rows && this.calcs) {
            for (const row of form.data.rows) {
                if (row && row.fieldName && this.calcs.find(i => i.name === row.fieldName)) {
                    calcFields = this.templateService.addToCalcFields(row.fieldName, calcFields);
                }
            }
        }
        const q = this.templateService.getQueryParams(this.search, this.board, this.viseFields, this.table, 
                                                      justNote, extraFilters, statusNote, isCustom, this.calcs, calcFields);
        this.isNote = q.isNote;
        this.groups = q.groups;
        return q.params;
    }

    /* Vises features */
    addComment(doc) {
        this.viseStatusService.addComment(doc, this.table);
    }

    approveVise(doc, primaryKey) { 
        this.viseStatusService.approveVise(primaryKey, doc, () => { });
    }

    rejectVise(doc) {
        this.viseStatusService.rejectVise(doc, this.table, () => { });
    }            

    /* Create new task && task features */
    shortCreate(group: GroupByList, k, alert?) {
        // Short create item.
        if (this.preCreatedFields && this.preCreatedFields.length) {
            group.newItem = [];
            for (const f of this.preCreatedFields) {
                group.newItem.splice(group.newItem.length, 0, this.base.copy(f));
            }
            group.nextField = group.newItem[0];
            if (alert) {
                this.goToNextAlert(group);
            } else {
                setTimeout(() => {
                    if (this.container) {
                        const newItems = this.container.nativeElement.getElementsByClassName(`new-item${k}`);
                        if (newItems && newItems.length) {
                            const t = newItems[0].getElementsByTagName('textarea');
                            if (t.length) {
                                t[0].focus();
                            }
                        }
                    }
                }, 100);
            }
        }
    }

    private async goToNextAlert(group: GroupByList) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant(group.nextField.title),
            inputs: [
                {
                    name: 'name',
                    type: 'text',
                    placeholder: this.trans.instant(group.nextField.title),
                    id: 'new-item-9999',
                },
            ],
            buttons: [
                {
                    text: this.trans.instant('no'), 
                    role: 'cancel', 
                    cssClass: 'secondary', 
                    handler: (_) => {
                        group.nextField = null; 
                        group.newItem = null;
                    }
                },
                {
                    text: this.trans.instant('ok'),
                    handler: (params) => {
                        if (params && params.name) {
                            group.nextField.value = params.name;
                            this.goToNextField(group, 13, true);
                        } else {
                            group.nextField = null; 
                            group.newItem = null;
                        }
                    }
                }
            ]
        });
        await alert.present();
        setTimeout(() => {
            const e = document.getElementById('new-item-9999');
            if (e) {
                e.focus();
            }
        }, 500);
    }

    goToNextField(group: GroupByList, code, alert?) {
        // Short create field: cicle of fields.
        if (code !== 13 || !group.newItem.length) {
            return; 
        }
        for (let i = 0; i < group.newItem.length; i++) {
            if (group.newItem[i].name === group.nextField.name) {
                if (i + 1 < group.newItem.length) {
                    group.nextField = group.newItem[i + 1];
                    if (alert) {
                        this.goToNextAlert(group);
                    }
                } else {
                    const data = {};
                    for (const j of group.newItem) {
                        data[j.name] = j.value;
                    }
                    
                    let name = null; let type = null;
                    for (const f of this.board.filters) {
                        if (f.isChecked && f.equal === Equal.EQ && f.value && f.name) {
                            const tt = this.metaFields.find(ff => ff.name === f.name);
                            const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                            data[f.name] = f.value[pk] || f.value;
                        }
                    }
                    if (group.isCustom) {
                        if (group.filters) {
                            for (const f of group.filters) {
                                if (f.isChecked && f.equal === Equal.EQ && f.value && f.name) {
                                    if (!name && f.type === FieldType.REF) {
                                        name = f.name;
                                    }
                                    const tt = this.metaFields.find(ff => ff.name === f.name);
                                    const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                                    data[f.name] = f.value[pk] || f.value;
                                }
                            }
                        }
                    } else {
                        type = this.board.groupBy.type; name = this.board.groupBy.name;
                        if (name !== 'tag_name' && name !== 'tag_uuid' && this.canMoving() && name) {
                            if (!data[name] && (group.obj || group.name)) {
                                const gr = this.groups.find(g => g.group === group.name);
                                const tt = this.metaFields.find(ff => ff.name === name);
                                const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                                if (type === FieldType.INT && this.board.groupBy.choices.length) {
                                    data[name] = gr ? gr.value : null;
                                } else if ((type === FieldType.INT || type === FieldType.DECIMAL || type === FieldType.DATE 
                                            || type === FieldType.DATETIME) && gr) {
                                    data[name] = gr.value ? gr.value[0] : null;
                                } else if ((type === FieldType.DATE || type === FieldType.DATETIME)) {
                                    data[name] = group.name;                    
                                } else if (type === FieldType.CHAR) {
                                    data[name] = group.name;
                                } else if (type === FieldType.BOOL && gr) {
                                    data[name] = gr.value;
                                } else if (type === FieldType.REF && group.obj && group.obj[pk]) {
                                    data[name] = group.obj[pk];
                                }
                            }
                        }
                    }

                    this.base.createTable(data, this.table).subscribe(resp => {
                        const pk = this.base.getTablePk(this.table);
                        const tt = this.metaFields.find(ff => ff.name === name);
                        const pkT = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                        if (resp[pk]) {
                            if ((name === 'tag_name' || name === 'tag_uuid') && group.obj && group.obj[pkT]) {
                                this.taskService.createTaskTag({ task: resp[pk], tag: group.obj[pkT]}).subscribe(_ => { });
                            }
                        } else if (resp.error) {
                            this.base.sendToast(this.base.getError(resp.error));
                        }
                        group.newItem = null;
                        group.nextField = null;
                    });
                }
                break;
            }
        }
    }

    approveChanges(v) {
        this.taskService.approve(v);
    }

    /* Card movements && scroll board */
    drag() {
        // Move item between list.
        // Find next free dragula name.
        let i = 0;
        for (; this.dragulaService.find(`bag${i}`) !== undefined; i++) {}
        this.bag = `bag${i}`;

        // Try to move droped item.
        this.dragulaService.dropModel(this.bag).subscribe(({target, item, source}) => {
            this.blockScroll = false;
            this.body.nativeElement.onmousemove = null;
            this.moveBetween(this.boardGroup[parseInt(target.id, 10)], this.boardGroup[parseInt(source.id, 10)], item);
        });

        // When item moving: scroll to left or right if item reached end of side.
        this.dragulaService.drag(this.bag).subscribe(_ => {
            this.scrollByMove();
        });

        // Stop listen event of scroll document.
        this.dragulaService.dragend(this.bag).subscribe(_ => {
            document.onmousemove = null;
            this.blockScroll = false;
        });

        // --------------------------- Custom list ------------------------- //
        // Move custom list of board: find next free dragula name.
        for (; this.dragulaService.find(`lBag${i}`) !== undefined; i++) {}
        this.lBag = `lBag${i}`;

        // Restrict just moving by custom.
        this.dragulaService.createGroup(this.lBag, {
            direction: 'horizontal',
            moves: (el, container, handle, sibling) => { 
                return handle.className.indexOf('is-custom') !== -1; 
            }
        });

        // When item moving: scroll to left or right if item reached end of side.
        this.dragulaService.drag(this.lBag).subscribe(value => {
            this.scrollByMove();
        });

        // Update custom array after moving.
        this.dragulaService.dragend(this.lBag).subscribe(value => {
            document.onmousemove = null;
            this.blockScroll = false;
            this.isChanged = true;
        });
        
        // Create group that deny moving.
        if (!this.dragulaService.find('prevent')) {
            this.dragulaService.createGroup('prevent', {moves: (el, container, handle, sibling) => false});
        }
    }

    private scrollByMove() {
        document.onmousemove = e => {
            // tslint:disable-next-line: deprecation
            const event = e || window.event;
            // tslint:disable-next-line: no-string-literal
            const mouseX = event['pageX'];
            const width = window.innerWidth;
            if (mouseX / width > 0.9) {
                this.container.nativeElement.scrollLeft = this.container.nativeElement.scrollLeft + width * 2 / 5;
            } else if (mouseX / width < 0.1) {
                this.container.nativeElement.scrollLeft = this.container.nativeElement.scrollLeft - width * 2 / 5;
            }
            this.blockScroll = true;
            this.body.nativeElement.onmousemove = null;
        };
    }

    private moveBetween(groupTo: GroupByList, groupFrom: GroupByList, item) {
        // Move card between list of board.
        if (groupTo.pk === groupFrom.pk) {
            return this.base.sendToast(this.trans.instant('moving-in-list-not-saved'));
        }
        
        if (groupTo.isCustom) {
            return this.moveBetweenCustom(groupTo, item, groupFrom);
        }

        const groupBy = this.board.groupBy; 
        let value; 
        // Group is config by user.
        if (!this.isNote) {

            if (groupBy.type === FieldType.BOOL) {

                value = groupTo.value;

            } else if (groupBy.type === FieldType.DECIMAL) {

                if (groupTo.value[0] === groupTo.value[1]) {
                    value = groupTo.value[0];
                } else {
                    return this.cancelMove(this.trans.instant('moving-in-interval-deny'));
                }

            } else if (groupBy.type === FieldType.INT || groupBy.type === FieldType.REF) {

                if (groupBy.groups && groupBy.groups.filter(g => g.ref && g.ref.groupName === groupTo.name).length > 1) {
                    return this.cancelMove(this.trans.instant('moving-in-multi-values-deny'));
                } 
                value = groupTo.value;
                if (groupBy.type === FieldType.REF) {
                    const tt = this.metaFields.find(ff => ff.name === groupBy.name);
                    const pk = (tt ? tt.related_field : null) || this.base.getTablePk(tt ? tt.related_model : null);
                    value = {code: groupTo.name, name: groupTo.name};
                    value[pk] = groupTo.value;
                }

            } else if (groupBy.type === FieldType.DATE || groupBy.type === FieldType.DATETIME) {

                if (groupBy.typeDate === 'other' && groupTo.value[0] === groupTo.value[1]) {
                    value = groupTo.value[0];
                } else {
                    return this.cancelMove(this.trans.instant('moving-in-interval-date-deny'));
                }

            } else {

                return this.cancelMove(this.trans.instant('moving-deny'));

            }
        } else { 

            if (groupBy.type === FieldType.CHAR) {
                value = groupTo.name;
            } else if (groupBy.type === FieldType.DATETIME || groupBy.type === FieldType.DATE) {
                if (groupBy.typeDate === Interval.DAY) {
                    value = groupTo.name;
                } else { 
                    return this.cancelMove(this.trans.instant('moving-in-interval-date-deny'));
                }
            } else if (groupBy.type === FieldType.REF && groupTo.data.length) {
                value = groupTo.data[0][groupBy.name] ? groupTo.data[0][groupBy.name] : null;
            } else {
                return this.cancelMove(this.trans.instant('moving-deny'));
            }

        }

        if (groupBy.name === 'tag_name') {
            const pkT = this.base.getTablePk('TaskTag');
            if (groupFrom && groupTo && groupFrom.obj && groupTo.obj) {
                if (groupFrom.obj.parent && groupTo.obj.parent && groupFrom.obj.parent[pkT] === groupTo.obj.parent[pkT]) {
                    this.taskService.removeTaskTag(groupFrom.obj.tasktag_uuid).subscribe(resp => {
                        if (resp) {
                            this.base.sendToast(this.base.getError(resp));
                        } else {
                            for (let i = 0; i < item.tags.length; i++) {
                                if (item.tags[i][pkT] === groupFrom.obj[pkT]) {
                                    item.tags.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    });
                } 
                this.taskService.createTaskTag({ task: item[pkT], tag: groupTo.obj[pkT]}).subscribe(resp => {
                    if (resp && resp[pkT]) {
                        item.tags.splice(item.tags.length, 0, this.base.copy(groupTo.obj));
                    } else if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
            }
        } else {
            item[groupBy.name] = value;
            const data = {};
            data[groupBy.name] = null;
            if (typeof(value) === 'object' && value) {
                const tt = this.metaFields.find(f => f.name === groupBy.name);
                data[groupBy.name] = value[this.base.getTablePk(tt ? tt.related_model : null)];
            } else {
                data[groupBy.name] = value;
            }
            const pk = this.base.getTablePk(this.table);
            this.base.updateTable(data, item[pk], item.edited, this.table).subscribe(resp => {
                if (resp && resp[pk]) {
                    item.edited = resp.edited;
                    this.base.sendToast(this.trans.instant('edited-success'));
                    const col = this.board.listDisplay.find(i => i.isChecked && i.name === groupBy.name);
                    if (col) {
                        item[col.name + '__repr'] = this.representCell(col, item);
                    }
                    item = this.calcExtraField(item);
                } else if (resp && resp.error) {
                    const e = this.base.getError(resp.error);
                    return this.cancelMove(e === 'Не найдено.' ? this.trans.instant('object-not-found') : e);
                } else {
                    return this.cancelMove(this.trans.instant('error'));
                }
            });
        }
    }

    private moveBetweenCustom(groupTo: GroupByList, item, groupFrom: GroupByList) {

        const filters = groupTo.filters ? groupTo.filters.filter(
            i => this.canMoving(i.name) && i.equal === Equal.EQ && (i.name !== 'tag_uuid' || (i.name === 'tag_uuid' && i.value))) : [];
        if (!filters.length) {
            return this.cancelMove(this.trans.instant('moving-to-list-deny'));
        }

        let reload = false; let data = null;
        const tags = []; 
        for (const f of filters) {
            if (f.name === 'tag_uuid') {
                const pk = this.base.getTablePk('Task');
                tags.push(this.taskService.createTaskTag({ task: item[pk], tag: f.value}));
            } else {
                data = data ? data : {};
                data[f.name] = f.value;
                if (groupTo.data.length) {
                    item[f.name] = groupTo.data[0][f.name];
                    item[f.name + '__repr'] = groupTo.data[0][f.name + '__repr'];
                } else {
                    reload = true;
                }
            }
        }

        if (item.tags && item.tags.length) {
            const pk = this.base.getTablePk('Task');
            for (const f of groupFrom.filters.filter(f => f.name === 'tag_uuid' && f.value)) {   
                const tag = item.tags.find(t => t[pk] === (f.value[pk] || f.value));
                if (tag) {
                    tags.push(this.taskService.removeTaskTag(tag.tasktag_uuid));
                }
            }
        } 

        if (!tags.length) {
            tags.push(of({}));
        }

        zip(...tags).subscribe(vals => {
            for (const val of vals) {
                const resp: any = val;
                if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                    reload = true;
                }
            }

            if (data) {
                const pk = this.base.getTablePk(this.table);
                this.base.updateTable(data, item[pk], item.edited, this.table).subscribe(resp => {
                    if (resp && resp[pk]) {
                        item.edited = resp.edited;
                        this.base.sendToast(this.trans.instant('edited-success'));
                    } else if (resp && resp.error) {
                        const e = this.base.getError(resp.error);
                        return this.cancelMove(e === 'Не найдено.' ? this.trans.instant('object-not-found') : e);
                    } else {
                        return this.cancelMove(this.trans.instant('error'));
                    }
                    if (reload) {
                        return this.cancelMove(this.trans.instant('error'));
                    }
                });
            } else if (reload) {
                this.doRefresh(undefined);
            }
        })
    }

    private cancelMove(message) {
        this.base.sendToast(message);
        this.doRefresh(undefined);
    }

    private canMoving(fieldName?): boolean {
        return ['status_type', 'vise_user', 'vise_type', 'uuid', 'code', 'added', 'edited', 'id', 
                'tag_parent_name'].indexOf(fieldName || this.board.groupBy.name) === -1;
    }

    private addSepColumn(): ListDisplay[] {
        const columns: ListDisplay[] = [];
        this.board.refresh()
        const items = this.board.listDisplay;
        for (let i = 0; i < items.length; i ++) {
            items[i].flex = items[i + 1] ? items[i + 1].isFlex || items[i].isFlex : items[i].isFlex;
            columns.push(items[i]);
            if (items[i].isFlex && items[i + 1] && items[i + 2] && !items[i + 1].isFlex && items[i + 2].isFlex) {
                const sep = ListDisplay.fromJson({});
                sep.isSeparator = true;
                columns.push(sep);                 
            }
        }
        return columns;
    }

    private changeGroupBy() {
        this.cardCanMoving = this.canMoving();
    }

    chartRefresher() {
        this.doRefresh(undefined); 
        this.isChanged = true;
    }

    addFilterEvent([col, o]) {
        this.addFilter(col, o);
    }

    openFormatingEvent([col, o]) {
        this.openFormating(col, o);
    }

    refreshFilter(f) {
        f.isChecked = !f.isChecked; 
        this.pageList = 1; 
        this.doRefresh(undefined); 
        this.isChanged = true; 
        this.refresherGraph.next(this.board);
    }

    private exportToExcel() {
        this.dataIsLoaded = false;
        const data = []; const rows = [];
        
        for (const col of this.board.listDisplay.filter(f => f.isChecked)) {
            rows.push({
                verboseName: col.verboseName2 || col.verboseName, 
                fieldName: col.name,
                type: col.type,
            });
        }

        for (const group of this.boardGroup) {
            for (const item of group.data || []) {
                const val = {};
                for (const row of rows) {
                    if ([FieldType.DECIMAL, FieldType.DATE, FieldType.DATETIME, FieldType.TIME].find(i => i === row.type)) {
                        val[row.fieldName] = item[row.fieldName];
                    } else if (item[row.fieldName] && item[row.fieldName + '__repr']) {
                        val[row.fieldName] = item[row.fieldName + '__repr'].alt || item[row.fieldName + '__repr'];
                    } else {
                        val[row.fieldName] = item[row.fieldName];
                    }
                }
                data.push(val);
            }
        }

        this.exportSubject.next({data, rows});
    }

    complete() {
        this.dataIsLoaded = true;    
    }

    private async importFromExcel() {
        const modal = await this.modalCtrl.create({
            component: ImportFromExcelPage,
            componentProps: {table: this.table},
            cssClass: 'modal-item-detail-big',
            showBackdrop: false
        });
        modal.onDidDismiss().then((_) => {
        });
        return await modal.present();
    }

    private async importImages() {
        const modal = await this.modalCtrl.create({
            component: ImportImagesPage,
            componentProps: {table: this.table},
            cssClass: 'modal-item-detail-big',
            showBackdrop: false
        });
        modal.onDidDismiss().then((_) => {
        });
        return await modal.present();
    }

    async more(ev) {
        const buttons = [];
        if (this.board.pk) {
            buttons.push({title: 'share', src: 'assets/icon/share.svg'});
        }
        if (this.board.listDisplay.length) {
            buttons.push({title: 'export-to-excel', src: 'assets/icon/cloud-upload.svg'});
            buttons.push({title: 'import-from-excel', src: 'assets/icon/download-outline.svg'});
            buttons.push({title: 'import-images', src: 'assets/icon/images-outline.svg'});
        }
        if (!this.board.isTable) {
            buttons.push({title: 'print', src: 'assets/icon/print.svg'});
            buttons.push({title: 'list', src: 'assets/icon/plus.svg'});
        }
        if (!buttons.length) {
            return;
        }

        const popover = await this.popoverCtrl.create({
            component: SubMenuPage,
            componentProps: {buttons},
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.btn) {
                if (details.data.btn.title === 'share') {
                    this.share();
                } else if (details.data.btn.title === 'export-to-excel') {
                    this.exportToExcel();
                } else if (details.data.btn.title === 'print') {
                    this.print();
                } else if (details.data.btn.title === 'list') {
                    this.openSetting();
                } else if (details.data.btn.title === 'import-from-excel') {
                    this.importFromExcel();
                } else if (details.data.btn.title === 'import-images') {
                    this.importImages();
                }
            }
        });
        return await popover.present();
    }

    async moreMobile(ev) {
        const buttons = [];
        buttons.push({title: 'toggle-view', src: 'assets/icon/toggle-view.svg'});
        buttons.push({title: 'charts', src: 'assets/icon/charts.svg'});
        buttons.push({title: 'scan', src: 'assets/icon/camera.svg'});
        buttons.push({title: 'scan-pistol', src: 'assets/icon/pistol.svg'});
        if (this.board.pk) {
            buttons.push({title: 'share', src: 'assets/icon/share.svg'});
        }
        if (this.board.listDisplay.length) {
            buttons.push({title: 'export-to-excel', src: 'assets/icon/cloud-upload.svg'});
            buttons.push({title: 'import-from-excel', src: 'assets/icon/download-outline.svg'});
            buttons.push({title: 'import-images', src: 'assets/icon/images-outline.svg'});
        }
        if (!this.board.isTable) {
            buttons.push({title: 'print', src: 'assets/icon/print.svg'});
            buttons.push({title: 'list', src: 'assets/icon/add-list.svg'});
        }
        buttons.push({title: 'setting', src: 'assets/icon/setting-black.svg'});

        const popover = await this.popoverCtrl.create({
            component: SubMenuPage,
            componentProps: {buttons},
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.btn) {
                if (details.data.btn.title === 'toggle-view') {
                    this.changeView();
                } else if (details.data.btn.title === 'charts') {
                    this.showGraph.ok = !this.showGraph.ok;
                } else if (details.data.btn.title === 'scan') {
                    this.scan();
                } else if (details.data.btn.title === 'scan-pistol') {
                    this.pistol();
                } else if (details.data.btn.title === 'share') {
                    this.share();
                } else if (details.data.btn.title === 'export-to-excel') {
                    this.exportToExcel();
                } else if (details.data.btn.title === 'print') {
                    this.print();
                } else if (details.data.btn.title === 'list') {
                    this.openSetting();
                } else if (details.data.btn.title === 'setting') {
                    this.showDesk();
                } else if (details.data.btn.title === 'import-from-excel') {
                    this.importFromExcel();
                } else if (details.data.btn.title === 'import-images') {
                    this.importImages();
                }
            }
        });
        return await popover.present();
    }

    focusInputSearch() {
        this.inputSearch.setFocus();
    }
 
    private swipeBoard(isLeft) {
        const el = document.getElementsByClassName('board')[0];
        if (isLeft) {
            el.scrollLeft += window.innerWidth;
            if (el.scrollLeft > (this.boardGroup.length - 1) * window.innerWidth) {
                el.scrollLeft = (this.boardGroup.length - 1) * window.innerWidth;
            }
        } else {
            el.scrollLeft -= window.innerWidth;
            if (el.scrollLeft < 0) {
                el.scrollLeft = 0;
            }
        }
        this.currentBoard = parseFloat((el.scrollLeft * (this.scaleBig ? 2 : 1) / window.innerWidth).toFixed());
    }

    private getTouches(ev) {
        return ev.touches || ev.originalEvent.touches;
    }                                                     
      
    handleTouchStart(ev) {
        const firstTouch = this.getTouches(ev)[0];                                      
        this.xDown = firstTouch.clientX;                                      
        this.yDown = firstTouch.clientY;                                      
    }                                                
      
    handleTouchMove(ev) {
        if (!this.xDown || !this.yDown) {
            return;
        }
      
        const xUp = ev.touches[0].clientX;                                    
        const yUp = ev.touches[0].clientY;
      
        const xDiff = this.xDown - xUp;
        const yDiff = this.yDown - yUp;
      
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            this.swipeBoard(xDiff > 0);               
        }
        this.xDown = null;
        this.yDown = null;                                             
    }

    keypress(ev) {
        console.log(ev);
    }

    get isHierarchy(): boolean {
        return this.board.hierarchyFields && this.board.hierarchyFields.length > 0;
    }

    private hierarchyRefresh(item?: any) {
        if (item) {
            // this.hierarchySubject.next(item);
            this.pageList = 1;
            this.initHierarchy()
        } else {
            this.pageList = 1;
            this.initHierarchy()
        }
    }

    initHierarchy(eventRefresh?) {
        if (!this.isHierarchy || !this.canRequestGroup) {
            return;
        }

        const hierarchy = this.board.hierarchyFields[0];
        this.canRequestGroup = false;

        let filters: BoardFilter[] = [];
        if (hierarchy.useDefaultFilter) {
            filters.splice(filters.length, 0, ...this.board.filters.filter(f => f.isChecked))
        }
        if (hierarchy.filterFunc) {
            const val = this.base.getCalcRepr({parent: null, parents: []}, null, hierarchy.filterFunc);
            if (val && val.length) {
                filters = val.map(f => BoardFilter.fromJson(f)) 
            } 
        }
        
        let params = '';
        if (hierarchy.paramsFunc) {
            params = this.base.getCalcRepr({parent: null, parents: []}, null, hierarchy.paramsFunc);
        }

        if (this.pageList <= 1) {
            this.pageList = 1; this.boardGroup = [];
        }

        this.base.getData(hierarchy.table, this.pageList, hierarchy.maxElements, params, filters, true)
        .pipe(
            finalize(() => {
                if (eventRefresh) {   
                    eventRefresh.target.complete();
                }

                this.canRequestGroup = true;
                this.dataIsLoaded = true;
            })
        ).subscribe(resp => {
            if (resp.data) {
                
                let groups: GroupByList[] = [];
                if (this.pageList !== 1) {
                    groups = this.boardGroup.slice();
                }

                for (let i = 0; i < resp.data.length; i++) {
                    const repr = this.base.getCalcRepr(resp.data[i], {parent: null, parents: []}, hierarchy.reprFunc);
                    groups.push(new GroupByList([], repr, [], 1, true, true, resp.data[i], null, resp.data[i],
                                                false, false, false, repr, null, null, true, 0));
                }

                this.boardGroup = groups;
                this.loadingSpinnerX = resp.has_next;

                if (resp.has_next) { 
                    this.pageList ++; 
                } 
            }
        })
    }
 }
