import { Component, OnInit, HostListener, Inject, ViewChild } from '@angular/core';
import { NavParams, ModalController, AlertController, PopoverController } from '@ionic/angular';
import { TaskService } from '../services/task.service';
import { BaseService } from '../services/base.service';
import { TagPage } from '../tag/tag.page';
import { ChoicesPage } from '../choices/choices.page';
import { BehaviorSubject } from 'rxjs';
import {DOCUMENT} from '@angular/common';
import { SubMenuPage } from '../components/sub-menu/sub-menu.page';
import { DatetimeWidgetPage } from '../components/datetime-widget/datetime-widget.page';
import { TranslateService } from '@ngx-translate/core';
import { TaskParticipantsPage } from '../task-participants/task-participants.page';
import { Equal, FieldType } from '../classes/enums';
import { BoardFilter } from '../classes/board-filters';
import { Base } from '../classes/base';

@Component({
    selector: 'app-task',
    templateUrl: './task.page.html',
    styleUrls: ['./task.page.scss'],
})
export class TaskPage implements OnInit {

    @ViewChild('addTaskName') addTaskName: any;
    @ViewChild('taskName') taskName: any;
    table = 'Task';
    pk = ''; tagPk = ''; userPk = '';

    openedItem: any;
    currentUser = null;
    month = '';
    newComment = null;
    hideDetail = true;
    editName = false;
    changeItem = false;
    showProducer = false; 
    boolChange = false;
    
    addTask = null; showSubTask = false; subtask = null;
    tab = 0;
    innerHeight = 0;
    approveBlock = false;
    interval: any;

    itemChanged = new BehaviorSubject(null);
    changedItemSubject: any;
    subscription: any;

    subtasksPercentDone = 0;
    comments = [];
    actions = [];
    
    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.innerHeight = window.innerHeight;
    }

    constructor(private taskService: TaskService, 
                private base: BaseService, 
                private navParams: NavParams, 
                private modalCtrl: ModalController, 
                private alertCtrl: AlertController, 
                private popoverCtrl: PopoverController,
                private trans: TranslateService,
                @Inject(DOCUMENT) private document) { }

    ngOnInit() {
        this.userPk = this.base.getTablePk('User');
        this.pk = this.base.getTablePk(this.table);
        this.tagPk = this.base.getTablePk('Tag');
        this.innerHeight = window.innerHeight; 
        this.currentUser = Base.getCurrentUser();
        this.month = this.base.getMonthList();
        this.openedItem = this.navParams.get('item');
        
        if (this.openedItem.openedItem.name === undefined) {
            this.openedItem.openedItem.name = null;
        }
        if (this.openedItem.openedItem.description === undefined) {
            this.openedItem.openedItem.description = null;
        }
        if (!this.openedItem.openedItem.user) {
            const user = Base.getCurrentUser();
            this.openedItem.openedItem.user = {name: user ? user.name : null};
            this.openedItem.openedItem.user[this.userPk] = user[this.userPk];
        }
        if (!this.openedItem.openedItem.notification) {
            this.openedItem.openedItem.notification = -1;
        }

        this.interval = setInterval(() => {
            this.approveBlock = false;
            const e = this.document.getElementById('msg_container');
            if (e) {
                this.approveBlock = e.scrollTop + e.clientHeight <= e.scrollHeight - 20;
            }
        }, 1000);
        
        if (this.changedItemSubject) {
            this.subscription = this.changedItemSubject.subscribe(item => {
                this.itemChanged.next(item);
                if (item) {
                    this.openedItem.openedItem = item;
                }
                this.refreshSubtasksPercentDone(this.openedItem.openedItem);
            });
        } else {
            this.refreshSubtasksPercentDone(this.openedItem.openedItem);
        }
    }

    ionViewWillLeave() {
        clearInterval(this.interval);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refreshSubtasksPercentDone(item) {
        this.subtasksPercentDone = 0;
        item.dateClass = this.base.getDateClass(item.date_to, item.is_done);
        item.dateToRepr =  this.base.getTerm(item.date_to, true);
        if (item.subtasks && item.subtasks.length) {
            const cnt = item.subtasks.filter(t => t.is_done === true).length;
            if (cnt) {
                this.subtasksPercentDone = Math.floor(cnt * 100 / item.subtasks.length);
            }
            for (const subtask of item.subtasks) {
                subtask.dateClass = this.base.getDateClass(subtask.date_to, subtask.is_done);
                subtask.dateToRepr =  this.base.getTerm(subtask.date_to, true);
            }
            item.subtasks = this.base.sort(item.subtasks, 'added');
        }

        this.comments = this.getComments(item.comments || [], this.tab);
        this.actions = this.getActions(item.comments, 3);
    }

    async openSubMenu(ev, t) {
        this.subtask = t;
        const buttons = [];
        if (this.subtask) {
            buttons.push({title: 'open-new-window', src: 'assets/open-new-window.svg'});
            buttons.push({title: 'unarchive', src: 'assets/archive.svg'});
            if (!this.subtask.is_archive) {
                buttons[buttons.length - 1].title = 'archive';
            }
            buttons.push({title: 'untouch-subtask', src: 'assets/icon/close-outline.svg'});
        } else {
            buttons.push({title: 'btn-copy', src: 'assets/copy.svg'});
            buttons.push({title: 'unarchive', src: 'assets/archive.svg'});
            if (!this.openedItem.openedItem.is_archive) {
                buttons[buttons.length - 1].title = 'archive';
            }
            buttons.push({title: 'delete', src: 'assets/trash.svg'});
        }

        const popover = await this.popoverCtrl.create({
            component: SubMenuPage,
            componentProps: {buttons},
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.btn) {
                if (details.data.btn.title === 'open-new-window') {
                    this.openParent(this.subtask); 
                    this.subtask = null;
                } else if (details.data.btn.title === 'unarchive' || details.data.btn.title === 'archive') {
                    if (this.subtask) {
                        this.subtask.is_archive = !this.subtask.is_archive; 
                        this.updateSubTask({is_archive: this.subtask.is_archive}, this.subtask);
                        this.subtask = null;
                    } else {
                        this.openedItem.openedItem.is_archive = !this.openedItem.openedItem.is_archive; 
                        this.updateTask({is_archive: this.openedItem.openedItem.is_archive});
                    }
                } else if (details.data.btn.title === 'untouch-subtask') {
                    this.subtask.parent = null; 
                    this.updateSubTask({parent: null}, this.subtask);
                    this.subtask = null;
                } else if (details.data.btn.title === 'btn-copy') {
                    this.copy();
                } else if (details.data.btn.title === 'delete') {
                    this.removeTask();
                }
            }
        });
        return await popover.present();
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    focusName() {
        this.editName = true; 
        setTimeout(() => {
            this.taskName.nativeElement.focus();
        }, 500);
    }

    updateTask(data, onsuccess?) {
        this.base.updateTable(data, this.openedItem.openedItem[this.pk], this.openedItem.openedItem.edited, this.table).subscribe(resp => {
            if (resp && resp[this.pk]) {
                this.openedItem.openedItem.edited = resp.edited;
                this.editName = false;
                this.boolChange = false;
                if (onsuccess) {
                    onsuccess();
                }
            } else {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    updateName(code) {
        if (this.changeItem && code === 13) {
            this.changeItem = false;
            this.updateTask({name: this.openedItem.openedItem.name});
        }
    }

    removeTag(tag) {
        const pk = this.openedItem.openedItem.tags.find(t => t[this.tagPk] === tag[this.tagPk]).tasktag_uuid;
        this.taskService.removeTaskTag(pk).subscribe(resp => {
            if (resp) {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    async openTags(ev) {
        const popover = await this.popoverCtrl.create({
            component: TagPage,
            componentProps: {openedItem: this.openedItem, itemChanged: this.itemChanged},
            cssClass: 'tags-popover',
            showBackdrop: false,
            event: ev
        });
        return await popover.present();  
    }

    updateDescription(code) {
        if (this.changeItem && code === 13) {
            this.changeItem = false;
            this.updateTask({description: this.openedItem.openedItem.description});
        }
    }

    updateDone() {
        this.openedItem.openedItem.is_done = !this.openedItem.openedItem.is_done;
        this.updateTask({is_done: this.openedItem.openedItem.is_done});
    }

    updateDateTo() {
        this.updateTask({date_to: this.openedItem.openedItem.date_to});
    }

    async openDatetime(ev, t?) {
        const openedItem = t || this.openedItem.openedItem;
        const dt = openedItem.date_to ? this.base.copy(openedItem.date_to) : null;
        const item = {val: dt};
        const popover = await this.popoverCtrl.create({
            component: DatetimeWidgetPage,
            componentProps: {item},
            showBackdrop: false,
            event: ev
        });

        popover.onDidDismiss().then((_: any) => {
            if (t && t.date_to !== item.val) {
                if (t[this.pk]) {
                    this.updateSubTask({date_to: item.val}, t);
                } else {
                    t.date_to = item.val;
                    t.dateClass = this.base.getDateClass(t.date_to, t.is_done);
                    t.dateToRepr =  this.base.getTerm(t.date_to, true);
                }
            } else if (!t && this.openedItem.openedItem.date_to !== item.val) {
                this.updateTask({date_to: item.val});
            }
        });
        return await popover.present();  
    }

    updateAutodone() {
        if (this.boolChange) {
            this.updateTask({auto_done: this.openedItem.openedItem.auto_done});
        }
    }
    
    updateSubTask(data, task) {
        this.base.updateTable(data, task[this.pk], task.edited, this.table).subscribe(resp => {
            if (resp && resp[this.pk]) {
                task.edited = resp.edited;
            } else {
                this.base.sendToast(this.base.getError(resp));
            }
        });
    }

    doneSubTask(t) {
        t.is_done = !t.is_done; 
        this.updateSubTask({is_done: t.is_done}, t);
    }

    async changeExecutor(subTask) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'User',
                selected: this.base.copy(subTask.executor)
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                if (subTask[this.pk]) {
                    this.updateSubTask({executor: details.data.item[this.userPk]}, subTask);
                } else {
                    subTask.executor = details.data.item;
                }
            }
        });
        return await modal.present();
    }

    updateSubTaskName(t) {
        if (t.isChanged) {
            t.isChanged = false;
            this.updateSubTask({name: t.name}, t);
        }
    }

    addSubTask(code) {
        if (code !== 13 || !this.addTask || !this.addTask.name) {
            return;
        }
        this.taskService.createTask({name: this.addTask.name, parent: this.openedItem.openedItem[this.pk], 
                                     executor: this.addTask.executor ? this.addTask.executor[this.userPk] : null,
                                     date_to: this.addTask.date_to ? this.addTask.date_to : null,
                                     producer: this.openedItem.openedItem.executor ? 
                                     this.openedItem.openedItem.executor[this.userPk] : null})
                                     .subscribe(resp => {
            if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp.error));
            }
        });
        this.addTask = null;
    }

    async chooseSubTask() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: this.table,
                filters: [new BoardFilter('parent', Equal.EQ, null), 
                          new BoardFilter(this.pk, Equal.NOT, this.openedItem.openedItem[this.pk])]
            },
            backdropDismiss: true,
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.item) {
                const item = detail.data.item;
                if (item[this.pk] && item[this.pk] !== this.openedItem.openedItem[this.pk] && !item.parent) {
                    this.updateSubTask({parent: this.openedItem.openedItem[this.pk]}, item);
                    this.addTask = null;
                } else if (item.parent) {
                    this.base.sendToast(this.trans.instant('subtask-is-connected'));
                }
            }
        });
        return await modal.present();
    }

    newSubTask() {
        this.addTask = {
            parent: this.openedItem.openedItem[this.pk], 
            name: null, 
            executor: this.base.copy(this.openedItem.openedItem.executor) ? this.openedItem.openedItem.executor : null,
        };
        this.addTask.dateClass = this.base.getDateClass(this.addTask.date_to, this.addTask.is_done);
        this.addTask.dateToRepr =  this.base.getTerm(this.addTask.date_to, true);

        setTimeout(() => {
            this.addTaskName.setFocus();
        }, 500);
    }

    async chooseUser(field) {
        const before = this.openedItem.openedItem[field] ? this.base.copy(this.openedItem.openedItem[field]) : null;
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'User',
                selected: this.openedItem.openedItem[field],
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                const data = {};
                data[field] = details.data.item[this.userPk];
                this.updateTask(data, () => {
                    this.updateSubtasks(field, before, details.data.item);
                });
            }
        });
        return await modal.present();
    }

    addRemoveUser(user) {
        const part = (this.openedItem.openedItem.participants || []).find(p => p.user && p.user[this.userPk] === user[this.userPk]); 
        const pk = part ? part[this.base.getTablePk('TaskHistory')] : null;
        if (!pk) {
            this.taskService.addComment(this.openedItem.openedItem[this.pk], null, 2, true, null, user[this.userPk]).subscribe(resp => {
                if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                }
            });
        } else {
            this.taskService.removeParticipant(pk).subscribe(resp => {
                if (!resp) {
                    if (this.openedItem.openedItem.subtasks && this.openedItem.openedItem.subtasks.length) {
                        this.proposeRemoveParticipantFromSubTasks(this.base.copy(this.openedItem.openedItem.subtasks), 
                                                                  this.base.copy(user), [this.openedItem.openedItem[this.pk]]);
                    }
                } else if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                }
            });
        }
    }

    async proposeRemoveParticipantFromSubTasks(subtasks, user, tasks) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('changes'),
            message: this.trans.instant('delete-user-from-subtask', {name: user.name}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        this.removeParticipantFromSubTasks(subtasks, user, tasks);
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                },
            ]
        });
        await alert.present();
    }

    removeParticipantFromSubTasks(subtasks, user, tasks) {
        const taskPk = this.base.getTablePk('TaskHistory');
        if (subtasks && subtasks.length && user) {
            for (const t of subtasks) {
                this.taskService.getComments(t[this.pk]).subscribe(resp => {
                    if (resp && resp.data) {
                        for (const tt of resp.data) {
                            if (tt.user && tt.type === 2 && tt.user[this.userPk] === user[this.userPk]) {
                                this.taskService.removeParticipant(tt[taskPk]).subscribe(_ => {});
                            }
                        }
                    }
                });
                if (tasks.find(i => i === t[this.pk])) {
                    continue;
                }
                tasks.push(t[this.pk]);
                this.base.getData('Task', 1, 100, `&parent=${t[this.pk]}`).subscribe(resp => {
                    if (resp && resp.data && resp.data.length) {
                        this.removeParticipantFromSubTasks(resp.data, user, tasks);
                    }
                });
            }
        } 
    }

    async showUsers(ev) {
        const addRemoveUserFunc = new BehaviorSubject(null); 
        const subscription = addRemoveUserFunc.subscribe(user => {
            if (user) {
                this.addRemoveUser(user);
            }
        });

        const modal = await this.modalCtrl.create({
            component: TaskParticipantsPage,
            componentProps: {
                participants: this.openedItem.openedItem.participants,
                subject: this.itemChanged,
                addRemoveUserFunc,
            },
            showBackdrop: false,
            cssClass: 'participants-popover'
        });
        modal.onDidDismiss().then((_: any) => {
            subscription.unsubscribe();
        });
        return await modal.present();  
    }

    seeParentTask() {
        if (this.boolChange) {
            this.updateTask({seen_parent: this.openedItem.openedItem.seen_parent});
        }
    }

    openTab(tab) {
        this.tab = tab;
        this.comments = this.getComments(this.openedItem.openedItem.comments || [], this.tab);
    }

    getComment(comment) {
        if (comment.data && comment.comment) {
            if (typeof(comment.data) === 'string') {
                try {
                    comment.data = JSON.parse(comment.data);
                } catch {}
            }
            for (const p of Object.keys(comment.data)) {
                const indx = comment.comment.indexOf(`{{${p}}}`);
                if (indx !== -1) {
                    let v = `${comment.data[p]}`;
                    if (comment.data.type === FieldType.DATETIME) {
                        v = `${this.base.dateToStringPretty(v)}`;
                    }
                    comment.comment = comment.comment.slice(0, indx) + v + comment.comment.slice(indx + `{{${p}}}`.length); 
                }
            }
        } 
        return comment.comment ? comment.comment.trim() : comment.comment;
    }

    scrollMessages(ev) {
        this.approveBlock = ev.scrollTop + ev.clientHeight <= ev.scrollHeight - 20;
    }

    getActions(comments, type) {
        return comments.filter(i => !i.approve && i.user && i.user[this.userPk] === this.currentUser[this.userPk] 
               && [3].indexOf(i.type) !== -1 && (type === null || type === i.type));
    }

    scrollApprove() {
        this.approveBlock = false;
        const e = document.getElementById('msg_container');
        if (e) {
            e.scrollTop = e.scrollHeight - e.clientHeight;
        }
    }

    addComment(code, isShift?, body?) {
        if (isShift) {
            return;
        }
        if (code !== 13 || !(body || this.newComment)) {
            return; 
        }
        this.taskService.addComment(this.openedItem.openedItem[this.pk], body || this.newComment).subscribe(resp => {
            if (resp && resp[this.pk]) {
                this.newComment = null;
            } else if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp.error));
            }
        });
    }

    approve(v) {
        this.taskService.approve(v);
    }

    removeTask() {
        this.taskService.removeTask(this.openedItem.openedItem[this.pk]).subscribe(resp => {
            if (resp) {
                this.base.sendToast(this.base.getError(resp));
            } else {
                this.closeModal();
            }
        });
    }

    copy() {
        const task = this.base.copy({
            date_to: this.openedItem.openedItem.date_to, 
            notification: this.openedItem.openedItem.notification, 
            name: this.openedItem.openedItem.name, 
            executor: this.openedItem.openedItem.executor ? this.openedItem.openedItem.executor[this.userPk] : null,
            producer: this.openedItem.openedItem.producer ? this.openedItem.openedItem.producer[this.userPk] : null,
            is_done: this.openedItem.openedItem.is_done,
            seen_parent: this.openedItem.openedItem.seen_parent,
            auto_done: this.openedItem.openedItem.auto_done ? true : false,
            parent: this.openedItem.openedItem.parent ? this.openedItem.openedItem.parent[this.pk] : null,
        });
        if (!task.name) {
            return this.base.sendToast(this.trans.instant('caption-required'));
        }
        if (this.openedItem.openedItem.description) {
            task.description = this.base.copy(this.openedItem.openedItem.description);
        }

        this.taskService.createTask(task).subscribe(resp => {
            if (resp && resp[this.pk]) {
                this.openParent(resp);
            } else if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp.error));
            }
        });
    }

    openParent(task) {
        if (!task) {
            task = this.openedItem.openedItem.parent;
        }
        if (task) {
            this.base.getData('Task', 1, 1, `&${this.pk}=${task[this.pk]}`).subscribe(resp => {
                if (resp && resp.data && resp.data.length === 1) {
                    this.openSubTask(resp.data[0]);
                } else {
                    this.base.sendToast(this.trans.instant('task-not-found'));
                }
            });
        }
    }

    openSubTask(t) {
        const openedItem: any = {openedItem: this.base.copy(t)};
        let props: any; props = {item: openedItem};
        this.base.openItemModal(this.table, props, openedItem, TaskPage, 'task-window', true);
    }

    getComments(data, type) {
        if (!data) {
            return [];
        }
        const comments = []; let lastSepDate = null; let approveChanges = false;
        for (const t of data) {
            if (((t.type === 0 && type === 0) || (t.type !== 0 && type === 1) || type === 2) && t.user && t.type !== 2) {
                if (t.type === 3 && !t.approve) {
                    if (t.user && t.user[this.userPk] === this.currentUser[this.userPk]) {
                        approveChanges = true;
                    }
                    continue;
                }
                t.sep_date = null; const sepDate = this.base.getDateSeparator(t.edited);
                t.approveChanges = approveChanges;
                if (!comments.length || lastSepDate !== sepDate) {
                    t.sep_date = sepDate;
                    lastSepDate = sepDate.slice();
                }
                t.messageDate = this.base.getMessageDate(t.edited);
                t.messageText = this.getComment(t);
                comments.push(t);
            }
        }
        return comments;
    }

    updateSubtasks(field, before, current) {
        if (this.openedItem.openedItem.subtasks && this.openedItem.openedItem.subtasks.length) {
            if (field === 'producer' && current) {     
                this.proposeChangeProducerOfSubTasks(current, before);
            }
            if (field === 'executor' && this.openedItem.openedItem.executor) {
                this.proposeChangeExecutorOfSubTasks(current);
            }
        }
    }

    async proposeChangeExecutorOfSubTasks(executor) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('changes'),
            message: this.trans.instant('change-producer-in-subtasks', {name: executor.name}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        for (const t of this.openedItem.openedItem.subtasks) {
                            this.updateSubTask({producer: executor[this.userPk]}, t);
                        } 
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                },
            ]
        });
        await alert.present();
    }

    async proposeChangeProducerOfSubTasks(producer, preProducer) {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('changes'),
            message: this.trans.instant('add-to-watcher', {name: producer.name}),
            buttons: [
                {
                    text: this.trans.instant('yes'),
                    handler: () => {
                        for (const t of this.openedItem.openedItem.subtasks) {
                            this.taskService.getComments(t[this.pk]).subscribe(resp => {
                                if (resp && resp.data) {
                                    if (!resp.data.find(com => com.type === 2 && com.user 
                                                        && com.user[this.userPk] === producer[this.userPk])) {
                                        this.addParticipantToSubTask(t, producer);
                                    }
                                }
                            });
                        } 
                    }
                },
                {
                    text: this.trans.instant('no'),
                    handler: () => {}
                },
                {
                    text: this.trans.instant('replace-to', {name: preProducer.name}),
                    handler: () => {
                        for (const t of this.openedItem.openedItem.subtasks) {
                            this.taskService.getComments(t[this.pk]).subscribe(resp => {
                                if (resp && resp.data) {
                                    if (!resp.data.find(com => com.type === 2 && com.user && 
                                                        com.user[this.userPk] === producer[this.userPk])) {
                                        this.addParticipantToSubTask(t, producer);
                                    }
                                    for (const tt of resp.data) {
                                        if (tt.user && tt.type === 2 && tt.user[this.userPk] === preProducer[this.userPk]) {
                                            this.taskService.removeParticipant(tt[this.pk]).subscribe(_ => {});
                                        }
                                    }
                                }
                            });
                        } 
                    }
                },
            ]
        });
        await alert.present();
    }

    addParticipantToSubTask(task, user) {
        this.taskService.addComment(task[this.pk], null, 2, true, null, user[this.userPk]).subscribe(_ => {});
    }

    loadFile(ev) {
        this.addComment(13, false, ev.url);
    }
}
