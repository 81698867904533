import { BoardFilter, Choice } from "./board-filters";
import { FieldType, FunctionType, Interval } from "./enums"
import * as uuid from 'uuid';


export class GroupByDate {
    
    constructor(public gte: string,
                public lte: string) {
    }

    serilize(): any {
        return { valueGte: this.gte, valueLte: this.lte };
    }
}

export class GroupByNumber {
    
    constructor(public gte: number,
                public lte: number) {
    }

    serilize(): any {
        return { valueGte: this.gte, valueLte: this.lte };
    }
}

export class GroupByRef {
    
    constructor(public value: any,
                public repr: string,
                public groupName: string,
                public name: string) {
    }

    serilize(): any {
        return {
            value: this.value,
            value_repr: this.repr,
            groupName: this.groupName,
            name: this.name 
        };
    }
}

export class GroupByItem {

    constructor(public type: FieldType,
                public num?: GroupByNumber,
                public date?: GroupByDate,
                public ref?: GroupByRef) {
    }

    serialize(): any {
        if (this.num) {
            return this.num.serilize();
        }
        if (this.date) {
            return this.date.serilize();
        }
        if (this.ref) {
            return this.ref.serilize();
        }
    }

    static fromJson(d: any, type: FieldType, choices: Choice[]): GroupByItem[] {
        const list: GroupByItem[] = [];
        if (!d || !d.length) {
            return list;
        }
        for (const f of d) {
            if ((type == FieldType.INT && choices.length) || type === FieldType.REF) {
                list.push(new GroupByItem(type, null, null, new GroupByRef(f.value, f.value_repr, f.groupName, f.name)));
                continue;
            }
            switch (type) {
            case FieldType.INT:
            case FieldType.DECIMAL:
                list.push(new GroupByItem(type, new GroupByNumber(f.valueGte || 0, f.valueLte || 0)));
                break;
            case FieldType.DATETIME:
            case FieldType.DATE:
                list.push(new GroupByItem(type, null, new GroupByDate(f.valueGte || 0, f.valueLte || 0)));
                break;
            }
        }
        return list;
    }

    static serialize(groups: GroupByItem[]) {
        if (!groups) {
            return [];
        }
        return groups.map(f => f.serialize());
    }

}

export class GroupByList {

    constructor(public filters: BoardFilter[],
                public name: string,
                public data: any[],
                public nextPage: number,
                public isExtraFilter: boolean,
                public isNew: boolean,
                public value: any,
                public color: string,
                public obj: any,
                public hasNext: boolean,
                public isCustom: boolean,
                public hidden: boolean,
                public verbose: string,
                public pk: string,
                public fieldName: string,
                public scrollComplete: boolean,
                public cnt: number,
                public groupValue?: any,
                public groupTitle?: any,
                public newItem?: any[],
                public nextField?: any) {
        if (!this.pk) {
            this.pk = uuid.v4();
        }
        if (!this.newItem) {
            this.newItem = [];
        }
    }

    serialize(): any {
        return {    
            filters: BoardFilter.toList(this.filters),
            name: this.name, 
            data: [], 
            nextPage: this.nextPage, 
            isExtraFilter: this.isExtraFilter, 
            isNew: this.isNew,
            value: this.value,
            color: this.color,
            obj: this.obj,
            hasNext: this.hasNext,
            isCustom: this.isCustom,
            hidden: this.hidden,
            pk: this.pk,
            verbose: this.verbose,
            field_name: this.fieldName,
            newItem: this.newItem
        }
    }

    static fromJson(data: any): GroupByList[] {
        const list: GroupByList[] = [];
        if (!data || !data.length) {
            return list;
        }
        for (const d of data) {
            list.push(new GroupByList((d.filters || []).map(f => BoardFilter.fromJson(f)), d.name, d.data, d.nextPage, d.isExtraFilter || false, d.isNew || false,
                d.value, d.color, d.obj, d.hasNext || false, d.isCustom || false, d.hidden || false, d.verbose, d.pk, d.field_name, true, 0, null, null, d.newItem, null));
        }
        return list;
    }

    static serialize(list: GroupByList[]): any[] {
        if (!list) {
            return [];
        }
        return list.map(f => f.serialize());
    }

    static default(): GroupByList {
        return new GroupByList([], null, [], 1, false, false, null, null, null, false, false, false, null, null, null, true, 0);
    }
}


export class GroupBy {
    public isAscCheck: string;

    constructor(public name: string, 
                public verboseName: string,  
                public type: FieldType,
                public groups: GroupByItem[],
                public choices: Choice[],
                public typeDate: Interval,
                public isAsc: boolean,
                public hideDesk: boolean,
                public aggrField: string,
                public aggrFieldTitle: string,
                public func: FunctionType,
                public custom: GroupByList[],
                public hidden: GroupByList[],
                public showTagDetails: boolean,
                public hideFilterTags: boolean,
                public printWidth: number,
                public scanFilter: string,
                public scanFilterField: string,
                public scanFilterFieldRelated: string,
                public hideImages: boolean,
                public periodBegin: string,
                public periodEnd: string) {

        this.isAscCheck = `${this.isAsc}`;
    }

    serialize(): any {
        return { 
            name: this.name,
            verbose_name: this.verboseName,
            type: this.type,
            groups: GroupByItem.serialize(this.groups),
            choices: Choice.serialize(this.choices),
            typeDate: this.typeDate,
            isAsc: this.isAsc,
            hideDesk: this.hideDesk || false,
            aggrField: this.aggrField,
            aggrFieldTitle: this.aggrFieldTitle,
            function: this.func,
            custom: GroupByList.serialize(this.custom),
            hidden: GroupByList.serialize(this.hidden),
            showTagDetails: this.showTagDetails || false,
            hideFilterTags: this.hideFilterTags || false,
            printWidth: this.printWidth,
            scanFilter: this.scanFilter,
            scanFilterField: this.scanFilterField,
            scanFilterFieldRelated: this.scanFilterFieldRelated,
            hideImages: this.hideImages || false,
            periodBegin: this.periodBegin,
            periodEnd: this.periodEnd,
        }
    }

    changeOrder(): void {
        this.isAsc = !this.isAsc;
    }

    removeCustom(group: GroupByList) {
        for (let i = this.custom.length -1; i >= 0; i--) {
            if (this.custom[i].pk == group.pk) {
                this.custom.splice(i, 1);
            }
        }
    }

    addToHidden(group: GroupByList, pk: string) {
        if (!group.obj) {
            return;
        }
        const groupByList = new GroupByList([], group.verbose || group.name, [], 1, false, false, group.name, null, group.obj, false, false, true, null, null, this.name, true, 0);
        groupByList[pk] = group.obj ? group.obj[pk] : null;
        this.hidden.splice(this.hidden.length, 0, groupByList);   
    }

    removeHidden(group: GroupByList) {
        for (let j = 0; j < this.hidden.length; j++) {
            if (this.hidden[j].name === group.name) {
                this.hidden.splice(j, 1);
            }
        }
    }

    getCustom(group: GroupByList): GroupByList {
        for (let i = this.custom.length -1; i >= 0; i--) {
            if (this.custom[i].pk == group.pk) {
                return this.custom[i];
            }
        }
        return group;
    }

    static fromJson(f: any): GroupBy {
        const choices = Choice.fromJson(f.choices);
        return new GroupBy(f.name, f.verbose_name, f.type, GroupByItem.fromJson(f.groups, f.type, choices), choices,
                           f.typeDate, f.isAsc, f.hideDesk || false, f.aggrField, f.aggrFieldTitle,
                           f.function, GroupByList.fromJson(f.custom), GroupByList.fromJson(f.hidden), f.showTagDetails || false, f.hideFilterTags || false,
                           f.printWidth || 0, f.scanFilter, f.scanFilterField, f.scanFilterFieldRelated, f.hideImages || false,
                           f.periodBegin, f.periodEnd);
    }

    static default(): GroupBy {
        return new GroupBy(null, null, null, [], [], null, true, false, null, null, null, [], [], false, false, 0, null, null, null, false, null, null)
    }
}

