import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';
import { Equal } from '../classes/enums';
import { Base } from '../classes/base';


@Injectable({
    providedIn: 'root'
})
export class TaskService {

    task: any;
    constructor(private http: HttpClient, 
                private base: BaseService) { }

    addComment(task, comment, type = 0, approve = true, values = null, user = null): Observable<any> {
        const data = {
            user: user ? user : Base.getCurrentUser()[this.base.getTablePk('User')],
            comment,
            task,
            type,
            approve,
            data: values
        }; 
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/task-history/`, data)
        .pipe(
            tap(_ => this.base.log('fetched task-history')),
            catchError(this.base.handleError('addComment', this.task))
        );
    }

    updateComment(pk, edited, data): Observable<any> {
        data.edited = edited;
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/task-history/${pk}/`, data)
        .pipe(
            tap(_ => this.base.log('update task-history')),
            catchError(this.base.handleError('updateComment', this.task))
        );
    }

    getComments(pk): Observable<any> {
        return this.http.get<any>(`${this.base.getRootUrl()}portal/api/task-history/?task=${pk}&o=-added&page=1&pages=99999`)
        .pipe(
            tap(_ => this.base.log('fetched comments')),
            catchError(this.base.handleError('getComments', this.task))
        );
    }

    createTask(data): Observable<any> {
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/task/`, data)
        .pipe(
            tap(_ => this.base.log('create task')),
            catchError(this.base.handleError('createTask'))
        );
    }

    updateTask(data, pk, edited): Observable<any> {
        data.edited = edited;
        return this.http.patch<any>(`${this.base.getRootUrl()}portal/api/task/${pk}/`, data)
        .pipe(
            tap(_ => this.base.log('update task')),
            catchError(this.base.handleError('updateTask'))
        );
    }

    removeParticipant(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/task-history/${pk}/`)
        .pipe(
            tap(_ => this.base.log('remove participant')),
            catchError(this.base.handleError('removeParticipant'))
        );
    }

    approve(v) {
        const pk = this.base.getTablePk('TaskHistory');
        this.updateComment(v[pk], v.edited, {approve: true}).subscribe(resp => {
            if (resp && resp.error) {
                this.base.sendToast(this.base.getError(resp.error));
            }
        });
    }

    removeTask(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/task/${pk}/`)
        .pipe(
            tap(_ => this.base.log('remove task')),
            catchError(this.base.handleError('removeTask'))
        );
    }

    getTags(parent, page, pages, search, isActive, justMy = true): Observable<any> {
        let params = `&is_active=${isActive ? 1 : 0}`;
        if (search) {
            params += `&s=${search}`;
        }
        const filters = [{name: 'parent', equal: Equal.EQ, value: parent ? parent : null}]; 
        if (justMy) {
            const groupBy = [
                {
                    name: 'user',
                    value: Base.getCurrentUser()[this.base.getTablePk('User')],
                    group: 1 
                }, 
                {
                    name: 'is_public',
                    value: true,
                    group: 1
                }
            ];
            params += `&group_by=${JSON.stringify(groupBy)}`;
            filters.push(
                {
                    name: 'note',
                    equal: Equal.EQ,
                    value: 1 
                }
           );
        }
        params += `&filter_fields=${JSON.stringify(filters)}&o=name`;
        return this.base.getData('Tag', page, pages, params);
    }

    createTaskTag(data): Observable<any> {
        return this.http.post<any>(`${this.base.getRootUrl()}portal/api/task-tag/`, data)
        .pipe(
            tap(_ => this.base.log('create task tag')),
            catchError(this.base.handleError('createTaskTag'))
        );
    }

    removeTaskTag(pk): Observable<any> {
        return this.http.delete<any>(`${this.base.getRootUrl()}portal/api/task-tag/${pk}/`)
        .pipe(
            tap(_ => this.base.log('remove task tag')),
            catchError(this.base.handleError('removeTaskTag'))
        );
    }
}
