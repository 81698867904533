import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ScannerPage } from './scanner.page';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';

const routes: Routes = [
    {
        path: 'scanner',
        component: ScannerPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        NgQrScannerModule,
        BarecodeScannerLivestreamModule
    ],
    declarations: [ScannerPage],
    exports: [ScannerPage]
})
export class ScannerPageModule {}
