import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { DatePage } from './date.page';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        MaterialDesignFrameworkModule,
        DatePickerModule
    ],
    declarations: [DatePage],
    exports: [DatePage],
    entryComponents: [DatePage]
})
export class DatePageModule {}
