export class ExtraFields {

    constructor(public fields: string[] = []) {
    }

    private add(val: string) {
        if (!this.fields.find(f => f === val)) {
            this.fields.splice(this.fields.length, 0, val);
        }
    }

    private addExtraFields(fields: any[], rootFields: any[]) {
        for (const field of fields) {
            if (!rootFields.find(f => f.name === field.name)) {
                this.add(field.name);
            }
        }
    }

    private get name(): string {
        return 'extra_fields';
    }

    public get serialize(): string {
        if (this.fields.length) {
            return `&${this.name}=${JSON.stringify(this.fields)}`;
        }
        return '';
    }

    public static build(form: any, rootFields: any[]): ExtraFields {
        const fields = new ExtraFields();
        if (!form || !form.data) {
            return fields;
        }
        if (form.data.listDisplay) {
            fields.addExtraFields(form.data.listDisplay.filter(f => f.isChecked), rootFields);
        }
        if (form.data.orderBy) {
            fields.addExtraFields(form.data.orderBy.filter(f => f.isChecked), rootFields);
        }
        if (form.data.filters) {
            fields.addExtraFields(form.data.filters.filter(f => f.isChecked), rootFields);
        }
        return fields;
    }
}
