import { Component, OnInit, Input, HostListener, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { finalize } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/services/page.service';
import { Equal } from 'src/app/classes/enums';
import { BoardFilter } from 'src/app/classes/board-filters';

@Component({
    selector: 'app-catalog',
    templateUrl: './catalog.page.html',
    styleUrls: ['./catalog.page.scss'],
})
export class CatalogPage implements OnInit {
    @Input() row: any;
    searchFocus = false;
    searchValue = null;
    @Input() subscription: any;
    @Input() relateds: any;
    @Input() rootItem: any;
    @Input() form: any;
    @Input() activeRelateds: any;
    @Input() itemChanged: any;
    @Output() save = new EventEmitter();
    @Input() parentFilter: string;
    @Input() parent2Filter: string;
    @Input() elementFilter: string;
    page = 1;
    canRequest = true;
    elements = [];
    loadingSpinner = false;
    parents = [];
    parents2 = [];
    stage = 0;
    height = 0;
    width = 0;
    pistolScan = false;
    @ViewChild('pistolInput') pistolInput: any;
    @ViewChild('searchInput') searchInput: any;

    constructor(private base: BaseService,
                private trans: TranslateService,
                private pageService: PageService,
                @Inject(DOCUMENT) private document) { }

    @HostListener('window:resize', ['$event']) onResize(event: Event) {
        this.changeSize();
    }

    private changeSize() {
        this.height = window.innerHeight;
        const el = this.document.getElementsByClassName('elements')[0];
        if (el) {
            this.width = el.clientWidth - 8 - 10;
        }
    }

    ngOnInit() {
        this.changeSize();
        setTimeout(() => {
            this.changeSize();
        }, 1000);
        setTimeout(() => {
            this.changeSize();
        }, 2000);

        this.start();
        if (this.subscription) {
            this.subscription.subscribe(resp => {
                if (resp) {
                    this.page = 1;
                    this.refresh();
                }
            });
        }
    }

    openSearch() {
        this.searchFocus = true;
        setTimeout(() => {
            this.searchInput.setFocus();
        }, 100);
    }

    private start() {
        if (this.row.parentTable) {
            this.getParents(null, '');
            this.stage = 0;
        } else {
            this.getElements(null, '');
            this.stage = 2;
        }
    }

    private getElements(eventRefresh, params) {
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        const filters: BoardFilter[] = this.getFilters(this.elementFilter);
        if (this.parents.length) {
            const pk = this.base.getTablePk(this.row.parentTable);
            filters.push(new BoardFilter(this.row.parentField, Equal.EQ, this.parents[this.parents.length - 1][pk]));
        }
        if (this.parents2.length) {
            const pk = this.base.getTablePk(this.row.parentTable2);
            filters.push(new BoardFilter(this.row.parentField2, Equal.EQ, this.parents2[this.parents2.length - 1][pk]));
        }
        this.loadData(this.row.elementTable, params, eventRefresh, filters);
    }

    private getParents(eventRefresh, params) {
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        const filters: BoardFilter[] = this.getFilters(this.parentFilter);
        if (this.row.parentTable) {
            const pk = this.base.getTablePk(this.row.parentTable);
            const field = this.base.getTableMeta(this.row.parentTable).fields.find(f => f.related_model === this.row.parentTable);
            const value = this.parents.length ? this.parents[this.parents.length - 1][pk] : null;
            filters.push(new BoardFilter(field.name, Equal.EQ, value));
        }
        this.loadData(this.row.parentTable, params, eventRefresh, filters);
    }

    private getParents2(eventRefresh, params) {
        if (!this.canRequest) {
            return;
        }
        this.canRequest = false;
        const filters: BoardFilter[] = this.getFilters(this.parent2Filter);
        if (this.row.parentTable) {
            const pk = this.base.getTablePk(this.row.parentTable);
            const field = this.base.getTableMeta(this.row.parentTable2).fields.find(f => f.related_model === this.row.parentTable);
            const value = this.parents.length ? this.parents[this.parents.length - 1][pk] : null;
            filters.push(new BoardFilter(field.name, Equal.EQ, value));
        }
        if (this.row.parentTable2) {
            const pk = this.base.getTablePk(this.row.parentTable2);
            const field = this.base.getTableMeta(this.row.parentTable2).fields.find(f => f.related_model === this.row.parentTable2);
            const value = this.parents2.length ? this.parents2[this.parents2.length - 1][pk] : null;
            filters.push(new BoardFilter(field.name, Equal.EQ, value));
        }
        this.loadData(this.row.parentTable2, params, eventRefresh, filters);
    }

    private loadData(table, params, eventRefresh, filters: BoardFilter[]) {
        params = params ? params : '';
        if (this.searchValue) {
            params += '&s=' + this.searchValue;
        }
        this.base.getData(table, this.page, 50, params, filters, true).pipe(
            finalize(() => {
                if (eventRefresh) {   
                    eventRefresh.target.complete();
                }
                this.canRequest = true;
            })
        ).subscribe(data => {
            if (data && data.data) {
                let elements = [];
                if (this.page !== 1) {
                    elements = this.elements.slice();
                }
                elements.push(...data.data);
                this.elements = elements;
                this.refreshChecked();
                this.loadingSpinner = data.has_next;
                if (data.has_next) { 
                    this.page ++; 
                } 
            } else {
                this.loadingSpinner = false;
            }
        });
    }

    doSearch(event) {
        this.searchValue = event.detail.value;
        this.page = 1;
        this.refresh();
    }

    private refresh() {
        if (this.stage === 0) {
            this.getParents(null, '');
        } else if (this.stage === 1) {
            this.getParents2(null, '');
        } else {
            this.getElements(null, '');
        }
    }

    goToParent(i) {
        if (i === this.parents.length - 1 && !this.parents2.length) {
            return;
        }
        this.parents.splice(i + 1, this.parents.length - i - 1);
        this.stage = this.parents.length && !this.parents[this.parents.length - 1].has_children ? 1 : 0;  
        this.searchValue = null;
        this.parents2 = [];
        this.page = 1;
        this.refresh();
    }

    goToParent2(i) {
        if (i === this.parents2.length - 1) {
            return;
        }
        this.parents2.splice(i + 1, this.parents2.length - i - 1);
        this.page = 1;
        this.searchValue = null;
        this.stage = 1;
        this.refresh();
    }

    clear() {
        if (!this.parents.length) {
            return;
        }
        this.page = 1;
        this.parents = [];
        this.parents2 = [];
        this.start();
    }

    scrollElements(event) {
        if (this.loadingSpinner && this.canRequest && 
            event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.refresh();
        }
    }

    openElement(el) {
        if (this.stage === 0) {
            this.page = 1;
            this.parents.splice(this.parents.length, 0, this.base.copy(el));
            if (!el.has_children) {
                this.stage = this.row.parentTable2 ? 1 : 2;
                this.searchValue = null;                
            }
            this.refresh();
        } else if (this.stage === 1) {
            this.page = 1;
            this.parents2.splice(this.parents2.length, 0, this.base.copy(el));
            if (!el.has_children) {
                this.stage = 2;
                this.searchValue = null;
            }
            this.refresh();
        } else if (this.relateds) {
            this.setElement(el);
        }
    }

    private setElement(el) {
        if (!this.relateds[this.row.relatedTable]) {
            this.relateds[this.row.relatedTable] = [];
        }
        const pk = this.base.getTablePk(this.row.elementTable);
        const element: any = {}; element[this.row.relatedField] = this.base.copy(el);
        if (this.row.incrementField) {
            element[this.row.incrementField] = 1;
            for (let i = 0; i < this.relateds[this.row.relatedTable].length; i++) {
                const row = this.relateds[this.row.relatedTable][i]; 
                if (row.item.openedItem && row.item.openedItem[this.row.relatedField] 
                    && row.item.openedItem[this.row.relatedField][pk] === el[pk]) {
                    row.item.openedItem[this.row.incrementField] = parseInt(row.item.openedItem[this.row.incrementField] || 0, 10) + 1;
                    this.itemChanged.next(0);
                    let changes: any; changes = {};
                    changes[this.row.incrementField] = row.item.openedItem[this.row.incrementField];
                    const form = this.form.data.relateds.find(ff => ff.data.table === this.row.relatedTable);
                    this.save.emit({item: row.item, form, fields: changes, index: i});
                    return; 
                }
            }
        }
        
        const form = this.form.data.relateds.find(i => i.data.table === this.row.relatedTable);
        this.base.setDefault(element, form, this.rootItem, this.activeRelateds, this.relateds);
        if (this.row.relatedFill) {
            for (const fill of this.row.relatedFill) {
                if (fill.from && fill.to && fill.from.name && fill.to.name) {
                    element[fill.to.name] = element[this.row.relatedField][fill.from.name];
                }
            }
        }
        const item = {
            item: {openedItem: element}, 
            error: null, 
            fields: this.base.getTableMeta(this.row.relatedTable).fields, 
            hideEmpties: {}
        };
        this.relateds[this.row.relatedTable].splice(this.relateds[this.row.relatedTable].length, 0, item);
        let changes: any; changes = {};
        changes[this.row.incrementField] = element[this.row.incrementField];
        this.save.emit({item: this.relateds[this.row.relatedTable][this.relateds[this.row.relatedTable].length - 1].item, form, 
                        fields: changes, index: this.relateds[this.row.relatedTable].length});
        this.refreshChecked();
        this.itemChanged.next(0);
        this.funcAfterChanged(item.item);
    }

    private refreshChecked() {
        const pk = this.base.getTablePk(this.row.elementTable);
        const relateds = this.relateds && this.relateds[this.row.relatedTable] ? this.relateds[this.row.relatedTable] : [];
        for (const el of this.elements) {
            el.checked = relateds.find(f => f.item.openedItem[this.row.relatedField] 
                                        && f.item.openedItem[this.row.relatedField][pk] === el[pk]);
            el.repr = this.getRepr(el);
        }
    }

    pistol() {
        this.pistolScan = !this.pistolScan;
        if (this.pistolScan) {
            this.pistolInput.setFocus();
        }
    }

    pistolValue(ev) {
        if (ev.detail.value && this.pistolScan) {
            if (!this.canRequest) {
                return;
            }
            this.canRequest = false;
            this.base.getData(this.row.elementTable, 1, 2, '', [new BoardFilter(this.row.pistolField, Equal.EQ, ev.detail.value)], true).pipe(
                finalize(() => {
                    this.canRequest = true;
                    this.pistolInput.value = null;
                })
            ).subscribe(data => {
                if (data && data.data) {
                    if (data.data.length === 1) {
                        this.setElement(data.data[0]);
                    } else if (data.data.length > 1) {
                        this.base.sendToast(this.trans.instant('found-more-than-one-element', 
                                            {cnt: data.data.length, code: ev.detail.value}), 5000);
                    } else {
                        this.base.sendToast(this.trans.instant('not-found-element', {code: ev.detail.value}), 5000);
                    }
                }
            });
        }
    }

    private getRepr(element) {
        if (this.row.fieldRepr) {
            const divRepr = this.base.getCalcRepr(element, this, this.row.fieldRepr);
            if (divRepr) {
                return this.pageService.trustHtml(divRepr);
            }
        }
    }

    private funcAfterChanged(item: any) {
        if (this.row.funcAfterChanged) {
            return this.base.getCalcRepr(item.openedItem, this.rootItem ? this.rootItem.openedItem : item.openedItem, this.row.funcAfterChanged, this.relateds);
        }
    }

    private getFilters(func: string): BoardFilter[] {
        if (!func) {
            return [];
        }
        const val = this.base.getCalcRepr({elements: this.elements, parents: this.parents, parents2: this.parents2}, this.rootItem, func, this.relateds);
        return (val || []).map(f => BoardFilter.fromJson(f));
    }
}
