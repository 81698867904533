import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    transform(val: string): string {
        let phone = '';
        switch (val.length) {
        case 13:
            phone += val.slice(0, 1);
            val = val.slice(1, val.length);
        case 12:
            phone += val.slice(0, 2) + " ";
            val = val.slice(2, val.length);
        case 10:
            phone += " (" + val.slice(0, 3) + ") " + val.slice(3, 6) + "-" + val.slice(6, 8) + "-" + val.slice(8);
            break;
        default:
            return val;
        }
        return phone;
    }
}
