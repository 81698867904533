import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../services/base.service';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Equal } from '../classes/enums';

@Component({
    selector: 'app-news',
    templateUrl: './news.page.html',
    styleUrls: ['./news.page.scss'],
})
export class NewsPage implements OnInit {

    @Input() currentParent = null;
    @Input() group: any;
    news = [];
    page = 1;
    parent = [];
    hasNext = false;
    scrollComplete = true;
    currentGroup: any;

    constructor(private base: BaseService) { }

    ngOnInit() { 
        this.doRefresh(this.currentParent);
    }

    doRefresh(parent, eventRefresh= null) {
        this.currentParent = parent;
        if (this.group) {
            this.scrollComplete = false;
            this.getNews(this.group, this.page, 5, parent, null)
            .pipe(
                finalize(() => {
                    this.scrollComplete = true; 
                    if (eventRefresh) { 
                        eventRefresh.target.complete();
                    }
                })
            )
            .subscribe(resp => {
                if (resp && resp.data) {
                    if (!this.currentGroup && resp.data.length) {
                        this.currentGroup = this.base.copy(resp.data[0].group);
                    }
                    if (this.page === 1) {
                        this.news = resp.data;
                    } else {
                        for (const item of resp.data) {
                            this.news.splice(this.news.length, 0, item);
                        }
                    }
                    this.hasNext = resp.has_next;
                    if (this.hasNext) {
                        this.page ++;
                    }
                } else if (resp && resp.error) {
                    this.base.sendToast(this.base.getError(resp.error));
                }
            });
        }
    }

    goUp() {
        this.page = 1; let parent = null;
        if (this.parent.length <= 1) {
            this.parent = [];
        } else {
            this.parent.splice(this.parent.length - 1, 1);
            parent = this.parent[this.parent.length - 1];
        }
        this.doRefresh(parent);
    }

    goDown(obj) {
        if (obj.has_children) {
            this.page = 1;
            const pk = this.base.getTablePk('New');
            this.parent.splice(this.parent.length, 0, obj[pk]); 
            this.doRefresh(obj[pk]);
        } 
    }

    scroll(event) {
        if (this.hasNext && this.scrollComplete 
            && event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.doRefresh(this.currentParent);
        }
    }

    getNews(group: string, page: number, pages: number, parent = null, search = null): Observable<any> {
        let params = '&o=-has_children, -date, num';
        if (search) {
            params += `&s=${search}`;
        }
        const filters = [{name: 'has_children', equal: Equal.EQ, value: 0}];
        params += `&filter_fields=${JSON.stringify(filters)}`;
        if (parent) {
            params += `&parent=${parent}`;
        }
        params += `&group=${group}`;
        return this.base.getData('New', page, pages, params);
    }
}
