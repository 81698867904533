import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WidgetsPage } from './widgets.page';
import { ServicePageModule } from '../service/service.module';
import { NewsPageModule } from '../news/news.module';
import { ListPageModule } from '../list/list.module';
import { InfoGraphPageModule } from '../info-graph/info-graph.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ServicePageModule,
        NewsPageModule,
        ListPageModule,
        InfoGraphPageModule
    ],
    declarations: [WidgetsPage],
    exports: [WidgetsPage]
})
export class WidgetsPageModule {}
