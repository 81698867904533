import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { BoardEvent } from 'src/app/classes/board';
import { Call, MessageType, CallStatus, RedirectType } from 'src/app/classes/sip';
import { BaseService } from 'src/app/services/base.service';
import { SipService } from 'src/app/services/sip.service';
import { InputPhoneWidgetPage } from '../input-phone-widget/input-phone-widget.page';

enum TabType {
    WAITER, 
    CALLING,
    REDIRECT
}

@Component({
    selector: 'app-call-widget',
    templateUrl: './call-widget.page.html',
    styleUrls: ['./call-widget.page.scss'],
})
export class CallWidgetPage implements OnInit {
    
    @Input() call: Call
    waiter: boolean = false;
    subscription: Subscription;
    buttons = {
        CALL: false,
        CANCEL: false,
        HOLD_ON: false,
        HOLD_UP: false,
        REDIRECT: false,
        ADD: false,
        ACTIVE_REDIRECT: false,
        INPUT_PHONE: false,
        START: false,
    }
    tab: TabType;
    TabType = TabType;
    CallStatus = CallStatus;
    RedirectType = RedirectType;
    Call = Call;
    interval: any;

    constructor(private modalCtrl: ModalController,
                public sip: SipService,
                private base: BaseService,
                private http: HttpClient) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.refreshButtons();
        }, 5000);

        this.refreshButtons();

        this.subscription = this.sip.subject.subscribe(answer => {
            if (!answer || !answer.call) {
                this.refreshButtons();
                return;
            }

            const call = Call.calls.find(c => c.id === answer.call.id);

            switch (answer.type) {
            case MessageType.BUSY_CALL:
            case MessageType.CANCEL_CALL:

                this.canceledCall()
                break;

            case MessageType.ACCEPT_CALL:
            case MessageType.HOLD_ON:
            case MessageType.HOLD_UP:

                if (call) {
                    this.call = call;
                }
                break;

            }

            this.refreshButtons();
        });
    }

    accept() {
        this.call.accept();
        this.refreshButtons();
        if (this.call.incoming) {
            this.getCallEvent(this.call);
        }
    }

    cancel(call: Call) {
        call.cancel();
        this.sip.removeCall(call);
        this.canceledCall();
    }

    hold() {
        if (this.buttons.HOLD_ON) {
            this.call.holdOn();
        } else if (this.buttons.HOLD_UP) {
            this.call.holdUp();
        }
    }

    add() {
        if (this.buttons.ADD) {
            this.newCall(false, null);
        }
    }

    redirect() {
        this.tab = TabType.REDIRECT;
    }

    redirectBack() {
        if (this.tab === TabType.REDIRECT) {
            this.tab = null;
            this.refreshButtons();
        }
    }

    select(call: Call) {
        if (call.id !== this.call.id) {
            this.call = call;
        }
        this.refreshButtons();
    }

    selectRedirect(call: Call) {
        if (call.redirect !== RedirectType.NONE) {
            call.redirect = RedirectType.NONE;
        } else {
            if (Call.calls.find(c => c.redirect === RedirectType.FROM)) {
                for (const call of Call.calls) {
                    if (call.redirect === RedirectType.TO) {
                        call.redirect = RedirectType.NONE;
                    }
                }
                call.redirect = RedirectType.TO;
            } else {
                call.redirect = RedirectType.FROM;
            }
        }
        this.refreshButtons();
    }

    inputPhone() {
        const call = Call.calls.find(c => c.redirect === RedirectType.FROM);
        if (call) {
            this.newCall(true, call);
            this.redirectBack();
        }
    }

    sendRedirect() {
        const fromCall = Call.calls.find(c => c.redirect === RedirectType.FROM);
        const toCall = Call.calls.find(c => c.redirect === RedirectType.TO);
        if (this.buttons.ACTIVE_REDIRECT && fromCall && toCall) {
            this.cancel(toCall);
            fromCall.refer(toCall.phone);
        }
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private refreshButtons() {
        this.buttons.START =  this.call.status === CallStatus.START;
        this.buttons.CALL = this.call.incoming && this.call.status === CallStatus.RECEIVE;
        this.buttons.CANCEL = true;
        this.buttons.HOLD_ON = this.call.status === CallStatus.CALLING;
        this.buttons.HOLD_UP = this.call.status === CallStatus.HOLD_ON;
        this.buttons.REDIRECT = [CallStatus.CALLING, CallStatus.HOLD_ON, CallStatus.HOLD_UP].indexOf(this.call.status) !== -1;
        this.buttons.ADD = Call.calls.find(c => c.status !== CallStatus.HOLD_ON) ? false : true;
        this.buttons.INPUT_PHONE = Call.calls.find(c => c.redirect === RedirectType.FROM) ? true : false;
        this.buttons.ACTIVE_REDIRECT = this.buttons.INPUT_PHONE && Call.calls.find(c => c.redirect === RedirectType.TO) ? true : false;

        if (this.buttons.CALL || this.call.status === CallStatus.START) {
            this.tab = TabType.WAITER;
        } else if (this.tab !== TabType.REDIRECT) {
            this.tab = TabType.CALLING;
        }
        if (!Call.calls.length) {
            this.modalCtrl.dismiss();
        }
    }

    private canceledCall() {
        if (!Call.calls.length) {
            this.modalCtrl.dismiss();
        } else {
            this.call = Call.calls[Call.calls.length-1];
        }
    }

    private async newCall(refer, call: Call) {
        const modal = await this.modalCtrl.create({
            component: InputPhoneWidgetPage,
            componentProps: {
                call, 
                refer
            },
            cssClass: 'call-widget',
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
        });
        return await modal.present();
    }

    private getCallEvent(call: Call) {
        this.http.post<any>(`${this.base.getRootUrl()}portal/call-event`, {phone: call.phone}).subscribe(event => {
            if (event) {
                BoardEvent.fromJson(event).run();
            }
        })
    }
}
