import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { JsonSchemaFormService } from 'angular6-json-schema-form';
import { BaseService } from 'src/app/services/base.service';
import { DatetimeWidgetPage } from '../../datetime-widget/datetime-widget.page';

@Component({
    selector: 'app-datetime',
    templateUrl: './datetime.page.html',
    styleUrls: ['./datetime.page.scss'],
})
export class DatetimePage implements OnInit {

    formControl: AbstractControl;
    controlName: string;
    controlValue: any;
    controlDisabled = false;
    boundControl = false;
    options: any;
    @Input() layoutNode: any;
    @Input() layoutIndex: number[];
    @Input() dataIndex: number[];
    dateRepr = '';

    constructor(private jsf: JsonSchemaFormService,
                private base: BaseService,
                private popoverCtrl: PopoverController) {
    }

    ngOnInit() {
        this.options = this.layoutNode.options || {};
        this.jsf.initializeControl(this, !this.options.readonly);
        this.changeDateRepr();
    }

    updateValue(ev) {
        this.options.showErrors = true;
        this.jsf.updateValue(this, ev.value);
    }

    async openDatetime(ev) {
        const dt = this.controlValue ? this.base.copy(this.controlValue) : null;
        const item = this.base.copy({val: dt});
        const popover = await this.popoverCtrl.create({
            component: DatetimeWidgetPage,
            componentProps: {item, open: false},
            showBackdrop: false,
            event: ev
        });

        popover.onDidDismiss().then((_: any) => {
            if (dt !== item.val) {
                this.updateValue({value: item.val || null});
            }
        });
        return await popover.present();  
    }

    changeDateRepr() {
        this.dateRepr = '';
        if (this.controlValue) {
            this.dateRepr = this.base.dateTimeToString(this.controlValue);
        }
    }

}
