import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { InputPage } from './input.page';
import { MaterialDesignFrameworkModule } from 'angular6-json-schema-form';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        MaterialDesignFrameworkModule
    ],
    declarations: [InputPage],
    exports: [InputPage],
    entryComponents: [InputPage],
})
export class InputPageModule {}
