import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BotService {

    botgroupColl = 'botgroup';
    botmessageColl = 'botmessage';
    botbindColl = 'botbind';
    botbuttonColl = 'botbutton';
    tableColl = 'table';
    
    constructor(private base: BaseService,                 
                private http: HttpClient) { 
    }

    get(collection, page: number, pages: number, params: string): Observable<any> {
        let url = `${this.getRootUrl()}bot/api/${collection}?page=${page}&pages=${pages}`;
        if (params) {
            url += params;
        }
        return this.http.get<any>(url);
    }

    post(data, collection): Observable<any> {
        return this.http.post<any>(`${this.getRootUrl()}bot/api/${collection}`, data)
        .pipe(
            tap(_ => this.base.log(`post ${collection}`)),
            catchError(this.base.handleError('post'))
        );
    }

    patch(data, primaryKey, collection): Observable<any> {
        return this.http.patch<any>(`${this.getRootUrl()}bot/api/${collection}/${primaryKey}`, data)
        .pipe(
            tap(_ => this.base.log(`patch ${collection}`)),
            catchError(this.base.handleError('patch'))
        );
    }

    delete(primaryKey, collection): Observable<any> {
        return this.http.delete<any>(`${this.getRootUrl()}bot/api/${collection}/${primaryKey}`)
        .pipe(
            tap(_ => this.base.log('delete ' + collection)),
            catchError(this.base.handleError('delete'))
        );
    }

    getRootUrl() {
        return this.base.getRootUrl();
    }

    isEmpty(resp) {
        if (!resp) {
            return true;
        }
        return JSON.stringify(resp) === '{}';
    }
}
