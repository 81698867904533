import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { GraphSettingPage } from './graph-setting.page';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPageModule } from '../filter/filter.module';

const routes: Routes = [
    {
        path: '',
        component: GraphSettingPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        FilterPageModule
    ],
    declarations: [GraphSettingPage],
})
export class GraphSettingPageModule {}
