export enum BoardEventType {
    OPEN_ITEM,
    FILTER
}

export enum Equal {
    EQ = '=',
    NOT = '!=',
    GT = '>',
    GTE = '>=',
    LT = '<',
    LTE = '<='
}

export enum FieldType {
    INT = 'IntegerField',
    DECIMAL = 'DecimalField',
    BOOL = 'BooleanField',
    CHAR = 'CharField',
    REF = 'ForeignKey',
    DATE = 'DateField',
    TIME = 'TimeField',
    DATETIME = 'DateTimeField'
}


export enum Interval {
    DAYS = 'days',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
    OTHER = 'other'
}

export enum FunctionType {
    COUNT = 'count',
    SUM = 'sum'
}

export enum ChartType {
    PIE = 'pie', 
    ADVANCED_PIE = 'advanced-pie', 
    PIE_GRID = 'pie-grid', 
    VERTICAL_BAR = 'vertical-bar', 
    HORIZONTAL_BAR = 'horizontal-bar', 
    STACKED_VERTICAL_BAR = 'stacked-vertical-bar', 
    STACKED_HORIZONTAL_BAR = 'stacked-horizontal-bar', 
    NORMALIZED_VERTICAL_BAR = 'normalized-vertical-bar', 
    NORMALIZED_HORIZONTAL_BAR = 'normalized-horizontal-bar', 
    LINE = 'line', 
    AREA = 'area', 
    AREA_STACKED = 'area-stacked', 
    AREA_NORMALIZED = 'area-normalized', 
    POLAR = 'polar', 
    NUMBER_CARD = 'number-card', 
    TREE_MAP = 'tree-map', 
    HEAT_MAP = 'heat-map'
}

export enum TableAction {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete'
}