import { Component, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './classes/app.constants';
import { BaseService } from './services/base.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SipService } from './services/sip.service';
import { CallWidgetPage } from './components/call-widget/call-widget.page';
import { InputPhoneWidgetPage } from './components/input-phone-widget/input-phone-widget.page';
import { Call } from './classes/sip';
import { Base } from './classes/base';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {
    
    @ViewChild('audio') audio;

    public showMenu = false;
    public isShowMenu = false;
    public isShowChat = false;
    public unread = 0;
    public isAuth = false;
    public allowTelegram = false;
    subscriptionAuth: Subscription;
    subscriptionStream: Subscription;
    showChatSubject = new BehaviorSubject({isShowChat: this.isShowChat, unread: this.unread});
    public changed = false;
    canCall = false;
    hideStream = true;

    constructor(private platform: Platform,
                private authService: AuthService,
                private translate: TranslateService,
                private base: BaseService,
                private sip: SipService,
                private modalCtrl: ModalController) {
        this.initializeApp();
    }

    initializeApp() {
        this.sip.callPage = CallWidgetPage;
        this.platform.ready().then(() => {});
        this.translate.setDefaultLang(Constants.DEFAULT_LANG);
        this.subscriptionAuth = this.base.auth.subscribe(_ => {
            if (this.isAuth !== this.authService.authenticated()) {
                this.changed = true;
            }
            this.isAuth = this.authService.authenticated();
            this.allowTelegram = this.isAuth && this.base.allowTelegram.indexOf(this.base.getDomain()) !== -1;
            this.showChatSubject.next({isShowChat: this.isShowChat, unread: this.unread});
            this.base.setLogo();
            this.base.setLanguage(this.base.getLanguage());
            const user = Base.getCurrentUser();
            if (user && user.binotel) {
                this.sip.account = user.binotel;
                this.sip.init();
                this.canCall = true;
                this.hideStream = true;
                this.subscriptionStream = this.sip.streamSubject.subscribe(stream => {
                    if (stream) {
                        setTimeout(() => {
                            this.hideStream = false;
                        }, 500)
                        setTimeout(() => {
                            if (this.audio) {
                                this.audio.nativeElement.srcObject = stream;
                            }
                        }, 1000);
                    }
                })
            }
        });
    }

    async call() {
        if (Call.calls.length) {
            await this.sip.openCallWidget(Call.calls[0]);
            return;
        }
        const modal = await this.modalCtrl.create({
            component: InputPhoneWidgetPage,
            componentProps: {
            },
            cssClass: 'call-widget',
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
        });
        return await modal.present();
    }

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnDestroy() {
        if (this.subscriptionAuth) {
            this.subscriptionAuth.unsubscribe();
        }
        if (this.subscriptionStream) {
            this.subscriptionStream.unsubscribe();
        }
    }

    closeEvent(ev) {
        if (!ev) {
            return;
        }
        this.isShowChat = ev.isShowChat ? true : false;
        this.unread = ev.unread || 0;
    }
}
