import { TranslateService } from "@ngx-translate/core";
import { BaseService } from "../services/base.service";

export enum MessageType {
    INIT_TRACK,
    NEW_CALL,
    CANCEL_CALL,
    INCOMING_CALL,
    RINGING_CALL,
    ACCEPT_CALL,
    BUSY_CALL,
    HOLD_ON,
    HOLD_UP,
    REFER_CALL,
    INIT_AUDIO_MSG
}

export enum CallStatus {
    START,
    RECEIVE,
    WAIT,
    CALLING,
    HOLD_ON,
    HOLD_UP,
    CLOSING
}

export enum RedirectType {
    NONE,
    FROM,
    TO
}

export class Caller {

    constructor(public phone: string,
                private trans: TranslateService,
                private _name?: string, 
                public image?: string) {
        
        this._name = _name;
    }

    get name(): string {
        if (!this._name) {
            return this.trans.instant('unknown');
        }
        return this._name;
    }
}

export class Call {

    public static calls: Call[] = [];

    private _redirect: RedirectType;
    public duration: string;
    private startTime: Date;
    private durationInterval: any;

    constructor(public id: string, 
                public phone: string, 
                private _status: CallStatus, 
                public account: any, 
                public ws: WebSocket, 
                public incoming: boolean,
                public caller: Caller) {
        
        this.status = _status;
        this.calcDuration();
        this.redirect = RedirectType.NONE;
    }

    get status(): CallStatus {
        return this._status;
    }

    set status(val: CallStatus) {
        this._status = val;
    }

    get redirect(): RedirectType {
        return this._redirect;
    }

    set redirect(val: RedirectType) {
        this._redirect = val;
    }

    accept() {
        this.status = CallStatus.CALLING;
        this.send(MessageType.ACCEPT_CALL);
    }

    cancel() {
        this.send(MessageType.CANCEL_CALL);
    }

    holdOn() {
        this.send(MessageType.HOLD_ON);
    }

    refer(referTo: string) {
        this.send(MessageType.REFER_CALL, referTo);
    }

    holdUp() {
        this.send(MessageType.HOLD_UP);
    }

    ringing() {
        this.send(MessageType.RINGING_CALL)
    }

    drop() {
        const indx = Call.calls.indexOf(this);
        if (indx !== -1) {
            Call.calls.splice(indx, 1);
        }

        if (this.durationInterval) {
            clearInterval(this.durationInterval);
        }
    }

    private calcDuration() {
        this.startTime = new Date();
        this.durationInterval = setInterval(() => {
            let seconds = parseInt((((new Date()).getTime() - this.startTime.getTime()) / 1000).toFixed(0));
            const minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;
            this.duration = `${minutes < 10 ? '0': ''}${minutes}:${seconds < 10 ? '0': ''}${seconds}`;
        }, 1000)
    }

    private send(type: MessageType, params?: string) {
        this.ws.send(JSON.stringify({type, account: this.account, params, call_id: this.id}));
    }

    static getCall(raw: any): Call {
        if (raw.call_id) {
            return this.calls.find(c => c.id === raw.call_id);
        }
    }
}

export class Answer {

    constructor(public data: string, 
                public type: MessageType, 
                public err: string, 
                public call: Call, 
                public ws: WebSocket, 
                public account: any,
                private base: BaseService) {
    }

    presentError() {
        this.base.sendToast(this.err);
    }
}
