import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseService } from '../services/base.service';
import { TaskService } from '../services/task.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { TemplateService } from '../services/template.service';
import { ChoicesPage } from '../choices/choices.page';
import { SubMenuPage } from '../components/sub-menu/sub-menu.page';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Equal } from '../classes/enums';
import { Constants } from '../classes/app.constants';
import { Base } from '../classes/base';

@Component({
    selector: 'app-tag',
    templateUrl: './tag.page.html',
    styleUrls: ['./tag.page.scss'],
})
export class TagPage implements OnInit {

    @ViewChild('search') search: any;
    @ViewChild('name') name: any;
    @Input() openedItem: any;
    @Input() itemChanged: any;
    subscription: any;
    tab = 0; 
    editedTag: any;
    tagSearch = ''; 
    hasNext = false; 
    page = 1;
    tags = []; 
    scrollComplete = true; 
    tagIsActive = true; 
    tagParent = []; 
    justMy = true;
    currentUser: any;
    subMenuTag: any;
    taskTagCnt = 0;
    colors = [];
    currentParent = null;
    pk = '';
    taskTagPk = null;
    bgPk = '';


    constructor(private base: BaseService, 
                private taskService: TaskService, 
                private modalCtrl: ModalController, 
                private templateServise: TemplateService, 
                private popoverCtrl: PopoverController,
                private trans: TranslateService) { }

    ngOnInit() {
        this.pk = this.base.getTablePk('Tag');
        this.bgPk = this.base.getTablePk('Background');
        this.templateServise.getBackgrounds(true, 1, 500, null, 1).subscribe(resp => {
            if (resp && resp.data) {
                this.colors = resp.data;
            }
        });

        if (!this.openedItem) {
            this.openedItem = {openedItem: {tags: []}};
        }
        this.currentUser = Base.getCurrentUser();
        this.loadTags();

        if (this.itemChanged) {
            this.subscription = this.itemChanged.subscribe(item => {
                if (item && item.tags) {
                    this.openedItem.openedItem.tags = item.tags;
                }
                this.refreshTags();
            });
        } else {
            this.refreshTags();
        }

        setTimeout(() => {
            this.search.setFocus();
        }, 500);
    }

    ionViewWillLeave() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    saveTag() {
        if (this.editedTag) {
            const data = {
                color: this.editedTag.color ? this.editedTag.color[this.bgPk] : null,
                is_public: this.editedTag.is_public ? true : false,
                is_active: this.editedTag.is_active ? true : false,
                name: this.editedTag.name,
                parent: this.editedTag.parent ? this.editedTag.parent[this.pk] : null
            };
            if (!data.color) {
                return this.base.sendToast(this.trans.instant('color-required'));
            }
            if (!data.name) {
                return this.base.sendToast(this.trans.instant('name-required'));
            }
            if (this.editedTag[this.pk]) {
                this.base.updateTable(data, this.editedTag[this.pk], this.editedTag.edited, 'Tag').subscribe(resp => {
                    if (resp && resp[this.pk]) {
                        this.tab = 0; this.page = 1;
                        this.loadTags();
                    } else if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
            } else {
                this.base.createTable(data, 'Tag').subscribe(resp => {
                    if (resp && resp[this.pk]) {
                        this.tab = 0; this.page = 1;
                        this.loadTags();
                    } else if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
            }
        }
    }

    loadTags() {
        if (!this.scrollComplete) {
            return;
        }
        if (this.tagSearch) {
            this.page = 1;
        }
        const p = this.currentParent ? this.currentParent[this.pk] : null;
        this.scrollComplete = false;
        this.taskService.getTags(p, this.page, Constants.ON_PAGE, this.tagSearch, this.tagIsActive, this.justMy)
        .pipe(
            finalize(() => {
                this.scrollComplete = true;
            })
        )
        .subscribe(resp => {
            if (this.page === 1) {
                this.tags = [];
            }
            if (resp && resp.data) {
                for (const t of resp.data) {
                    this.tags.splice(this.tags.length, 0, t);
                }
                this.hasNext = resp.has_next;
                if (this.hasNext) {
                    this.page ++; 
                }
                this.refreshTags();
            }
        });
    }

    scrollTags(event) {
        if (this.hasNext && this.scrollComplete 
            && event.srcElement.scrollTop / (event.srcElement.scrollHeight - event.srcElement.clientHeight) >= 0.9) {
            this.loadTags();
        }
    }

    tagGoBack() {
        if (this.tagParent.length) {
            this.tagParent.splice(this.tagParent.length - 1, 1);
            this.refreshTagParent();
            this.page = 1; this.tagSearch = null;
            this.loadTags();
        } else {
            this.refreshTagParent();
        }
    }

    refreshTagParent() {
        if (this.tagParent.length) {
            this.currentParent = this.base.copy(this.tagParent[this.tagParent.length - 1]);
            this.currentParent.tagged = this.getTaskTag(this.openedItem.openedItem.tags, this.currentParent[this.pk]) ? true : false;
        } else {
            this.currentParent = null;
        }
    }

    refreshTags() {
        for (const t of this.tags) {
            t.tagged = this.getTaskTag(this.openedItem.openedItem.tags, t[this.pk]);   
        }
    }

    addRemoveTag(tag, isParent) {
        const taskPk = this.base.getTablePk('Task');
        if (!this.openedItem.openedItem[taskPk]) {
            return;
        }
        const addOrRemove = () => {
            const pk = this.getTaskTag(this.openedItem.openedItem.tags, tag[this.pk]);
            if (!pk) {
                this.taskService.createTaskTag({task: this.openedItem.openedItem[taskPk], tag: tag[this.pk]}).subscribe(resp => {
                    if (resp && resp.error) {
                        this.base.sendToast(this.base.getError(resp.error));
                    }
                });
            } else {
                this.taskService.removeTaskTag(pk).subscribe(resp => {
                    if (resp) {
                        this.base.sendToast(this.base.getError(resp));
                    }
                });
            }
        };

        if (!isParent) {
            this.taskService.getTags(tag[this.pk], 1, Constants.ON_PAGE, null, this.tagIsActive, this.justMy).subscribe(resp => {
                if (resp && resp.data && resp.data.length) {
                    this.tagParent.splice(this.tagParent.length, 0, this.base.copy(tag)); 
                    this.refreshTagParent();
                    this.page = 1; this.tagSearch = null; this.tags = [];
                    for (const t of resp.data) {
                        this.tags.splice(this.tags.length, 0, t);
                    }
                    this.hasNext = resp.has_next;
                    if (this.hasNext) {
                        this.page ++; 
                    }
                    this.refreshTags(); 
                } else {
                    addOrRemove();
                }
            });
        } else {
            addOrRemove();
        }
    }

    getTaskTag(taskTags, pk) {
        if (!taskTags) {
            return null;
        }
        const i = taskTags.find(t => t[this.pk] === pk);
        if (i) {
            return i.tasktag_uuid;
        }
        return null;
    }

    async chooseItem(tag) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: 'Tag',
                selected: tag.parent,
            },
            backdropDismiss: true,
        });
        modal.onDidDismiss().then((detail: any) => {
            if (detail && detail.data && detail.data.item) {
                tag.parent = detail.data.item;
            }
        });
        return await modal.present();
    }

    removeTag(tag) {
        if (tag && tag[this.pk]) {
            this.base.removeTable(tag[this.pk], null, 'Tag').subscribe(resp => {
                if (resp) {
                    this.base.sendToast(this.base.getError(resp));
                } else {
                    this.tab = 0; this.page = 1;
                    this.loadTags();
                }
            });
        }
    }

    getCnt(tag) {
        if (tag) {
            tag.cnt = 0;
            const q = `&filter_fields=${JSON.stringify([{name: 'tag', equal: Equal.EQ, value: tag[this.pk]}])}`;
            this.base.getData('TaskTag', 1, 1, q).subscribe(resp => {
                if (resp && resp.cnt) {
                    tag.cnt = resp.cnt;
                }
            });
        }
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }

    async openSubMenu(ev, tag) {
        this.subMenuTag = this.base.copy(tag);
        const buttons = [];
        if (this.subMenuTag.is_active) {
            buttons.push({title: 'change', src: 'assets/edited.svg'});
            buttons.push({title: 'add-parent-tag', src: 'assets/add-parent.svg'});
            buttons.push({title: 'archive', src: 'assets/archive.svg'});
        } else {
            buttons.push({title: 'restore', src: 'assets/restore.svg'});
        }
        buttons.push({title: 'delete', src: 'assets/trash.svg'});

        const popover = await this.popoverCtrl.create({
            component: SubMenuPage,
            componentProps: {buttons},
            showBackdrop: false,
            event: ev,
        });
        popover.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.btn) {
                if (details.data.btn.title === 'change') {
                    this.editedTag = this.subMenuTag; 
                    this.tab = 2; 
                    setTimeout(() => {
                        this.name.nativeElement.focus();
                    }, 500);
                    this.subMenuTag = null;
                } else if (details.data.btn.title === 'add-parent-tag') {
                    this.editedTag = {name: null, color: null, is_public: false, is_active: true, parent: {name: this.subMenuTag.name}};
                    this.editedTag.parent[this.pk] = this.subMenuTag[this.pk]; 
                    this.tab = 1; 
                    setTimeout(() => {
                        this.name.nativeElement.focus();
                    }, 500);
                    this.subMenuTag = null;
                } else if (details.data.btn.title === 'archive') {
                    this.editedTag = this.subMenuTag; 
                    this.editedTag.is_active = false; 
                    this.saveTag(); 
                    this.subMenuTag = null;
                } else if (details.data.btn.title === 'restore') {
                    this.editedTag = this.subMenuTag; 
                    this.editedTag.is_active = true; 
                    this.saveTag(); 
                    this.subMenuTag = null;
                } else if (details.data.btn.title === 'delete') {
                    this.tab = 3; 
                    this.getCnt(this.subMenuTag);
                }
            }
        });
        return await popover.present();
    }

    newTag() {
        this.editedTag = {
            name: null, 
            color: null, 
            is_public: false, 
            is_active: true, 
            parent: this.currentParent
        }; 
        this.tab = 1; 
        setTimeout(() => {
            this.name.nativeElement.focus();
        }, 500);
    }
}
