import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MenuPage } from './menu.page';
import { TranslateModule } from '@ngx-translate/core';
import { UserPhotoPageModule } from '../components/user-photo/user-photo.module';

const routes: Routes = [
    {
        path: 'menu',
        component: MenuPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        UserPhotoPageModule
    ],
    declarations: [MenuPage],
    exports: [MenuPage]
})
export class MenuPageModule {}
