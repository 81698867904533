import { Component, OnInit, Input } from '@angular/core';
import { Board } from 'src/app/classes/board';
import { BoardButton, ButtonAction } from 'src/app/classes/board-buttons';

@Component({
    selector: 'app-board-button',
    templateUrl: './board-button.page.html',
    styleUrls: ['./board-button.page.scss'],
})
export class BoardButtonPage implements OnInit {

    @Input() board: Board;

    constructor() { }

    ngOnInit() {
    }

    addButton() {
        this.board.buttons.splice(this.board.buttons.length, 0, new BoardButton('', '', ButtonAction.UPDATE, '', ''));
    }

    clear() {
        this.board.buttons = [];
    }
}
