import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MapPage } from './map.page';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
    {
        path: 'map',
        component: MapPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule.forChild(routes)
    ],
    declarations: [MapPage],
    exports: [MapPage]
})
export class MapPageModule {}
