import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ChoicesPage } from './choices.page';
import { TranslateModule } from '@ngx-translate/core';
import { CellPageModule } from '../components/cell/cell.module';

const routes: Routes = [
    {
        path: 'choices',
        component: ChoicesPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        CellPageModule
    ],
    declarations: [ChoicesPage]
})
export class ChoicesPageModule {}
