import { Component, OnInit, Input } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { ModalController } from '@ionic/angular';
import { BotService } from 'src/app/services/bot.service';
import { BaseService } from 'src/app/services/base.service';
import { FieldType } from 'src/app/classes/enums';

@Component({
    selector: 'app-collection',
    templateUrl: './collection.page.html',
    styleUrls: ['./collection.page.scss'],
})
export class CollectionPage implements OnInit {

    @Input() item: any;
    fieldTypes = [
        FieldType.INT,
        FieldType.DECIMAL,
        FieldType.BOOL,
        FieldType.CHAR,
        FieldType.REF,
        FieldType.DATE,
        FieldType.TIME
    ];
    tables = [];

    constructor(private meta: MetaService,
                private modalCtrl: ModalController,
                private botService: BotService,
                private base: BaseService) { }

    ngOnInit() {
        this.tables = this.meta.getTablesArray();
    }

    save(close?) {
        const data: any = {
            verbose_name: this.item.verbose_name,
            fields: this.item.fields,
        };
        if (this.item._id) {
            this.botService.patch(data, this.item._id, this.botService.tableColl).subscribe(resp => {
                if (resp && resp._id) {
                    this.meta.getTablesMeta().subscribe(_ => {});
                    if (close) {
                        this.modalCtrl.dismiss({item: this.item});
                    } 
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        } else {
            data.table = this.item.table;
            this.botService.post(data, this.botService.tableColl).subscribe(resp => {
                if (resp && resp._id) {
                    this.meta.getTablesMeta().subscribe(_ => {});
                    this.item._id = resp._id;
                    if (close) {
                        this.modalCtrl.dismiss({item: this.item});
                    } 
                } else {
                    this.base.sendToast(this.base.getError(resp));
                }
            });
        }
    }

    addField() {
        if (!this.item.fields) {
            this.item.fields = [];
        }
        this.item.fields.splice(this.item.fields.length, 0, {
            choices: [],
            func_repr: null,
            name: '',
            related_model: null,
            repr: null,
            type: null,
            verbose_name: ''
        });
    }

    addFieldChoice(field) {
        if (!field.choices) {
            field.choices = [];
        }
        field.choices.splice(field.choices.length, 0, [field.choices.length, '']);
    }

    close() {
        this.modalCtrl.dismiss({});
    }
}
