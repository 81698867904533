import { Component, OnInit, Input } from '@angular/core';
import { Board, HierarchyField } from 'src/app/classes/board';
import { BaseService } from 'src/app/services/base.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
    selector: 'app-hierarchy-field',
    templateUrl: './hierarchy-field.page.html',
    styleUrls: ['./hierarchy-field.page.scss'],
})
export class HierarchyFieldPage implements OnInit {

    @Input() board: Board;
    tables: any = {};
    tablesTitles: any[] = [];

    constructor(private base: BaseService) { }

    ngOnInit() {

        for (const table of this.base.getLocalMeta()) {
            this.tables[table.table] = table;
            this.tablesTitles.splice(this.tablesTitles.length, 0, {title: table.verbose_name || table.table, value: table.table});
        }
        this.tablesTitles = this.base.sort(this.tablesTitles, 'title')
        console.log(this.tables, this.tablesTitles)
    }

    addField() {
        this.board.hierarchyFields.splice(this.board.hierarchyFields.length, 0, HierarchyField.fromJson({}))
    }

    reorder(ev) {
        const itemToMove = this.board.hierarchyFields.splice(ev.detail.from, 1)[0];
        this.board.hierarchyFields.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }
}
