import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChoicesPage } from 'src/app/choices/choices.page';
import { BoardFilter } from 'src/app/classes/board-filters';
import { BotProcessCondition, GroupBotProcess, Variable } from 'src/app/classes/botprocess';
import { Equal, FieldType, TableAction } from 'src/app/classes/enums';
import { Process, ProcessBind } from 'src/app/classes/process';
import { ProcessVersion } from 'src/app/classes/processversion';
import { FormPage } from 'src/app/form/form.page';
import { BaseService } from 'src/app/services/base.service';
import { MetaService } from 'src/app/services/meta.service';

@Component({
    selector: 'app-process-bind',
    templateUrl: './process-bind.page.html',
    styleUrls: ['./process-bind.page.scss'],
})
export class ProcessBindPage implements OnInit {

    @Input() processes: Process[];
    @Input() bind: ProcessBind;
    @Input() processFrom: Process;

    toId: number;
    fromId: number;
    types: FieldType[] = [FieldType.BOOL, FieldType.CHAR, FieldType.DECIMAL, FieldType.INT, FieldType.REF];
    FieldType = FieldType;
    tables = [];

    constructor(private modalCtrl: ModalController,
                private alertCtrl: AlertController,
                private trans: TranslateService,
                private base: BaseService,
                private meta: MetaService) { }

    ngOnInit() {
        for (const table of this.meta.getTablesArray()) {
            this.tables.splice(this.tables.length, 0, {title: table[1], id: table[0]});
        }

        if (this.bind) {
            this.fromId = this.bind.fromId.id;
            this.toId = this.bind.toId.id;
        } else if (this.processFrom && this.processFrom.id) {
            this.fromId = this.processFrom.id;
        }

        if (!this.bind) {
            let fromId: GroupBotProcess;
            if (this.processFrom && this.processFrom.id) {
                fromId = GroupBotProcess.fromJson(this.processFrom.serialize())
            }
            this.bind = new ProcessBind(null, fromId, null, false, [], ProcessVersion.get());
        }

        const filters: BoardFilter[] = [];
        for (const cond of this.bind.condition) {
            if (cond.variableId && cond.variableId.id) {
                filters.push(new BoardFilter('id', Equal.EQ, cond.variableId.id));
            }
        }

        if (filters.length) {
            this.base.getData(Variable.table, 1, 999, '', filters, true).subscribe(resp => {
                if (resp && resp.data) {
                    for (const item of resp.data) {
                        const variable = Variable.fromJson(item);
                        for (const cond of this.bind.condition) {
                            if (cond.variableId && cond.variableId.id == variable.id) {
                                cond.variableId =  Variable.fromJson(item);
                            }
                        }
                    }
                } else {
                    this.base.sendToast(this.base.getError(resp))
                }
            })
        }
    }

    selectProcessFrom(ev) {
        const process = this.processes.find(p => p.id === this.fromId);
        if (process) {
            this.bind.fromId = GroupBotProcess.fromJson(process.serialize())
        }
    }

    selectProcessTo(ev) {
        const process = this.processes.find(p => p.id === this.toId);
        if (process) {
            this.bind.toId = GroupBotProcess.fromJson(process.serialize())
        }
    }

    async closeModal() {
        if (this.bind.id) {
            this.modalCtrl.dismiss({action: TableAction.UPDATE});
        } else if (this.bind.fromId && this.bind.fromId.id && this.bind.toId && this.bind.toId.id) {
            await this.askSave();
        } else {
            this.modalCtrl.dismiss({});
        }
    }

    async askSave() {
        const alert = await this.alertCtrl.create({
            header: this.trans.instant('want-to-create'),
            buttons:  [
                {
                    text: this.trans.instant('yes'),
                    handler: (_) => {
                        this.create()
                    }
                },
                {
                    text: this.trans.instant('no'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (_) => {
                        this.modalCtrl.dismiss({});
                    }
                }, 
            ]
        });
        await alert.present();
    }

    create() {
        if (!this.bind.fromId || !this.bind.fromId.id || !this.bind.toId || !this.bind.toId.id) {
            return;
        }
        this.base.createTable(this.bind.serialize(), ProcessBind.table).subscribe(resp => {
            if (!resp || !resp.id) {
                this.base.sendToast(this.base.getError(resp))
            } else {
                this.bind.id = resp.id;
                this.modalCtrl.dismiss({bind: this.bind});
            }
        })
    }

    delete() {
        this.base.removeTable(this.bind.id, null, ProcessBind.table).subscribe(resp => {
            if (resp) {
                this.base.sendToast(this.base.getError(resp))
            } else {
                this.modalCtrl.dismiss({action: TableAction.DELETE});
            }
        })
    }

    async chooseField(condition: BotProcessCondition) {
        if (!condition.table) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: condition.table,
                selected: condition.ref
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(condition.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                condition.ref = details.data.item;
                if (pk == 'id') {
                    condition.id = details.data.item[pk];
                } else {
                    condition.uuid = details.data.item[pk];
                }
            }
        });
        return await modal.present();
    }

    addCondition() {
        this.bind.condition.splice(this.bind.condition.length, 0, BotProcessCondition.fromJson({equal: Equal.EQ, type: FieldType.BOOL}))
    }

    async chooseVariable(cond: BotProcessCondition) {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            showBackdrop: false,
            componentProps: {
                model: Variable.table,
                filters: [new BoardFilter('is_parent', Equal.EQ, false), ProcessVersion.filter()],
            },
        });
        modal.onDidDismiss().then((details: any) => {
            const pk = this.base.getTablePk(Variable.table);
            if (details && details.data && details.data.item && details.data.item[pk]) {
                cond.variableId = Variable.fromJson(details.data.item)
            }
        });
        return await modal.present();
    }

    editObj(field: any, table: string) {
        this.base.editObj(field, table, FormPage)
    }
}
