import { ChartType, Interval } from "./enums";

export class Constants {
    public static DEFAULT_LANG = 'ru';
    public static LANGUAGES = [
        { title: 'Українська', code: 'uk' },
        { title: 'Русский', code: 'ru' },
        { title: 'Čeština', code: 'cs' },
        { title: 'English', code: 'en' }
    ];
    
    public static INTERVALS = [Interval.YEAR, Interval.QUARTER, Interval.MONTH, Interval.WEEK, Interval.DAY];

    public static COLORS_SHADOW = [
        ['#DCAA56', '#2a1e09'],
        ['#AC5DAC', '#221122'],
        ['#34BDC0', '#0b2828'],
        ['#94BBF3', '#05162e'],
        ['#FD917B', '#320901'],
        ['#34505F', '#121c21'],
        ['#4BB16E', '#0f2416'],
        ['#D36163', '#280b0c'],
        ['#CA6FC1', '#250e23'],
        ['#A5B4BD', '#161a1d'],
        ['#272727', '#0d0d0d'],
        ['#C7A684', '#231a10'],
        ['#279689', '#0a2925'],
        ['#3C9CDC', '#081d2b'],
        ['#CFC98D', '#24220f'],
        ['#0779BD', '#022031']
    ];

    public static CHART_TYPES = [ChartType.PIE, ChartType.ADVANCED_PIE, ChartType.PIE_GRID, ChartType.VERTICAL_BAR, ChartType.HORIZONTAL_BAR, ChartType.STACKED_VERTICAL_BAR, 
                                 ChartType.STACKED_HORIZONTAL_BAR, ChartType.NORMALIZED_VERTICAL_BAR, ChartType.NORMALIZED_HORIZONTAL_BAR, ChartType.LINE, ChartType.AREA, 
                                 ChartType.AREA_STACKED, ChartType.AREA_NORMALIZED, ChartType.POLAR, ChartType.NUMBER_CARD, ChartType.TREE_MAP, ChartType.HEAT_MAP];

    public static STUN = 'stun:stun.l.google.com:19302';
    
    public static ON_PAGE = 50;
    public static ON_PAGE_LIST = 10; 
    public static ON_PAGE_ITEMS = 20;
}
