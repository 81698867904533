import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-comment',
    templateUrl: './comment.page.html',
    styleUrls: ['./comment.page.scss'],
})
export class CommentPage implements OnInit {
    @Input() value;
    @Input() msgIncome;
    @Input() msgSend;
    @Input() msgReject;
    type = 'text';
    ext = '';
    constructor() { }

    ngOnInit() {
        if (this.value && (this.value.startsWith('https://') || this.value.startsWith('http://'))) {
            const file = this.value.split('.');
            this.ext = file[file.length - 1];
            if (['png', 'jpeg', 'jpg', 'gif', 'svg'].indexOf(this.ext) !== -1) {
                this.type = 'image';
            } else if (this.ext === 'mov' || this.ext == 'mp4') {
                this.type = 'mov';
            } else {
                this.type = 'file';
            }
        }
        if (this.value) {
            this.value = this.value.trim();
        }
    }

    download() {
        window.open(this.value);
    }
}
