import { Equal, FieldType } from "./enums";
import { ProcessVersion } from "./processversion";

export enum ActionType {
    ADD,
	CHANGE,
	DELETE,
	CHOOSE,
	SAVE_LOCAL,
	SAVE_FROM,
	SEND_QR,
	HTTP_GET,
	HTTP_POST,
	HTTP_PATCH,
	SEND_INVOICE,
	ANSWER_SHIPPING_QUERY,
	ANSWER_PRE_CHECKOUT_QUERY,
	SEND_FILE,
    HTTP_PUT,
    HTTP_DELETE,
    ADD_FROM,
    CHANGE_FROM,
    SEND_STICKER
}

export enum MessageType {
    CALLBACK,
	TEXT,
	STICKER,
	FILE,
	IMAGE,
	CONTACT,
	SEND_INVOICE,
	ANSWER_SHIPPING_QUERY,
	ANSWER_PRE_CHECKOUT_QUERY,
	SUCCESSFUL_PAYMENT,
	LOCATION
}

export class GroupBotProcess {

    constructor(public id: number,
                public name: string,
                public version: ProcessVersion) {}

    static fromJson(d: any): GroupBotProcess {
        if (!d) {
            return null;
        }
        return new GroupBotProcess(d.id, d.name, ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return this.id
    }
}

export class Variable {
    static table = "variable";

    constructor(public id: number,
                public name: string,
                public sql: string,
                public version: ProcessVersion) {
    }

    static fromJson(d: any): Variable {
        if (!d) {
            return null
        }
        return new Variable(d.id ? d.id : d, d.name, d.sql, ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return this.id
    }
}

export class Translation {
    static table = "translation";

    constructor(public id: number,
                public name: string,
                public code: string,
                public version: ProcessVersion) {
    }

    static fromJson(d: any): Translation {
        if (!d) {
            return null
        }
        return new Translation(d.id ? d.id : d, d.name, d.code, ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return this.id
    }
}

export class BotProcessField {

    constructor(public name: string,
                public fieldFrom: string,
                public template: string,
                public variableId: Variable,
                public isRaw: boolean,
                public checkbox: boolean,
                public num: number,
                public text: string,
                public amount: number,
                public id: number,
                public uuid: string,
                public isHierarchy: boolean,
                public showOne: boolean,
                public verboseName: string,
                public type: FieldType,
                public ref: any,
                public choices: any,
                public templateLangId: Translation,
                public repr: string,
                public localVariable: string,
                public decimalInput: boolean) {

        if (this.choices && !this.choices.length) {
            this.choices = null;
        }
    }

    static fromJson(d: any): BotProcessField {
        return new BotProcessField(d.name, d.field_from, d.template, Variable.fromJson(d.variable_id), d.is_raw, d.checkbox, 
                                   d.num, d.text, d.amount, d.id, d.uuid, d.is_hierarchy, d.show_one, d.verbose_name, d.type, 
                                   d.ref, d.choices, Translation.fromJson(d.template_lang_id), d.repr, d.local_variable, d.decimal_input);
    }

    public serialize(): any {
        return {
            name: this.name,
            field_from: this.fieldFrom,
            template: this.template,
            variable_id: this.variableId ? this.variableId.serialize() : null,
            is_raw: this.isRaw,
            checkbox: this.checkbox,
            num: this.num,
            text: this.text,
            amount: this.amount,
            id: this.id,
            uuid: this.uuid,
            is_hierarchy: this.isHierarchy,
            show_one: this.showOne,
            verbose_name: this.verboseName,
            type: this.type,
            ref: this.ref,
            choices: this.choices,
            template_lang_id: this.templateLangId ? this.templateLangId.serialize() : null,
            repr: this.repr,
            local_variable: this.localVariable,
            decimal_input: this.decimalInput || false
        }
    }
}

export class WebApp {
    static table = "webapp";

    constructor(public id: number,
                public name: string,
                public body: string,
                public version: ProcessVersion) {
    }

    static fromJson(d: any): WebApp {
        if (!d) {
            return null;
        }
        return new WebApp(d.id ? d.id : d, d.name, d.body, ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return this.id
    }
} 

export class BotProcessButton {
    
    constructor(public text: string,
                public callbackData: string,
                public hide: boolean,
                public variableId: Variable,
                public textLangId: Translation,
                public webAppId: WebApp) {
    }

    static fromJson(d: any): BotProcessButton {
        return new BotProcessButton(d.text, d.callback_data, d.hide, Variable.fromJson(d.variable_id), Translation.fromJson(d.text_lang_id), WebApp.fromJson(d.web_app_id));
    }

    public serialize(): any {
        return {
            text: this.text,
            callback_data: this.callbackData,
            hide: this.hide,
            variable_id: this.variableId ? this.variableId.serialize() : null,
            text_lang_id: this.textLangId ? this.textLangId.serialize() : null,
            web_app_id: this.webAppId ? this.webAppId.serialize() : null
        }
    }
}

export class Bot {
    static table = 'bot';

    constructor(public id: number,
                public name: string) {}
    
    static fromJson(d: any): Bot {
        if (!d) {
            return null;
        }
        return new Bot(d.id, d.name);
    }

    public serialize(): any {
        return this.id
    }
}

export class BotProcess {

    static table = "botprocess";

    constructor(public id: number,
                public name: string,
                public group: GroupBotProcess,
                public left: number,
                public top: number,
                public comment: string,
                public actionType: ActionType,
                public table: string,
                public tableFrom: string,
                public fields: BotProcessField[],
                public msgText: string,
                public msgTextId: Variable,
                public buttons: BotProcessButton[],
                public isReply: boolean,
                public isBack: boolean,
                public isCancel: boolean,
                public command: string,
                public messageType: MessageType,
                public localVariable: string,
                public localVariableId: Variable,
                public skip: boolean,
                public skipBack: boolean,
                public clearData: boolean,
                public paginateButton: boolean,
                public sendToId: Variable,
                public deleteAfter: number,
                public objectId: Variable,
                public onPage: number,
                public variableModelId: Variable,
                public variableHttpId: Variable,
                public extraId: Variable,
                public botId: Bot,
                public msgTextLangId: Translation,
                public deleteKeyboard: boolean,
                public commandTranslateId: Translation,
                public userStatus: number,
                public isNew: boolean,
                public version: ProcessVersion,
                public leftNew?: number,
                public topNew?: number) {}

    static fromJson(d: any): BotProcess {
        return new BotProcess(
            d.id,
            d.name,
            GroupBotProcess.fromJson(d.group_id),
            d.left,
            d.top,
            d.comment,
            d.action_type,
            d.table,
            d.table_from,
            d.fields && d.fields.length ? d.fields.map(f => BotProcessField.fromJson(f)) : [],
            d.msg_text,
            Variable.fromJson(d.msg_text_id),
            d.buttons && d.buttons.length ? d.buttons.map(f => BotProcessButton.fromJson(f)) : [],
            d.is_reply,
            d.is_back,
            d.is_cancel,
            d.command,
            d.message_type,
            d.local_variable,
            Variable.fromJson(d.local_variable_id),
            d.skip,
            d.skip_back,
            d.clear_data,
            d.paginate_button,
            Variable.fromJson(d.send_to_id),
            d.delete_after,
            Variable.fromJson(d.object_id),
            d.on_page,
            Variable.fromJson(d.variable_model_id),
            Variable.fromJson(d.variable_http_id),
            Variable.fromJson(d.extra_id),
            Bot.fromJson(d.bot_id),
            Translation.fromJson(d.msg_text_lang_id),
            d.delete_keyboard || false,
            Translation.fromJson(d.command_translate_id),
            d.user_status,
            d.is_new,
            ProcessVersion.fromJson(d.version_id)
        );
    }

    public serialize(): any {
        return {
            id: this.id, 
            name: this.name,
            group_id: this.group ? this.group.serialize() : null,
            left: this.leftNew || this.left,
            top: this.topNew || this.top,
            comment: this.comment,
            action_type: this.actionType,
            table: this.table,
            table_from: this.tableFrom,
            fields: this.fields.map(f => f.serialize()),
            msg_text: this.msgText,
            msg_text_id: this.msgTextId ? this.msgTextId.serialize() : null,
            buttons: this.buttons.map(f => f.serialize()),
            is_reply: this.isReply,
            is_back: this.isBack,
            is_cancel: this.isCancel,
            command: this.command,
            message_type: this.messageType,
            local_variable: this.localVariable,
            local_variable_id: this.localVariableId ? this.localVariableId.serialize() : null,
            skip: this.skip,
            skip_back: this.skipBack,
            clear_data: this.clearData,
            paginate_button: this.paginateButton,
            send_to_id: this.sendToId ? this.sendToId.serialize() : null,
            delete_after: this.deleteAfter,
            object_id: this.objectId ? this.objectId.serialize() : null,
            on_page: this.onPage,
            variable_model_id: this.variableModelId ? this.variableModelId.serialize() : null,
            variable_http_id: this.variableHttpId ? this.variableHttpId.serialize() : null,
            extra_id: this.extraId ? this.extraId.serialize() : null,
            bot_id: this.botId ? this.botId.serialize() : null,
            is_group: false,
            msg_text_lang_id: this.msgTextLangId ? this.msgTextLangId.serialize() : null,
            delete_keyboard: this.deleteKeyboard,
            command_translate_id: this.commandTranslateId ? this.commandTranslateId.serialize() : null,
            user_status: this.userStatus || 0,
            is_new: this.isNew || false,
            version_id: this.version ? this.version.id : 0
        }
    }
}

export class BotProcessCondition {
    
    constructor(public equal: Equal,
                public type: FieldType,
                public variableText: string,
                public variableId: Variable,
                public checkbox: boolean,
                public num: number,
                public text: string,
                public amount: number,
                public id: number,
                public uuid: string,
                public ref: any,
                public choices: any,
                public table: any,
                public equals: Equal[] = []) {
        if (this.choices && !this.choices.length) {
            this.choices = null;
        }
        this.refreshEquals()
    }

    static fromJson(d: any): BotProcessCondition {
        return new BotProcessCondition(d.equal, d.type, d.variable_text, Variable.fromJson(d.variable_id), d.checkbox, d.num, d.text, d.amount, d.id, d.uuid, d.ref, d.choices, d.table);
    }

    public serialize(): any {
        return {
            equal: this.equal,
            type: this.type,
            variable_text: this.variableText,
            variable_id: this.variableId ? this.variableId.serialize() : null,
            checkbox: this.checkbox,
            num: this.num,
            text: this.text,
            amount: this.amount,
            id: this.id,
            uuid: this.uuid,
            ref: this.ref,
            choices: this.choices,
            table: this.table
        }
    }

    public refreshEquals() {
        switch (this.type) {
        case FieldType.BOOL:
            this.equals = [Equal.EQ];
            break;
        case FieldType.REF:
        case FieldType.CHAR:
            this.equals = [Equal.EQ, Equal.NOT];
            break;
        case FieldType.INT:
        case FieldType.DECIMAL:
            this.equals = [Equal.EQ, Equal.GT, Equal.GTE, Equal.LT, Equal.LTE, Equal.NOT];
            break;
        default:
            this.equals = [];
        }
    }
}

export class BotProcessBind {

    static table = "botprocessbind"

    constructor(public id: number,
                public fromId: GroupBotProcess,
                public toId: GroupBotProcess,
                public isOr: boolean,
                public condition: BotProcessCondition[],
                public version: ProcessVersion) {}

    static fromJson(d: any): BotProcessBind {
        return new BotProcessBind(d.id, GroupBotProcess.fromJson(d.from_id), GroupBotProcess.fromJson(d.to_id), d.is_or, 
            d.condition ? d.condition.map(f => BotProcessCondition.fromJson(f)) : [], ProcessVersion.fromJson(d.version_id));
    }

    public serialize(): any {
        return {
            from_id: this.fromId.serialize(),
            to_id: this.toId.serialize(),
            is_or: this.isOr,
            condition: this.condition.map(f => f.serialize()),
            version_id: this.version ? this.version.id : 0
        }
    }
}