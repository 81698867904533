import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FilterPage } from './filter.page';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

const routes: Routes = [
    {
        path: 'filter',
        component: FilterPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        DatePickerModule,
        DateTimePickerModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild()
    ],
    declarations: [FilterPage],
    exports: [FilterPage]
})
export class FilterPageModule {}
