import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'callback',
})
export class CallbackPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, val: any) => boolean, val: any): any {
        if (!items || !callback) {
            return items || [];
        }
        const v = items.filter(item => callback(item, val));
        return v;
    }
}
