import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from 'angular6-json-schema-form';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.page.html',
    styleUrls: ['./checkbox.page.scss'],
})
export class CheckboxPage implements OnInit {

    formControl: AbstractControl;
    controlName: string;
    controlValue: any;
    controlDisabled = false;
    boundControl = false;
    options: any;
    @Input() layoutNode: any;
    @Input() layoutIndex: number[];
    @Input() dataIndex: number[];

    constructor(private jsf: JsonSchemaFormService) {
    }

    ngOnInit() {
        this.options = this.layoutNode.options || {};
        this.jsf.initializeControl(this, !this.options.readonly);
    }

    updateValue(ev) {
        this.options.showErrors = true;
        this.jsf.updateValue(this, ev.target.value);
    }
}
