import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ButtonWidgetPage } from './button-widget.page';
import { TranslateModule } from '@ngx-translate/core';
import { ClassWidgetPageModule } from '../class-widget/class-widget.module';
import { StyleWidgetPageModule } from '../style-widget/style-widget.module';
import { ColorPickerFieldPageModule } from '../color-picker-field/color-picker-field.module';

const routes: Routes = [];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        ClassWidgetPageModule,
        StyleWidgetPageModule,
        ColorPickerFieldPageModule
    ],
    declarations: [ButtonWidgetPage],
    exports: [ButtonWidgetPage]
})
export class ButtonWidgetPageModule {}
