import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ImportFromExcelPage } from './import-from-excel.page';
import { TranslateModule } from '@ngx-translate/core';


const routes: Routes = [
    {
        path: 'form',
        component: ImportFromExcelPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule
    ],
    declarations: [ImportFromExcelPage]
})
export class ImportFromExcelPageModule {}
