import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { JsonSchemaFormService } from 'angular6-json-schema-form';
import { ChoicesPage } from 'src/app/choices/choices.page';

@Component({
    selector: 'app-foreign',
    templateUrl: './foreign.page.html',
    styleUrls: ['./foreign.page.scss'],
})
export class ForeignPage implements OnInit {

    formControl: AbstractControl;
    controlName: string;
    controlValue: any;
    controlDisabled = false;
    boundControl = false;
    options: any;
    @Input() layoutNode: any;
    @Input() layoutIndex: number[];
    @Input() dataIndex: number[];
    repr = '';

    constructor(private jsf: JsonSchemaFormService,
                private modalCtrl: ModalController) {
    }

    ngOnInit() {
        this.options = this.layoutNode.options || {};
        if (this.options && this.options.repr) {
            this.repr = this.options.repr;
        }
        this.jsf.initializeControl(this, !this.options.readonly);
    }

    updateValue(event) {
        this.options.showErrors = true;
        this.jsf.updateValue(this, event.target.value);
    }

    async chooseFieldItem() {
        const modal = await this.modalCtrl.create({
            component: ChoicesPage,
            componentProps: {
                model: this.options.model,
                selected: this.controlValue, 
            },
            showBackdrop: false
        });
        modal.onDidDismiss().then((details: any) => {
            if (details && details.data && details.data.item) {
                const value = {};
                for (const f of (this.options.fields || '').split(',')) {
                    value[f.trim()] = details.data.item[f.trim()];
                }
                this.updateValue({target: {value}});
            }
        });
        return await modal.present();
    }
    
    clearField() {
        this.updateValue({target: {value: null}});
    }
}
