import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'app-sub-menu',
    templateUrl: './sub-menu.page.html',
    styleUrls: ['./sub-menu.page.scss'],
})
export class SubMenuPage implements OnInit {

    @Input() buttons = [];

    constructor(private popoverCtrl: PopoverController) { }

    ngOnInit() {
    }

    click(btn) {
        this.popoverCtrl.dismiss({btn});
    }
}
