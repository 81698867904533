import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrderBy } from 'src/app/classes/board-order-by';

@Component({
    selector: 'app-order-by',
    templateUrl: './order-by.page.html',
    styleUrls: ['./order-by.page.scss'],
})
export class OrderByPage implements OnInit {

    @Input() orderBy: OrderBy[] = [];
    @Input() showHeader = false;

    constructor(private popoverCtrl: PopoverController) { }

    ngOnInit() {
    }

    reorderOrderBy(ev) {
        const itemToMove = this.orderBy.splice(ev.detail.from, 1)[0];
        this.orderBy.splice(ev.detail.to, 0, itemToMove);
        ev.target.complete();
    }

    closePopover() {
        this.popoverCtrl.dismiss();
    }
}
