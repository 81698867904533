import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { JsonSchemaFormService } from 'angular6-json-schema-form';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-date',
    templateUrl: './date.page.html',
    styleUrls: ['./date.page.scss'],
})
export class DatePage implements OnInit {

    formControl: AbstractControl;
    controlName: string;
    controlValue: any;
    controlDisabled = false;
    boundControl = false;
    options: any;
    @Input() layoutNode: any;
    @Input() layoutIndex: number[];
    @Input() dataIndex: number[];

    constructor(private jsf: JsonSchemaFormService,
                private base: BaseService) {
    }

    ngOnInit() {
        this.options = this.layoutNode.options || {};
        this.jsf.initializeControl(this, !this.options.readonly);
    }

    updateValue(ev) {
        this.options.showErrors = true;
        this.jsf.updateValue(this, this.base.dateObjToISO(ev.value));
    }
}
