import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';

@Component({
    selector: 'app-datetime-widget',
    templateUrl: './datetime-widget.page.html',
    styleUrls: ['./datetime-widget.page.scss'],
})
export class DatetimeWidgetPage implements OnInit {

    @ViewChild('calendar') calendar: any;
    @Input() item: any;
    @Input() itemStyle: any;
    @Input() itemClass: any;
    @Input() open = true;
    focusField = false;
    val: any;
    changed = false;

    constructor(private base: BaseService) { }

    ngOnInit() {
        this.item.val2 = this.base.copy(this.item.val) ? this.item.val : null;
        if (this.open) {
            setTimeout(() => {
                this.calendar.show();
            }, 500);
        }
    }

    timeChanged(ev) {
        if (ev.value) {
            this.item.val = (ev.value ? this.base.toDate(ev.value) : '') || '';
        } else if (ev.target) {
            this.item.val = ev.target.value;
        } else {
            this.item.val = null;
        }
    }
}
